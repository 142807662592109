import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { DateTime } from 'luxon'
import { DatePicker } from '@material-ui/pickers'

import {
  Button,
  TextField,
  MenuItem,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'

import {
  formatCurrency,
  snakeToTitleCase,
  pluralize,
  getRRuleSetFromValue,
} from '../../../../../shared/helperfunctions'
import {
  CREATE_PROPOSAL_ITEM,
  GET_PROPOSAL_ITEMS,
} from '../../../../../util/apollo/queries/proposalItems'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  content: {
    padding: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
})

function getFirstAvailableDate(datesAvailable) {
  if (!datesAvailable) {
    return DateTime.local().toFormat('yyyy-MM-dd')
  }
  const rrule = getRRuleSetFromValue(datesAvailable)
  const firstDate = rrule.after(new Date(), true)
  return DateTime.fromISO(firstDate).toFormat('yyyy-MM-dd')
}

const PurchaseBox = ({
  classes,
  productSizes,
  campaign,
  history,
  match,
  match: {
    params: { campaignuuid },
  },
}) => {
  const [adType, setAdType] = useState('0')
  //const [adTypeIndex, setAdTypeIndex] = useState('')
  const [adQuantityIndex, setAdQuantityIndex] = useState(0)
  const [eventType, setEventType] = useState('')
  const [preferredStartDate, setPreferredStartDate] = useState(
    getFirstAvailableDate(productSizes.edges[adType].datesAvailable)
  )
  const [datePreferences, setDatePreferences] = useState('')
  const [comments, setComments] = useState('')
  const [adQuantityArray, setAdQuantityArray] = useState(
    Array.from(
      Array(
        Math.floor(
          Math.abs(
            (productSizes.edges[0].buyMax - productSizes.edges[0].buyMin) /
              productSizes.edges[0].buyIncrement
          )
        ) + 1
      ),
      (x, index) =>
        productSizes.edges[0].buyMin +
        index * productSizes.edges[0].buyIncrement
    )
  )

  const [adQuantity, setAdQuantity] = useState(
    Array.from(
      Array(
        Math.floor(
          Math.abs(
            (productSizes.edges[0].buyMax - productSizes.edges[0].buyMin) /
              productSizes.edges[0].buyIncrement
          )
        ) + 1
      ),
      (x, index) =>
        productSizes.edges[0].buyMin +
        index * productSizes.edges[0].buyIncrement
    )[0]
  )

  const [total, setTotal] = useState(
    productSizes.edges[0].setupFee +
      Array.from(
        Array(
          Math.floor(
            Math.abs(
              (productSizes.edges[0].buyMax - productSizes.edges[0].buyMin) /
                productSizes.edges[0].buyIncrement
            )
          ) + 1
        ),
        (x, index) =>
          productSizes.edges[0].buyMin +
          index * productSizes.edges[0].buyIncrement
      )[0] *
        productSizes.edges[0].pricePerUnit
  )

  let adQuantityArrayLength = 0

  const calculateTotalCost = (index, quantity) => {
    const totalCost =
      productSizes.edges[index].setupFee +
      quantity * productSizes.edges[index].pricePerUnit
    setTotal(totalCost)
  }

  const clickOnAdQuantity = e => {
    setAdQuantity(adQuantityArray[e.target.value])
    setAdQuantityIndex(e.target.value)
    calculateTotalCost(adType, adQuantityArray[e.target.value])
  }

  const clickOnAdType = e => {
    const adType = e.target.value
    setAdType(adType)
    calculateTotalCost(adType, adQuantity)
    adQuantityArrayLength =
      Math.floor(
        Math.abs(
          (productSizes.edges[adType].buyMax -
            productSizes.edges[adType].buyMin) /
            productSizes.edges[adType].buyIncrement
        )
      ) + 1
    setAdQuantityArray(
      Array.from(
        Array(adQuantityArrayLength),
        (x, index) =>
          productSizes.edges[adType].buyMin +
          index * productSizes.edges[adType].buyIncrement
      )
    )
    setAdQuantityIndex(0)
    setAdQuantity(
      Array.from(
        Array(adQuantityArrayLength),
        (x, index) =>
          productSizes.edges[adType].buyMin +
          index * productSizes.edges[adType].buyIncrement
      )[0]
    )
    setPreferredStartDate(
      getFirstAvailableDate(productSizes.edges[adType].datesAvailable)
    )
  }

  const submitHandler = mutation => () => {
    const variables = {
      campaign: campaign,
      productSize: productSizes.edges[adType].uuid,
      quantity: adQuantity,
      preferredStartDate: preferredStartDate + 'T12:00:00',
      datePreferenceNotes: datePreferences,
      otherPreferenceNotes: comments,
    }
    mutation({
      variables,
      refetchQueries: [
        {
          query: GET_PROPOSAL_ITEMS,
          variables: {
            campaignuuid,
            status: 'in',
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data.createProposalitem.ok)
          history.push(
            match.url
              .split('/')
              .filter((_, i) => i < 3)
              .join('/') + '/proposal'
          )
      })
      .catch(() => null)
  }
  return (
    <Paper className={classes.content}>
      <Typography variant="h6">{productSizes.edges[adType].title}</Typography>
      <Typography>{productSizes.edges[adType].description}</Typography>
      <Typography variant="subtitle1" color="primary">
        {formatCurrency(total)}
      </Typography>
      <TextField
        select
        required
        label="Ad Type"
        value={adType}
        variant="outlined"
        margin="normal"
        className={classes.textField}
        onChange={e => clickOnAdType(e)}
      >
        {productSizes.edges.map((item, index) => (
          <MenuItem key={index} value={index}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        required
        label="Ad Quantity"
        value={adQuantityIndex}
        variant="outlined"
        margin="normal"
        className={classes.textField}
        onChange={e => clickOnAdQuantity(e)}
      >
        {adQuantityArray.map((item, index) => (
          <MenuItem key={index} value={index}>
            {item}{' '}
            {pluralize(
              snakeToTitleCase(productSizes.edges[adType].buyUnit),
              item
            )}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        required
        label="Event Type"
        value={eventType}
        variant="outlined"
        margin="normal"
        className={classes.textField}
        onChange={e => setEventType(e.target.value)}
      >
        <MenuItem value={'Brand Awareness'}>Brand Awareness</MenuItem>
        <MenuItem value={'Event Promotion'}>Event Promotion</MenuItem>
      </TextField>
      <DatePicker
        label="Preferred Start Date"
        required
        margin="normal"
        value={new DateTime.fromISO(preferredStartDate)}
        minDate={new DateTime.utc()}
        format="fullDate"
        shouldDisableDate={date => {
          if (!productSizes.edges[adType].datesAvailable) {
            return false
          }

          const rrule = getRRuleSetFromValue(
            productSizes.edges[adType].datesAvailable
          )
          const afterDate = date
            .startOf('day')
            .toUTC()
            .toJSDate()
          const beforeDate = date
            .endOf('day')
            .toUTC()
            .toJSDate()

          return rrule.between(afterDate, beforeDate, true).length === 0
        }}
        onChange={date => setPreferredStartDate(date)}
      />
      <TextField
        label="Date Preferences"
        value={datePreferences}
        className={classes.textField}
        margin="normal"
        required
        multiline
        rows="4"
        variant="outlined"
        onChange={e => setDatePreferences(e.target.value)}
      />
      <TextField
        label="Other Comments"
        value={comments}
        className={classes.textField}
        margin="normal"
        required
        multiline
        rows="4"
        variant="outlined"
        onChange={e => setComments(e.target.value)}
      />
      <Mutation
        mutation={CREATE_PROPOSAL_ITEM}
        refetchQueries={[
          {
            query: GET_PROPOSAL_ITEMS,
            variables: {
              campaignUuid: campaign,
            },
          },
        ]}
      >
        {(addProposalItem, { error }) => {
          if (error) alert(error)
          return (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={submitHandler(addProposalItem)}
            >
              Add to Proposal
            </Button>
          )
        }}
      </Mutation>
    </Paper>
  )
}

PurchaseBox.propTypes = {
  classes: PropTypes.object,
  productSizes: PropTypes.object,
  campaign: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(PurchaseBox)
