import React from 'react'
import { withStyles, Grid } from '@material-ui/core'
import { PropTypes } from 'prop-types'

import Summary from './Summary'
import AlertTable from './AlertTable'

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#f5f7fa',
  },
})

const Overview = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Summary />
        </Grid>
        <Grid item xs={12}>
          <AlertTable classes={classes} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Overview)

Overview.propTypes = {
  classes: PropTypes.object,
}
