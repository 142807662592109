import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withStyles, Paper, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import EditDeleteMenu from './EditDeleteMenu'

const styles = theme => ({
  product: {
    display: 'flex',
    alignItems: 'center',
    height: 72,
    padding: `0 ${theme.spacing(3)}px`,
  },
  productDetails: {
    width: '100%',
  },
  productDetailsContent: {
    padding: theme.spacing(2),
  },
  productHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const ProductItem = ({ classes, product, refetchQueries, companyUUID }) => {
  return (
    <Paper className={classes.product} variant="outlined">
      <div className={classes.productHeader}>
        <Typography variant="h6">{product.title}</Typography>
        <EditDeleteMenu
          uuid={product.uuid}
          refetchQueries={refetchQueries}
          companyUUID={companyUUID}
        />
      </div>
    </Paper>
  )
}

ProductItem.propTypes = {
  classes: PropTypes.object,
  product: PropTypes.object,
  refetchQueries: PropTypes.array,
  companyUUID: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withRouter
)(ProductItem)
