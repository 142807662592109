import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

const BackButton = ({ buttonProps, className, history, link }) => {
  const goBack = () => (link ? history.push(link) : history.goBack())
  return (
    <Button
      className={className}
      variant="contained"
      style={{ backgroundColor: '#fff' }}
      onClick={goBack}
      {...buttonProps}
    >
      Back
    </Button>
  )
}

BackButton.propTypes = {
  buttonProps: PropTypes.object,
  className: PropTypes.string,
  history: PropTypes.object,
  link: PropTypes.string,
}

export default withRouter(BackButton)
