/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import logo from '../../../assets/logos/Logo@3x.png'
import Notifications from './Notifications'
import LogoutMenu from './LogoutMenu'
import CampaignsMenu from './CampaignsMenu'

const height = 64
const styles = theme => ({
  accountCircleIcon: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
  },
  accountNameContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(1),
  },
  appBar: {
    background: '#f7f7f7',
    color: '#144256',
    width: '100%',
    flexShrink: 0,
  },
  button: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    fontSize: 12,
    marginLeft: theme.spacing(4),
    minWidth: 300,
    maxWidth: '100%',
  },
  filler: {
    height,
    width: '100%',
  },
  pushToEnd: {
    marginLeft: 'auto',
    marginRight: 8,
  },
  logo: {
    width: 120,
  },
})

const NavigationHeader = ({ classes, history }) => {
  return (
    <AppBar possition="static" className={classes.appBar}>
      <Toolbar>
        <img src={logo} className={classes.logo} alt="PlaceIt" />
        <CampaignsMenu />
        <div className={classes.pushToEnd}>
          <Notifications />
          <LogoutMenu history={history} />
        </div>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = state => ({
  campaigns: state.campaigns,
})

export { height }
export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(styles)
)(NavigationHeader)

NavigationHeader.propTypes = {
  classes: PropTypes.object,
  campaigns: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  userType: PropTypes.string,
}
