import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-toolbar.css'
import 'react-mde/lib/styles/css/react-mde-editor.css'
import 'react-mde/lib/styles/css/react-mde.css'
import { FormLabel } from '@material-ui/core'
import MarkdownDisplay from '../MarkdownDisplay'

export default function RichTextEditor({ name, label, value, onChange }) {
  const [tab, setTab] = useState('write')
  return (
    <>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ReactMde
        key={name}
        textAreaProps={{
          id: name,
          name: name,
        }}
        value={value}
        onChange={onChange}
        selectedTab={tab}
        onTabChange={setTab}
        generateMarkdownPreview={async markdown => (
          <MarkdownDisplay markdown={markdown} />
        )}
      />
    </>
  )
}

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
