import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, Box, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import RRule from 'rrule'

export default function Rule({ value, type, onDelete, deleteEnabled }) {
  switch (type) {
    case 'rrule': {
      return (
        <ListItem>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box fontStyle="italic">{value.toText()}</Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={onDelete}
              disabled={!deleteEnabled}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Box>
        </ListItem>
      )
    }
  }
}

Rule.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(RRule).isRequired])
    .isRequired,
  type: PropTypes.oneOf(['rrule']).isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
}
