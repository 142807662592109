import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'

import { DUPLICATE_CONTRACT } from '../../../../../../util/apollo/queries/contracts'
import { withNotifications } from '../../../../../../util'

const styles = () => ({
  root: {},
})

const Component = ({
  // eslint-disable-next-line no-unused-vars
  classes,
  match: {
    params: { contractuuid },
  },
  notify,
}) => {
  const [mutation] = useMutation(DUPLICATE_CONTRACT)

  const duplicateHandler = () => {
    mutation({ variables: { contractuuid } })
      .then(({ data }) => {
        if (data.duplicateContract.ok) notify.success('Duplicated')
      })
      .catch(() => null)
  }

  return (
    <Tooltip title="Duplicate">
      <IconButton onClick={duplicateHandler}>
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object,
}

export default compose(
  withNotifications,
  withRouter,
  withStyles(styles)
)(Component)
