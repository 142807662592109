import gql from 'graphql-tag'

const GET_PRODUCTS = gql`
  query(
    $mediaType_In: String
    $sponsored: Boolean
    $minCost: Float
    $maxCost: Float
    $geoLocation: String
    $minImpressions: Int
    $maxImpressions: Int
    $ageFocus: String
    $genderFocus: String
    $cpmUpper: Float
    $cpmLower: Float
    $campaign: String!
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allProducts(
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
      status: "active"
      campaign: $campaign
      mediaType_In: $mediaType_In
      productSize_SponsoredContent: $sponsored
      productSize_PurchaseMin_Gte: $minCost
      productSize_PurchaseMin_Lte: $maxCost
      geolocation: $geoLocation
      productSize_ImpressionsMin_Gte: $minImpressions
      productSize_ImpressionsMin_Lte: $maxImpressions
      ageFocus_In: $ageFocus
      genderFocus_In: $genderFocus
      productSize_CostPerThousand_Lte: $cpmUpper
      productSize_CostPerThousand_Gte: $cpmLower
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          uuid
          title
          description
          mediaType
          purchaseMin
          starred
          fileItem {
            edges {
              node {
                id
                uuid
                type
              }
            }
          }
        }
      }
    }
  }
`

const GET_PRODUCTS_SINGULAR = gql`
  query($uuid: UUID, $campaign: String!) {
    allProducts(uuid: $uuid, campaign: $campaign) {
      edges {
        node {
          id
          uuid
          title
          description
          mediaType
          purchaseMin
          starred
        }
      }
    }
  }
`

const GET_PRODUCT_DETAIL = gql`
  query($productUUID: UUID!, $campaign: String) {
    allProducts(uuid: $productUUID, campaign: $campaign) {
      edges {
        node {
          id
          uuid
          title
          description
          demographic
          mediaType
          contentRestriction
          priceDescription
          status
          ageFocus
          genderFocus
          logistics
          starred
          productLocation {
            edges {
              node {
                id
                latitude
                longitude
                geojson
                address
              }
            }
          }
          contact {
            edges {
              node {
                id
                uuid
              }
            }
          }
          productSize {
            edges {
              node {
                uuid
                title
                description
                pricePerUnit
                buyMin
                buyMax
                buyUnit
                buyIncrement
                setupFee
                status
                datesAvailable
              }
            }
          }
          fileItem {
            edges {
              node {
                id
                uuid
                originalFileName
                savedFileName
                type
                description
                source
              }
            }
          }
        }
      }
    }
  }
`

const GET_PRODUCT_INFO_FOR_SELLER = gql`
  query($productUUID: UUID!, $campaign: String) {
    allProducts(uuid: $productUUID, campaign: $campaign) {
      edges {
        node {
          uuid
          title
          description
          demographic
          mediaType
          contentRestriction
          priceDescription
          status
          ageFocus
          genderFocus
          logistics
          productLocation {
            edges {
              node {
                address
              }
            }
          }
          contact {
            edges {
              node {
                uuid
              }
            }
          }
        }
      }
    }
  }
`

const GET_PRODUCT_INFO_EDITMODE = gql`
  query($uuid: UUID!, $campaign: String!) {
    allProducts(uuid: $uuid, campaign: $campaign) {
      edges {
        node {
          title
          description
          demographic
          mediaType
          contentRestriction
          priceDescription
          status
          ageFocus
          genderFocus
          productLocation {
            edges {
              node {
                uuid
                address
              }
            }
          }
          fileItem {
            edges {
              node {
                id
                type
                uuid
                savedFileName
                description
              }
            }
          }
          productSize {
            edges {
              node {
                uuid
                title
                description
                pricePerUnit
                buyMin
                buyMax
                buyIncrement
                impressionsPerUnit
                setupFee
                buyUnit
                datesAvailable
              }
            }
          }
        }
      }
    }
  }
`

const GET_PRODUCT_SIZES = gql`
  query($productUuid: UUID, $status: String) {
    allProductsizes(product_Uuid: $productUuid, status: $status) {
      edges {
        node {
          uuid
          title
          description
          pricePerUnit
          buyMin
          buyMax
          buyUnit
          buyIncrement
          setupFee
          status
        }
      }
    }
  }
`

const GET_PRODUCT_SIZES_FROM_PROPOSALITEM = gql`
  query($uuid: UUID!) {
    allProposalitems(uuid: $uuid) {
      edges {
        node {
          productSize {
            product {
              productSize {
                edges {
                  node {
                    uuid
                    title
                    buyMin
                    buyMax
                    buyIncrement
                    buyUnit
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const GET_PRODUCT_SIZE = gql`
  query($uuid: UUID) {
    allProductsizes(uuid: $uuid) {
      edges {
        node {
          title
          description
          pricePerUnit
          buyMin
          buyMax
          buyUnit
          buyIncrement
          setupFee
          status
          impressionsPerUnit
          sponsoredContent
          datesAvailable
        }
      }
    }
  }
`

const GET_STARRED_PRODUCTS = gql`
  query(
    $campaign: UUID!
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allSavedproducts(
      campaign_Uuid: $campaign
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          uuid
          product {
            id
            uuid
            title
            description
            mediaType
            purchaseMin
            starred
            fileItem {
              edges {
                node {
                  id
                  uuid
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`

const ADD_STARRED_PRODUCT = gql`
  mutation($product: String, $campaign: String) {
    createSavedproducts(input: { product: $product, campaign: $campaign }) {
      ok
    }
  }
`

const REMOVE_STARRED_PRODUCT = gql`
  mutation($product: String, $campaign: String) {
    deleteSavedproducts(input: { product: $product, campaign: $campaign }) {
      ok
    }
  }
`

const ADD_PRODUCT_FILE = gql`
  mutation($fileUUID: [String], $productUUID: String) {
    updateProduct(input: { uuid: $productUUID, addFileItem: $fileUUID }) {
      ok
    }
  }
`

const UPDATE_PRODUCT = gql`
  mutation(
    $productUUID: String
    $title: String
    $description: String
    $demographic: String
    $mediaType: String
    $contentRestriction: String
    $priceDescription: String
    $ageFocus: String
    $genderFocus: String
    $logistics: String
    $resetContactList: [String]
    $productLocationString: String
  ) {
    updateProduct(
      input: {
        uuid: $productUUID
        title: $title
        logistics: $logistics
        description: $description
        demographic: $demographic
        mediaType: $mediaType
        contentRestriction: $contentRestriction
        priceDescription: $priceDescription
        ageFocus: $ageFocus
        genderFocus: $genderFocus
        resetContact: $resetContactList
        productLocationString: $productLocationString
      }
    ) {
      ok
    }
  }
`

const GET_SELLER = gql`
  query($productUUID: UUID) {
    allProducts(uuid: $productUUID) {
      edges {
        node {
          seller {
            uuid
          }
        }
      }
    }
  }
`

const GET_SELLERS_PRODUCTS = gql`
  query($companyUUID: UUID) {
    allProducts(seller_Uuid: $companyUUID, status: "active") {
      edges {
        node {
          uuid
          title
          mediaType
        }
      }
    }
  }
`

const DELETE_PRODUCT = gql`
  mutation($uuid: String) {
    updateProduct(input: { uuid: $uuid, status: "inactive" }) {
      ok
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation(
    $title: String
    $description: String
    $demographic: String
    $mediaType: String
    $contentRestriction: String
    $priceDescription: String
    $ageFocus: String
    $genderFocus: String
    $logistics: String
    $seller: String
    $resetContactList: [String]
    $productLocationString: String
  ) {
    createProduct(
      input: {
        ageFocus: $ageFocus
        title: $title
        description: $description
        demographic: $demographic
        mediaType: $mediaType
        contentRestriction: $contentRestriction
        priceDescription: $priceDescription
        logistics: $logistics
        seller: $seller
        genderFocus: $genderFocus
        resetContact: $resetContactList
        productLocationString: $productLocationString
      }
    ) {
      ok
      product {
        uuid
      }
    }
  }
`

const GET_PRODUCT_TEMPLATES = gql`
  query($uuid: UUID) {
    allProducts(uuid: $uuid) {
      edges {
        node {
          fileItem {
            edges {
              node {
                id
                type
                uuid
                originalFileName
                description
              }
            }
          }
        }
      }
    }
  }
`

const DELETE_FILEITEM = gql`
  mutation($productUUID: String, $fileItemArray: [String]) {
    updateProduct(
      input: { uuid: $productUUID, removeFileItem: $fileItemArray }
    ) {
      ok
    }
  }
`

const UPDATE_PRODUCT_SIZE = gql`
  mutation(
    $uuid: String
    $title: String
    $description: String
    $sponsoredContent: Boolean
    $pricePerUnit: Float
    $impressionPerUnit: Int
    $buyMin: Int
    $buyMax: Int
    $buyUnit: String
    $buyIncrement: Int
    $setupFee: Float
    $datesAvailable: String
  ) {
    updateProductsize(
      input: {
        uuid: $uuid
        title: $title
        description: $description
        sponsoredContent: $sponsoredContent
        pricePerUnit: $pricePerUnit
        impressionsPerUnit: $impressionPerUnit
        buyMin: $buyMin
        buyMax: $buyMax
        buyUnit: $buyUnit
        buyIncrement: $buyIncrement
        setupFee: $setupFee
        datesAvailable: $datesAvailable
      }
    ) {
      ok
    }
  }
`

const CREATE_PRODUCT_SIZE = gql`
  mutation(
    $title: String
    $description: String
    $sponsoredContent: Boolean
    $pricePerUnit: Float
    $impressionsPerUnit: Int
    $buyMin: Int
    $buyMax: Int
    $buyUnit: String
    $buyIncrement: Int
    $setupFee: Float
    $productUUID: String
    $datesAvailable: String
  ) {
    createProductsize(
      input: {
        title: $title
        description: $description
        sponsoredContent: $sponsoredContent
        pricePerUnit: $pricePerUnit
        impressionsPerUnit: $impressionsPerUnit
        buyMin: $buyMin
        buyMax: $buyMax
        buyUnit: $buyUnit
        buyIncrement: $buyIncrement
        setupFee: $setupFee
        product: $productUUID
        datesAvailable: $datesAvailable
      }
    ) {
      ok
    }
  }
`

const DELETE_PRODUCT_SIZE = gql`
  mutation($uuid: String, $status: String) {
    updateProductsize(input: { uuid: $uuid, status: $status }) {
      ok
    }
  }
`

export {
  ADD_STARRED_PRODUCT,
  ADD_PRODUCT_FILE,
  GET_PRODUCT_INFO_EDITMODE,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_INFO_FOR_SELLER,
  GET_PRODUCT_SIZES,
  GET_PRODUCT_SIZE,
  GET_SELLER,
  GET_STARRED_PRODUCTS,
  GET_PRODUCTS,
  REMOVE_STARRED_PRODUCT,
  UPDATE_PRODUCT,
  GET_SELLERS_PRODUCTS,
  DELETE_PRODUCT,
  CREATE_PRODUCT,
  GET_PRODUCT_TEMPLATES,
  DELETE_FILEITEM,
  UPDATE_PRODUCT_SIZE,
  DELETE_PRODUCT_SIZE,
  CREATE_PRODUCT_SIZE,
  GET_PRODUCTS_SINGULAR,
  GET_PRODUCT_SIZES_FROM_PROPOSALITEM,
}
