import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Paper,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useMutation, useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'

import { withNotifications } from '../../../../util'
import {
  GET_CLIENT_USERS,
  ADD_USER,
} from '../../../../util/apollo/queries/clients'
import { AutoSuggestion } from '../../../../shared/components'
import { GET_COMPANY_USERS } from '../../../../util/apollo/queries/companies'

import DeleteButton from './DeleteButton'

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(3),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  table: {
    paddingTop: theme.spacing(3),
  },
})

const ManageUsers = ({ classes, notify }) => {
  const { clientuuid } = useParams()
  const [autocompleteValue, setAutocompleteValue] = useState({})
  const getCompanyUsersResult = useQuery(GET_COMPANY_USERS)
  const getClientUsersResult = useQuery(GET_CLIENT_USERS, {
    variables: { clientuuid },
  })
  const [addUser] = useMutation(ADD_USER, {
    variables: { userList: autocompleteValue.value, clientuuid },
    refetchQueries: [{ query: GET_CLIENT_USERS, variables: { clientuuid } }],
    onCompleted(data) {
      if (data.updateClient.ok)
        notify.success('Added ' + autocompleteValue.label)
    },
  })
  if (clientuuid === 'new') return null

  if (getCompanyUsersResult.loading || getClientUsersResult.loading)
    return 'Loading...'
  if (getCompanyUsersResult.error || getClientUsersResult.error) return 'Error'
  const companyUsers = getCompanyUsersResult.data.allUsers.edges.map(
    ({ node }) => node
  )
  const clientUsers = getClientUsersResult.data.allClients.edges[0].node.user
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Manage Users</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={!(autocompleteValue && autocompleteValue.value)}
          onClick={addUser}
        >
          Add
        </Button>
      </div>
      <Divider />
      <div className={classes.content}>
        <AutoSuggestion
          className={classes.autoSuggestion}
          options={companyUsers.map(({ uuid, email }) => ({
            value: uuid,
            label: email,
          }))}
          value={autocompleteValue}
          setValue={setAutocompleteValue}
          selectProps={{
            placeholder: 'Select User',
          }}
          attachAbove
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {clientUsers.map(({ uuid, email, firstName, lastName }) => (
              <TableRow key={uuid}>
                <TableCell>{email}</TableCell>
                <TableCell>{firstName}</TableCell>
                <TableCell>{lastName}</TableCell>
                <TableCell padding="none">
                  <DeleteButton useruuid={uuid} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

ManageUsers.propTypes = {
  classes: PropTypes.object,
  notify: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withNotifications
)(ManageUsers)
