import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'

import { CREATE_USER } from '../../../util/apollo/queries/user'
import { oClient } from '../../../util/apollo/client'
import { withNotifications } from '../../../util'
import { validateEmail } from '../../../shared/helperfunctions'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: 400,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  submitButton: {
    letterSpacing: 0.5,
    fontWeight: 700,
    margin: `${theme.spacing(2)}px 0`,
    width: '100%',
  },
  submitText: {
    color: theme.palette.primary.contrastText,
  },
})

const Register = ({ classes, history, notify }) => {
  const [error, setError] = useState(false)
  const [userDetails, setUserDetails] = useState({
    password: '',
    password2: '',
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
    phone: '',
    email: '',
  })

  useEffect(() => {
    if (userDetails.email.length && !validateEmail(userDetails.email)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [userDetails.email])

  const handleChange = name => event => {
    setUserDetails({ ...userDetails, [name]: event.target.value })
  }

  const createUser = e => {
    e.preventDefault()
    oClient
      .mutate({
        mutation: CREATE_USER,
        variables: {
          password: userDetails.password,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          addressLine1: userDetails.addressLine1,
          addressLine2: userDetails.addressLine2,
          addressCity: userDetails.addressCity,
          addressState: userDetails.addressState,
          addressZip: userDetails.addressZip,
          phone: userDetails.phone,
          email: userDetails.email,
        },
      })
      .then(({ data }) => {
        if (data.createUser.ok) {
          notify.success(
            'Your account has successfully been created.\n' +
              'Please login with your username and password'
          )
          history.push('/auth/login')
        }
      })
      .catch(() => null)
  }

  return (
    <Paper className={classes.root}>
      <form onSubmit={createUser}>
        <div className={classes.header}>
          <Typography variant="h5">Create New Account</Typography>
        </div>
        {error && (
          <p>
            No company account found with that domain. To contact sales,{' '}
            <a href="#">click here</a>.
          </p>
        )}
        <TextField
          type="email"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          label="Enter your email"
          value={userDetails.email}
          required
          fullWidth
          onChange={handleChange('email')}
        />
        <TextField
          label="Password"
          type="password"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          value={userDetails.password}
          required
          fullWidth
          onChange={handleChange('password')}
        />
        <TextField
          label="Confirm Password"
          type="password"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          value={userDetails.password2}
          required
          fullWidth
          onChange={handleChange('password2')}
        />
        <TextField
          label="First Name"
          variant="outlined"
          className={classes.textField}
          value={userDetails.firstName}
          onChange={handleChange('firstName')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="Last Name"
          variant="outlined"
          className={classes.textField}
          value={userDetails.lastName}
          onChange={handleChange('lastName')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="Street Address 1"
          variant="outlined"
          className={classes.textField}
          value={userDetails.addressLine1}
          onChange={handleChange('addressLine1')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="Street Address 2"
          variant="outlined"
          className={classes.textField}
          value={userDetails.addressLine2}
          onChange={handleChange('addressLine2')}
          margin="normal"
          fullWidth
        />
        <TextField
          label="City"
          variant="outlined"
          className={classes.textField}
          value={userDetails.addressCity}
          onChange={handleChange('addressCity')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="State"
          variant="outlined"
          className={classes.textField}
          value={userDetails.addressState}
          onChange={handleChange('addressState')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="zipcode"
          variant="outlined"
          className={classes.textField}
          value={userDetails.addressZip}
          onChange={handleChange('addressZip')}
          margin="normal"
          required
          fullWidth
        />
        <TextField
          label="phone"
          variant="outlined"
          className={classes.textField}
          value={userDetails.phone}
          onChange={handleChange('phone')}
          margin="normal"
          required
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          <Typography variant="subtitle1" className={classes.submitText}>
            Create Account
          </Typography>
        </Button>
        <Divider />
        <div className={classes.footer}>
          <Button onClick={history.goBack}>Back</Button>
        </div>
      </form>
    </Paper>
  )
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object,
  history: PropTypes.object,
  notify: PropTypes.object,
}

export default compose(
  withNotifications,
  withStyles(styles)
)(Register)

Register.propTypes = {
  match: PropTypes.object,
}
