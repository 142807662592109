import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { ApolloProvider } from 'react-apollo'
import { StripeProvider } from 'react-stripe-elements'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

import { rClient } from './util/apollo/client'
import history from './util/router/history'
import stripeConfig from './util/stripe'
import { store, persistor } from './util/redux'
import { Notifications } from './util/notifications'

import App from './App'
import { PersistGate } from 'redux-persist/integration/react'

import './index.css'

ReactDOM.render(
  <StripeProvider apiKey={stripeConfig.key}>
    <ApolloProvider client={rClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <LastLocationProvider>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <App />
                <Notifications />
              </MuiPickersUtilsProvider>
            </LastLocationProvider>
          </Router>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </StripeProvider>,
  document.getElementById('root')
)
