import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { get } from 'lodash'

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Divider,
  TextField,
  withStyles,
} from '@material-ui/core'
import { Elements } from 'react-stripe-elements'
import { useQuery, useMutation } from 'react-apollo'

import { AddPaymentMethod } from '../../../../shared/dialogs'
import {
  abbreviateNumber,
  formatCurrency,
} from '../../../../shared/helperfunctions'
import { GET_PAYMENT_METHODS } from '../../../../util/apollo/queries/payments'
import { GET_PROPOSAL_ITEMS } from '../../../../util/apollo/queries/proposalItems'
import { REQUEST_CONTRACT } from '../../../../util/apollo/queries/contracts'
import { GET_CLIENT } from '../../../../util/apollo/queries/campaigns'
import { withNotifications } from '../../../../util'

const styles = theme => ({
  root: {},
  tableTitle: {
    paddingTop: 24,
    paddingLeft: 24,
  },
  button: {
    background: '#0dbfff',
    color: '#fff',
    height: 40.5,
    fontWeight: 700,
    marginRight: theme.spacing(3),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  tableCell: {
    borderBottom: 0,
    padding: 0,
  },
  textField: {
    width: '100%',
  },
})

const RequestContract = ({
  classes,
  totalImpressions,
  contractTotal,
  currentCampaign,
  notify,
  refetchProposalItems,
}) => {
  const [paymentMethod, setPaymentMethod] = useState('')
  const [requestContract] = useMutation(REQUEST_CONTRACT)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const {
    loading: loading_client,
    error: error_client,
    data: data_client,
  } = useQuery(GET_CLIENT, {
    variables: { uuid: currentCampaign },
  })
  const {
    loading: loading_payment,
    error: error_payment,
    data: data_payment,
  } = useQuery(GET_PAYMENT_METHODS, {
    variables: {
      clientuuid: get(data_client, 'allCampaigns.edges[0].node.client.uuid'),
    },
    skip: loading_client || error_client,
  })
  if (loading_payment) return 'Loading...'
  if (loading_client) return 'Loading...'
  if (error_payment || error_client) return `Error`
  let options = (
    <MenuItem value={null} disabled>
      -
    </MenuItem>
  )

  options = data_payment.allPaymentmethods.edges
    .map(e => e.node)
    .map(({ uuid, description }) => {
      return (
        <MenuItem key={uuid} value={uuid}>
          {description}
        </MenuItem>
      )
    })

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant="h6">Payment Method</Typography>
        <TextField
          label="Payment Method"
          value={paymentMethod}
          onChange={e => {
            setPaymentMethod(e.target.value)
          }}
          variant="outlined"
          margin="dense"
          className={classes.textField}
          select
        >
          <MenuItem value="New">Add New</MenuItem>
          <Divider />
          {options}
        </TextField>
        {paymentMethod === 'New' && (
          <Elements>
            <AddPaymentMethod
              clientuuid={data_client.allCampaigns.edges[0].node.client.uuid}
              noButton
              setPaymentMethod={setPaymentMethod}
            />
          </Elements>
        )}
      </Paper>
      <Paper className={`${classes.paper} ${classes.marginTop}`}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">Total Impressions</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="subtitle1">
                  {abbreviateNumber(totalImpressions, 1)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">Contract Total </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="subtitle1">
                  {formatCurrency(contractTotal)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider className={classes.marginBottom} />
        <Button
          disabled={buttonDisabled}
          variant="contained"
          onClick={() => {
            if (!paymentMethod) {
              notify.error('Please select a payment method')
            } else {
              setButtonDisabled(true)
              requestContract({
                variables: {
                  campaignUuid: currentCampaign,
                  paymentMethodUuid: paymentMethod,
                },
                refetchQueries: [
                  {
                    query: GET_PROPOSAL_ITEMS,
                    variables: {
                      campaignUuid: currentCampaign,
                      status: 'in',
                    },
                  },
                ],
              }).then(({ data }) => {
                if (data.requestContract.ok) {
                  notify.success(
                    'Your contract has been requested. You will receive an email when your contract is ready.'
                  )
                  refetchProposalItems()
                }
                setButtonDisabled(false)
              })
            }
          }}
          className={classes.button}
        >
          Request Contract
        </Button>
      </Paper>
    </div>
  )
}

RequestContract.propTypes = {
  classes: PropTypes.object,
  totalImpressions: PropTypes.number,
  contractTotal: PropTypes.number,
  currentCampaign: PropTypes.string,
  notify: PropTypes.object,
  refetchProposalItems: PropTypes.func,
}

export default compose(
  withStyles(styles),
  withNotifications
)(RequestContract)
