import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  DELETE_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN_LIST,
} from '../../../../../util/apollo/queries/campaigns'
import * as actions from '../../../../../util/redux/actions'
import { withNotifications } from '../../../../../util'

const styles = () => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const DeleteButton = ({
  classes,
  client,
  campaignuuid,
  match: {
    params: { clientuuid },
  },
  notify,
  setCampaigns,
}) => {
  const [open, setOpen] = useState(false)
  const updateOpen = state => e => {
    e.stopPropagation()
    setOpen(state)
  }

  const stopPropagation = e => e.stopPropagation()

  const updateCampaigns = () =>
    client
      .query({
        query: GET_ALL_CAMPAIGNS,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { allCampaigns: { edges } } }) => {
        const campaigns = edges.map(({ node }) => node)
        return setCampaigns(campaigns)
      })

  const [mutation] = useMutation(DELETE_CAMPAIGN)

  const deleteCampaign = () => {
    mutation({
      variables: { campaignuuid },
      refetchQueries: [{ query: GET_CAMPAIGN_LIST, variables: { clientuuid } }],
    }).then(({ data }) => {
      if (data.updateCampaign.ok)
        updateCampaigns().then(() => {
          notify.error('Deleted')
          setOpen(false)
        })
    })
  }

  return (
    <>
      <IconButton onClick={updateOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={updateOpen(false)} onClick={stopPropagation}>
        <DialogTitle>Delete Campaign</DialogTitle>
        <DialogContent>Are you sure?</DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={updateOpen(false)}>
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={deleteCampaign}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  setCampaigns: campaigns =>
    new Promise(resolve => {
      dispatch(actions.campaigns.setCampaigns(campaigns))
      resolve(campaigns)
    }),
})

DeleteButton.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  client: PropTypes.object,
  campaignuuid: PropTypes.string.isRequired,
  match: PropTypes.object,
  setCampaigns: PropTypes.func,
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles),
  withNotifications,
  withRouter,
  withApollo
)(DeleteButton)
