import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { SEND_MESSAGE } from '../../../../../util/apollo/queries/messages'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const RequestConsultation = ({ classes, currentCampaign, history, match }) => {
  const [open, openModal] = useState(false)
  const [description, setDescription] = useState('')
  const [phone, setPhone] = useState('')

  const { campaignuuid } = match.params

  const submitHandler = mutation => e => {
    e.preventDefault()

    mutation({
      variables: {
        campaign: currentCampaign,
        body: description + '  Phone: ' + phone,
        fileItemList: [],
      },
    })
      .then(({ data, errors }) => {
        if (data.createMessage.ok) {
          setDescription('')
          setPhone('')
          openModal(false)

          history.push(`/customer/${campaignuuid}/messages/all`)
        } else
          errors.forEach(e => {
            throw new Error(e.message)
          })
      })
      .catch(e => alert(e))
  }

  const closeHandler = () => {
    openModal(false)
    setDescription('')
    setPhone('')
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => openModal(true)}
      >
        Request Consultation
      </Button>
      <Dialog open={open} onClose={() => closeHandler()}>
        <DialogTitle>Request Consultation</DialogTitle>
        <Divider />
        <Mutation mutation={SEND_MESSAGE}>
          {mutation => (
            <form onSubmit={submitHandler(mutation)}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={e => setDescription(e.target.value)}
                      value={description}
                      label="Please describe your advertising needs"
                      multiline
                      rows="4"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={e => setPhone(e.target.value)}
                      value={phone}
                      label="Phone Number"
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={closeHandler} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="primary">
                  Send Request
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

RequestConsultation.propTypes = {
  classes: PropTypes.object,
  currentCampaign: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(RequestConsultation)
