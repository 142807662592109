import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { GET_SUMMARY } from '../../../../util/apollo/queries/alertactivities'

const styles = theme => ({
  root: {},
  summary: {
    padding: theme.spacing(2),
  },
  summaryDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  summaryItem: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  numberColor: {
    color: '#0dbffe',
  },
  tableButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: 180,
  },
})

const parseSummary = edges => {
  const summary = {}
  edges.forEach(edge => (summary[edge.node.type] = edge.node))
  return summary
}

function Summary({ classes }) {
  const { loading, error, data } = useQuery(GET_SUMMARY)
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  const summary = parseSummary(data.summaryAlertactivity.edges)
  return (
    <Paper className={classes.summary}>
      <Typography variant="h6">Action Items</Typography>
      <Divider className={classes.summaryDivider} />
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.summaryGrid}
      >
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.total.count}
            </Typography>
            <Typography noWrap> Total Follow-Up Items</Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.unread_message.count}
            </Typography>
            <Typography noWrap> New Messages </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.late_proposal.count}
            </Typography>
            <Typography noWrap> Late Proposal</Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.late_contract_signing.count}
            </Typography>
            <Typography noWrap> Late Signed Contract </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.late_creative.count}
            </Typography>
            <Typography noWrap> Late Creative </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.summaryItem}>
            <Typography variant="h4" className={classes.numberColor}>
              {summary.late_payment.count}
            </Typography>
            <Typography noWrap> Late Payment </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

Summary.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Summary)
