import gql from 'graphql-tag'

const GET_STATEMENT_OVERVIEW = gql`
  query(
    $statementUuid: UUID
    $company: String
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allStatements(
      uuid: $statementUuid
      company_Name: $company
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uuid
          company {
            name
          }
          createdDate
          dueDate
          details
          statementId
        }
      }
    }
  }
`

const CREATE_STATEMENT_PDF = gql`
  mutation CREATE_STATEMENT_PDF($statementUuid: String!) {
    createStatementPdf(input: { statement: $statementUuid }) {
      ok
      uuid
      originalFileName
    }
  }
`
export { GET_STATEMENT_OVERVIEW, CREATE_STATEMENT_PDF }
