import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Paper,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { Elements } from 'react-stripe-elements'
import { useParams } from 'react-router-dom'

import { AddPaymentMethod } from '../../../../shared/dialogs'
import {
  removeEdgesAndNodes,
  snakeToTitleCase,
} from '../../../../shared/helperfunctions'
import { GET_PAYMENT_METHODS } from '../../../../util/apollo/queries/payments'
import DeleteButton from './DeleteButton'
import ConfirmAccountButton from './ConfirmAccountButton'

const saveMenuHeight = 64
const styles = theme => ({
  root: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    minHeight: '100%',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(4) + saveMenuHeight,
  },
  addUserButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    width: 150,
  },
  addUserContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  paper: {
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
    minWidth: 600,
  },
  pushToRight: {
    marginLeft: 'auto',
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  toolbar: {
    boxSizing: 'border-box',
    width: '100%',
  },
  user: {
    alignItems: 'center',
    border: 'solid 1px #e0e0e0',
    borderRadius: 3,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  usersContainer: {
    display: 'grid',
    gridTempalateColumns: '1fr',
    gridGap: `${theme.spacing(2)}px`,
    padding: theme.spacing(3),
  },
})

const Payment = ({ classes }) => {
  const { clientuuid } = useParams()
  const { loading, error, data } = useQuery(GET_PAYMENT_METHODS, {
    variables: { clientuuid },
  })
  if (loading) return ''
  if (error || !data.allPaymentmethods.edges[0]) {
    return null
  }
  if (clientuuid === 'new') return null
  const textStyle = status => {
    if (status == 'PENDING_VERIFICATION') return { color: '#52a653' }
    else return {}
  }

  const edges = removeEdgesAndNodes(data.allPaymentmethods).edges
  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h6">Payment Methods</Typography>
        <Elements>
          <AddPaymentMethod clientuuid={clientuuid} />
        </Elements>
      </div>
      <Divider />
      <div className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map(({ uuid, description, status }) => {
              return (
                <TableRow key={uuid}>
                  <TableCell>
                    <Typography>{description}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={textStyle(status)}>
                      {snakeToTitleCase(status).toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell padding="none">
                    {status == 'PENDING_VERIFICATION' ? (
                      <>
                        <ConfirmAccountButton paymentmethoduuid={uuid} />{' '}
                        <DeleteButton paymentmethoduuid={uuid} />
                      </>
                    ) : (
                      <DeleteButton paymentmethoduuid={uuid} />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        )
      </div>
    </Paper>
  )
}

Payment.propTypes = {
  classes: PropTypes.object,
}

export default compose(withStyles(styles))(Payment)
