import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, Button, styled } from '@material-ui/core'
import { compose, spacing, flexbox, display } from '@material-ui/system'
import ChatModal from './ChatModal'

const Wrapper = styled(Paper)(
  compose(
    flexbox,
    spacing,
    display
  )
)
export default function Chat({ chatType, contractuuid }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper
      marginTop={3}
      padding={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography variant="h6">
        {chatType === 'buyer' ? 'Buyer' : 'Seller'}
      </Typography>
      <Button color="primary" onClick={() => setIsOpen(true)}>
        View Messages
      </Button>
      <ChatModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        chatType={chatType}
        contractuuid={contractuuid}
      />
    </Wrapper>
  )
}

Chat.propTypes = {
  chatType: PropTypes.oneOf(['buyer', 'seller']).isRequired,
  contractuuid: PropTypes.string.isRequired,
}
