import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import InvoiceList from './Overview'
import InvoiceDetail from './Details'

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
  },
})

const Invoices = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route path={match.path + '/detail/:uuid'} component={InvoiceDetail} />
        <Route path={match.path + '/'} component={InvoiceList} />
      </Switch>
    </div>
  )
}

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(Invoices)
