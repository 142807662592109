import gql from 'graphql-tag'

const ARCHIVE_CONVERSATION = gql`
  mutation($uuid: String) {
    updateChannelactivity(input: { uuid: $uuid, status: "archive" }) {
      channelActivity {
        uuid
        id
        status
      }
    }
  }
`

const GET_MESSAGES = gql`
  query($channeluuid: UUID, $channelType: String, $contractuuid: UUID) {
    allMessageactivities(
      message_Channel_Uuid: $channeluuid
      message_Channel_Type: $channelType
      message_Channel_Contract_Uuid: $contractuuid
    ) {
      edges {
        node {
          uuid
          read
          message {
            id
            body
            createdDate
            urgent
            fileItem {
              edges {
                node {
                  uuid
                  originalFileName
                }
              }
            }
            sender {
              uuid
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

const GET_ALL_CONVERSATIONS = gql`
  query(
    $status: String
    $campaignUUID: UUID
    $readStatus: Boolean
    $contractuuid: UUID
  ) {
    allChannelactivities(
      status: $status
      channel_Campaign_Uuid: $campaignUUID
      read: $readStatus
      channel_Contract_Uuid: $contractuuid
    ) {
      edges {
        node {
          uuid
          status
          read
          title
          channel {
            uuid
            lastMessageFragment
            lastMessageTimestamp
            uuid
          }
        }
      }
    }
  }
`

const SEND_MESSAGE = gql`
  mutation(
    $product: String
    $campaign: String
    $channeluuid: String
    $body: String!
    $urgent: Boolean
    $fileItemList: [String]
  ) {
    createMessage(
      input: {
        campaign: $campaign
        product: $product
        channel: $channeluuid
        body: $body
        urgent: $urgent
        addFileItem: $fileItemList
      }
    ) {
      ok
      message {
        body
        fileItem {
          edges {
            node {
              uuid
            }
          }
        }
      }
    }
  }
`

const GET_ACTIVITY_FROM_CHANNEL = gql`
  query($channelUuid: UUID) {
    allChannelactivities(channel_Uuid: $channelUuid) {
      edges {
        node {
          uuid
        }
      }
    }
  }
`

const GET_CHANNEL_STATUS = gql`
  query($channeluuid: UUID) {
    allChannelactivities(channel_Uuid: $channeluuid) {
      edges {
        node {
          uuid
          status
          title
        }
      }
    }
  }
`

const GET_CHANNEL_TITLE = gql`
  query($uuid: UUID) {
    allChannelactivities(channel_Uuid: $uuid) {
      edges {
        node {
          title
        }
      }
    }
  }
`

const SET_CONVERSATION_READ = gql`
  mutation($channelactivityuuid: String!) {
    updateChannelactivity(input: { uuid: $channelactivityuuid, read: true }) {
      ok
    }
  }
`

const UPDATE_CHANNEL_STATUS = gql`
  mutation($channelactivityuuid: String!, $status: String!) {
    updateChannelactivity(
      input: { uuid: $channelactivityuuid, status: $status }
    ) {
      ok
      channelActivity {
        id
        status
      }
    }
  }
`

const GET_SELLER_CHANNEL_FROM_CONTRACT = gql`
  query($contractuuid: UUID, $type: String) {
    allChannelactivities(
      channel_Contract_Uuid: $contractuuid
      channel_Type: $type
    ) {
      edges {
        node {
          uuid
          channel {
            uuid
          }
        }
      }
    }
  }
`

const GET_BUYER_CHANNEL_FROM_CONTRACT = gql`
  query($campaignuuid: UUID, $selleruuid: UUID, $type: String) {
    allChannelactivities(
      channel_Campaign_Uuid: $campaignuuid
      channel_Seller_Uuid: $selleruuid
      channel_Type: $type
    ) {
      edges {
        node {
          uuid
          channel {
            uuid
          }
        }
      }
    }
  }
`

const GET_CHANNEL = gql`
  query($contractUuid: UUID, $type: String) {
    allChannels(contract_Uuid: $contractUuid, type: $type) {
      edges {
        node {
          uuid
          type
        }
      }
    }
  }
`

const GET_CHANNELS_FOR_CONTRACT = gql`
  query($contract: UUID) {
    allChannelactivities(channel_Contract_Uuid: $contract) {
      edges {
        node {
          uuid
          channel {
            title
            type
          }
        }
      }
    }
  }
`

export {
  ARCHIVE_CONVERSATION,
  GET_ACTIVITY_FROM_CHANNEL,
  GET_CHANNEL_STATUS,
  GET_CHANNEL_TITLE,
  GET_MESSAGES,
  GET_ALL_CONVERSATIONS,
  SEND_MESSAGE,
  SET_CONVERSATION_READ,
  UPDATE_CHANNEL_STATUS,
  GET_SELLER_CHANNEL_FROM_CONTRACT,
  GET_BUYER_CHANNEL_FROM_CONTRACT,
  GET_CHANNEL,
  GET_CHANNELS_FOR_CONTRACT,
}
