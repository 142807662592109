import React, { useState } from 'react'
import { IconButton, Menu } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'

import { ConfirmDelete } from '../../../../../../shared/actionmenus'

import { EditContractNote } from './menuitems'

const DELETE_MUTATION = gql`
  mutation($noteUUID: String!, $status: String!) {
    updateContractnote(input: { uuid: $noteUUID, status: $status }) {
      ok
    }
  }
`

const NotesActionMenu = ({ note, contract }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = event => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const DELETE_VARIABLES = {
    noteUUID: note.uuid,
    status: 'archive',
  }

  return (
    <div>
      <IconButton id="icon-button" onClick={openMenu}>
        <MoreVertIcon id="more-vert-icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <EditContractNote
          note={note}
          closeParentMenu={closeMenu}
          contract={contract}
        />
        <ConfirmDelete
          MUTATION={DELETE_MUTATION}
          MUTATION_VARIABLES={DELETE_VARIABLES}
          closeParentMenu={closeMenu}
          contract={contract}
        />
      </Menu>
    </div>
  )
}

NotesActionMenu.propTypes = {
  note: PropTypes.object,
  contract: PropTypes.string,
}

export default NotesActionMenu
