import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { GET_COMPANY_USERS } from '../../../../util/apollo/queries/companies'

import DeleteUser from './DeleteUser'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
  },
  header: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
})

const Admin = ({ classes }) => {
  const { loading, error, data } = useQuery(GET_COMPANY_USERS)
  if (loading) return 'Loading...'
  if (error) return error.message
  const allUsers = data.allUsers.edges.map(({ node }) => node)
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Manage Company Users</Typography>
      </div>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {allUsers.map(({ uuid: useruuid, firstName, email }) => (
            <TableRow key={useruuid}>
              <TableCell>{firstName}</TableCell>
              <TableCell>{email}</TableCell>
              <TableCell align="right">
                <DeleteUser useruuid={useruuid} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

Admin.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Admin)
