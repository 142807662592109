import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Mutation } from 'react-apollo'

import { DELETE_USER } from '../../../../../util/apollo/queries/user'
import { GET_COMPANY_USERS } from '../../../../../util/apollo/queries/companies'
import { withNotifications } from '../../../../../util'

const styles = theme => ({
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#f35d66',
  },
})

const ConfirmDelete = ({ notify, useruuid }) => {
  const [open, openModal] = useState(false)
  const updateModalState = state => () => openModal(state)

  const submitHandler = mutation => e => {
    e.preventDefault()
    mutation({
      variables: { useruuid },
      refetchQueries: [
        {
          query: GET_COMPANY_USERS,
        },
      ],
    })
      .then(({ data }) => {
        if (data.updateUser.ok) {
          openModal(false)
          notify.success('Account deleted')
        }
      })
      .catch(() => null)
  }

  return (
    <>
      <IconButton onClick={updateModalState(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={updateModalState(false)}>
        <DialogTitle>
          {`Warning, are you sure you want to delete this user's account?`}
        </DialogTitle>
        <Divider />
        <Mutation mutation={DELETE_USER}>
          {mutation => (
            <form onSubmit={submitHandler(mutation)}>
              <DialogActions>
                <Button onClick={updateModalState(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  Yes, Delete This User
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </>
  )
}

ConfirmDelete.propTypes = {
  uuid: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withNotifications
)(ConfirmDelete)
