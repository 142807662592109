import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Slider, Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  checkboxLabel: {
    marginTop: theme.spacing(0.5),
  },
  checkboxLabelLabel: {
    marginLeft: theme.spacing(2),
  },
  checkboxLabelRoot: {
    marginLeft: -2,
  },
  checkboxRoot: {
    padding: 0,
  },
  clearFilter: {
    color: '#00bcfa',
    cursor: 'pointer',
    fontWeight: 700,
    marginLeft: theme.spacing(1),
  },
  filterGroup: {
    marginTop: theme.spacing(1.5),
  },
  mediaTypeCheckboxes: {
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 700,
  },
  sliderContainer: {
    boxSizing: 'border-box',
    // margin: `0 ${theme.spacing(3)}px`,
    marginRight: theme.spacing(3),
  },
})

const MIN = 0
const MAX = 100

const Component = ({ classes, setFilters }) => {
  const [value, setValue] = useState([MIN, MAX])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [min, max] = value

  useEffect(() => {
    if (min === MIN) {
      setFilters(prev => {
        delete prev.minImpressions
        return { ...prev }
      })
      return
    }

    setFilters(prev => ({ ...prev, minImpressions: min }))
  }, [min, setFilters])

  useEffect(() => {
    if (max === MAX) {
      setFilters(prev => {
        delete prev.maxImpressions
        return { ...prev }
      })
      return
    }

    setFilters(prev => ({ ...prev, maxImpressions: max }))
  }, [max, setFilters])

  const marks = [
    {
      value: MIN,
      label: MIN,
    },
    {
      value: MAX,
      label: `${MAX}K`,
    },
  ]

  const onClear = () => setValue([MIN, MAX])

  return (
    <div className={classes.filterGroup}>
      <Typography className={classes.sectionTitle} display="inline">
        Impressions
      </Typography>
      <Typography
        className={classes.clearFilter}
        display="inline"
        variant="caption"
        onClick={onClear}
      >
        Clear Filter
      </Typography>
      <div className={classes.sliderContainer}>
        <Slider
          value={value}
          onChange={handleChange}
          min={MIN}
          max={MAX}
          valueLabelDisplay="auto"
          valueLabelFormat={v => v + 'K'}
          marks={marks}
        />
      </div>
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
