import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  InputAdornment,
  Typography,
  TextField,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
  },
  sectionTitle: {
    fontWeight: 700,
  },
})

const Component = ({ classes, setFilters }) => {
  const [minCost, setMinCost] = useState('')
  const [maxCost, setMaxCost] = useState('')

  const updateMinCost = ({ target: { value } }) => setMinCost(value)
  const updateMaxCost = ({ target: { value } }) => setMaxCost(value)

  useEffect(() => {
    if (minCost.length) {
      setFilters(prev => ({
        ...prev,
        minCost,
      }))
    } else {
      setFilters(prev => {
        delete prev.minCost
        return { ...prev }
      })
    }
  }, [minCost, setFilters])

  useEffect(() => {
    if (maxCost.length) {
      setFilters(prev => ({
        ...prev,
        maxCost,
      }))
    } else {
      setFilters(prev => {
        delete prev.maxCost
        return { ...prev }
      })
    }
  }, [maxCost, setFilters])

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.sectionTitle}>
        Min Cost
      </Typography>
      <Typography gutterBottom className={classes.sectionTitle}>
        Max Cost
      </Typography>
      <TextField
        type="number"
        value={minCost}
        onChange={updateMinCost}
        InputProps={{
          startAdornment: <InputAdornment position="start"> $</InputAdornment>,
        }}
      />
      <TextField
        type="number"
        value={maxCost}
        onChange={updateMaxCost}
        InputProps={{
          startAdornment: <InputAdornment position="start"> $</InputAdornment>,
        }}
      />
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
