import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import CompanyInfo from './CompanyInfo'
import Contacts from './Contacts'
import ProductSummary from './ProductSummary'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
})

const Seller = ({ classes, match }) => {
  const [companyUUID, setCompanyUUID] = useState(match.params.uuid)
  return (
    <div className={classes.root}>
      <CompanyInfo setCompanyUUID={setCompanyUUID} companyUUID={companyUUID} />
      <Contacts companyUUID={companyUUID} />
      <ProductSummary companyUUID={companyUUID} />
    </div>
  )
}

Seller.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Seller)
