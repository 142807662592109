/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  makeStyles,
} from '@material-ui/core'
import RRule from 'rrule'

const useStyles = makeStyles({
  root: {
    minWidth: '175px',
  },
})

export const DAYS_OF_THE_WEEK = [
  { label: 'Monday', value: RRule.MO },
  { label: 'Tuesday', value: RRule.TU },
  { label: 'Wednesday', value: RRule.WE },
  { label: 'Thursday', value: RRule.TH },
  { label: 'Friday', value: RRule.FR },
  { label: 'Saturday', value: RRule.SA },
  { label: 'Sunday', value: RRule.SU },
]

export function getRRuleDayIndexFromLuxon(date) {
  // shifts the day from being "Sunday" based to "Monday" based
  return (date.weekday - 1 + 7) % 7
}

export function getRRuleDayFromLuxon(date) {
  const index = getRRuleDayIndexFromLuxon(date)
  return DAYS_OF_THE_WEEK[index].value
}

export function getDayLabelFromLuxon(date) {
  return DAYS_OF_THE_WEEK[getRRuleDayIndexFromLuxon(date)].label
}

export default function WeekdayField({ weekdays, onChange }) {
  const classes = useStyles()
  // calling  onChange when the tab is selected
  return (
    <FormControl className={classes.root}>
      <InputLabel id="days-of-the-week-label">Day(s) of the Week</InputLabel>
      <Select
        labelId="days-of-the-week-label"
        id="days-of-the-week"
        multiple
        value={weekdays}
        renderValue={values =>
          DAYS_OF_THE_WEEK.filter((value, index) => values.includes(index))
            .map(dayOfTheWeek => dayOfTheWeek.label)
            .join(', ')
        }
        onChange={event => onChange(event.target.value)}
        input={<Input />}
      >
        {DAYS_OF_THE_WEEK.map(({ label }, index) => (
          <MenuItem key={index} value={index}>
            <Checkbox checked={weekdays.includes(index)} />
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

WeekdayField.propTypes = {
  weekdays: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

WeekdayField.displayName = 'WeekdayField'
