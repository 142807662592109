import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'

import { CREATE_COMPANY } from '../../../../../util/apollo/queries/companies'
import { CREATE_CONTACT } from '../../../../../util/apollo/queries/contacts'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  continueButtonContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 60,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  newSection: {
    marginTop: theme.spacing(2),
  },
})

const CreateNewBuyer = ({ classes, history }) => {
  //Form
  const [form, setForm] = useState({
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  })
  const { name, firstName, lastName, email, phone } = form

  const handleChange = key => ({ target: { value } }) =>
    setForm(prev => ({ ...prev, [key]: value }))

  //Mutation
  const [createCompany] = useMutation(CREATE_COMPANY)
  const [createPrimaryContact] = useMutation(CREATE_CONTACT)

  const onSubmit = async e => {
    e.preventDefault()

    createCompany({
      variables: {
        name,
        domain: '@' + form.email.split('@')[1],
        type: 'buyer',
      },
    }).then(({ data }) => {
      if (data.createCompany.ok) {
        const { uuid } = data.createCompany.company
        createPrimaryContact({
          variables: {
            firstName,
            lastName,
            email,
            phone,
            companyUUID: uuid,
            primaryCompanyContact: true,
          },
        }).then(({ data }) => {
          if (data.createContact.ok)
            history.push(`/moderator/companies/buyer/${uuid}/overview`)
        })
      }
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={onSubmit}>
          <Typography variant="h5">Create New Buyer</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6">Company Information</Typography>
          <TextField
            label="Company Name"
            value={name}
            onChange={handleChange('name')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Typography variant="h6" className={classes.newSection}>
            Primary Contact
          </Typography>
          <TextField
            label="First Name"
            value={firstName}
            onChange={handleChange('firstName')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={handleChange('lastName')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Email"
            value={email}
            onChange={handleChange('email')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={handleChange('phone')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <div className={classes.continueButtonContainer}>
            <Button variant="contained" color="primary" type="submit">
              Continue
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

CreateNewBuyer.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(CreateNewBuyer)
