import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import { withRouter } from 'react-router-dom'

const styles = () => ({
  root: {},
})

// eslint-disable-next-line no-unused-vars
const Component = ({ classes, history, productuuid }) => {
  const viewAdPage = () => {
    history.push(`/moderator/products/customerview/${productuuid}`)
  }

  return (
    <Tooltip title="View Ad">
      <IconButton onClick={viewAdPage}>
        <FindInPageIcon />
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  productuuid: PropTypes.string,
}

export default compose(
  withRouter,
  withStyles(styles)
)(Component)
