import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { withRouter } from 'react-router-dom'
import { baseUrl } from '../../../../../util/apollo/config'

import {
  formatDate,
  downloadFileOnClick,
} from '../../../../../shared/helperfunctions'
import { UploadFile } from '../../../../../shared/dialogs'
import { GET_CONTRACT_DETAIL } from '../../../../../util/apollo/queries/contracts'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  button: {
    marginTop: -theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 0`,
  },
})

const PaymentSchedule = ({
  classes,
  match: {
    params: { contractuuid },
  },
  fileItem,
}) => {
  const files = fileItem.edges.map(({ node }) => node)
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Files</Typography>
        <UploadFile
          fileTypes={['creative', 'signed_contract']}
          variables={{ contractUUID: contractuuid }}
          refetchQueries={[
            { query: GET_CONTRACT_DETAIL, variables: { contractuuid } },
          ]}
          customButton={props => (
            <Tooltip title="Upload Creative">
              <IconButton className={classes.button} {...props}>
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          )}
          onFileTypeChange={(
            fileType,
            { setHideDescription, setDescription }
          ) => {
            setHideDescription(fileType === 'signed_contract')
            setDescription(
              fileType === 'signed_contract' ? 'Signed buyer contract' : ''
            )
          }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Description</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {files.length ? (
            files.map(
              ({ originalFileName, createdDate, description, uuid }) => (
                <TableRow key={uuid}>
                  <TableCell>{originalFileName}</TableCell>
                  <TableCell>{formatDate(createdDate)}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell padding="none">
                    <IconButton
                      onClick={downloadFileOnClick(
                        baseUrl + '/api/rest/v1/file/download/r/' + uuid,
                        originalFileName
                      )}
                    >
                      <SaveAltIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

PaymentSchedule.propTypes = {
  classes: PropTypes.object,
  fileItem: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(PaymentSchedule)
