import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import Overview from './Overview'
import DetailPage from './DetailPage'
import EsignPage from './EsignPage'

const styles = theme => ({
  root: {
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    width: '100%',
  },
})

const Contracts = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route
          path={match.path + '/detail/:contractuuid'}
          component={DetailPage}
        />
        <Route
          path={match.path + '/esign/:contractuuid'}
          component={EsignPage}
        />
        <Route path={match.path + '/'} component={Overview} />
      </Switch>
    </div>
  )
}

Contracts.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default withStyles(styles)(Contracts)
