import React from 'react'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import Charts from './Charts'
import PricePerImpression from './PricePerImpression'

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
  },
})

const Overview = ({
  classes,
  match: {
    params: { campaignuuid },
  },
}) => {
  if (campaignuuid === 'undefined')
    return <Typography>Select a campaign</Typography>

  return (
    <div className={classes.root}>
      <Charts />
      <PricePerImpression />
    </div>
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Overview)
