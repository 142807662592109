import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogContent,
  Typography,
  withStyles,
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const styles = theme => ({
  root: {},
  content: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px !important`,
  },
  contentRoot: {
    position: 'relative',
    overflow: 'visible',
  },
  icon: {
    position: 'absolute',
    top: -32,
    left: 62,
    color: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  paper: {
    backgroundColor: theme.palette.grey[800],
    position: 'absolute',
    top: 38,
    left: 110,
    display: 'block',
    overflow: 'visible',
  },
  text: {
    color: theme.palette.getContrastText(theme.palette.grey[800]),
  },
})

const Component = ({ classes, open, setOpen }) => {
  const closeTooltip = () => setOpen(false)

  return (
    <Dialog
      open={open}
      onClose={closeTooltip}
      classes={{ paper: classes.paper }}
    >
      <DialogContent
        className={classes.content}
        classes={{ root: classes.contentRoot }}
      >
        <ArrowUpwardIcon className={classes.icon} />
        <Typography className={classes.text} variant="caption">
          Select your active campaign here as you create more clients and
          campaigns
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default withStyles(styles)(Component)
