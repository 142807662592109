import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  MenuItem,
  withStyles,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { Mutation } from 'react-apollo'
import { GET_CONTRACTNOTES } from '../../util/apollo/queries/contracts'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const ConfirmDelete = ({
  closeParentMenu,
  MUTATION,
  MUTATION_VARIABLES,
  contract,
}) => {
  const [open, openModal] = useState(false)

  const closeHandler = () => {
    openModal(false)
    closeParentMenu()
  }

  const openHandler = () => {
    openModal(true)
  }

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    mutation({ variables: MUTATION_VARIABLES })
    closeHandler()
  }

  return (
    <React.Fragment>
      <MenuItem onClick={openHandler}>Delete</MenuItem>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle>Are you sure?</DialogTitle>
        <Divider />
        <Mutation
          mutation={MUTATION}
          refetchQueries={[
            {
              query: GET_CONTRACTNOTES,
              variables: {
                contract: contract,
              },
            },
          ]}
        >
          {mutation => (
            <form onSubmit={e => submitHandler(e, mutation)}>
              <DialogActions>
                <Button onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" type="submit" color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

ConfirmDelete.propTypes = {
  closeParentMenu: PropTypes.func,
  MUTATION: PropTypes.object,
  MUTATION_VARIABLES: PropTypes.object,
  contract: PropTypes.string,
}

export default withStyles(styles)(ConfirmDelete)
