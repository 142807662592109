import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  withStyles,
  Typography,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'

const styles = theme => ({
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#0dbffe',
  },
})

const ConfirmPause = ({ title, message }) => {
  const [open, openModal] = useState(true)

  return (
    <Dialog open={open} onClose={() => openModal(false)}>
      <DialogTitle>{title || 'System Message'}</DialogTitle>
      <DialogContent>
        <Typography>{message || 'Internal System Error'}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => openModal(false)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmPause.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
}

export default withStyles(styles)(ConfirmPause)
