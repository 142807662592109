import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useMutation, withApollo } from 'react-apollo'
import { connect } from 'react-redux'

import {
  GET_CLIENTS,
  REMOVE_USER,
} from '../../../../../util/apollo/queries/clients'
import { GET_ALL_CAMPAIGNS } from '../../../../../util/apollo/queries/campaigns'
import { withNotifications } from '../../../../../util'
import * as actions from '../../../../../util/redux/actions'

const styles = () => ({
  button: {
    visibility: 'hidden',
  },
})

const HideClient = ({
  classes,
  client,
  clientuuid,
  notify,
  setCampaigns,
  useruuid,
}) => {
  const [hideClientMutation] = useMutation(REMOVE_USER)

  const updateCampaigns = () =>
    client
      .query({
        query: GET_ALL_CAMPAIGNS,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { allCampaigns: { edges } } }) => {
        const campaigns = edges.map(({ node }) => node)
        return setCampaigns(campaigns)
      })

  const hideClient = e => {
    e.stopPropagation()
    hideClientMutation({
      variables: {
        useruuid,
        clientuuid,
      },
      refetchQueries: [
        {
          query: GET_CLIENTS,
          variables: { useruuid },
        },
      ],
    }).then(({ data }) => {
      if (data.updateClient.ok)
        updateCampaigns().then(() => notify.success('Removed'))
    })
  }
  return (
    <Tooltip title="Hide Client">
      <IconButton onClick={hideClient} className={classes.button}>
        <VisibilityOffIcon />
      </IconButton>
    </Tooltip>
  )
}

const mapDispatchToProps = dispatch => ({
  setCampaigns: campaigns =>
    new Promise(resolve => {
      dispatch(actions.campaigns.setCampaigns(campaigns))
      resolve(campaigns)
    }),
})

HideClient.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  clientuuid: PropTypes.string,
  notify: PropTypes.object,
  setCampaigns: PropTypes.func,
  useruuid: PropTypes.string,
}

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  ),
  withApollo,
  withNotifications
)(HideClient)
