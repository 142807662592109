import React from 'react'
import PropTypes from 'prop-types'

import { Paper, Typography, withStyles } from '@material-ui/core'

import { drawerWidth } from '../../../../shared/user-interface'

import MediaType from './MediaType'
import AdFeatures from './AdFeatures'
import SavedAds from './SavedAds'
import Dates from './Dates'
import Location from './Location'
import Budget from './Budget'
import CPM from './Cpm'
import Impressions from './Impressions'
import AgeFocus from './AgeFocus'
import GenderFocus from './GenderFocus'

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 64,
    bottom: 0,
    left: drawerWidth,
    width: filterPanelWidth,
    boxSizing: 'border-box',
    overflow: 'scroll',
    background: '#fff',
    padding: theme.spacing(4),
  },
})

const filterPanelWidth = 320

const Component = ({ classes, setFilters }) => {
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Filters</Typography>
      <MediaType setFilters={setFilters} />
      <AdFeatures setFilters={setFilters} />
      <SavedAds setFilters={setFilters} />
      <Dates setFilters={setFilters} />
      <Location setFilters={setFilters} />
      <Budget setFilters={setFilters} />
      <CPM setFilters={setFilters} />
      <Impressions setFilters={setFilters} />
      <AgeFocus setFilters={setFilters} />
      <GenderFocus setFilters={setFilters} />
    </Paper>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
export { filterPanelWidth }
