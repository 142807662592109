import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Paper } from '@material-ui/core'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
} from 'react-google-maps'
import { Query } from 'react-apollo'

import { GET_API_KEY } from '../../../util/apollo/queries/googlemaps'

const MyMapComponent = compose(
  withScriptjs,
  withGoogleMap
)(({ polygons, markers, center }) => {
  return (
    <GoogleMap defaultZoom={10} defaultCenter={center}>
      {polygons &&
        polygons.map(({ coordinates, addressGPS }, i) => {
          return (
            <React.Fragment key={`location${i}`}>
              <Polygon paths={coordinates} />
              {location.addressGPS && <Marker place={addressGPS} />}
            </React.Fragment>
          )
        })}
      {markers &&
        markers.map(({ latitude: lat, longitude: lng }) => {
          return <Marker key={lat * lng} position={{ lat, lng }} />
        })}
    </GoogleMap>
  )
})

const ProductMap = ({ productLocation }) => {
  const googleMapProps = {
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <Paper style={{ height: `375px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }

  let centerLat = 0
  let centerLong = 0
  let centerCount = 0
  let polyNames = []
  let polygonsArray = []
  let markers = []

  for (let i = 0; i < productLocation.length; i++) {
    centerLat += productLocation[i].latitude
    centerLong += productLocation[i].longitude
    centerCount++
    if (productLocation[i].geojson) {
      let geojsonItem = JSON.parse(productLocation[i].geojson)
      if (!polyNames.includes(geojsonItem.name)) {
        polyNames.push(geojsonItem.name)
        if (geojsonItem.geometry.type === 'Polygon') {
          let coordinates = geojsonItem.geometry.coordinates
          geojsonItem.geometry.coordinates = coordinates.map(polygon => {
            return polygon.map(([lng, lat]) => ({ lat, lng }))
          })
          polygonsArray.push(geojsonItem.geometry)
        }
        if (geojsonItem.geometry.type === 'MulitPolygon') {
          for (let j = 0; j < geojsonItem.geometry.coordinates.length; j++) {
            let coordinates = geojsonItem.geometry.coordinates[j]
            geojsonItem.geometry.coordinates = coordinates.map(polygon => {
              return polygon.map(([lng, lat]) => ({ lat, lng }))
            })
            polygonsArray.push(geojsonItem.geometry)
          }
        }
      }
    } else {
      markers.push(productLocation[i])
    }
  }
  const centerItem = {
    lat: centerLat / centerCount,
    lng: centerLong / centerCount,
  }

  return (
    <Query query={GET_API_KEY} variables={{ apitype: 'javascript' }}>
      {({ loading, error, data }) => {
        if (loading) return '...loading'
        if (error) return error.message
        if (data) {
          const { apiKey } = data.getApiKey

          return (
            <MyMapComponent
              center={centerItem}
              polygons={polygonsArray}
              markers={markers}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
              {...googleMapProps}
            />
          )
        }
      }}
    </Query>
  )
}

ProductMap.propTypes = {
  productLocation: PropTypes.array,
  center: PropTypes.object,
}

export default ProductMap
