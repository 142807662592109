import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'

import {
  GET_CHANNEL_STATUS,
  UPDATE_CHANNEL_STATUS,
  GET_ALL_CONVERSATIONS,
} from '../../../../../util/apollo/queries/messages'
import { withNotifications } from '../../../../../util'

import { getConversationQueryVariables } from '../../ConversationsPanel'

const genOppositeLabel = current =>
  current === 'ACTIVE' ? 'Archive' : 'Unarchive'

const genOppositeMutationVariable = current =>
  current === 'ACTIVE' ? 'archive' : 'active'

const Component = ({
  channeluuid,
  status,
  channelactivityuuid,
  history,
  match,
  notify,
}) => {
  const oppositeStatus = genOppositeLabel(status)
  const { filter, campaignuuid } = match.params

  const [toggleStatus] = useMutation(UPDATE_CHANNEL_STATUS, {
    variables: {
      channelactivityuuid,
      status: genOppositeMutationVariable(status),
    },
    refetchQueries: [
      {
        query: GET_CHANNEL_STATUS,
        variables: { channeluuid },
      },
      {
        query: GET_ALL_CONVERSATIONS,
        variables: getConversationQueryVariables(filter, campaignuuid),
      },
    ],
    onCompleted(data) {
      if (data.updateChannelactivity.ok) {
        const status = data.updateChannelactivity.channelActivity.status
        notify.success(`${oppositeStatus}d`)
        const { pathname } = history.location
        const path = pathname
          .split('/')
          .slice(0, -2)
          .concat([status === 'ARCHIVE' ? 'all' : 'archived'])
          .join('/')
        history.push(path)
      }
    },
  })

  return (
    <Button variant="outlined" color="primary" onClick={toggleStatus}>
      {oppositeStatus}
    </Button>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  notify: PropTypes.object,
  match: PropTypes.object,
  channeluuid: PropTypes.string,
  status: PropTypes.string,
  channelactivityuuid: PropTypes.string,
}

export default compose(
  withRouter,
  withNotifications
)(Component)
