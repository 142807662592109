import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  withStyles,
  TextField,
  Tooltip,
} from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { GET_CONTRACTNOTES } from '../../../../../../util/apollo/queries/contracts'

const styles = () => ({
  content: {
    width: 350,
  },
})

const ADD_CONTRACT_NOTE = gql`
  mutation(
    $contractUUID: String!
    $description: String!
    $date: DateTime!
    $status: String!
  ) {
    createContractnote(
      input: {
        contract: $contractUUID
        description: $description
        reminderDate: $date
        status: $status
      }
    ) {
      ok
      contractNote {
        id
      }
    }
  }
`

const AddContractNote = ({ classes, contractUUID }) => {
  const [open, openModal] = useState(false)
  const [description, setDescription] = useState('')

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    const variables = {
      contractUUID,
      description,
      date: new Date(),
      status: 'active',
    }
    mutation({ variables: variables })
    openModal(false)
  }

  return (
    <React.Fragment>
      <Tooltip title="Add Note">
        <IconButton className={classes.button} onClick={() => openModal(true)}>
          <NoteAddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => openModal(false)}>
        <DialogTitle>Create Note</DialogTitle>
        <Divider />
        <Mutation
          mutation={ADD_CONTRACT_NOTE}
          refetchQueries={[
            {
              query: GET_CONTRACTNOTES,
              variables: {
                contract: contractUUID,
              },
            },
          ]}
        >
          {(mutation, { error }) => {
            if (error) alert(error) && openModal(false)
            return (
              <form onSubmit={e => submitHandler(e, mutation)}>
                <DialogContent className={classes.content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={e => setDescription(e.target.value)}
                        placeholder="Add Note"
                        multiline
                        rows="4"
                        label="Note"
                        required
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button onClick={() => openModal(false)} color="primary">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" color="primary">
                    Add
                  </Button>
                </DialogActions>
              </form>
            )
          }}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

AddContractNote.propTypes = {
  classes: PropTypes.object,
  contractUUID: PropTypes.string.isRequired,
}

export default withStyles(styles)(AddContractNote)
