import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import clsx from 'clsx'

import { BackButton } from '../../../../shared/components'
import Contact from './Contact'
import Notes from './Notes'
import Alerts from './Alerts'
import Files from './Files'
import Terms from './Terms'
import Chat from './Chat'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    maxWidth: 1000,
    margin: `0 auto`,
    boxShadow: 'none',
  },
  backButton: {
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(1),
  },
  button_alert: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#f35d66',
  },
  caption: {
    color: '#888',
  },
  contact: {
    marginRight: theme.spacing(2),
  },
  contacts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  iconButton: {
    button: {
      width: 36,
      height: 36,
    },
  },
  iconCell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  info: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  infoSingle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  infoContainer: {
    padding: theme.spacing(3),
  },
  itemRoot: {
    marginTop: theme.spacing(3),
  },
  pushRight: {
    marginRight: 'auto',
  },
  textField: {
    width: '100%',
  },
})

const Overview = ({
  classes,
  match: {
    params: { contractuuid },
  },
}) => {
  return (
    <div className={classes.root}>
      <BackButton className={classes.backButton} link="/moderator/contracts" />
      <Terms classes={classes} contractuuid={contractuuid} />
      <Notes contract={contractuuid} classes={classes} />
      <Alerts contract={contractuuid} />
      <Chat chatType="buyer" contractuuid={contractuuid} />
      <Chat chatType="seller" contractuuid={contractuuid} />
      <Files classes={classes} contract={contractuuid} />
      <div className={clsx(classes.itemRoot, classes.contacts)}>
        <Contact
          classes={classes}
          className={classes.contact}
          contactType="seller"
          contract={contractuuid}
        />
        <Contact
          classes={classes}
          contactType="buyer"
          contract={contractuuid}
        />
      </div>
    </div>
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Overview)
