import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Paper,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core'

import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'

import { uploadFile, generateFileUrl } from '../../../../shared/helperfunctions'
import { ADD_NEW_COMPANY_LOGO } from '../../../../util/apollo/queries/companies'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    width: '100%',
  },
  icon: {
    filter: 'opacity(.8)',
    fontSize: 42,
    color: '#ddd',
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  'thumbnail--selected': {
    height: '100%',
    width: '100%',
    zIndex: 20,
    '&:hover': {
      filter: 'brightness(.8)',
    },
  },
  'thumbnail--saved': {
    height: '100%',
    width: '100%',
    zIndex: 19,
    '&:hover': {
      filter: 'brightness(.8)',
    },
  },
  thumbnailContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`,
    width: 200,
    outline: 'none',
    '&:hover': {
      filter: 'brightness(.8)',
    },
  },
  uploadText: {
    position: 'absolute',
    fontWeight: 700,
  },
})

const Customization = ({ classes, client, company, token }) => {
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [savedPreview, setSavedPreview] = useState(null)

  const [snackbarSuccess, setSnackbarSuccess] = useState(false)
  const [snackbarError, setSnackbarError] = useState(false)

  const openSuccessSnackbar = () => setSnackbarSuccess(true)
  const closeSuccessSnackbar = () => setSnackbarSuccess(false)
  const openErrorSnackbar = () => setSnackbarError(true)
  const closeErrorSnackbar = () => setSnackbarError(false)

  const getFileUrl = async url => await generateFileUrl(url)

  const companyUUID = company ? company[0].uuid : null

  useEffect(() => {
    if (!savedPreview) {
      setSavedPreview(getFileUrl('2b90e11f-85bb-4132-a55a-68e909525cb3'))
    }
  }, [savedPreview])

  useEffect(() => {
    if (file) {
      URL.revokeObjectURL(preview)
    }
  }, [file, preview])

  const onFileSelected = acceptedFiles => {
    setFile(acceptedFiles[0])
    setPreview(URL.createObjectURL(acceptedFiles[0]))
  }

  const rejectFile = () => {
    alert('Accepted File Types are: JPEG, PNG, SVG')
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/svg',
    multiple: false,
    onDropAccepted: onFileSelected,
    onDropRejected: rejectFile,
  })

  const uploadLogo = () => {
    const formData = {
      file,
      type: 'company_logo',
      description: 'Company Logo',
      source: 'other',
    }

    const config = {
      client,
      mutation: ADD_NEW_COMPANY_LOGO,
      variables: { companyUUID },
      successFn: openSuccessSnackbar,
      errorFn: openErrorSnackbar,
    }

    uploadFile(token, formData, config)
  }

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6" className={classes.tableTitle}>
          Upload your logo
        </Typography>
        <Paper
          {...getRootProps({
            className: classes.thumbnailContainer,
          })}
        >
          <input {...getInputProps()} />
          <Typography className={classes.uploadText} color="textSecondary">
            Choose file...
          </Typography>
          {savedPreview && savedPreview !== 'error' ? (
            <img src={savedPreview} className={classes['thumbnail--saved']} />
          ) : null}
          {preview ? (
            <img src={preview} className={classes['thumbnail--selected']} />
          ) : null}
        </Paper>
        <Typography color="textSecondary">
          *Upload a logo 200px by 200px in PNG, JPEG, or SVG
        </Typography>
        <Button
          className={classes.saveButton}
          variant="contained"
          color="primary"
          onClick={uploadLogo}
          disabled={!file}
        >
          Save
        </Button>
      </Paper>

      <Snackbar
        open={snackbarSuccess}
        autoHideDuration={2000}
        onClose={closeSuccessSnackbar}
        message="Saved File Successfully"
      />
      <Snackbar
        open={snackbarError}
        autoHideDuration={2000}
        onClose={closeErrorSnackbar}
        message="Error Saving File"
      />
    </>
  )
}

Customization.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  client: PropTypes.object,
  company: PropTypes.array,
}

const mapStateToProps = state => ({
  token: state.tokens.token,
  company: state.userInfo.company,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Customization)
