import gql from 'graphql-tag'

const GET_PROPOSAL_ITEMS = gql`
  query($campaignuuid: UUID, $status: String) {
    allProposalitems(proposal_Campaign_Uuid: $campaignuuid, status: $status) {
      edges {
        node {
          uuid
          id
          status
          quantity
          preferredStartDate
          datePreferenceNotes
          otherPreferenceNotes
          estimatedTotalPrice
          estimatedTotalImpressions
          productSize {
            title
            buyUnit
            impressionsPerUnit
            pricePerUnit
            product {
              title
              mediaType
              description
              demographic
              priceDescription
              logistics
              fileItem {
                edges {
                  node {
                    type
                    uuid
                  }
                }
              }
            }
          }
          proposal {
            id
            uuid
            campaign {
              client {
                company {
                  name
                  logo {
                    uuid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const UPDATE_PROPOSAL_ITEM = gql`
  mutation UpdateProposalitem(
    $uuid: String!
    $status: String
    $datePreferenceNotes: String
    $quantity: Int
    $preferredStartDate: DateTime
    $otherPreferenceNotes: String
  ) {
    updateProposalitem(
      input: {
        uuid: $uuid
        status: $status
        datePreferenceNotes: $datePreferenceNotes
        quantity: $quantity
        preferredStartDate: $preferredStartDate
        otherPreferenceNotes: $otherPreferenceNotes
      }
    ) {
      ok
      proposalItem {
        status
        id
        uuid
      }
    }
  }
`

const CREATE_PROPOSAL_ITEM = gql`
  mutation(
    $campaign: String
    $productSize: String
    $quantity: Int
    $preferredStartDate: DateTime
    $datePreferenceNotes: String
    $otherPreferenceNotes: String
  ) {
    createProposalitem(
      input: {
        campaign: $campaign
        productSize: $productSize
        quantity: $quantity
        preferredStartDate: $preferredStartDate
        datePreferenceNotes: $datePreferenceNotes
        otherPreferenceNotes: $otherPreferenceNotes
      }
    ) {
      ok
    }
  }
`

export { CREATE_PROPOSAL_ITEM, GET_PROPOSAL_ITEMS, UPDATE_PROPOSAL_ITEM }
