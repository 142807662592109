import React, { Fragment } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  MenuItem,
  withStyles,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = () => ({})

const GenericModalForm = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    fields,
    title,
    openState,
    openModal,
    setUUID,
  } = props

  const closeDialog = () => {
    openModal(false)
    if (typeof setUUID !== 'undefined') {
      setUUID(undefined)
    }
  }

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  return (
    <React.Fragment>
      <Dialog open={openState} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <form onSubmit={props.handleSubmit}>
          <DialogContent>
            {fields.map(field => {
              if (field.type == 'textfield') {
                return (
                  <TextField
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )
              }
              if (field.type == 'textfield-multiline') {
                return (
                  <TextField
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={field.rows}
                  />
                )
              } else if (field.type == 'date') {
                return (
                  <TextField
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )
              } else if (field.type == 'selectfield') {
                return (
                  <TextField
                    select
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  >
                    {field.options.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              } else if (field.type == 'checkbox') {
                return (
                  <FormControlLabel
                    key={field.name}
                    control={
                      <Checkbox
                        checked={values[field.name]}
                        name={field.name}
                        color="primary"
                        onChange={change.bind(null, [field.name])}
                      />
                    }
                    label={field.label}
                  />
                )
              } else if (field.type == 'component') {
                return <Fragment key={field.name}>{field.component}</Fragment>
              }
            })}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

GenericModalForm.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  props: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  handleSubmit: PropTypes.func,
  openState: PropTypes.bool,
  openModal: PropTypes.func,
  setUUID: PropTypes.func,
  fields: PropTypes.array,
  title: PropTypes.string,
}

export default withStyles(styles)(GenericModalForm)
