import React, { useState } from 'react'
import PropTypes from 'prop-types'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { withStyles } from '@material-ui/core'
import { DateRangePicker } from 'react-dates'

// import './react_dates_overrides.css'

const styles = () => ({
  root: {},
})

const Component = ({
  // eslint-disable-next-line no-unused-vars
  classes,
  endDateHook: [endDate, setEndDate],
  startDateHook: [startDate, setStartDate],
}) => {
  const [focusedInput, setFocusedInput] = useState(null)
  const falseFunc = () => false

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="your_unique_start_date_id"
      endDate={endDate}
      endDateId="your_unique_end_date_id"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
      }}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => setFocusedInput(focusedInput)}
      isOutsideRange={falseFunc}
    />
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  endDateHook: PropTypes.array,
  startDateHook: PropTypes.array,
}

export default withStyles(styles)(Component)
