import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import Menu from './Menu'

const styles = theme => ({
  productDetails: {
    width: '100%',
  },
  productDetailsContent: {
    padding: theme.spacing(2),
  },
  productHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const Template = ({
  classes,
  fileItem: { uuid, originalFileName },
  productUUID,
  refetchQueries,
}) => {
  return (
    <ExpansionPanel className={classes.product} variant="outlined">
      <ExpansionPanelSummary>
        <div className={classes.productHeader}>
          <Typography variant="h6">{originalFileName}</Typography>
          <Menu
            productUUID={productUUID}
            uuid={uuid}
            refetchQueries={refetchQueries}
          />
        </div>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  )
}

Template.propTypes = {
  classes: PropTypes.object,
  fileItem: PropTypes.object,
  productUUID: PropTypes.string,
  refetchQueries: PropTypes.array,
}

export default compose(
  withStyles(styles),
  withRouter
)(Template)
