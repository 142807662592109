import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Paper, withStyles } from '@material-ui/core'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../assets/logos/Logo@4x.png'
import { store } from '../../util/redux/store'
import history from '../../util/router/history'
import * as actions from '../../util/redux/actions'
import { oClient, rClient } from '../../util/apollo/client'

import Login from './Login'
import Register from './Register'
import { buyerHomePath, moderatorHomePath } from './constants'

const logout = () => {
  oClient.resetStore()
  rClient.resetStore()
  store.dispatch(actions.logout.logoutUser())
  history.push({
    pathname: '/auth/login',
    state: { preventLastLocation: true },
  })
}

const styles = theme => ({
  root: {
    alignItems: 'center',
    background: '#f5f5f5',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingTop: 87 + theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(6),
    width: 250,
  },
})

const Auth = ({ classes, history, match, token, userType }) => {
  // Redirect to home if valid tokens
  if (token) {
    if (userType === 'buyer') history.push(buyerHomePath)
    if (userType === 'moderator') history.push(moderatorHomePath)
  }

  return (
    <Paper className={classes.root}>
      <img className={classes.logo} src={Logo} alt="PlaceIt" />
      <Switch>
        <Route path={match.url + '/login'} component={Login} />
        <Route path={match.url + '/register'} component={Register} />
        <Redirect to={'/auth/login'} />
      </Switch>
    </Paper>
  )
}

Auth.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  userType: PropTypes.string,
}

const mapStateToProps = state => ({
  token: state.tokens.token,
  userType: state.userInfo.type,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter
)(Auth)

export { logout }
