import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'
import { omitBy } from 'lodash'
import RRule from 'rrule'

import { Formik } from 'formik'

import { withStyles } from '@material-ui/core'
import { useMutation, withApollo, useQuery } from 'react-apollo'

import {
  CREATE_PRODUCT_SIZE,
  UPDATE_PRODUCT_SIZE,
  GET_PRODUCT_SIZE,
} from '../../../../../../../../util/apollo/queries/products'
import GenericModalForm from '../../../../../../../../shared/components/forms/GenericModalForm'

import RecurrenceFields from './RecurrenceFields'

const styles = theme => ({
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
})

function getInitialDatesAvailable() {
  return new RRule({
    freq: RRule.DAILY,
    interval: 1,
    dtstart: new Date(), // might be wrong because of TZs after converting from moment
  }).toString()
}

const AddNewAdType = ({
  adSizeUUID,
  refetchQueries,
  openModal,
  openState,
  productUUID,
}) => {
  const [createNewAdType] = useMutation(CREATE_PRODUCT_SIZE)
  const [updateAdType] = useMutation(UPDATE_PRODUCT_SIZE)
  const { loading, error, data } = useQuery(GET_PRODUCT_SIZE, {
    variables: { uuid: adSizeUUID },
    skip: !adSizeUUID,
  })

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`

  let initialData = {
    datesAvailable: getInitialDatesAvailable(),
    sponsoredContent: false,
    buyUnit: '',
  }
  if (data && data.allProductsizes) {
    initialData = {
      ...initialData,
      ...omitBy(data.allProductsizes.edges[0].node, value => value == null),
    }
    initialData.buyUnit = initialData.buyUnit.toLowerCase()
  }

  return (
    <React.Fragment>
      <Formik
        onSubmit={values => {
          if (!adSizeUUID) {
            createNewAdType({
              variables: { ...values, productUUID: productUUID },
              refetchQueries: refetchQueries,
            })
          } else if (adSizeUUID) {
            updateAdType({
              variables: { ...values, uuid: adSizeUUID },
              refetchQueries: refetchQueries,
            })
          }
          openModal(false)
        }}
        initialValues={initialData}
        render={props => (
          <GenericModalForm
            {...props}
            title={'Add New Ad Type'}
            openState={openState}
            openModal={openModal}
            fields={[
              {
                name: 'title',
                label: 'Title',
                type: 'textfield',
              },
              {
                name: 'description',
                label: 'Description',
                type: 'textfield',
              },
              {
                name: 'pricePerUnit',
                label: 'Price Per Unit',
                type: 'textfield',
              },
              {
                name: 'impressionsPerUnit',
                label: 'Impressions Per Unit',
                type: 'textfield',
              },
              {
                name: 'setupFee',
                label: 'Setup Fee',
                type: 'textfield',
              },
              {
                name: 'buyMin',
                label: 'Minimum Buy Quantity',
                type: 'textfield',
              },
              {
                name: 'buyMax',
                label: 'Maximum Buy Quantity',
                type: 'textfield',
              },
              {
                name: 'buyIncrement',
                label: 'Buy Increment',
                type: 'textfield',
              },
              {
                name: 'buyUnit',
                label: 'Buy Unit',
                type: 'selectfield',
                options: [
                  { value: 'day', title: 'Day' },
                  { value: 'week', title: 'Week' },
                  { value: 'month', title: 'Month' },
                  { value: 'year', title: 'Year' },
                  { value: 'publication_issue', title: 'Publication Issue' },
                  { value: 'show', title: 'Show' },
                ],
              },
              {
                name: 'sponsoredContent',
                label: 'Sponsored Content',
                type: 'checkbox',
              },
              {
                component: <RecurrenceFields />,
                name: 'recurrenceFields', // unused, except as a key
                type: 'component',
              },
            ]}
          />
        )}
      />
    </React.Fragment>
  )
}

AddNewAdType.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  adSizeUUID: PropTypes.string,
  noButton: PropTypes.bool,
  productUUID: PropTypes.string,
  refetchQueries: PropTypes.array,
  openState: PropTypes.bool,
  openModal: PropTypes.func,
}

export default compose(
  withApollo,
  withStyles(styles)
)(AddNewAdType)
