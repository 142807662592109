import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { Mutation } from 'react-apollo'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'

const EditDeleteMenu = ({
  uuid,
  mutationVariables,
  openModal,
  setUUID,
  mutation,
  refetchQueries,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const deleteHandleClose = (event, deleteItem) => {
    handleClose(event)
    deleteItem({
      variables: mutationVariables,
    })
  }

  const editHandleClose = event => {
    handleClose(event)
    openModal(true)
    setUUID(uuid)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = event => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  return (
    <div>
      <Mutation mutation={mutation} refetchQueries={refetchQueries}>
        {(deleteItem, { error }) => {
          if (error) alert(error)
          return (
            <>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                <MenuItem onClick={editHandleClose}>Edit</MenuItem>
                <MenuItem
                  onClick={event => {
                    deleteHandleClose(event, deleteItem)
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        }}
      </Mutation>
    </div>
  )
}

EditDeleteMenu.propTypes = {
  uuid: PropTypes.string,
  mutationVariables: PropTypes.object,
  openModal: PropTypes.func,
  setUUID: PropTypes.func,
  mutation: PropTypes.object,
  refetchQueries: PropTypes.array,
}

export default EditDeleteMenu
