import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, Tooltip, IconButton } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useQuery } from 'react-apollo'

import { GET_CONTRACT_DETAIL } from '../../../../util/apollo/queries/contracts'
import { GET_PAYMENT_METHODS } from '../../../../util/apollo/queries/payments'
import { BackButton } from '../../../../shared/components'
import AddPayment from '../../../../shared/dialogs/AddPayment'

import { removeEdgesAndNodes } from '../../../../shared/helperfunctions'
import Header from './Header'
import KeyDates from './KeyDates'
import PaymentSchedule from './PaymentSchedule'
import Files from './Files'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    marginTop: -theme.spacing(2),
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    overflow: 'hidden',
  },
})

const ContractDetail = ({
  classes,
  match: {
    params: { contractuuid, campaignuuid },
  },
}) => {
  const { loading, error, data } = useQuery(GET_CONTRACT_DETAIL, {
    variables: { contractuuid },
  })
  const {
    loading: loadingPayment,
    error: errorPayment,
    data: dataPayment,
  } = useQuery(GET_PAYMENT_METHODS, {
    variables: { campaignuuid },
  })
  if (loading || loadingPayment) return 'Loading...'
  if (error || errorPayment) return `Error! ${error.message}`
  if (!data) return null
  const contract = data.allContracts.edges[0].node
  let {
    actualBuyerPrice,
    channel,
    contractDate,
    contractSigningStatus,
    contractDocumentUrl,
    creativeStatus,
    endDate,
    fileItem,
    invoice,
    payment,
    paymentStatus,
    productSize: { product },
    stage,
    startDate,
    title,
    campaign,
    creativeUploaded,
    buyerContractSigned,
    preferredPaymentMethod,
  } = contract

  const generatePaymentElement = () => {
    return (
      <AddPayment
        payee={'buyer'}
        accountType="contract"
        accountTypeUUID={contractuuid}
        config={{
          payee: false,
          description: false,
          type: false,
          paymentMethod: true,
          amount: true,
          preferredPaymentMethod: removeEdgesAndNodes(
            dataPayment
          ).allPaymentmethods.edges.map(item => {
            return { uuid: item.uuid, description: item.description }
          }),
        }}
        preferredPaymentMethod={preferredPaymentMethod.uuid}
        description={'Buyer manual payment'}
        customButton={props => (
          <Tooltip title="Add Payment">
            <IconButton {...props}>
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
        )}
      />
    )
  }

  return (
    <div className={classes.root}>
      <BackButton
        className={classes.backButton}
        link={`/customer/${campaignuuid}/contracts`}
      />
      <Header
        actualBuyerPrice={actualBuyerPrice}
        channel={channel}
        contractSigningStatus={contractSigningStatus}
        contractDocumentUrl={contractDocumentUrl}
        creativeStatus={creativeStatus}
        paymentStatus={paymentStatus}
        product={product}
        stage={stage}
        startDate={startDate}
        endDate={endDate}
        title={title}
        clientuuid={campaign.client.uuid}
        creativeUploaded={creativeUploaded}
        buyerContractSigned={buyerContractSigned}
        invoice={invoice}
        fileItem={fileItem}
        paymentButton={generatePaymentElement()}
      />
      <KeyDates contractDate={contractDate} invoice={invoice} />
      <PaymentSchedule
        invoice={invoice}
        payment={payment}
        clientuuid={campaign.client.uuid}
        paymentButton={generatePaymentElement()}
      />
      <Files fileItem={fileItem} />
    </div>
  )
}

ContractDetail.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(ContractDetail)
