import gql from 'graphql-tag'

const GET_API_KEY = gql`
  query($apitype: String!) {
    getApiKey(apiType: $apitype) {
      apiKey
    }
  }
`

export { GET_API_KEY }
