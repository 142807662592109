import gql from 'graphql-tag'

const CREATE_CAMPAIGN = gql`
  mutation($name: String, $clientuuid: String) {
    createCampaign(input: { name: $name, client: $clientuuid }) {
      ok
      campaign {
        uuid
      }
    }
  }
`

const DELETE_CAMPAIGN = gql`
  mutation($campaignuuid: String!) {
    updateCampaign(input: { uuid: $campaignuuid, status: "inactive" }) {
      ok
      campaign {
        uuid
        status
      }
    }
  }
`

const GET_ALL_CAMPAIGNS = gql`
  query {
    allCampaigns(status: "active") {
      edges {
        node {
          uuid
          name
          status
          client {
            uuid
            name
          }
        }
      }
    }
  }
`

const GET_CAMPAIGN_LIST = gql`
  query($clientuuid: UUID!) {
    allCampaigns(client_Uuid: $clientuuid, status: "active") {
      edges {
        node {
          uuid
          name
          createdDate
          status
          contractAmountActive
          contractNumberActive
        }
      }
    }
  }
`

const GET_CAMPAIGN_USERS = gql`
  query($uuid: UUID) {
    allCampaigns(uuid: $uuid) {
      edges {
        node {
          uuid
          name
          status
          client {
            name
            user {
              uuid
              email
            }
          }
        }
      }
    }
  }
`

const GET_CAMPAIGN_DETAILS = gql`
  query($campaignuuid: UUID) {
    allCampaigns(uuid: $campaignuuid) {
      edges {
        node {
          name
          client {
            uuid
            name
            user {
              email
            }
          }
        }
      }
    }
  }
`

const GET_CLIENT = gql`
  query($uuid: UUID) {
    allCampaigns(uuid: $uuid) {
      edges {
        node {
          client {
            uuid
          }
        }
      }
    }
  }
`

const GET_CLIENT_NAME = gql`
  query($clientcampaignuuid: UUID!) {
    allCampaigns(uuid: $clientcampaignuuid) {
      edges {
        node {
          client {
            name
          }
        }
      }
    }
  }
`

const REMOVE_USER_LIST = gql`
  mutation($userList: [String], $uuid: String) {
    updateCampaign(input: { removeUser: $userList, uuid: $uuid }) {
      ok
    }
  }
`

const UPDATE_CAMPAIGN = gql`
  mutation($name: String, $campaignuuid: String) {
    createCampaign(input: { name: $name, campaign: $campaignuuid }) {
      ok
      campaign {
        uuid
      }
    }
  }
`

const UPDATE_USER_LIST = gql`
  mutation($userList: [String], $uuid: String) {
    updateCampaign(input: { userList: $userList, uuid: $uuid }) {
      ok
    }
  }
`

export {
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_USERS,
  GET_CAMPAIGN_DETAILS,
  GET_CLIENT,
  GET_CLIENT_NAME,
  REMOVE_USER_LIST,
  UPDATE_CAMPAIGN,
  UPDATE_USER_LIST,
}
