import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { useMutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  VERIFY_BANK_ACCOUNT,
  GET_PAYMENT_METHODS,
} from '../../../../../util/apollo/queries/payments'

import { withNotifications } from '../../../../../util'

const styles = theme => ({
  creditCardYear: {
    display: 'inline-block',
    width: 100,
    marginLeft: theme.spacing(2),
  },
  dialogContent: {
    width: 400,
    overflow: 'hidden',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  expirationText: {
    marginTop: theme.spacing(2),
  },
  liabilityWarning: {
    margin: theme.spacing(1),
  },
  monthSelect: {
    width: 100,
  },
  pushToRight: {
    marginLeft: 'auto',
  },
})

const ConfirmAccountButton = ({
  classes,
  paymentmethoduuid,
  match: {
    params: { clientuuid },
  },
  notify,
}) => {
  const [open, setOpen] = useState(false)
  const [formControl, setFormControl] = useState({
    deposit1: 0,
    deposit2: 0,
  })

  const updateState = e => {
    // Intentional pattern below to cache target for asynchronous state update
    // since React reuses the event object for SyntheticEvents
    const target = e.target
    setFormControl(prev => ({
      ...prev,
      [target.name]: target.value,
    }))
  }

  const updateOpen = state => () => setOpen(state)

  const [mutation] = useMutation(VERIFY_BANK_ACCOUNT)

  const confirmPaymentMethod = e => {
    e.preventDefault()
    updateOpen(false)
    mutation({
      variables: {
        deposit1: formControl.deposit1,
        deposit2: formControl.deposit2,
        paymentmethoduuid,
      },
      refetchQueries: [
        { query: GET_PAYMENT_METHODS, variables: { clientuuid } },
      ],
    }).then(({ data }) => {
      if (data.verifyBankAccount.ok) notify.success('Payment Verified')
      else notify.success('Payment Failed')
    })
  }

  return (
    <>
      <IconButton onClick={updateOpen(true)}>
        <AccountBalanceIcon />
      </IconButton>
      <Dialog open={open} onClose={updateOpen(false)}>
        <DialogTitle>Confirm Deposits</DialogTitle>
        <form onSubmit={confirmPaymentMethod}>
          <DialogContent className={classes.dialogContent}>
            <Typography
              color="textSecondary"
              className={classes.liabilityWarning}
            >
              {`There will be two small deposits in your bank account. Please enter those amounts below to confirm your ACH payment method.`}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  name="deposit1"
                  label="Deposit 1"
                  value={formControl.deposit_1}
                  onChange={updateState}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="deposit2"
                  label="Deposit 2"
                  value={formControl.deposit_2}
                  onChange={updateState}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>

          <Divider />
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

ConfirmAccountButton.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  client: PropTypes.object,
  paymentmethoduuid: PropTypes.string.isRequired,
  match: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withNotifications,
  withRouter,
  withApollo
)(ConfirmAccountButton)
