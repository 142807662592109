import React from 'react'
import { PropTypes } from 'prop-types'

import { Formik } from 'formik'
import { useQuery, useMutation } from 'react-apollo'

import {
  CREATE_CONTACT,
  GET_CONTACT,
  UPDATE_CONTACT,
} from '../../../../../../util/apollo/queries/contacts'
import { removeEdgesAndNodes } from '../../../../../../shared/helperfunctions'
import GenericModalForm from '../../../../../../shared/components/forms/GenericModalForm'

const BLANK_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  type: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressZip: '',
  primaryCompanyContact: '',
}

const AddNewContact = ({
  companyUUID,
  contactUUID,
  openState,
  openModal,
  setContactUUID,
  refetchQueries,
}) => {
  const { loading, error, data } = useQuery(GET_CONTACT, {
    variables: { uuid: contactUUID },
    skip: !contactUUID,
  })
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onError: alert,
    refetchQueries,
  })
  const [createContact] = useMutation(CREATE_CONTACT, {
    onError: alert,
    refetchQueries,
  })

  if (loading) return 'Loading...'
  if (error) {
    return null
  }

  if (contactUUID && !(data && data.allContacts.edges[0])) {
    return 'Error: Contact Not Found'
  }

  const cleanedData = data && removeEdgesAndNodes(data.allContacts).edges[0]
  const initialValues = contactUUID ? cleanedData : BLANK_INITIAL_VALUES
  initialValues.type = initialValues.type.toLowerCase()
  return (
    <div>
      <Formik
        onSubmit={async values => {
          if (contactUUID) {
            await updateContact({
              variables: {
                ...values,
                uuid: contactUUID,
              },
            }).catch(console.error)
          } else {
            await createContact({
              variables: {
                ...values,
                companyUUID,
              },
            })
          }
          openModal(false)
          setContactUUID(undefined)
        }}
        enableReinitialize
        initialValues={initialValues}
        render={props => (
          <GenericModalForm
            {...props}
            title={`${contactUUID ? 'Update' : 'Add New'} Contact`}
            fields={[
              {
                name: 'type',
                label: 'Department',
                type: 'selectfield',
                options: [
                  { value: 'production', title: 'Production' },
                  { value: 'billing', title: 'Billing' },
                  { value: 'sales', title: 'Sales' },
                  { value: 'other', title: 'Other' },
                ],
              },
              {
                name: 'firstName',
                label: 'First Name',
                type: 'textfield',
              },
              { name: 'lastName', label: 'Last Name', type: 'textfield' },
              { name: 'email', label: 'Email', type: 'textfield' },
              { name: 'phone', label: 'Phone', type: 'textfield' },
              {
                name: 'addressLine1',
                label: 'Address Line 1',
                type: 'textfield',
              },
              {
                name: 'addressLine2',
                label: 'Address Line 2',
                type: 'textfield',
              },
              { name: 'addressCity', label: 'City', type: 'textfield' },
              { name: 'addressState', label: 'State', type: 'textfield' },
              { name: 'addressZip', label: 'Zip', type: 'textfield' },
              {
                name: 'primaryCompanyContact',
                label: 'Primary Company Contact',
                type: 'selectfield',
                options: [
                  { value: false, title: 'False' },
                  { value: true, title: 'True' },
                ],
              },
            ]}
            openState={openState}
            openModal={openModal}
            setUUID={setContactUUID}
          />
        )}
      />
    </div>
  )
}

AddNewContact.propTypes = {
  classes: PropTypes.object,
  companyUUID: PropTypes.string,
  contactUUID: PropTypes.string,
  openState: PropTypes.bool,
  openModal: PropTypes.func,
  setContactUUID: PropTypes.func,
  refetchQueries: PropTypes.array,
}

export default AddNewContact
