import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  withStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const styles = theme => ({
  button: {
    textTransform: 'none',
  },
  dialog: {
    minWidth: 400,
  },
  domain: {
    alignItems: 'center',
    border: 'solid 1px #e0e0e0',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(1),
    width: 350,
  },
  textField: {
    width: 384,
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
})

const GET_DOMAINS = gql`
  query($companyUUID: UUID!) {
    allCompanies(uuid: $companyUUID) {
      edges {
        node {
          id
          emailDomain
        }
      }
    }
  }
`

const SET_DOMAINS = gql`
  mutation($companyUUID: String!, $emailDomains: String!) {
    updateCompany(input: { uuid: $companyUUID, emailDomain: $emailDomains }) {
      ok
      company {
        id
        emailDomain
      }
    }
  }
`

const ManageDomains = ({ classes, match }) => {
  const uuid = match.params.uuid
  const apollo_variables = {
    companyUUID: uuid,
  }

  const [open, openModal] = useState(false)
  const [domains, setDomains] = useState([])
  const [newDomain, setNewDomain] = useState('')

  const addDomain = (e, mutation) => {
    e.preventDefault()

    const variables = {
      companyUUID: uuid,
      emailDomains: [...domains, newDomain].join(','),
    }

    mutation({ variables })

    setNewDomain('')
    setDomains([...domains, newDomain])
  }

  const removeDomain = (e, mutation, domainToRemove) => {
    e.preventDefault()

    const variables = {
      companyUUID: uuid,
      emailDomains: domains
        .filter(domain => domain !== domainToRemove)
        .join(','),
    }

    mutation({ variables })
    setDomains(domains.filter(domain => domain !== domainToRemove))
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => openModal(true)}
      >
        Manage Domains
      </Button>
      <Dialog
        open={open}
        onClose={() => openModal(false)}
        className={classes.dialog}
      >
        <DialogTitle>Manage Domains</DialogTitle>
        <Divider />
        <Query query={GET_DOMAINS} variables={apollo_variables}>
          {({ loading, error, data }) => {
            if (loading) return 'Loading'
            if (error) return 'Error'
            const tmpDomains = data.allCompanies.edges[0].node.emailDomain.split(
              ','
            )
            if (domains.toString() !== tmpDomains.toString())
              setDomains(data.allCompanies.edges[0].node.emailDomain.split(','))
            return (
              <>
                <DialogContent>
                  {domains.map((domain, index) => (
                    <Mutation mutation={SET_DOMAINS} key={index}>
                      {(mutation, { error }) => {
                        if (error) alert(error)
                        return (
                          <form
                            onSubmit={e => removeDomain(e, mutation, domain)}
                          >
                            <div className={classes.domain}>
                              <Typography>{domain}</Typography>
                              <IconButton type="submit">
                                <ClearIcon />
                              </IconButton>
                            </div>
                          </form>
                        )
                      }}
                    </Mutation>
                  ))}
                </DialogContent>
                <Divider />
                <Mutation mutation={SET_DOMAINS}>
                  {mutation => (
                    <form onSubmit={e => addDomain(e, mutation)}>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        label="Add New Domain"
                        placeholder="@gmail.com"
                        value={newDomain}
                        onChange={e => setNewDomain(e.target.value)}
                      />
                      <DialogActions>
                        <Button
                          onClick={() => openModal(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Add
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Mutation>
              </>
            )
          }}
        </Query>
      </Dialog>
    </React.Fragment>
  )
}

ManageDomains.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(ManageDomains)
