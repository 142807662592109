import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { useQuery } from 'react-apollo'
import {
  withStyles,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { removeEdgesAndNodes } from '../../../../../shared/helperfunctions'
import { GET_BUYER_PROFILE } from '../../../../../util/apollo/queries/contacts'

import PrimaryContact from './PrimaryContact'
import ManageDomains from './ManageDomains'
import ActivationButton from './ActivationButton'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
  header: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
  },
  button: {
    textTransform: 'none',
  },
  summaryContainer: {
    display: 'flex',
  },
  summaryItem: {
    textAlign: 'center',
    padding: theme.spacing(1),
    margin: 16,
    minWidth: 160,
  },
  summaryPaper: {
    marginTop: theme.spacing(3),
  },
  toolbar: {
    paddingRight: theme.spacing(3),
  },
  numberColor: {
    color: '#0dbffe',
  },
  halfPageModal: {
    margin: `${theme.spacing(3)}px 0`,
    height: '100%',
  },
})

const Overview = ({ classes, match, refetchQueries, status, setStatus }) => {
  const { uuid } = match.params
  const query_variables = {
    buyerUUID: uuid,
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { loading, error, data } = useQuery(GET_BUYER_PROFILE, {
    variables: query_variables,
  })
  if (loading) return 'Loading...'
  if (error) return 'Error'

  removeEdgesAndNodes(data)
  const company = data.allCompanies.edges[0]
  const { name: companyName } = company

  if (status !== company.status.toLowerCase())
    setStatus(company.status.toLowerCase())

  const primaryContact = company.contact.edges.find(
    contactItem => contactItem.primaryCompanyContact
  )
  const users = company.contact.edges.filter(
    contactItem => !contactItem.primaryCompanyContact
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.halfPageModal}>
            <div className={classes.header}>
              <div>
                <Typography variant="h5" noWrap>
                  {companyName}
                </Typography>
                <Typography variant="caption">({status})</Typography>
              </div>
              <ActivationButton
                uuid={uuid}
                status={status}
                refetchQueries={refetchQueries}
              />
            </div>
            <Divider />
            <PrimaryContact
              refetchQueries={refetchQueries}
              primaryContact={primaryContact}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.halfPageModal}>
            <div className={classes.header}>
              <Typography variant="h6">Users</Typography>
              <ManageDomains />
            </div>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {`${user.firstName} ${user.lastName}`}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TablePagination
                  classes={{
                    toolbar: classes.toolbar,
                  }}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.summaryPaper}>
            <div className={classes.header}>
              <Typography variant="h6">Summary Stats</Typography>
            </div>
            <Divider />
            <Grid container spacing={2}>
              <Grid item>
                <Paper className={classes.summaryItem}>
                  <Typography variant="h4" className={classes.numberColor}>
                    {company.userCount}
                  </Typography>
                  <Typography nowrap="True">Total Users</Typography>
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.summaryItem}>
                  <Typography variant="h4" className={classes.numberColor}>
                    {company.clientCount}
                  </Typography>
                  <Typography nowrap="True">Total Clients</Typography>
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.summaryItem}>
                  <Typography variant="h4" className={classes.numberColor}>
                    {company.campaignCount}
                  </Typography>
                  <Typography nowrap="True">Total Campaigns</Typography>
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.summaryItem}>
                  <Typography variant="h4" className={classes.numberColor}>
                    {`$${company.contractAmountAll}`}
                  </Typography>
                  <Typography noWrap="True">Total Contract Value</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  refetchQueries: PropTypes.array,
}

export default compose(
  withStyles(styles),
  withRouter
)(Overview)
