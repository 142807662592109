import gql from 'graphql-tag'

const CHART_QUERY = gql`
  query(
    $startDate: String!
    $endDate: String!
    $campaignuuid: String
    $qType: String!
  ) {
    summaryStatistics(
      startDate: $startDate
      endDate: $endDate
      campaign: $campaignuuid
      queryType: $qType
    ) {
      edges {
        node {
          results {
            x
            y
            category
          }
        }
      }
    }
  }
`

export { CHART_QUERY }
