import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'

import {
  formatDate,
  capitalizationCase,
} from '../../../../../shared/helperfunctions'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  header: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 0`,
  },
})

const KeyDates = ({ classes, contractDate }) => {
  const keyDates = [...contractDate.edges.map(({ node }) => node)]
    .filter(
      item =>
        item.type != 'CREATIVE_PROOF_DUE' &&
        item.type != 'PROOF_OF_DELIVERY_DUE'
    )
    .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Key Dates</Typography>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Due Date</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keyDates.length ? (
            keyDates.map(({ uuid, dueDate, description, amount }) => (
              <TableRow key={uuid}>
                <TableCell>{formatDate(dueDate)}</TableCell>
                <TableCell>
                  {capitalizationCase(description) ||
                    `Invoice due: $${amount.toFixed(2)}`}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>(none)</TableCell>
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

KeyDates.propTypes = {
  classes: PropTypes.object,
  contractDate: PropTypes.object,
  invoice: PropTypes.array,
}

export default withStyles(styles)(KeyDates)
