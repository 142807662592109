import gql from 'graphql-tag'

const ADD_NEW_COMPANY_LOGO = gql`
  mutation($companyUUID: String, $fileUUID: String) {
    updateCompany(input: { uuid: $companyUUID, logo: $fileUUID }) {
      ok
    }
  }
`

const SET_ACCOUNT_STATUS = gql`
  mutation($companyUUID: String!, $status: String!) {
    updateCompany(input: { uuid: $companyUUID, status: $status }) {
      ok
    }
  }
`

const GET_COMPANY_USERS = gql`
  query {
    allUsers {
      edges {
        node {
          uuid
          email
          firstName
        }
      }
    }
  }
`

const GET_COMPANY_SUMMARY = gql`
  query($companyType: String, $companyUUID: UUID, $companyName: String) {
    allCompanies(
      uuid: $companyUUID
      type: $companyType
      name: $companyName
      contact_PrimaryCompanyContact: true
    ) {
      edges {
        node {
          uuid
          name
          website
          phone
          type
          contact {
            edges {
              node {
                uuid
                firstName
                lastName
                phone
                email
              }
            }
          }
        }
      }
    }
  }
`

const GET_COMPANY_SUMMARY_LIMITED = gql`
  query(
    $companyType: String
    $companyUUID: UUID
    $status: String
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allCompanies(
      uuid: $companyUUID
      type: $companyType
      status: $status
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uuid
          name
          website
          phone
          type
          status
        }
      }
    }
  }
`

const GET_COMPANY_INFO = gql`
  query($uuid: UUID) {
    allCompanies(uuid: $uuid) {
      edges {
        node {
          uuid
          name
          website
          phone
          product {
            edges {
              node {
                uuid
                title
              }
            }
          }
          contact {
            edges {
              node {
                uuid
                firstName
                lastName
                type
                phone
                email
                addressLine1
                addressLine2
                addressState
                addressCountry
                addressZip
              }
            }
          }
        }
      }
    }
  }
`

const UPDATE_COMPANY_SUMMARY = gql`
  mutation($uuid: String, $name: String, $website: String, $phone: String) {
    updateCompany(
      input: { uuid: $uuid, name: $name, website: $website, phone: $phone }
    ) {
      ok
      company {
        uuid
      }
    }
  }
`

const CREATE_COMPANY = gql`
  mutation(
    $name: String
    $website: String
    $phone: String
    $type: String
    $domain: String
  ) {
    createCompany(
      input: {
        name: $name
        website: $website
        phone: $phone
        type: $type
        emailDomain: $domain
      }
    ) {
      ok
      company {
        uuid
      }
    }
  }
`

const GET_DOMAINS = gql`
  query($companyUUID: UUID!) {
    allCompanies(uuid: $companyUUID) {
      edges {
        node {
          id
          emailDomain
        }
      }
    }
  }
`

const SET_DOMAINS = gql`
  mutation($companyUUID: String!, $emailDomains: String!) {
    updateCompany(input: { uuid: $companyUUID, emailDomain: $emailDomains }) {
      ok
      company {
        id
        emailDomain
      }
    }
  }
`

export {
  ADD_NEW_COMPANY_LOGO,
  GET_COMPANY_INFO,
  GET_COMPANY_SUMMARY,
  GET_COMPANY_USERS,
  UPDATE_COMPANY_SUMMARY,
  CREATE_COMPANY,
  GET_COMPANY_SUMMARY_LIMITED,
  SET_ACCOUNT_STATUS,
  GET_DOMAINS,
  SET_DOMAINS,
}
