import gql from 'graphql-tag'

const SEND_FEEDBACK = gql`
  mutation($feedback: String) {
    createFeedback(input: { feedback: $feedback }) {
      ok
    }
  }
`

export { SEND_FEEDBACK }
