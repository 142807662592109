import { combineReducers } from 'redux'

import campaigns from './campaigns'
import notifications from './notifications'
import tokens from './tokens'
import userInfo from './userInfo'

const appReducer = combineReducers({
  campaigns,
  notifications,
  tokens,
  userInfo,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
