import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import { useQuery, withApollo } from 'react-apollo'
import { connect } from 'react-redux'

import {
  ADD_USER,
  GET_ALL_CLIENTS,
  GET_CLIENTS,
} from '../../../../../util/apollo/queries/clients'
import { GET_ALL_CAMPAIGNS } from '../../../../../util/apollo/queries/campaigns'
import * as actions from '../../../../../util/redux/actions'
import { AutoSuggestion } from '../../../../../shared/components'
import { withNotifications } from '../../../../../util'

const styles = theme => ({
  root: {
    marginRight: theme.spacing(1),
  },
  content: {},
  contentRoot: {
    overflow: 'visible',
  },
  paper: {
    overflow: 'visible',
  },
})

const Component = ({ classes, client, notify, setCampaigns, useruuid }) => {
  const [open, setOpen] = useState(false)
  const openStatus = state => () => setOpen(state)

  const [autocompleteValue, setAutocompleteValue] = useState(null)

  const { data: { allClients } = {} } = useQuery(GET_ALL_CLIENTS)

  const updateCampaigns = () =>
    client
      .query({
        query: GET_ALL_CAMPAIGNS,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { allCampaigns: { edges } } }) => {
        const campaigns = edges.map(({ node }) => node)
        return setCampaigns(campaigns)
      })

  const submitHandler = () => {
    if (!autocompleteValue) return notify.error('Please select a client')

    const { value: clientuuid, label: name } = autocompleteValue

    client
      .mutate({
        mutation: ADD_USER,
        variables: { userList: useruuid, clientuuid },
        refetchQueries: [{ query: GET_CLIENTS, variables: { useruuid } }],
      })
      .then(({ data }) => {
        if (data.updateClient.ok) {
          updateCampaigns().then(() => notify.success('Added ' + name))
        }
      })

    setOpen(false)
  }

  return (
    <>
      <Button
        className={classes.root}
        onClick={openStatus(true)}
        variant="contained"
      >
        Add Existing
      </Button>
      <Dialog
        open={open}
        onClose={openStatus(false)}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>Add Existing Client</DialogTitle>
        <DialogContent
          className={classes.content}
          classes={{ root: classes.contentRoot }}
        >
          <AutoSuggestion
            className={classes.autoSuggestion}
            options={
              allClients
                ? allClients.edges
                    .map(({ node }) => node)
                    .map(({ uuid, name }) => ({
                      value: uuid,
                      label: name,
                    }))
                : []
            }
            value={autocompleteValue}
            setValue={setAutocompleteValue}
            selectProps={{
              placeholder: 'Select Client',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={openStatus(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={submitHandler}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  useruuid: state.userInfo.uuid,
})

const mapDispatchToProps = dispatch => ({
  setCampaigns: campaigns =>
    new Promise(resolve => {
      dispatch(actions.campaigns.setCampaigns(campaigns))
      resolve(campaigns)
    }),
})

Component.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  notify: PropTypes.object,
  setCampaigns: PropTypes.func,
  useruuid: PropTypes.string,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withNotifications,
  withApollo
)(Component)
