import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, Grid, Toolbar } from '@material-ui/core'
import { Route, Switch, Redirect } from 'react-router-dom'

import { LeftNavigation, NavigationHeader } from '../../shared/user-interface'

import Overview from './Overview'
import Search from './Search'
import Messages from './Messages'
import Proposal from './Proposal'
import Invoice from './Invoices'
import Settings from './Settings'
import Contracts from './Contracts'
import Products from './Products'
import Client from './Client'

const styles = () => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  bodyContainer: {
    flexGrow: 1,
  },
  navigationBox: {
    scroll: 'auto',
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  bodyBox: {
    scroll: 'auto',
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '100vh',
  },
})

const Customer = ({
  classes,
  match: {
    params: { campaignuuid },
    path,
  },
}) => {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.header}>
        <NavigationHeader userType="customer" />
      </Grid>
      <Grid container className={classes.bodyContainer}>
        <Grid item xs={2} className={classes.navigationBox}>
          <LeftNavigation userType="customer" />
        </Grid>
        <Grid item xs={10} className={classes.bodyBox}>
          <Toolbar />
          <Switch>
            <Route path={path + '/overview'} component={Overview} />
            <Route path={path + '/search'} component={Search} />
            <Route
              path={path + '/messages/:filter?/:channeluuid?'}
              component={Messages}
            />
            <Route path={path + '/products'} component={Products} />
            <Route path={path + '/proposal'} component={Proposal} />
            <Route path={path + '/contracts'} component={Contracts} />
            <Route path={path + '/invoices'} component={Invoice} />
            <Route path={path + '/settings/:tab?'} component={Settings} />
            <Route path={path + '/client'} component={Client} />
            <Redirect to={`/customer/${campaignuuid}/overview`} />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  )
}

Customer.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Customer)
