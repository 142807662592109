import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { GET_BUYER_PROFILE } from '../../../../util/apollo/queries/contacts'

import Overview from './Overview'
import CreateNewBuyer from './New'

const View = ({ match }) => {
  const { uuid } = match.params
  const [status, setStatus] = useState(null)

  const creatingNewBuyer = !uuid

  const refetchQueries = [
    {
      query: GET_BUYER_PROFILE,
      variables: {
        buyerUUID: uuid,
      },
    },
  ]

  if (creatingNewBuyer) {
    return <CreateNewBuyer />
  } else {
    return (
      <Overview
        refetchQueries={refetchQueries}
        setStatus={setStatus}
        status={status}
      />
    )
  }
}

View.propTypes = {
  match: PropTypes.object,
}

export default View
