import React, { useState, Fragment } from 'react'
import RRule, { RRuleSet } from 'rrule'
import { useField } from 'formik'
import { Button, List, Divider } from '@material-ui/core'

import { getRRuleSetFromValue } from '../../../../../../../../../shared/helperfunctions'

import NewRuleDialog from './NewRuleDialog'
import Rule from './Rule'

function createRRuleSet(rrules) {
  const rruleSet = new RRuleSet()
  rrules.forEach(rrule => rruleSet.rrule(rrule))
  return rruleSet
}
export default function RecurrenceFields() {
  const [{ value }, , { setValue: onChange }] = useField('datesAvailable')
  const [modalType, setModalType] = useState(null)

  const rruleSet = getRRuleSetFromValue(value)
  const rrules = rruleSet.rrules()

  return (
    <>
      <List>
        {rrules.map((rrule, index) => (
          <Fragment key={rrule.toString()}>
            <Divider />
            <Rule
              value={rrule}
              type="rrule"
              deleteEnabled={rrules.length > 1}
              onDelete={() => {
                const copy = [...rrules]
                copy.splice(index, 1)
                const rruleSet = createRRuleSet(copy)
                onChange(rruleSet.toString())
              }}
            />
          </Fragment>
        ))}
      </List>
      <Button
        variant="contained"
        onClick={() => setModalType('newRecurringRule')}
      >
        Add new recurring rule
      </Button>
      <NewRuleDialog
        modalType={modalType}
        onClose={() => setModalType(null)}
        onSave={value => {
          const allRrulesAsStrings = new Set(
            rrules.map(rrule => rrule.toString())
          )
          // If the set already contains this rrule, dont add it
          if (allRrulesAsStrings.has(value)) {
            return
          }
          const newRrules = rrules.concat(RRule.fromString(value))
          const newRruleSet = createRRuleSet(newRrules)
          onChange(newRruleSet.toString())
        }}
      />
    </>
  )
}
