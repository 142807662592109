import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  withStyles,
  Button,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { removeEdgesAndNodes } from '../../../../../../shared/helperfunctions'
import { GET_PRODUCT_SIZES } from '../../../../../../util/apollo/queries/products'

import AdType from './AdType'
import NewAdType from './AdType/New'

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  pushRight: {
    marginLeft: 'auto',
  },
})

const AdTypes = ({ classes, productUUID }) => {
  const [openState, openModal] = useState(false)
  const { loading, error, data } = useQuery(GET_PRODUCT_SIZES, {
    variables: {
      productUuid: productUUID,
      status: 'active',
    },
  })
  const refetchQueries = [
    {
      query: GET_PRODUCT_SIZES,
      variables: {
        productUuid: productUUID,
        status: 'active',
      },
    },
  ]

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  const cleanedData = removeEdgesAndNodes(data.allProductsizes)
  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Ad Types</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal(true)}
        >
          Add New Ad Type
        </Button>
        <NewAdType
          className={classes.pushRight}
          productUUID={productUUID}
          openState={openState}
          openModal={openModal}
          refetchQueries={refetchQueries}
        />
      </div>
      <Divider />
      {productUUID && (
        <div className={classes.content}>
          {cleanedData.edges.map((adType, index) => (
            <AdType
              key={index}
              index={index}
              adType={adType}
              refetchQueries={refetchQueries}
            />
          ))}
        </div>
      )}
      {!productUUID && <div className={classes.content} />}
    </Paper>
  )
}

AdTypes.propTypes = {
  classes: PropTypes.object,
  productUUID: PropTypes.string,
}

export default withStyles(styles)(AdTypes)
