import React, { useMemo, useState } from 'react'
import {
  Divider,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import clsx from 'clsx'

import {
  titleCase,
  removeEdgesAndNodes,
} from '../../../../../shared/helperfunctions'
import { AddPayment } from '../../../../../shared/dialogs'
import {
  GET_BUYER_CONTACTS,
  GET_SELLER_CONTACTS,
} from '../../../../../util/apollo/queries/contacts'

const Contact = ({ classes, className, contactType, contract }) => {
  const [contact, setContact] = useState(undefined)
  const [company, setCompany] = useState(undefined)
  const [contactIndex, setContactIndex] = useState(0)

  const query = useMemo(
    () => (contactType == 'buyer' ? GET_BUYER_CONTACTS : GET_SELLER_CONTACTS),
    [contactType]
  )

  const { loading, error, data } = useQuery(query, {
    variables: { uuid: contract },
  })
  if (loading) return 'Loading...'
  if (error) return alert(error.message)

  const setContactInfo = (allContacts, index) => {
    setContact(allContacts[index])
    setCompany(allContacts[index].company)
    setContactIndex(index)
  }

  const initializeContactInfo = (allContacts, index) =>
    !contact && !company && setContactInfo(allContacts, index)
  let options = <MenuItem value={''}>Loading</MenuItem>
  let allContacts
  removeEdgesAndNodes(data.allContracts)
  if (contactType == 'buyer') {
    allContacts = [data.allContracts.edges[0].buyerContact]
  } else if (contactType == 'seller') {
    allContacts =
      data.allContracts.edges[0].productSize.product.seller.contact.edges
  }

  options = allContacts
    .filter(item => item.status == 'ACTIVE')
    .map(({ firstName, lastName, type }, index) => (
      <MenuItem key={index} value={index}>
        {titleCase(type)} Contact ({`${firstName} ${lastName}`})
      </MenuItem>
    ))
  initializeContactInfo(allContacts, 0)

  return (
    <Paper className={clsx(className, classes.halfPageModal)}>
      <div className={classes.header}>
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {titleCase(contactType)}:
          </Typography>
          <Typography variant="h6">{allContacts[0].company.name}</Typography>
        </div>
        <AddPayment
          payee={contactType}
          accountType="contract"
          config={{
            payee: { [contactType]: true },
            paymentMethod: true,
            amount: true,
            description: true,
            type: { payment: true, credit: true },
          }}
          buttonProps={{
            variant: 'contained',
            color: 'primary',
          }}
        />
      </div>
      <Divider />
      <div className={classes.header}>
        <TextField
          value={contactIndex}
          onChange={e => setContactInfo(allContacts, e.target.value)}
          className={classes.textField}
          variant="outlined"
          select
          required
        >
          {options}
        </TextField>
      </div>
      <Divider />
      <div className={classes.infoContainer}>
        <div className={classes.info}>
          <Typography variant="caption" className={classes.caption}>
            Name
          </Typography>
          <Typography>
            {contact ? `${contact.firstName} ${contact.lastName}` : '-'}
          </Typography>
        </div>
        <div className={classes.info}>
          <Typography variant="caption" className={classes.caption}>
            Company
          </Typography>
          <Typography>{company ? company.name || '-' : '-'}</Typography>
        </div>
        <div className={classes.info}>
          <Typography variant="caption" className={classes.caption}>
            Email
          </Typography>
          <Typography>{contact ? contact.email || '-' : '-'}</Typography>
        </div>
        <div className={classes.info}>
          <Typography variant="caption" className={classes.caption}>
            Phone
          </Typography>
          <Typography>{contact ? contact.phone || '-' : '-'} </Typography>
        </div>
        <div className={classes.info}>
          <Typography variant="caption" className={classes.caption}>
            Physical Address
          </Typography>
          {contact && (
            <>
              <Typography>{contact.addressLine1}</Typography>
              {contact.addressLine2 && (
                <Typography>{contact.addressLine2}</Typography>
              )}
              <Typography>{`${contact.addressCity}, ${contact.addressState} ${
                contact.addressZip
              } ${contact.addressCountry}`}</Typography>
            </>
          )}
        </div>
        {contactType === 'buyer' && (
          <div className={classes.info}>
            <Typography variant="caption" className={classes.caption}>
              Buyer Website
            </Typography>
            <Typography>
              {company
                ? (company.website && (
                    <a href={company.website}>{company.website}</a>
                  )) ||
                  '-'
                : '-'}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  )
}

Contact.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  contract: PropTypes.string,
  contactType: PropTypes.string,
}

export default Contact
