import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'

const styles = () => ({
  root: {
    cursor: 'pointer',
  },
})

const FileDownload = ({
  downloadAs,
  children,
  noMarkup,
  url,
  method = 'GET',
  body = null,
  token,
}) => {
  const downloadFile = async () => {
    await fetch(url, {
      method: method,
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json',
      },
      body: body,
    })
      .then(response => response.blob())
      .then(blob => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', downloadAs || 'download')

        // 3. Append to html page
        document.body.appendChild(link)

        // 4. Force download
        link.click()

        // 5. Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(error => {
        alert(error)
      })
  }

  //Apply onClick to outer node of children
  const modifiedChildren = React.Children.map(children, (child, index) => {
    if (index === 0 && noMarkup)
      return React.cloneElement(child, { onClick: downloadFile })
    return React.cloneElement(child)
  })

  return modifiedChildren
}

FileDownload.propTypes = {
  downloadAs: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  client: PropTypes.object,
  token: PropTypes.string,
  url: PropTypes.string,
  method: PropTypes.string,
  noMarkup: PropTypes.bool,
  body: PropTypes.string,
}

const mapStateToProps = state => ({
  token: state.tokens.token,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withApollo
)(FileDownload)
