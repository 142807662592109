import React from 'react'
import PropTypes from 'prop-types'

import {
  withStyles,
  Divider,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core'

import { formatAddress } from '../../../../../../shared/helperfunctions'
import EditDeleteMenu from '../../../../../../shared/components/forms/EditDeleteMenu'

const styles = theme => ({
  root: {},
  contactHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  contactInfo: {
    padding: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
})

const Contact = ({
  classes,
  contact,
  openModal,
  setContactUUID,
  refetchQueries,
  deleteMutation,
}) => {
  const {
    type,
    email,
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZip,
    uuid,
  } = contact
  const name = firstName + ' ' + lastName
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <div className={classes.contactHeader}>
          <div>
            <Typography variant="h6" display="inline">
              {name}{' '}
            </Typography>
            <Typography variant="body1" color="textSecondary" display="inline">
              ({type})
            </Typography>
          </div>
          <EditDeleteMenu
            uuid={uuid}
            mutationVariables={{ uuid: uuid }}
            openModal={openModal}
            setUUID={setContactUUID}
            mutation={deleteMutation}
            refetchQueries={refetchQueries}
          />
        </div>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails className={classes.contactInfo}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography variant="caption">Department</Typography>
            <Typography variant="body1">{type}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption">Email</Typography>
            <Typography variant="body1">{email}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">Phone</Typography>
            <Typography variant="body1">{phone}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption">Physical Address</Typography>
            <Typography variant="body1">
              {formatAddress(
                addressLine1,
                addressLine2,
                addressCity,
                addressState,
                addressZip
              )}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

Contact.propTypes = {
  classes: PropTypes.object,
  contact: PropTypes.object,
  index: PropTypes.number,
  openModal: PropTypes.func,
  setContactUUID: PropTypes.func,
  refetchQueries: PropTypes.array,
  deleteMutation: PropTypes.object,
}

export default withStyles(styles)(Contact)
