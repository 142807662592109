import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  withStyles,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { Mutation } from 'react-apollo'

const styles = () => ({})

const ConfirmAction = ({
  classes,
  buttonLabel,
  MUTATION,
  MUTATION_VARIABLES,
  disabled,
  REFETCHQUERIES,
}) => {
  const [open, openModal] = useState(false)

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    mutation({ variables: MUTATION_VARIABLES })
    openModal(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => openModal(true)}
        disabled={!!disabled}
      >
        {buttonLabel}
      </Button>
      <Dialog open={open} onClose={() => openModal(false)}>
        <DialogTitle>{buttonLabel}?</DialogTitle>
        <Divider />
        <Mutation mutation={MUTATION} refetchQueries={REFETCHQUERIES}>
          {mutation => (
            <form onSubmit={e => submitHandler(e, mutation)}>
              <DialogActions>
                <Button onClick={() => openModal(false)}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

ConfirmAction.propTypes = {
  classes: PropTypes.object,
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  MUTATION: PropTypes.object,
  MUTATION_VARIABLES: PropTypes.object,
  REFETCHQUERIES: PropTypes.array,
}

export default withStyles(styles)(ConfirmAction)
