import React from 'react'
import PropTypes from 'prop-types'

import { Formik } from 'formik'
import { useQuery, useMutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  GET_COMPANY_SUMMARY_LIMITED,
  UPDATE_COMPANY_SUMMARY,
  CREATE_COMPANY,
} from '../../../../../util/apollo/queries/companies'
import { removeEdgesAndNodes } from '../../../../../shared/helperfunctions'
import GenericForm from '../../../../../shared/components/forms/GenericForm'

const CompanyInfo = ({ companyUUID, history, setCompanyUUID }) => {
  const { loading, error, data } = useQuery(GET_COMPANY_SUMMARY_LIMITED, {
    variables: { companyUUID: companyUUID },
    skip: !companyUUID,
  })

  const [updateCompanySummary] = useMutation(UPDATE_COMPANY_SUMMARY, {
    onError: alert,
  })
  const [createCompany] = useMutation(CREATE_COMPANY, {
    refetchQueries: [
      {
        query: GET_COMPANY_SUMMARY_LIMITED,
        variables: {
          status: 'active',
        },
      },
    ],
    onCompleted({ createCompany }) {
      history.push(
        `/moderator/companies/seller/${createCompany.company.uuid}/overview`
      )
      setCompanyUUID(createCompany.company.uuid)
    },
    onError: alert,
  })

  if (loading) return 'Loading...'
  if (error) {
    return null
  }

  if (companyUUID && !(data && data.allCompanies.edges[0])) {
    return 'Error: CompanyInfo Not Found'
  }
  return (
    <div>
      <Formik
        onSubmit={values => {
          if (companyUUID) {
            return updateCompanySummary({
              variables: {
                uuid: companyUUID,
                ...values,
              },
            })
          }

          return createCompany({
            variables: {
              ...values,
              type: 'seller',
            },
          })
        }}
        initialValues={
          data
            ? removeEdgesAndNodes(data.allCompanies).edges[0]
            : { name: '', website: '', phone: '' }
        }
        render={props => (
          <GenericForm
            {...props}
            title={'Company Information'}
            fields={[
              {
                name: 'name',
                label: 'Company Name',
                type: 'textfield',
              },
              {
                name: 'website',
                label: 'Website',
                type: 'textfield',
              },
              {
                name: 'phone',
                label: 'Phone',
                type: 'textfield',
              },
            ]}
          />
        )}
      />
    </div>
  )
}

CompanyInfo.propTypes = {
  companyUUID: PropTypes.string,
  history: PropTypes.object,
  setCompanyUUID: PropTypes.func,
}

export default withRouter(CompanyInfo)
