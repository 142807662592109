import React, { useState } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import RRule from 'rrule'

import DailyTabPanel from './DailyTabPanel'
import WeeklyTabPanel from './WeeklyTabPanel'
import MonthlyTabPanel from './MonthlyTabPanel'
import YearlyTabPanel from './YearlyTabPanel'

const UNITS_OF_TIME = ['Year(s)', 'Month(s)', 'Week(s)', 'Day(s)']

const useStyles = makeStyles(theme =>
  createStyles({
    dateWrapper: {
      display: 'flex',
      columnGap: theme.spacing(2),
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
    recurrenceWrapper: {
      display: 'flex',
      columnGap: theme.spacing(1),
      justifyContent: 'flex-start',
    },
    interval: {
      width: 75,
    },
  })
)

export default function RecurrenceField({ value, onChange }) {
  const rrule = RRule.fromString(value)
  const classes = useStyles()
  const [unitOfTime, setUnitOfTime] = useState(rrule.options.freq)
  const [startDate, setStartDate] = useState(
    DateTime.fromISO(rrule.options.dtstart).toFormat('yyyy-MM-dd')
  )
  const [endDate, setEndDate] = useState(
    rrule.options.until
      ? DateTime.fromISO(rrule.options.until).toFormat('yyyy-MM-dd')
      : ''
  )
  const [timeInterval, setTimeInterval] = useState(rrule.options.interval)
  const isByDate =
    Array.isArray(rrule.options.bymonthday) &&
    rrule.options.bymonthday.length > 0
  return (
    <>
      <div className={classes.dateWrapper}>
        <TextField
          required
          margin="normal"
          variant="outlined"
          label="Start Date"
          type="date"
          name="startDate"
          value={startDate}
          onChange={e =>
            setStartDate(
              e.target.value
                ? DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd')
                : ''
            )
          }
        />
        <TextField
          margin="normal"
          variant="outlined"
          label="End Date"
          type="date"
          name="endDate"
          value={endDate}
          onChange={e =>
            setEndDate(
              e.target.value
                ? DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd')
                : ''
            )
          }
        />
      </div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.recurrenceWrapper}
      >
        {'Repeat every... '}
        <TextField
          className={classes.interval}
          required
          margin="none"
          label="Interval"
          type="number"
          name="interval"
          size="small"
          variant="outlined"
          value={timeInterval}
          onChange={e => setTimeInterval(parseInt(e.target.value, 10))} // TODO - Brittle, will break for certain inputs
        />
        <FormControl variant="outlined" size="small">
          <InputLabel id="unit-of-time-label">Unit of Time</InputLabel>
          <Select
            labelId="unit-of-time-label"
            id="unit-of-time"
            value={unitOfTime}
            onChange={event => setUnitOfTime(event.target.value)}
          >
            {UNITS_OF_TIME.map((label, key) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <br />
      <Box fontStyle="italic">{rrule.toText()}</Box>
      <DailyTabPanel
        startDate={startDate}
        endDate={endDate}
        interval={timeInterval}
        onChange={onChange}
        selectedDays={rrule.options.byweekday}
        isSelected={unitOfTime === 3}
      />
      <WeeklyTabPanel
        startDate={startDate}
        endDate={endDate}
        interval={timeInterval}
        onChange={onChange}
        selectedDays={rrule.options.byweekday}
        isSelected={unitOfTime === 2}
      />
      <MonthlyTabPanel
        startDate={startDate}
        endDate={endDate}
        interval={timeInterval}
        onChange={onChange}
        isByDate={isByDate}
        isSelected={unitOfTime === 1}
      />
      <YearlyTabPanel
        startDate={startDate}
        endDate={endDate}
        interval={timeInterval}
        onChange={onChange}
        isByDate={isByDate}
        isSelected={unitOfTime === 0}
      />
    </>
  )
}
