import React from 'react'
import { PropTypes } from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core'
import { DateTime } from 'luxon'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const AddPaymentSchedule = ({
  classes,
  schedule,
  setSchedule,
  open,
  openModal,
  date,
  setDate,
  amount,
  setAmount,
  editIndex,
  setEditIndex,
}) => {
  const submitHandler = e => {
    e.preventDefault()

    if (editIndex !== null) {
      schedule[editIndex] = {
        amount: Number(amount),
        dueDate: DateTime.fromISO(date).toJSDate(),
      }
      const newSchedule = [...schedule]
      setSchedule(newSchedule)
    } else if (editIndex == null) {
      setSchedule(prev => [
        ...prev,
        {
          amount: Number(amount),
          dueDate: DateTime.fromISO(date).toJSDate(),
        },
      ])
    }
    resetModal()
  }

  const resetModal = () => {
    openModal(false)
    setDate('')
    setAmount('')
    setEditIndex(null)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => openModal(true)}
      >
        Add Payment
      </Button>
      <Dialog open={open} onClose={() => resetModal()}>
        <DialogTitle>Add Payment Date</DialogTitle>
        <Divider />
        <form onSubmit={submitHandler}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Date"
                  value={date}
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  required
                  variant="outlined"
                  onChange={e => setDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Amount"
                  type="number"
                  value={amount}
                  variant="outlined"
                  onChange={e => setAmount(e.target.value)}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={() => resetModal()} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

AddPaymentSchedule.propTypes = {
  classes: PropTypes.object,
  setSchedule: PropTypes.func,
  schedule: PropTypes.array,
  open: PropTypes.bool,
  openModal: PropTypes.func,
  date: PropTypes.string,
  setDate: PropTypes.func,
  amount: PropTypes.string,
  setAmount: PropTypes.func,
  editIndex: PropTypes.number,
  setEditIndex: PropTypes.func,
}

export default withStyles(styles)(AddPaymentSchedule)
