import React from 'react'
import PropTypes from 'prop-types'

import { Route, Switch } from 'react-router-dom'
import { withStyles, Grid, Toolbar } from '@material-ui/core'

import { LeftNavigation, NavigationHeader } from '../../shared/user-interface'
import ProductDetail from '../Customer/Products/Details/index'

import { BackButton } from '../../shared/components'
import Contract from './Contract'
import Messages from './Messages'
import Overview from './Overview'
import Settings from './Settings'
import Invoices from './Invoices'
import Payments from './Payments'
import Company from './Company'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  bodyContainer: {
    flexGrow: 1,
  },
  backButton: {
    marginBottom: theme.spacing(4),
  },
  header: {},
  navigationBox: {
    scroll: 'auto',
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  bodyBox: {
    scroll: 'auto',
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '100vh',
  },
})

const Moderator = ({ classes, match }) => {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.header}>
        <NavigationHeader userType="moderator" />
      </Grid>
      <Grid container className={classes.bodyContainer}>
        <Grid item xs={2} className={classes.navigationBox}>
          <LeftNavigation userType="moderator" />
        </Grid>
        <Grid item xs={10} className={classes.bodyBox}>
          <Toolbar />
          <Switch>
            <Route
              path={match.path + '/products/customerview/:uuid'}
              render={props => (
                <>
                  <BackButton className={classes.backButton} />
                  <ProductDetail {...props} />
                </>
              )}
            />
            <Route path={match.path + '/contracts'} component={Contract} />
            <Route
              path={match.path + '/messages/:filter?/:channeluuid?'}
              component={Messages}
            />
            <Route path={match.path + '/overview'} component={Overview} />
            <Route path={match.path + '/settings'} component={Settings} />
            <Route path={match.path + '/invoices'} component={Invoices} />
            <Route path={match.path + '/payments'} component={Payments} />
            <Route path={match.path + '/companies'} component={Company} />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  )
}

Moderator.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Moderator)
