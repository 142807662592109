import React from 'react'
import PropTypes from 'prop-types'

import { Chip, IconButton, withStyles } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useDropzone } from 'react-dropzone'

import { footerHeight } from '../../../constants'

const styles = theme => ({
  buttonContainer: {
    marginLeft: theme.spacing(1),
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chipsContainer: {
    bottom: footerHeight + theme.spacing(1),
    display: 'grid',
    gridRowGap: theme.spacing(1),
    gridTemplateColumns: '1fr',
    position: 'fixed',
    right: theme.spacing(2),
  },
})

const Component = ({ classes, file, setFile }) => {
  const onDropAccepted = newFiles => setFile([newFiles[0]])

  const handleDelete = () => setFile([])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
  })

  return (
    <>
      <div className={classes.chipsContainer}>
        {file.map(({ name }, i) => (
          <div key={name + i} className={classes.chipContainer}>
            <Chip label={name} onDelete={handleDelete} />
          </div>
        ))}
      </div>

      <div className={classes.buttonContainer} {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton>
          <AttachFileIcon />
        </IconButton>
      </div>
    </>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  files: PropTypes.array,
  setFiles: PropTypes.func,
}

export default withStyles(styles)(Component)
