import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withRouter } from 'react-router-dom'
import {
  withStyles,
  Divider,
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { removeEdgesAndNodes } from '../../../../../shared/helperfunctions'
import { GET_SELLERS_PRODUCTS } from '../../../../../util/apollo/queries/products'

import ProductItem from './ProductItem'

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  pushRight: {
    marginLeft: 'auto',
  },
})

const ProductSummary = ({ classes, companyUUID, history }) => {
  const query_variables = {
    companyUUID: companyUUID,
  }

  const refetchQueries = [
    {
      query: GET_SELLERS_PRODUCTS,
      variables: query_variables,
    },
  ]

  const { loading, error, data } = useQuery(GET_SELLERS_PRODUCTS, {
    variables: query_variables,
    skip: !companyUUID,
  })

  if (loading) return 'Loading...'
  if (error) {
    alert(error)
    return null
  }

  const products =
    data && data.allProducts.edges[0]
      ? removeEdgesAndNodes(data.allProducts).edges
      : []
  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Products</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`product/new`)}
        >
          Add New Product
        </Button>
      </div>
      <Divider />
      <div className={classes.content}>
        {products.map((product, index) => (
          <ProductItem
            key={index}
            index={index}
            product={product}
            refetchQueries={refetchQueries}
            companyUUID={companyUUID}
          />
        ))}
      </div>
    </Paper>
  )
}

ProductSummary.propTypes = {
  classes: PropTypes.object,
  companyUUID: PropTypes.string,
  history: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(ProductSummary)
