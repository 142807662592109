import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Divider,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useMutation, useQuery } from 'react-apollo'

import { drawerWidth } from '../../../../shared/user-interface'
import {
  GET_STATEMENT_OVERVIEW,
  CREATE_STATEMENT_PDF,
} from '../../../../util/apollo/queries/statements'
import {
  removeEdgesAndNodes,
  formatDate,
  formatCurrency,
  downloadFileOnClick,
} from '../../../../shared/helperfunctions'
import { BackButton } from '../../../../shared/components'
import { baseUrl } from '../../../../util/apollo/config'

const downloadMenuHeight = 64
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: downloadMenuHeight,
    width: '100%',
  },
  backLink: {
    alignItems: 'center',
    color: '#0dbfff',
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(4),
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '350px 1fr',
  },
  headerDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  invoiceMetadata: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  invoiceTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    marginTop: theme.spacing(4),
  },
  pageHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  paper: {
    width: 850,
    minHeight: 1100,
  },
  pushToRight: {
    marginLeft: 'auto',
  },
  saveBar: {
    background: '#f7f7f7',
    bottom: 0,
    boxSizing: 'border-box',
    boxShadow: `0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12)`,
    height: downloadMenuHeight,
    left: drawerWidth,
    position: 'fixed',
    right: 0,
    top: 'auto',
  },
  saveButton: {
    backgroundColor: '#0dbfff',
    color: '#fff',
    marginLeft: theme.spacing(2),
  },
  tableAdjustments: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    paddingLeft: 350,
  },
  tableCell: {
    boxSizing: 'border-box',
    padding: theme.spacing(1),
  },
  tableHeader: {
    background: '#ecedf1',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    width: '100%',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
})

const Details = ({
  classes,
  match: {
    params: { uuid: statementUuid, campaignuuid, usertype },
  },
}) => {
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)
  const [createStatementPdf] = useMutation(CREATE_STATEMENT_PDF)
  const downloadInvoice = useCallback(async () => {
    setIsDownloadingPdf(true)
    const { data } = await createStatementPdf({
      variables: { statementUuid },
    })
    const { ok, uuid, originalFileName } = data.createStatementPdf
    if (ok) {
      await downloadFileOnClick(
        baseUrl + '/api/rest/v1/file/download/r/' + uuid,
        originalFileName
      )()
    }
    setIsDownloadingPdf(false)
  }, [createStatementPdf, statementUuid])
  const { loading, error, data } = useQuery(GET_STATEMENT_OVERVIEW, {
    variables: { statementUuid },
  })

  if (loading) return 'Loading...'
  if (error || !data.allStatements.edges[0]) {
    return null
  }
  const statement = removeEdgesAndNodes(data.allStatements.edges[0].node)
  const dataDetails = JSON.parse(statement.details)
  return (
    <>
      <div className={classes.root}>
        <div className={classes.rootGrid}>
          {usertype == 'customer' && (
            <div className={classes.pageHeaderContainer}>
              <BackButton link={`/customer/${campaignuuid}/invoices`} />

              <Button
                variant="contained"
                color="primary"
                disabled={isDownloadingPdf}
                onClick={downloadInvoice}
              >
                {isDownloadingPdf ? 'Generating...' : 'Generate Invoice PDF'}
              </Button>
            </div>
          )}
          <Paper className={classes.paper} id="my-invoice">
            <div className={classes.title}>
              <Typography variant="h4">Invoice</Typography>
            </div>
            <Divider />
            <div className={classes.content}>
              <div className={classes.header}>
                <div>
                  <Typography color="textSecondary">From</Typography>
                  <Typography variant="h6">
                    {dataDetails.from.company}
                  </Typography>
                  <Typography>{dataDetails.from.email}</Typography>
                  <Typography>{dataDetails.from.address_line1}</Typography>
                  <Typography>{dataDetails.from.address_line2}</Typography>
                  <Typography>
                    {dataDetails.from.address_city},{' '}
                    {dataDetails.from.address_state}{' '}
                    {dataDetails.from.address_zip}
                  </Typography>
                  <Typography>{dataDetails.from.phone}</Typography>
                </div>
                <div>
                  <Typography color="textSecondary">For</Typography>
                  <Typography variant="h6">{dataDetails.to.company}</Typography>
                  <Typography>{dataDetails.to.email}</Typography>
                  <Typography>{dataDetails.to.address_line1}</Typography>
                  <Typography>{dataDetails.to.address_line2}</Typography>
                  <Typography>
                    {dataDetails.to.address_city},{' '}
                    {dataDetails.to.address_state} {dataDetails.to.address_zip}
                  </Typography>
                  <Typography>{dataDetails.to.phone}</Typography>
                </div>
              </div>
              <Divider className={classes.headerDivider} />
              <div className={classes.invoiceMetadata}>
                <div>
                  <Typography>Number</Typography>
                  <Typography>Date</Typography>
                  <Typography>Due</Typography>
                </div>
                <div>
                  <Typography>INV-{statement.statementId}</Typography>
                  <Typography>{formatDate(statement.createdDate)}</Typography>
                  <Typography>{formatDate(statement.dueDate)}</Typography>
                </div>
              </div>
              <div className={classes.invoiceTable}>
                <Typography className={classes.tableHeader}>
                  Description
                </Typography>
                {/* <Typography className={classes.tableHeader}>
                          Price
                        </Typography>
                        <Typography className={classes.tableHeader}>
                          Quantity
                        </Typography> */}
                <Typography className={classes.tableHeader}>Amount</Typography>

                {dataDetails.items.map((row, index) => (
                  <React.Fragment key={index}>
                    <Typography className={classes.tableCell}>
                      {row.description}
                    </Typography>
                    {/* <Typography className={classes.tableCell}>
                              ${'0.00'}
                            </Typography>
                            <Typography className={classes.tableCell}>
                              1
                            </Typography> */}
                    <Typography className={classes.tableCell}>
                      {formatCurrency(row.amount)}
                    </Typography>
                  </React.Fragment>
                ))}
              </div>
              <Divider />
              <div className={classes.tableAdjustments}>
                <Typography className={classes.tableCell}>Subtotal</Typography>
                <Typography className={classes.tableCell}>
                  {formatCurrency(dataDetails.sub_total)}
                </Typography>
                <Typography className={classes.tableCell}>Discount</Typography>
                <Typography className={classes.tableCell}>
                  {formatCurrency(dataDetails.discount)}
                </Typography>
                <Typography className={classes.tableCell}>
                  Previous Balance
                </Typography>
                <Typography className={classes.tableCell}>
                  {formatCurrency(dataDetails.balance)}
                </Typography>
              </div>
              <Divider />
              <div className={classes.tableAdjustments}>
                <Typography variant="h6" className={classes.tableCell}>
                  Total
                </Typography>
                <Typography variant="h6" className={classes.tableCell}>
                  {formatCurrency(dataDetails.total)}
                </Typography>
              </div>
              <Divider />
              <Typography style={{ marginTop: 16 }}>
                Notes: {dataDetails.note}
              </Typography>
            </div>
          </Paper>
        </div>
      </div>
    </>
  )
}

Details.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Details)
