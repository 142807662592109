import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { withStyles, MenuItem, TextField, Button } from '@material-ui/core'
import { useQuery } from 'react-apollo'

import {
  snakeToTitleCase,
  pluralize,
} from '../../../../../shared/helperfunctions'
import { GET_PRODUCT_SIZES_FROM_PROPOSALITEM } from '../../../../../util/apollo/queries/products'

const styles = theme => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
})

const Form = props => {
  const {
    classes,
    values: {
      datePreferenceNotes,
      otherPreferenceNotes,
      preferredStartDate,
      title,
      quantity,
    },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    toggleEditMode,
    uuid,
  } = props

  const { loading, error, data } = useQuery(
    GET_PRODUCT_SIZES_FROM_PROPOSALITEM,
    { variables: { uuid } }
  )
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  if (loading) return null
  if (error) return null
  const product = data.allProposalitems.edges[0].node.productSize.product
  return (
    <form onSubmit={props.handleSubmit}>
      <TextField
        id="title"
        name="title"
        label="Product Size"
        helperText={touched.title ? errors.title : ''}
        error={touched.title && Boolean(errors.title)}
        value={title}
        onChange={change.bind(null, 'name')}
        required
        fullWidth
        margin="normal"
        variant="outlined"
        disabled
        select
      >
        {product.productSize.edges
          .map(e => e.node)
          .map(({ title, uuid }) => (
            <MenuItem key={uuid} value={title}>
              {title}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        id="quantity"
        name="quantity"
        label="Ad Quantity"
        helperText={touched.quantity ? errors.quantity : ''}
        error={touched.quantity && Boolean(errors.quantity)}
        value={quantity}
        onChange={change.bind(null, 'name')}
        required
        fullWidth
        margin="normal"
        variant="outlined"
        select
      >
        {product.productSize.edges
          .filter(e => e.node.title === title)
          .map(({ node: { buyMin, buyMax, buyIncrement, buyUnit } }) => {
            const adQuantityArrayLength =
              Math.floor(Math.abs((buyMax - buyMin) / buyIncrement)) + 1

            let values = []
            for (let i = 0; i < adQuantityArrayLength; i++) {
              values.push(buyMin + i * buyIncrement)
            }

            return values.map(val => (
              <MenuItem key={'adQuantity' + val} value={val}>
                {`${val} ${pluralize(snakeToTitleCase(buyUnit), val)}`}
              </MenuItem>
            ))
          })}
      </TextField>
      <TextField
        id="preferredStartDate"
        name="preferredStartDate"
        label="Preferred Start Date"
        helperText={touched.preferredStartDate ? errors.preferredStartDate : ''}
        error={touched.preferredStartDate && Boolean(errors.preferredStartDate)}
        value={DateTime.fromISO(preferredStartDate).format('yyyy-MM-dd')}
        onChange={change.bind(null, 'name')}
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        required
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        id="datePreferenceNotes"
        name="datePreferenceNotes"
        label="Date Preference"
        helperText={
          touched.datePreferenceNotes ? errors.datePreferenceNotes : ''
        }
        error={
          touched.datePreferenceNotes && Boolean(errors.datePreferenceNotes)
        }
        value={datePreferenceNotes}
        onChange={change.bind(null, 'name')}
        required
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        id="otherPreferenceNotes"
        name="otherPreferenceNotes"
        label="Other Comments"
        helperText={
          touched.otherPreferenceNotes ? errors.otherPreferenceNotes : ''
        }
        error={
          touched.otherPreferenceNotes && Boolean(errors.otherPreferenceNotes)
        }
        value={otherPreferenceNotes}
        onChange={change.bind(null, 'name')}
        required
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <div className={classes.buttonsContainer}>
        <Button
          onClick={toggleEditMode}
          className={classes.cancelButton}
          color="default"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          Save
        </Button>
      </div>
    </form>
  )
}

Form.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  props: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  handleSubmit: PropTypes.func,
  toggleEditMode: PropTypes.func,
  uuid: PropTypes.string,
}

export default withStyles(styles)(Form)
