import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  checkboxLabel: {
    marginTop: theme.spacing(0.5),
  },
  checkboxLabelLabel: {
    marginLeft: theme.spacing(2),
  },
  checkboxLabelRoot: {
    marginLeft: -2,
  },
  checkboxRoot: {
    padding: 0,
  },
  clearFilter: {
    color: '#00bcfa',
    cursor: 'pointer',
    fontWeight: 700,
    marginLeft: theme.spacing(1),
  },
  filterGroup: {
    marginTop: theme.spacing(1.5),
  },
  mediaTypeCheckboxes: {
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 700,
  },
})

const Component = ({ classes, setFilters }) => {
  const [control, setControl] = useState({
    ['less_than_18']: false,
    ['19_24']: false,
    ['25_45']: false,
    ['44_64']: false,
    ['65_and_older']: false,
  })

  const onClear = () => {
    setControl(
      Object.keys(control).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {})
    )
  }

  useEffect(() => {
    setFilters(prev => {
      // No checkboxes are set
      if (Object.values(control).indexOf(true) === -1) {
        delete prev.ageFocus
        return { ...prev }
      }

      // Apply to master filter control
      return {
        ...prev,
        ageFocus: Object.keys(control)
          .filter(key => control[key])
          .join(', '),
      }
    })
  }, [control, setFilters])

  const handleCheckboxChange = key => ({ target: { checked } }) =>
    setControl(prev => ({ ...prev, [key]: checked }))

  return (
    <div className={classes.filterGroup}>
      <Typography className={classes.sectionTitle} display="inline">
        Age Focus
      </Typography>
      <Typography
        className={classes.clearFilter}
        display="inline"
        variant="caption"
        onClick={onClear}
      >
        Clear Filter
      </Typography>
      <FormGroup className={classes.mediaTypeCheckboxes}>
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control['less_than_18']}
              onChange={handleCheckboxChange('less_than_18')}
              value="< 18"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="< 18"
        />
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control['19_24']}
              onChange={handleCheckboxChange('19_24')}
              value="19 - 24"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="19 - 24"
        />
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control['25_45']}
              onChange={handleCheckboxChange('25_45')}
              value="25 - 45"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="25 - 45"
        />
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control['44_64']}
              onChange={handleCheckboxChange('44_64')}
              value="44 - 64"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="44 - 64"
        />
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control['65_and_older']}
              onChange={handleCheckboxChange('65_and_older')}
              value="65 +"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="65 +"
        />
      </FormGroup>
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
