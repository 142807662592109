import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'

const SellersListActionMenu = ({ uuid, history }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = event => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const editHandler = () => {
    history.push({
      pathname: '/moderator/sellers/new',
      state: { uuid },
    })
  }

  return (
    <div>
      <IconButton id="icon-button" onClick={openMenu}>
        <MoreVertIcon id="more-vert-icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem>Summary</MenuItem>
        <MenuItem onClick={editHandler}>Edit</MenuItem>
      </Menu>
    </div>
  )
}

SellersListActionMenu.propTypes = {
  uuid: PropTypes.string,
  history: PropTypes.object,
}

export default withRouter(SellersListActionMenu)
