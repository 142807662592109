import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle } from '@material-ui/core'

export default function Title({ modalType }) {
  const title = modalType === 'newRecurringRule' ? 'Add recurrence' : null
  return <DialogTitle>{title}</DialogTitle>
}

Title.propTypes = {
  modalType: PropTypes.oneOf(['newRecurringRule']),
}
