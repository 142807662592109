import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core'
import { Mutation } from 'react-apollo'

import { SEND_FEEDBACK } from '../../../../util/apollo/queries/feedback'

const styles = theme => ({
  button: {
    margin: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
})

const Feedback = ({ classes }) => {
  const [open, openModal] = useState(false)
  const [description, setDescription] = useState('')

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    const variables = { feedback: description }
    setDescription('')
    mutation({ variables: variables })
    openModal(false)
    setDescription('')
  }

  const closeHandler = () => {
    openModal(false)
    setDescription('')
  }

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={() => openModal(true)}
      >
        Leave Feedback
      </Button>
      <Dialog open={open} onClose={() => closeHandler()}>
        <DialogTitle>Please let us know what we can do to improve!</DialogTitle>
        <Divider />
        <Mutation mutation={SEND_FEEDBACK}>
          {mutation => (
            <form onSubmit={e => submitHandler(e, mutation)}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={e => setDescription(e.target.value)}
                      value={description}
                      label="Response"
                      multiline
                      rows="4"
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={() => closeHandler()} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="primary">
                  Send Feedback
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

Feedback.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Feedback)
