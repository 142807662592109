import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Redirect, Route } from 'react-router-dom'

import ProductDetails from './Details'

const styles = theme => ({
  root: {
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    width: '100%',
  },
})

const Products = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route
          path={match.path + '/details/:uuid'}
          component={ProductDetails}
        />
        <Redirect to="/customer/overview" />
      </Switch>
    </div>
  )
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(Products)
