import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Typography, withStyles, Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import clsx from 'clsx'

import {
  dividerGray,
  headerHeight,
  hoverGray,
  timestampColor,
} from '../../constants'

import {
  SET_CONVERSATION_READ,
  GET_ALL_CONVERSATIONS,
} from '../../../../../util/apollo/queries/messages'

import { getConversationQueryVariables } from '../'

const styles = theme => ({
  root: {
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    borderBottom: `solid 1px ${dividerGray}`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: headerHeight,
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&:hover': {
      backgroundColor: hoverGray,
    },
  },
  date: {
    color: timestampColor,
  },
  selected: {
    backgroundColor: 'rgb(128, 198, 223)',
  },
  title: {
    fontWeight: 700,
  },
  unreadIndicator: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    height: 8,
    marginRight: theme.spacing(1),
    width: 8,
  },
})

const Conversation = ({
  body,
  classes,
  client,
  date,
  history,
  match: {
    params: { campaignuuid, channeluuid, filter, usertype },
  },
  title,
  channeluuid: uuid,
  channelactivityuuid,
  read,
}) => {
  const selected = channeluuid === uuid

  const setConversationToRead = () =>
    new Promise((resolve, reject) =>
      client
        .mutate({
          mutation: SET_CONVERSATION_READ,
          variables: { channelactivityuuid },
          refetchQueries: [
            {
              query: GET_ALL_CONVERSATIONS,
              variables: getConversationQueryVariables(filter, campaignuuid),
            },
          ],
        })
        .then(({ data }) => {
          if (data.updateChannelactivity.ok) resolve()
          else reject()
        })
    )

  const openConversation = () => {
    if (uuid)
      setConversationToRead().then(() => {
        if (typeof campaignuuid !== 'undefined') {
          history.push(
            `/${usertype}/${campaignuuid}/messages/${filter}/${uuid}`
          )
        } else {
          history.push(`/${usertype}/messages/${filter}/${uuid}`)
        }
      })
  }

  return (
    <div
      className={clsx(classes.root, selected && classes.selected)}
      onClick={openConversation}
    >
      <Grid container>
        <Grid item xs={9}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
              {!read && <div className={classes.unreadIndicator} />}
            </Grid>
            <Grid item>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.date}>{date}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{body}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

Conversation.propTypes = {
  body: PropTypes.string,
  classes: PropTypes.object,
  client: PropTypes.object,
  date: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  title: PropTypes.string,
  channelactivityuuid: PropTypes.string,
  channeluuid: PropTypes.string,
  read: PropTypes.bool,
}

export default compose(
  withStyles(styles),
  withRouter,
  withApollo
)(Conversation)
