/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import PauseIcon from '@material-ui/icons/Pause'
import PlayIcon from '@material-ui/icons/PlayArrow'
import { useMutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  CHANGE_CONTRACT_STATUS,
  GET_TERMS,
} from '../../../../../../util/apollo/queries/contracts'
import { withNotifications } from '../../../../../../util'

const styles = () => ({
  root: {},
})

const Component = ({
  classes,
  match: {
    params: { contractuuid },
  },
  notify,
  stage,
}) => {
  const [mutation] = useMutation(CHANGE_CONTRACT_STATUS)

  const togglePause = () => {
    mutation({
      variables: {
        contractuuid,
        status: stage === 'paused' ? 'unpause' : 'pause',
      },
      refetchQueries: [
        {
          query: GET_TERMS,
          variables: {
            contract: contractuuid,
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data.disputeContract.ok)
          notify.success(stage === 'paused' ? 'Un-paused' : 'Paused')
      })
      .catch(() => null)
  }

  return (
    <Tooltip title={stage === 'paused' ? 'Un-Pause' : 'Pause'}>
      <IconButton onClick={togglePause}>
        {stage === 'paused' ? <PlayIcon /> : <PauseIcon />}
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object,
  stage: PropTypes.string,
}

export default compose(
  withRouter,
  withNotifications,
  withStyles(styles)
)(Component)
