import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { formatDate } from '../../../../../shared/helperfunctions'

import AddPaymentSchedule from './AddPaymentSchedule'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1),
    width: 150,
    height: 40.5,
    marginRight: theme.spacing(3),
    background: '#fff',
    color: '#0dbffe',
    fontWeight: '700',
  },
  content: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  pushRight: {
    marginLeft: 'auto',
  },
  textField: {
    width: '100%',
  },
})

const PaymentSchedule = ({ classes, type, schedule, setSchedule }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = event => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const [open, openModal] = useState(false)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState('')
  const [editIndex, setEditIndex] = useState(null)

  const editClick = (e, index) => {
    setDate(DateTime.fromISO(schedule[index].dueDate).toFormat('yyyy-MM-dd'))
    setAmount(schedule[index].amount.toString())
    setEditIndex(index)
    openModal(true)
    closeMenu()
  }

  const deleteClick = (e, index) => {
    schedule.splice(index, 1)
    const newSchedule = [...schedule]
    setSchedule(newSchedule)
    closeMenu()
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{type} Payment Schedule</Typography>
        <AddPaymentSchedule
          setSchedule={setSchedule}
          schedule={schedule}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          className={classes.pushRight}
          open={open}
          openModal={openModal}
          date={date}
          setDate={setDate}
          amount={amount}
          setAmount={setAmount}
        />
      </div>
      <Divider />
      <div className={classes.content}>
        {schedule.length ? (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map((row, index) => (
                <TableRow key={index} hover className={classes.tableRow}>
                  <TableCell id="table-row-element">
                    {formatDate(row.dueDate)}
                  </TableCell>
                  <TableCell id="table-row-element">{row.amount}</TableCell>
                  <TableCell align="right">
                    <div>
                      <IconButton id="icon-button" onClick={openMenu}>
                        <MoreVertIcon id="more-vert-icon" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                        PaperProps={{
                          style: {
                            width: 200,
                          },
                        }}
                      >
                        <MenuItem
                          key={index}
                          onClick={e => editClick(e, index)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem onClick={e => deleteClick(e, index)}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          ''
        )}
      </div>
    </Paper>
  )
}

PaymentSchedule.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  schedule: PropTypes.array,
  setSchedule: PropTypes.func,
}

export default withStyles(styles)(PaymentSchedule)
