import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Icon, Tooltip, withStyles } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'

import { withRouter } from 'react-router-dom'
import { Elements } from 'react-stripe-elements'
import { loadCSS } from 'fg-loadcss'

import { AddPaymentMethod } from '../../../../../../shared/dialogs'
import { downloadFileOnClick } from '../../../../../../shared/helperfunctions'
import { baseUrl } from '../../../../../../util/apollo/config'

const styles = () => ({
  root: {},
})

const Component = ({
  classes,
  channel,
  clientuuid,
  paymentButton,
  match: {
    params: { campaignuuid },
  },
  history,
  product,
  fileItem,
}) => {
  const goToAdListing = () =>
    history.push(`/customer/${campaignuuid}/products/details/${product.uuid}`)

  const messagesClick = () => {
    if (channel.length) {
      const firstChannel = channel[0].uuid
      history.push(`/customer/${campaignuuid}/messages/all/${firstChannel}`)
    }
  }

  const downloadContract = () => {
    const files = fileItem.edges.map(({ node }) => node)
    if (files.length) {
      const contractFile = files
        .filter(item => item.type == 'UNSIGNED_CONTRACT')
        .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1))[0]
      downloadFileOnClick(
        baseUrl + '/api/rest/v1/file/download/r/' + contractFile.uuid,
        contractFile.originalFileName
      )()
    }
  }

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
  }, [])

  return (
    <div className={classes.buttonContainer}>
      <Elements>
        <AddPaymentMethod paymentIcon clientuuid={clientuuid} />
      </Elements>
      {paymentButton}
      <Tooltip title="View Messages">
        <IconButton onClick={messagesClick}>
          <ChatBubbleIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Ad Listing">
        <IconButton onClick={goToAdListing}>
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Sign Contract">
        <IconButton onClick={downloadContract}>
          <Icon className="fa fa-file-signature" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

Component.propTypes = {
  channel: PropTypes.array,
  classes: PropTypes.object,
  match: PropTypes.object,
  product: PropTypes.object,
  history: PropTypes.object,
  clientuuid: PropTypes.string,
  contractDocumentUrl: PropTypes.string,
  paymentButton: PropTypes.element,
  fileItem: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(Component)
