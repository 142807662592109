import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import { Formik } from 'formik'
import { withStyles } from '@material-ui/core'
import { useQuery, useMutation } from 'react-apollo'
import { connect } from 'react-redux'

import * as actions from '../../../util/redux/actions'
import { GET_USER_INFO, SET_USER_INFO } from '../../../util/apollo/queries/user'
import GenericForm from '../../../shared/components/forms/GenericForm'
import {
  removeEdgesAndNodes,
  formatPhoneNumber,
} from '../../../shared/helperfunctions'

const styles = () => ({
  root: {
    maxWidth: 650,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 8 * 2,
  },
  tableTitle: {
    paddingTop: 24,
    paddingLeft: 24,
  },
})

// eslint-disable-next-line no-unused-vars
const Settings = ({ classes, setUserInfo }) => {
  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER_INFO, { onError: alert })
  const [setUser, { data: setUserData }] = useMutation(SET_USER_INFO, {
    refetchQueries: [{ query: GET_USER_INFO }],
    onError: alert,
  })
  // TODO - fix with grid
  if (getUserLoading) {
    return <div className={classes.root}>Loading...</div>
  }
  if (getUserError) {
    return <div className={classes.root}>Error</div>
  }
  removeEdgesAndNodes(getUserData)
  const { currentUser } = getUserData
  const { phone } = currentUser

  if (phone) currentUser.phone = formatPhoneNumber(phone)
  // Updates redux store with new user info for logout menu
  if (setUserData) setUserInfo(setUserData.updateUser.user)

  //Initialize null values to empty string for formik initialValues
  Object.keys(currentUser).map(key => {
    if (!currentUser[key]) currentUser[key] = ''
  })
  return (
    <div className={classes.root}>
      <Formik
        onSubmit={values => {
          const variables = values

          //Remove variables for fields that haven't changed
          Object.keys(variables)
            .filter(key => variables[key] === currentUser[key])
            .forEach(key => delete variables[key])

          //Add uuid to variables
          variables.uuid = currentUser.uuid

          setUser({ variables })
        }}
        initialValues={currentUser}
        render={props => (
          <GenericForm
            {...props}
            title={'Company Information'}
            fields={[
              {
                name: 'firstName',
                label: 'First Name',
                type: 'textfield',
              },
              {
                name: 'lastName',
                label: 'Last Name',
                type: 'textfield',
              },
              {
                name: 'email',
                label: 'Email',
                type: 'textfield',
              },
              {
                name: 'phone',
                label: 'Phone',
                type: 'textfield',
              },
              {
                name: 'addressLine1',
                label: 'Address Line 1',
                type: 'textfield',
              },
              {
                name: 'addressLine2',
                label: 'Address Line 2',
                type: 'textfield',
              },
              {
                name: 'addressCity',
                label: 'City',
                type: 'textfield',
              },
              {
                name: 'addressState',
                label: 'State',
                type: 'textfield',
              },
              {
                name: 'addressZip',
                label: 'Zip',
                type: 'textfield',
              },
            ]}
          />
        )}
      />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setUserInfo: info =>
    new Promise(resolve => {
      dispatch(actions.userInfo.setUserInfo(info))
      resolve(info)
    }),
})

Settings.propTypes = {
  classes: PropTypes.object,
  setUserInfo: PropTypes.func,
}

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(Settings)
