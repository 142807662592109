import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeEdgesAndNodes } from '../../../../../shared/helperfunctions'
import { GET_CONTRACTNOTES } from '../../../../../util/apollo/queries/contracts'

import AddContractNote from './AddContractNote'
import NotesActionMenu from './NotesActionMenu'

const Notes = ({ classes, contract }) => {
  const { loading, error, data } = useQuery(GET_CONTRACTNOTES, {
    variables: { contract: contract },
  })
  if (loading) return 'Loading...'
  if (error) return alert(error.message)
  removeEdgesAndNodes(data)

  return (
    <Paper className={clsx(classes.itemRoot, classes.halfPageModal)}>
      <div className={classes.header}>
        <Typography variant="h6">Notes</Typography>
        <AddContractNote contractUUID={contract} />
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.allContractnotes.edges.map((note, index) => (
            <TableRow key={index}>
              <TableCell>{note.description}</TableCell>
              <TableCell>
                {DateTime.fromISO(note.createdDate).toLocaleString(
                  DateTime.DATE_SHORT
                )}
              </TableCell>
              <TableCell className={classes.iconCell}>
                <NotesActionMenu note={note} contract={contract} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

Notes.propTypes = {
  classes: PropTypes.object,
  contract: PropTypes.string,
}

export default Notes
