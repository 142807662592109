import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import SendMessageBar from '../../../../Messages/Footer/SendMessageBar'
import { GET_CHANNEL } from '../../../../../../util/apollo/queries/messages'
import { titleCase } from '../../../../../../shared/helperfunctions'
import MessagesPanel from './MessagesPanel'

export default function ChatModal({ isOpen, onClose, chatType, contractuuid }) {
  const { loading, error, data } = useQuery(GET_CHANNEL, {
    variables: { contractUuid: contractuuid, type: chatType },
  })

  if (loading) {
    return null
  }
  if (error) {
    return error
  }
  const channeluuid = data.allChannels.edges[0].node.uuid
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{titleCase(chatType)}</DialogTitle>
      <DialogContent>
        <MessagesPanel channeluuid={channeluuid} />
        <SendMessageBar channeluuid={channeluuid} />
      </DialogContent>
    </Dialog>
  )
}

ChatModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatType: PropTypes.oneOf(['buyer', 'seller']).isRequired,
  contractuuid: PropTypes.string.isRequired,
}
