import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Typography, TextField, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
  sectionTitle: {
    fontWeight: 700,
  },
})

const Component = ({ classes, setFilters }) => {
  const [location, setLocation] = useState('')
  const updateLocation = ({ target: { value } }) => setLocation(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (location.length)
        setFilters(prev => ({ ...prev, geoLocation: location }))
      else
        setFilters(prev => {
          delete prev.geoLocation
          return { ...prev }
        })
    }, 5000)
    return () => clearTimeout(timeout)
  }, [location, setFilters])

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.sectionTitle}>
        Location
      </Typography>
      <TextField fullWidth value={location} onChange={updateLocation} />
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
