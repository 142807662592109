import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import Overview from './Overview'
import Details from './Details'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    boxShadow: 'none',
  },
})

const Invoices = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route path={match.path + '/detail/:uuid'} component={Details} />
        <Route path={match.path + '/'} component={Overview} />
      </Switch>
    </div>
  )
}

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(Invoices)
