/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'

import { useQuery } from 'react-apollo'
import { Button, withStyles } from '@material-ui/core'

import { GET_COMPANY_SUMMARY_LIMITED } from '../../../../util/apollo/queries/companies'
import { capitalizationCase } from '../../../../shared/helperfunctions'
import GenericTableTwo from '../../../../shared/components/GenericTableTwo'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
})

const parseData = data =>
  data.allCompanies.edges
    .map(({ node }) => node)
    .map(({ uuid, name, type, website, status }) => ({
      uuid,
      type: capitalizationCase(type),
      name,
      website: website || '(none)',
      status: capitalizationCase(status),
    }))

const columns = [
  { name: 'uuid', options: { display: 'excluded', filter: false } },
  { name: 'type', options: { display: 'excluded', filter: false } },
  { name: 'name', label: 'Company Name' },
  { name: 'website', label: 'Website' },
  { name: 'type', label: 'Type' },
  { name: 'status', label: 'Status' },
]

const CompanyList = ({ classes, history }) => {
  const count = 10
  const { loading, error, data, fetchMore } = useQuery(
    GET_COMPANY_SUMMARY_LIMITED,
    {
      variables: { first: count },
    }
  )

  const addNewBuyer = () => history.push('/moderator/companies/buyer/new')
  const addNewSeller = () => history.push('/moderator/companies/seller/new')

  const onRowClick = row =>
    history.push(
      `/moderator/companies/${row.type.toLowerCase()}/${row.uuid}/overview`
    )
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (!data) return 'Error'
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewBuyer}
          className={classes.button}
        >
          Add Buyer
        </Button>
        <Button variant="contained" color="primary" onClick={addNewSeller}>
          Add Seller
        </Button>
      </div>
      <GenericTableTwo
        data={parseData(data)}
        title="Companies"
        columns={columns}
        pagination={{
          fetchMore,
          pageInfo: data.allCompanies.pageInfo,
          count: count,
        }}
        options={{
          selectableRows: 'none',
          onRowClick,
        }}
      />
    </div>
  )
}

CompanyList.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(CompanyList)
