import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Chip, Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { DateTime } from 'luxon'

import { downloadFileOnClick } from '../../../../../shared/helperfunctions'
import { baseUrl } from '../../../../../util/apollo/config'

const styles = theme => ({
  root: {
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  attachmentContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  myMessageRoot: {
    justifyContent: 'flex-end',
  },
  othersMessageRoot: {
    justifyContent: 'flex-start',
  },
  message: {
    display: 'block',
    padding: theme.spacing(1),
  },
  myMessage: {
    background: `${theme.palette.primary.main}44`,
  },
  othersMessage: {
    background: '#fff',
  },
  sender: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
})

const Component = ({
  classes,
  message: {
    message: {
      body,
      createdDate,
      sender: { uuid: senderuuid, firstName: senderFirstName },
      fileItem,
    },
  },
  useruuid,
}) => {
  const myMessage = useruuid === senderuuid
  const fileItems = fileItem.edges.map(({ node }) => node)

  const downloadFile = () =>
    downloadFileOnClick(
      baseUrl + '/api/rest/v1/file/download/r/' + fileItems[0].uuid,
      fileItems[0].originalFileName
    )()

  return (
    <div
      className={clsx(
        classes.root,
        myMessage ? classes.myMessageRoot : classes.othersMessageRoot
      )}
    >
      <Paper
        className={clsx(
          classes.message,
          myMessage ? classes.myMessage : classes.othersMessage
        )}
      >
        <div className={clsx(fileItems.length && classes.header)}>
          <Typography className={classes.sender} display="inline">
            {senderFirstName}
          </Typography>
          <Typography
            className={classes.date}
            display="inline"
            variant="caption"
          >
            {DateTime.fromISO(createdDate).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </Typography>
        </div>
        <Typography>{body}</Typography>
        {fileItems.length ? (
          <div className={classes.attachmentContainer}>
            <Typography variant="caption">Attachment: </Typography>
            <Chip
              variant="outlined"
              label={fileItems[0].originalFileName}
              onClick={downloadFile}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.object,
  useruuid: PropTypes.string,
}

const mapStateToProps = state => ({
  useruuid: state.userInfo.uuid,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Component)
