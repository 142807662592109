import gql from 'graphql-tag'

const GET_COMPANY_CONTACTS = gql`
  query GET_COMPANY_CONTACTS($companyUUID: UUID) {
    allContacts(company_Uuid: $companyUUID, status: "active") {
      edges {
        node {
          uuid
          firstName
          lastName
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          email
          phone
          type
        }
      }
    }
  }
`

const CREATE_CONTACT = gql`
  mutation(
    $firstName: String
    $lastName: String
    $type: String
    $email: String
    $phone: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $companyUUID: String
    $primaryCompanyContact: Boolean
  ) {
    createContact(
      input: {
        firstName: $firstName
        lastName: $lastName
        type: $type
        email: $email
        phone: $phone
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressCity: $addressCity
        addressState: $addressState
        addressZip: $addressZip
        company: $companyUUID
        primaryCompanyContact: $primaryCompanyContact
      }
    ) {
      ok
    }
  }
`
const GET_CONTACT = gql`
  query GET_CONTACT($uuid: UUID) {
    allContacts(uuid: $uuid) {
      edges {
        node {
          uuid
          firstName
          lastName
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          email
          phone
          type
          primaryCompanyContact
        }
      }
    }
  }
`

const DELETE_CONTACT = gql`
  mutation($uuid: String) {
    updateContact(input: { uuid: $uuid, status: "inactive" }) {
      ok
    }
  }
`

const UPDATE_CONTACT = gql`
  mutation(
    $uuid: String!
    $firstName: String
    $lastName: String
    $type: String
    $email: String
    $phone: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $primaryCompanyContact: Boolean
  ) {
    updateContact(
      input: {
        uuid: $uuid
        firstName: $firstName
        lastName: $lastName
        type: $type
        email: $email
        phone: $phone
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressCity: $addressCity
        addressState: $addressState
        addressZip: $addressZip
        primaryCompanyContact: $primaryCompanyContact
      }
    ) {
      ok
    }
  }
`

const GET_BUYER_PROFILE = gql`
  query($buyerUUID: UUID!) {
    allCompanies(type: "buyer", uuid: $buyerUUID) {
      edges {
        node {
          status
          uuid
          name
          website
          campaignCount
          userCount
          clientCount
          contractAmountAll
          contact {
            edges {
              node {
                uuid
                firstName
                lastName
                phone
                email
                primaryCompanyContact
              }
            }
          }
        }
      }
    }
  }
`

const GET_BUYER_CONTACTS = gql`
  query($uuid: UUID) {
    allContracts(uuid: $uuid) {
      edges {
        node {
          buyerContact {
            firstName
            lastName
            email
            phone
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZip
            type
            status
            company {
              name
              uuid
            }
          }
        }
      }
    }
  }
`

const GET_SELLER_CONTACTS = gql`
  query($uuid: UUID) {
    allContracts(uuid: $uuid) {
      edges {
        node {
          productSize {
            product {
              seller {
                contact {
                  edges {
                    node {
                      firstName
                      lastName
                      email
                      phone
                      addressLine1
                      addressLine2
                      addressCity
                      addressState
                      addressZip
                      type
                      status
                      company {
                        name
                        uuid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export {
  GET_COMPANY_CONTACTS,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  GET_CONTACT,
  DELETE_CONTACT,
  GET_BUYER_PROFILE,
  GET_BUYER_CONTACTS,
  GET_SELLER_CONTACTS,
}
