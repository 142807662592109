import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Button, TextField, withStyles } from '@material-ui/core'
import { useMutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import clsx from 'clsx'

import {
  GET_MESSAGES,
  SEND_MESSAGE,
} from '../../../../../util/apollo/queries/messages'
import { withNotifications } from '../../../../../util'
import { baseUrl } from '../../../../../util/apollo/config'
import AttachFile from './AttachFile'

const styles = theme => ({
  root: {},
  button: {
    height: 56,
    marginLeft: theme.spacing(1),
    width: 104,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
  },
  input: {
    background: '#fff',
  },
})

const Component = ({ classes, className, channeluuid, notify, token }) => {
  const [body, setBody] = useState('')
  const [file, setFile] = useState([])
  const [sendMessageMutation] = useMutation(SEND_MESSAGE)
  if (!channeluuid) return null

  const handleChange = ({ target: { value } }) => setBody(value)

  const sendMessage = variables => {
    sendMessageMutation({
      variables,
      refetchQueries: [{ query: GET_MESSAGES, variables: { channeluuid } }],
    }).then(({ data }) => {
      if (data.createMessage.ok) {
        notify.success('Message Sent')
        setBody('')
        setFile([])
      }
    })
  }
  const submitHandler = e => {
    e.preventDefault()

    if (file.length) {
      let data = new FormData()
      data.append('file', file[0])
      data.append('type', 'other')
      data.append('description', 'message attachment')
      data.append('source', 'other')
      data.append('security', 'private')

      fetch(baseUrl + '/api/rest/v1/file/upload', {
        method: 'POST',
        headers: {
          Authorization: 'JWT ' + token,
        },
        body: data,
      })
        .then(response => {
          if (!response.ok) throw new Error(response.statusText)
          return response.json()
        })
        .then(({ uuid }) =>
          sendMessage({
            channeluuid,
            body,
            urgent: false,
            fileItemList: [uuid],
          })
        )
    } else {
      sendMessage({
        channeluuid,
        body,
        urgent: false,
        fileItemList: [],
      })
    }
  }

  return (
    <form onSubmit={submitHandler} className={clsx(classes.form, className)}>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={4}
        label="Message"
        value={body}
        variant="outlined"
        onChange={handleChange}
        className={classes.input}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
      >
        Send
      </Button>
      <AttachFile file={file} setFile={setFile} />
    </form>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  client: PropTypes.object,
  channeluuid: PropTypes.string,
  notify: PropTypes.object,
  token: PropTypes.string,
}

const mapStateToProps = state => ({
  token: state.tokens.token,
})

export default compose(
  connect(mapStateToProps),
  withApollo,
  withRouter,
  withNotifications,
  withStyles(styles)
)(Component)
