import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ProductInfo from './ProductInfo'
import Template from './Templates'
import AdType from './AdTypes'

const Product = ({ match }) => {
  const [companyUUID] = useState(match.params.cuuid)
  const [productUUID, setProductUUID] = useState(match.params.uuid)

  return (
    <div>
      <ProductInfo
        setProductUUID={setProductUUID}
        productUUID={productUUID}
        companyUUID={companyUUID}
      />
      <Template productUUID={productUUID} />
      <AdType productUUID={productUUID} />
    </div>
  )
}

Product.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default Product
