import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { DialogContent, makeStyles } from '@material-ui/core'

import RecurrenceField from './RecurrenceField'

const useStyles = makeStyles({
  root: {
    minHeight: 320,
  },
})
export default function Content({ modalType }) {
  const classes = useStyles()
  const [{ value }, , { setValue: onChange }] = useField('value')
  switch (modalType) {
    case 'newRecurringRule': {
      return (
        <DialogContent className={classes.root}>
          <RecurrenceField value={value} onChange={onChange} />
        </DialogContent>
      )
    }
    default:
      return null
  }
}

Content.propTypes = {
  modalType: PropTypes.oneOf(['newRecurringRule']),
}
