import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  circleContainer: {
    display: 'inline-block',
    height: 20,
    marginRight: theme.spacing(1),
    width: 20,
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  innerCircle: {
    borderRadius: '100%',
    height: 10,
    left: 5,
    position: 'relative',
    top: -15,
    width: 10,
  },
  line: {
    alignItems: 'center',
    display: 'flex',
  },
  outerCircle: {
    borderRadius: '100%',
    height: 20,
    width: 20,
  },
})

const Circle = ({ classes, color }) => (
  <div className={classes.circleContainer}>
    <div
      className={classes.outerCircle}
      style={{ backgroundColor: color, filter: 'opacity(.2)' }}
    />
    <div className={classes.innerCircle} style={{ backgroundColor: color }} />
  </div>
)

Circle.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
}

export default withStyles(styles)(Circle)
