import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { connect } from 'react-redux'

import { logout } from '../../../../paths/Auth'

const height = 64
const styles = theme => ({
  accountCircleIcon: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
  },
  accountNameContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingTop: 0,
  },
  appBar: {
    background: '#f7f7f7',
    color: '#144256',
    display: 'flex',
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
  },
  button: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    fontSize: 12,
    marginLeft: theme.spacing(4),
    minWidth: 300,
    maxWidth: '100%',
  },
  divider: {
    margin: `${theme.spacing(1)}px 0`,
  },
  filler: {
    height,
    width: '100%',
  },
  logoutContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingBottom: 0,
  },
  menu: {
    padding: 0,
  },
  pushToEnd: {
    marginLeft: 'auto',
    marginRight: 8,
  },
  logo: {
    width: 120,
  },
})

const LogoutMenu = ({ classes, user }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const openMenu = e => {
    setAnchorEl(e.target)
    setOpen(true)
  }
  const closeMenu = () => setOpen(false)

  const handleLogout = () => {
    closeMenu()
    logout()
  }

  return (
    <>
      <IconButton onClick={openMenu}>
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        className={classes.menu}
      >
        <MenuItem dense>
          <AccountCircleIcon className={classes.accountCircleIcon} />
          <div>
            <Typography variant="body1" display="inline">
              {user.firstName} {user.lastName}
            </Typography>
          </div>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem dense onClick={handleLogout}>
          <Typography>Log Out</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

const mapStateToPropsLogout = state => ({
  user: state.userInfo,
})

LogoutMenu.propTypes = {
  user: PropTypes.object,
}

export default compose(
  withStyles(styles),
  connect(mapStateToPropsLogout)
)(LogoutMenu)
