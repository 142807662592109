import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import decode from 'jwt-decode'

import { logout } from '../../paths/Auth'
import * as actions from '../redux/actions'
import { store } from '../redux/store'
import history from '../router/history'
import { GENERATE_NEW_TOKENS } from './queries/user'

import { baseUrl } from './config'

const getAccessToken = () => store.getState().tokens.token
const getRefreshToken = () => store.getState().tokens.refreshToken

// Open client

const openErrorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    store.dispatch(actions.notifications.notifyError(networkError.message))
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      //Hack to parse response message
      if (message.includes(`[ErrorDetail(string='`)) {
        message = message.slice(20).split(',')[0]
        message = message.substring(1, message.length - 1)
      }

      if (message === 'Refresh token is expired') {
        message = 'Session expired. Please sign in again to continue.'
        logout()
        store.dispatch(actions.notifications.notifyError(message))
        return
      }

      store.dispatch(actions.notifications.notifyError(message))
    })
  }
})

const openHttpLink = new HttpLink({
  uri: baseUrl + '/api/ographql',
})

const oClient = new ApolloClient({
  link: ApolloLink.from([openErrorLink, openHttpLink]),
  cache: new InMemoryCache(),
})

// Restricted client

const httpLink = new HttpLink({ uri: baseUrl + '/api/rgraphql' })

const refreshLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken()
  if (token) {
    const { exp } = decode(token)

    // If token is expired generate new token
    if (Date.now() >= exp * 1000) {
      const refreshToken = getRefreshToken()
      if (refreshToken) {
        oClient
          .mutate({
            mutation: GENERATE_NEW_TOKENS,
            variables: { refreshToken },
          })
          .then(({ data: { refreshToken } }) => {
            store.dispatch(actions.tokens.setTokens(refreshToken))
            return forward(operation)
          })
          .catch(() => history.push('/auth/login'))
      } else {
        store.dispatch(actions.tokens.clearTokens())
        history.push('/auth/login')
      }
    } else {
      return forward(operation)
    }
  } else {
    store.dispatch(actions.tokens.clearTokens())
    history.push('/auth/login')
  }
})

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken()
  if (token)
    operation.setContext({
      headers: {
        authorization: `JWT ${token}`,
      },
    })
  else {
    history.push('/auth/login')
  }

  return forward(operation)
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log('networkError', { ...networkError })
    store.dispatch(actions.notifications.notifyError(networkError.message))
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      store.dispatch(actions.notifications.notifyError(message))
    )
  }
})

const rClient = new ApolloClient({
  link: ApolloLink.from([refreshLink, authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
})

export { rClient, oClient }
