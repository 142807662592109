import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { animateScroll } from 'react-scroll'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { GET_MESSAGES } from '../../../../../util/apollo/queries/messages'
import { withNotifications } from '../../../../../util'

const Component = ({ client, channeluuid, notify }) => {
  const refresh = () => {
    client.query({
      query: GET_MESSAGES,
      fetchPolicy: 'network-only',
      variables: { uuid: channeluuid },
      refetchQueries: [
        {
          query: GET_MESSAGES,
          variables: { uuid: channeluuid },
        },
      ],
    })

    animateScroll.scrollToBottom({
      containerId: 'messages-panel',
      duration: 1,
    })

    notify.success('Refreshed')
  }

  return (
    <IconButton onClick={refresh}>
      <RefreshIcon />
    </IconButton>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  notify: PropTypes.object,
  channeluuid: PropTypes.string,
}

export default compose(
  withApollo,
  withRouter,
  withNotifications
)(Component)
