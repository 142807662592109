import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  withStyles,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { connect } from 'react-redux'

import { GET_CLIENTS } from '../../../../util/apollo/queries/clients'

import AddExistingButton from './AddExisting'
import HideClient from './HideClient'
import WelcomeDialog from './WelcomeDialog'
import './overrides.css'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  iconButtonPlaceholder: {
    height: 48,
    width: 48,
  },
  joinExistingButton: {
    marginRight: theme.spacing(1),
  },
  tableTitle: {
    paddingTop: 24,
    paddingLeft: 24,
  },
})

const campaignList = campaign => {
  if (campaign.edges.length) {
    let list = campaign.edges
      .map(e => e.node)
      .filter(({ status }) => status === 'ACTIVE')
      .reduce((list, { name }, i) => {
        if (i) return `${list}, ${name}`
        return name
      }, '')

    if (list.length > 100) list = list.slice(0, 100) + ' ...'
    return list
  } else {
    return '(no campaigns)'
  }
}

const Clients = ({
  campaigns,
  classes,
  history,
  match: {
    params: { campaignuuid },
  },
  useruuid,
}) => {
  const { loading, data, error } = useQuery(GET_CLIENTS, {
    variables: { useruuid },
  })
  if (loading) return 'Loading...'
  if (error) return 'Error'
  const viewClient = uuid => () =>
    history.push(`/customer/${campaignuuid}/client/${uuid}`)

  const redirectToNewClient = () =>
    history.push(`/customer/${campaignuuid}/client/new`)

  const welcomeDialog = () => (!campaigns.length ? <WelcomeDialog /> : null)

  return (
    <>
      {welcomeDialog()}
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h6">Clients</Typography>
          <div>
            <AddExistingButton />
            <Button
              onClick={redirectToNewClient}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Campaigns</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.allClients.edges
              .map(e => e.node)
              .map(({ uuid: clientuuid, name, campaign }) => {
                return (
                  <TableRow
                    hover
                    key={clientuuid}
                    onClick={viewClient(clientuuid)}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Typography noWrap>{campaignList(campaign)}</Typography>
                    </TableCell>
                    <TableCell align="right" padding="none">
                      <HideClient clientuuid={clientuuid} useruuid={useruuid} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

const mapStateToProps = state => ({
  useruuid: state.userInfo.uuid,
  campaigns: state.campaigns,
})

Clients.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  useruuid: PropTypes.string.isRequired,
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(Clients)
