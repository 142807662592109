import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  withStyles,
  TextField,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import { camelCase } from '../helperfunctions'

const styles = () => ({})

const GET_DEPARTMENTS = gql`
  query {
    modelFieldOptions(modelName: "contact", fieldName: "type") {
      edges {
        node {
          fieldChoices
        }
      }
    }
  }
`

const AddNewContact = ({ classes, contacts, setContacts, edit }) => {
  const defaultState = {
    department: '',
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    apt: '',
    city: '',
    state: '',
    country: '',
    zip: '',
  }

  const [open, openModal] = useState(!!edit)
  const [state, setState] = useState(edit || defaultState)

  const handleChange = ({ target }) =>
    setState(prev => ({ ...prev, [target.name]: target.value }))

  const closeDialog = () => {
    openModal(false)
    if (edit) edit.onFinished()
  }

  const submitHandler = e => {
    e.preventDefault()

    if (edit) {
      contacts[edit.index] = state
      setContacts(contacts)
    } else setContacts([...contacts, state])
    setState(defaultState)
    closeDialog()
  }

  return (
    <React.Fragment>
      {!edit ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => openModal(true)}
        >
          Add New Contact
        </Button>
      ) : null}
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Add New Contact</DialogTitle>
        <Divider />
        <form onSubmit={submitHandler}>
          <DialogContent>
            <Query query={GET_DEPARTMENTS}>
              {({ loading, error, data }) => {
                let options = (
                  <MenuItem value={null} disabled>
                    -
                  </MenuItem>
                )
                if (!loading && !error)
                  options = data.modelFieldOptions.edges[0].node.fieldChoices.map(
                    option => (
                      <MenuItem key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </MenuItem>
                    )
                  )
                return (
                  <TextField
                    select
                    fullWidth
                    required
                    margin="normal"
                    name="department"
                    label="Department"
                    variant="outlined"
                    onChange={handleChange}
                    value={state.department}
                  >
                    {options}
                  </TextField>
                )
              }}
            </Query>
            {[
              'Name',
              'Email',
              'Phone',
              'Address Line 1',
              'Apt',
              'City',
              'State',
              'Zip',
              'Country',
            ].map(field => (
              <TextField
                key={field}
                required
                fullWidth
                label={field}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                name={camelCase(field)}
                value={state[camelCase(field)]}
              />
            ))}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button type="submit" color="primary" variant="contained">
              Continue
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

AddNewContact.propTypes = {
  classes: PropTypes.object,
  contacts: PropTypes.array,
  edit: PropTypes.object,
  setContacts: PropTypes.func,
}

export default withStyles(styles)(AddNewContact)
