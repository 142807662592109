import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

const styles = theme => ({
  button: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1),
    width: 150,
    height: 40.5,
    marginBottom: 16,
    background: '#fff',
    color: '#0dbffe',
    fontWeight: '700',
  },
  textField: {
    width: '100%',
  },
})

const ADD_CREDIT = gql`
  mutation addCredit {
    asdf
  }
`

const AddCredit = ({ classes }) => {
  const [open, openModal] = useState(false)

  const handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  const submitHandler = (e, addCredit, openModal) => {
    e.preventDefault()
    const { address, amount, description } = this.state

    const variables = {
      address,
      amount,
      description,
    }

    addCredit({ variables: variables })
    openModal(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => openModal(true)}
      >
        Add Credit
      </Button>
      <Dialog open={open} onClose={() => openModal(false)}>
        <DialogTitle>Add Credit</DialogTitle>
        <Divider />
        <Mutation mutation={ADD_CREDIT}>
          {addCredit => (
            <form onSubmit={e => submitHandler(e, addCredit, openModal)}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id="filled-multiline-static"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      name="amount"
                      placeholder="0.00"
                      label="Amount"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows="4"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      placeholder="Write a memo"
                      name="description"
                      label="Memo"
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={() => openModal(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

AddCredit.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(AddCredit)
