import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withRouter } from 'react-router-dom'

import {
  isUUID,
  capitalizationCase,
} from '../../../../../shared/helperfunctions'

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(1),
  },
})

const Header = ({
  classes,
  match,
  match: {
    params: { filter = 'all' },
  },
  history,
}) => {
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null)

  const openFilterMenu = e => setFilterMenuAnchor(e.currentTarget)
  const closeFilterMenu = () => setFilterMenuAnchor(null)

  const clickOnFilter = newFilter => () => {
    const url = match.url
      .split('/')
      .map(segment => (segment === filter ? newFilter : segment))
      .slice(0, 5)
      .join('/')

    closeFilterMenu()
    history.push(url)
  }

  const filterLabel = isUUID(filter)
    ? 'Contract Messages'
    : capitalizationCase(`${filter} Messages`)

  return (
    <>
      <Button className={classes.root} onClick={openFilterMenu}>
        <Typography variant="h6">{filterLabel}</Typography>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={filterMenuAnchor}
        open={Boolean(filterMenuAnchor)}
        onClose={closeFilterMenu}
      >
        <MenuItem onClick={clickOnFilter('all')} selected={filter === 'all'}>
          All Messages
        </MenuItem>
        <MenuItem
          onClick={clickOnFilter('unread')}
          selected={filter === 'unread'}
        >
          Unread Messages
        </MenuItem>
        <MenuItem
          onClick={clickOnFilter('archived')}
          selected={filter === 'archived'}
        >
          Archived Messages
        </MenuItem>
        {isUUID(filter) && (
          <MenuItem
            onClick={clickOnFilter('contract')}
            selected={filter === 'contract'}
          >
            Contract Messages
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

Header.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(Header)
