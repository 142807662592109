import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { DateTime } from 'luxon'

import { GET_STATEMENT_OVERVIEW } from '../../../../util/apollo/queries/statements'
import GenericTableTwo from '../../../../shared/components/GenericTableTwo'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
})

const parseData = data =>
  data.allStatements.edges
    .map(({ node }) => node)
    .map(({ uuid, statementId, company: { name }, createdDate, dueDate }) => ({
      uuid,
      statementId,
      companyName: name,
      createdDate: DateTime.fromISO(createdDate).toLocaleString(
        DateTime.DATE_SHORT
      ),
      dueDate: DateTime.fromISO(dueDate).toLocaleString(DateTime.DATE_SHORT),
    }))

const columns = [
  { name: 'uuid', options: { display: 'excluded' } },
  { name: 'statementId', label: 'ID' },
  { name: 'companyName', label: 'Company' },
  { name: 'createdDate', label: 'Created Date' },
  { name: 'dueDate', label: 'Due Date' },
]

const Overview = ({ history, classes }) => {
  const count = 20
  const { loading, error, data, fetchMore } = useQuery(GET_STATEMENT_OVERVIEW, {
    variables: { first: count },
  })

  const onRowClick = row =>
    history.push(`/moderator/invoices/detail/${row.uuid}`)
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (!data) return 'Error'
  return (
    <div className={classes.root}>
      <GenericTableTwo
        data={parseData(data)}
        title="Invoices"
        columns={columns}
        pagination={{
          fetchMore,
          pageInfo: data.allStatements.pageInfo,
          count: count,
        }}
        options={{
          selectableRows: 'none',
          onRowClick,
        }}
      />
    </div>
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(Overview)
