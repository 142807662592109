import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  CHANGE_CONTRACT_STATUS,
  GET_TERMS,
} from '../../../../../../util/apollo/queries/contracts'
import { withNotifications } from '../../../../../../util'

const Component = ({
  match: {
    params: { contractuuid },
  },
  notify,
  stage,
}) => {
  const [mutation] = useMutation(CHANGE_CONTRACT_STATUS)

  const togglePause = () => {
    mutation({
      variables: {
        contractuuid,
        status: 'cancel',
      },
      refetchQueries: [
        {
          query: GET_TERMS,
          variables: {
            contract: contractuuid,
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data.disputeContract.ok) notify.success('Deleted')
      })
      .catch(() => null)
  }

  return (
    <Tooltip title={'Delete'}>
      <IconButton onClick={togglePause} disabled={stage === 'canceled'}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object,
  stage: PropTypes.string,
}

export default compose(
  withRouter,
  withNotifications
)(Component)
