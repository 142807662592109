import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import {
  Typography,
  Link,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  Box,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    fontWeight: 'inherit',
    fontStyle: 'inherit',
  },
})

function Root(props) {
  return (
    <Box padding={2}>
      <React.Fragment {...props} />
    </Box>
  )
}
function Paragraph(props) {
  return <Typography {...props} paragraph />
}

function Emphasis(props) {
  const classes = useStyles()
  return (
    <Box fontStyle="italic">
      <Typography className={classes.root} {...props} />
    </Box>
  )
}

function Strong(props) {
  const classes = useStyles()
  return (
    <Box fontWeight="fontWeightBold">
      <Typography classes={classes} className={classes.root} {...props} />
    </Box>
  )
}

function Heading(props) {
  return <Typography {...props} variant={`h${props.level}`} />
}

Heading.propTypes = { level: PropTypes.number.isRequired }

export default function MarkdownDisplay({ markdown }) {
  return (
    <ReactMarkdown
      source={markdown}
      renderers={{
        root: Root,
        paragraph: Paragraph,
        emphasis: Emphasis,
        strong: Strong,
        link: Link,
        linkReference: Link,
        table: Table,
        tableHead: TableHead,
        tableBody: TableBody,
        tableRow: TableRow,
        tableCell: TableCell,
        list: List,
        listItem: ListItem,
        heading: Heading,
      }}
    />
  )
}

MarkdownDisplay.propTypes = {
  markdown: PropTypes.string.isRequired,
}
