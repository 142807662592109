import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Card,
  Grid,
  IconButton,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'

import { snakeToTitleCase } from '../../../../../shared/helperfunctions'
import {
  ADD_STARRED_PRODUCT,
  REMOVE_STARRED_PRODUCT,
  GET_STARRED_PRODUCTS,
  GET_PRODUCTS_SINGULAR,
} from '../../../../../util/apollo/queries/products'

import ProductImage from './ProductImage'

const styles = theme => ({
  root: {
    cursor: 'pointer',
    height: 200,
  },
  mainContainer: {
    height: '100%',
  },
  textContainer: {
    padding: theme.spacing(2),
    height: '100%',
  },
  contentDescription: {
    height: '100%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  contentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imgGrid: {
    flexGrow: 1,
    position: 'relative',
  },
  titleGrid: {
    position: 'relative',
  },
  descriptionGrid: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
  imgOverlay: {
    borderRadius: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: theme.spacing(0.5) + 2,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    position: 'absolute',
    width: '100%',
    top: theme.spacing(1.5),
    zIndex: 1000,
  },
  starIco: {
    position: 'absolute',
    top: theme.spacing(-1),
    right: theme.spacing(0.0),
  },
})

const Product = ({ campaignUuid, classes, product, history }) => {
  const [addStarMutation] = useMutation(ADD_STARRED_PRODUCT)
  const [removeStarMutation] = useMutation(REMOVE_STARRED_PRODUCT)

  const refetchQueries = [
    {
      query: GET_STARRED_PRODUCTS,
      variables: {
        campaign: campaignUuid,
      },
    },
    {
      query: GET_PRODUCTS_SINGULAR,
      variables: {
        uuid,
        campaign: campaignUuid,
      },
    },
  ]

  try {
    var {
      fileItem: { edges: fileItems },
    } = product
  } catch {
    //
  }

  let {
    purchaseMin = '{}',
    uuid = '',
    starred,
    title = '',
    description = '',
    mediaType = '',
  } = product

  purchaseMin = JSON.parse(purchaseMin) || {}

  const productImageUUID = fileItems.length
    ? fileItems
        .map(({ node }) => node)
        .filter(({ type }) => type === 'PRODUCT_IMAGE')[0].uuid
    : ''

  const addStarred = () => () => {
    const variables = {
      campaign: campaignUuid,
      product: uuid,
    }
    addStarMutation({ variables, refetchQueries })
  }

  const removeStarred = () => () => {
    const variables = {
      campaign: campaignUuid,
      product: uuid,
    }
    removeStarMutation({ variables, refetchQueries })
  }

  const goToDetails = e => {
    if (e.target.id !== 'star-icon' && !e.target.farthestViewportElement)
      history.push(`/customer/${campaignUuid}/products/details/` + uuid)
  }
  const createDescriptionString = description => {
    const maxLength = 200
    if (description.length > maxLength) {
      const newDescription = description.slice(0, maxLength) + '...'
      return newDescription
    } else {
      return description
    }
  }

  return (
    <Card id={uuid} className={classes.root} onClick={goToDetails}>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={3} className={classes.imgGrid}>
          <Paper className={classes.imgOverlay}>
            <Typography variant="subtitle1">
              {snakeToTitleCase(mediaType)}
            </Typography>
          </Paper>
          <ProductImage uuid={productImageUUID} />
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="column"
            justify="space-between"
            className={classes.textContainer}
          >
            <Grid item className={classes.titleGrid}>
              <Typography variant="h5">{title}</Typography>
              <IconButton
                id="star-icon"
                className={classes.starIco}
                onClick={starred ? removeStarred() : addStarred()}
              >
                {starred ? (
                  <StarIcon id="star-icon" />
                ) : (
                  <StarBorderIcon id="star-icon" />
                )}
              </IconButton>
            </Grid>
            <Grid item className={classes.descriptionGrid}>
              <Typography variant="subtitle2" color="textSecondary">
                {createDescriptionString(description)}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={8}>
                  <Typography display="inline" variant="subtitle2">
                    {`Starting at $${purchaseMin.price} for ${
                      purchaseMin.impressions
                    } impressions`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    style={{ justifySelf: 'flex-end' }}
                  >
                    {`${purchaseMin.buy_increment} ${
                      purchaseMin.buy_unit
                        ? purchaseMin.buy_unit.toLowerCase()
                        : ''
                    } minimum duration`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

Product.propTypes = {
  campaignUuid: PropTypes.string,
  product: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  productSavedList: PropTypes.array,
}

export default compose(
  withRouter,
  withStyles(styles)
)(Product)
