import gql from 'graphql-tag'

const GET_ALERTS = gql`
  query GET_ALERTS($todaysDate: DateTime) {
    allAlertactivities(notificationDate_Lte: $todaysDate, status: "active") {
      edges {
        node {
          uuid
          read
          status
          note
          notificationDate
          alertInformation {
            uuid
            type
            title
            message
            linkedType
            linkedUuid
            contract {
              uuid
            }
            campaign {
              name
              client {
                uuid
                name
              }
            }
          }
        }
      }
    }
  }
`
const GET_SUMMARY = gql`
  query GET_SUMMARY {
    summaryAlertactivity {
      edges {
        node {
          type
          count
        }
      }
    }
  }
`

const GET_CONTRACT_ALERTS = gql`
  query($contract: UUID, $date: DateTime) {
    allAlertactivities(
      alertInformation_Contract_Uuid: $contract
      status: "active"
      notificationDate_Lte: $date
    ) {
      edges {
        node {
          uuid
          status
          note
          notificationDate
          alertInformation {
            title
            message
            createdDate
          }
        }
      }
    }
  }
`

const SNOOZE_ALERT = gql`
  mutation snoozeAlert($alertUUID: String!, $date: DateTime!, $note: String!) {
    updateAlertactivity(
      input: { uuid: $alertUUID, notificationDate: $date, note: $note }
    ) {
      ok
    }
  }
`

const REMOVE_ALERT = gql`
  mutation REMOVE_ALERT($uuid: String) {
    updateAlertactivity(input: { uuid: $uuid, status: "archive" }) {
      ok
    }
  }
`

export {
  GET_ALERTS,
  GET_SUMMARY,
  GET_CONTRACT_ALERTS,
  SNOOZE_ALERT,
  REMOVE_ALERT,
}
