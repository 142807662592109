import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { animateScroll } from 'react-scroll'
import clsx from 'clsx'

import { lightGray } from '../constants'
import { GET_MESSAGES } from '../../../../util/apollo/queries/messages'

import Message from './Message'

const styles = theme => ({
  root: {
    background: lightGray,
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: '100%',
  },
  fileSpacer: {
    height: theme.spacing(5),
    width: '100%',
  },
  noMessagesContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})

const Messages = ({
  classes,
  className,
  channeluuid,
  match: {
    params: { filter },
  },
}) => {
  const [messages, setMessages] = useState([])

  const { data, refetch } = useQuery(GET_MESSAGES, {
    variables: { channeluuid },
  })

  // Get messages for channel when new channel selected
  useEffect(() => {
    if (channeluuid) refetch()
    else setMessages([])
  }, [channeluuid, refetch])

  // Set messages hook when query completes or updates
  useEffect(() => {
    if (data && Object.entries(data).length && channeluuid)
      setMessages(data.allMessageactivities.edges.map(({ node }) => node))
  }, [data, channeluuid])

  // Scroll messages pane to bottom when we fetch messages
  useEffect(
    () =>
      animateScroll.scrollToBottom({
        containerId: 'messages-panel',
        duration: 1,
      }),
    [messages]
  )

  // Scroll messages pane to bottom when we fetch messages
  useEffect(() => setMessages([]), [filter])

  return (
    <div className={clsx(classes.root, className)} id="messages-panel">
      {!messages.length ? (
        <div className={classes.noMessagesContainer}>
          <Typography variant="body1" color="textSecondary">
            Select a conversation to view messages
          </Typography>
        </div>
      ) : (
        messages.map(message => (
          <Message key={message.uuid} message={message} />
        ))
      )}
      <div className={classes.fileSpacer} />
    </div>
  )
}

Messages.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  match: PropTypes.object,
  channeluuid: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withRouter
)(Messages)
