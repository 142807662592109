/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import RRule from 'rrule'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { getRRuleDayFromLuxon } from '../WeekdayField'
import { ordinalSuffixOf } from '../../../../../../../../../../../../../shared/helperfunctions'

export default function YearlyTabPanel({
  startDate,
  endDate,
  interval,
  onChange,
  isByDate: initialIsByDate,
  isSelected,
}) {
  const startDateAsLuxon = DateTime.fromISO(startDate)
  const weekOfTheMonth = Math.ceil(startDateAsLuxon.day / 7)
  const [isByDate, setIsByDate] = useState(initialIsByDate)
  const onChangeRef = useRef(onChange)

  // calling  onChange when the tab is selected
  useEffect(() => {
    if (!isSelected) {
      return
    }
    const startDateAsLuxon = DateTime.fromISO(startDate)
    const rule = new RRule({
      freq: RRule.YEARLY,
      interval,
      dtstart: startDateAsLuxon.toJSDate(),
      bymonth: (startDateAsLuxon.month + 1) % 12,
      ...(endDate ? { until: DateTime.fromISO(endDate).toJSDate() } : {}),
      ...(isByDate
        ? {
            bymonthday: startDateAsLuxon.day,
          }
        : {
            byweekday: getRRuleDayFromLuxon(startDateAsLuxon).nth(
              weekOfTheMonth
            ),
          }),
    })
    onChangeRef.current(rule.toString())
  }, [startDate, weekOfTheMonth, endDate, isSelected, interval, isByDate])
  if (!isSelected) {
    return null
  }
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Yearly on</FormLabel>
      <RadioGroup
        aria-label="byDateOrByWeekOfMonth"
        name="byDateOrByWeekOfMonth"
        value={isByDate ? 'byDateOfMonth' : 'byWeekOfMonth'}
        onChange={event => setIsByDate(event.target.value === 'byDateOfMonth')}
      >
        <FormControlLabel
          value="byDateOfMonth"
          control={<Radio />}
          label={`day ${startDateAsLuxon.day}`}
        />
        <FormControlLabel
          value="byWeekOfMonth"
          control={<Radio />}
          label={`the ${ordinalSuffixOf(
            weekOfTheMonth
          )} ${startDateAsLuxon.toFormat('EEEE of MMMM')}`}
        />
      </RadioGroup>
    </FormControl>
  )
}

YearlyTabPanel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  interval: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isByDate: PropTypes.bool.isRequired,
}

YearlyTabPanel.displayName = 'YearlyTabPanel'
