import React from 'react'
import { withRouter, Redirect, Route, Switch } from 'react-router-dom'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'

import Auth from './paths/Auth'
import Customer from './paths/Customer'
import Moderator from './paths/Moderator'
import InvoiceDetailPage from './paths/Customer/Invoices/Details'
import ProductDetailPage from './paths/Customer/Products/Details'
import AuthRoute from './util/router/AuthRoute'
import theme from './util/material-ui/theme'

import './App.css'

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <div style={{ background: '#F5F7FA', height: '100vh' }}>
      <Switch>
        <Route
          path="/customer/invoices/detail/noframe/:uuid"
          exact
          render={props => <InvoiceDetailPage {...props} />}
        />
        <AuthRoute
          path="/customer/products/details/noframe/:uuid"
          component={ProductDetailPage}
          exact={true}
        />
        <AuthRoute
          path="/:usertype(customer)/:campaignuuid?"
          component={Customer}
        />
        <AuthRoute path="/:usertype(moderator)" component={Moderator} />
        <Route path="/auth" render={props => <Auth {...props} />} />
        <Redirect to="/auth/login" />
      </Switch>
    </div>
  </MuiThemeProvider>
)

export default withRouter(App)
