import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import Overview from './Overview/'
import NewContract from './New'
import ContractList from './List'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
})

const Contract = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route
          path={match.path + '/overview/:reviewmode(edit|new)/:contractuuid'}
          component={NewContract}
        />
        <Route
          path={match.path + '/overview/:contractuuid'}
          component={Overview}
        />
        <Route path={match.path + '/'} component={ContractList} />
      </Switch>
    </div>
  )
}

Contract.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default withStyles(styles)(Contract)
