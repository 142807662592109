import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import Filter from './Filter'
import Products from './Products'

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: `${211 + 300}px 1fr`,
  },
}

const Search = ({ classes, match }) => {
  const campaign = match.params.campaignuuid
  const [filters, setFilters] = useState({})

  return (
    <div className={classes.root}>
      <Filter setFilters={setFilters} />
      <Products filters={filters} campaign={campaign} />
    </div>
  )
}

export default withStyles(styles)(Search)

Search.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}
