/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, TextField, Typography, withStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useMutation } from 'react-apollo'
import { UPDATE_CONTACT } from '../../../../../../util/apollo/queries/contacts'

const styles = theme => ({
  root: {
    cursor: 'pointer',
    padding: `0 ${theme.spacing(2)}px`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  saveButtonContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 60,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
})

const PrimaryContact = ({ classes, primaryContact = {}, refetchQueries }) => {
  // Hover state
  const [hoveringContact, setHoveringContact] = useState(false)
  const hoverContact = state => () => setHoveringContact(state)

  // Clean up data to display
  let {
    firstName: displayFirstName,
    lastName: displayLastName,
    email: displayEmail = '-',
    phone: displayPhone = '-',
  } = primaryContact

  const fullName = !(displayFirstName || displayLastName)
    ? '-'
    : `${displayFirstName} ${displayLastName}`

  // Handle Edit Mode
  const [editMode, setEditMode] = useState(false)

  const setEdit = () => {
    if (editMode) return
    setEditMode(true)
  }

  const cancelEditMode = () => setEditMode(false)

  // Form
  const [form, setForm] = useState({ ...primaryContact })
  const { firstName, lastName, email, phone } = form

  const handleChange = key => ({ target: { value } }) =>
    setForm(prev => ({ ...prev, [key]: value }))

  // Mutation
  const [updateContact] = useMutation(UPDATE_CONTACT)

  const submitSaveContact = async e => {
    e.preventDefault()

    updateContact({
      variables: { ...form },
      refetchQueries,
    }).then(({ data }) => {
      if (data.updateContact.ok) {
        setEditMode(false)
      }
    })
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={hoverContact(true)}
      onMouseLeave={hoverContact(false)}
      onClick={setEdit}
    >
      <div className={classes.header}>
        <Typography className={classes.title} variant="h6">
          Primary Contact
        </Typography>
        {hoveringContact && !editMode && <EditIcon />}
      </div>
      {editMode ? (
        <form onSubmit={submitSaveContact}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={handleChange('firstName')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={handleChange('lastName')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Email"
            value={email}
            onChange={handleChange('email')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={handleChange('phone')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <div className={classes.saveButtonContainer}>
            <Button variant="outlined" onClick={cancelEditMode}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <>
          <div className={classes.item}>
            <Typography variant="caption">Name</Typography>
            <Typography>{fullName}</Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption">Email</Typography>
            <Typography>{displayEmail}</Typography>
          </div>
          <div>
            <Typography variant="caption">Phone</Typography>
            <Typography>{displayPhone}</Typography>
          </div>
        </>
      )}
    </div>
  )
}

PrimaryContact.propTypes = {
  classes: PropTypes.object,
  primaryContact: PropTypes.object,
  refetchQueries: PropTypes.array,
}

export default withStyles(styles)(PrimaryContact)
