import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell } from '@material-ui/core'
import { sortBy, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { formatDate } from '../../../../../../shared/helperfunctions'

function calculateInvoiceStatuses(payments, invoices) {
  const sortedInvoices = sortBy(invoices, 'dueDate')
  let totalPayments = sumBy(payments, 'amount')
  return sortedInvoices.map(invoice => {
    if (invoice.amount <= totalPayments) {
      totalPayments -= invoice.amount
      return {
        ...invoice,
        status: 'Paid',
      }
    }
    if (totalPayments > 0) {
      totalPayments = 0
      return {
        ...invoice,
        status: 'Partially Paid',
      }
    }
    if (DateTime.fromISO(invoice.dueDate) > DateTime.utc()) {
      return {
        ...invoice,
        status: 'Scheduled',
      }
    }
    return {
      ...invoice,
      status: 'Past Due',
    }
  })
}

export default function Invoices({ payments, invoices }) {
  if (invoices.length === 0) {
    return (
      <TableRow>
        <TableCell>(none)</TableCell>
        <TableCell />
      </TableRow>
    )
  }

  const invoicesWithStatuses = calculateInvoiceStatuses(payments, invoices)
  return invoicesWithStatuses.map(invoice => (
    <TableRow key={invoice.uuid}>
      <TableCell>{formatDate(invoice.dueDate)}</TableCell>
      <TableCell>${invoice.amount.toFixed(2)}</TableCell>
      <TableCell>{invoice.status}</TableCell>
    </TableRow>
  ))
}

Invoices.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
    })
  ),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      dueDate: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ),
}
