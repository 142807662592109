import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withStyles, List, ListItem, Paper, Toolbar } from '@material-ui/core'
import { NavLink, withRouter } from 'react-router-dom'

import {
  overviewActive,
  overviewRest,
  messagesActive,
  messagesRest,
  paymentsActive,
  paymentsRest,
  proposalActive,
  proposalRest,
  invoiceActive,
  invoiceRest,
  contractActive,
  contractRest,
  companyActive,
  companyRest,
  settingsActive,
  settingsRest,
  searchActive,
  searchRest,
} from '../../../assets/icons'
import Feedback from './Feedback'

const customerNavLinks = [
  {
    label: 'Overview',
    url: 'overview',
    activeUrls: ['overview'],
    defaultIco: overviewRest,
    activeIco: overviewActive,
  },
  {
    label: 'Messages',
    url: 'messages',
    activeUrls: ['messages'],
    defaultIco: messagesRest,
    activeIco: messagesActive,
  },
  {
    label: 'Search',
    url: 'search',
    activeUrls: ['search'],
    defaultIco: searchRest,
    activeIco: searchActive,
  },
  {
    label: 'Proposal',
    url: 'proposal',
    activeUrls: ['proposal'],
    defaultIco: proposalRest,
    activeIco: proposalActive,
  },
  {
    label: 'Invoices',
    url: 'invoices',
    activeUrls: ['invoices'],
    defaultIco: invoiceRest,
    activeIco: invoiceActive,
  },
  {
    label: 'Contracts',
    url: 'contracts',
    activeUrls: ['contracts'],
    defaultIco: contractRest,
    activeIco: contractActive,
  },
  {
    label: 'Settings',
    url: 'settings',
    activeUrls: ['settings', 'client'],
    defaultIco: settingsRest,
    activeIco: settingsActive,
  },
]

const moderatorNavLinks = [
  {
    label: 'Overview',
    url: 'overview',
    activeUrls: ['overview'],
    defaultIco: overviewRest,
    activeIco: overviewActive,
  },
  {
    label: 'Contracts',
    url: 'contracts',
    activeUrls: ['contracts'],
    defaultIco: contractRest,
    activeIco: contractActive,
  },
  {
    label: 'Payments',
    url: 'payments',
    activeUrls: ['payments'],
    defaultIco: paymentsRest,
    activeIco: paymentsActive,
  },
  {
    label: 'Invoices',
    url: 'invoices',
    activeUrls: ['invoices'],
    defaultIco: invoiceRest,
    activeIco: invoiceActive,
  },
  {
    label: 'Companies',
    url: 'companies',
    activeUrls: ['companies'],
    defaultIco: companyRest,
    activeIco: companyActive,
  },
  {
    label: 'Messages',
    url: 'messages',
    activeUrls: ['messages'],
    defaultIco: messagesRest,
    activeIco: messagesActive,
  },
  {
    label: 'Settings',
    url: 'settings',
    activeUrls: ['settings'],
    defaultIco: settingsRest,
    activeIco: settingsActive,
  },
]

const drawerWidth = 210
const styles = () => ({
  ico: {
    marginRight: 6,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  paper: {
    height: '100%',
  },
})

const LeftNavigation = ({ classes, match, location, userType }) => {
  let navLinks = []
  switch (userType) {
    case 'customer':
      navLinks = customerNavLinks
      break
    case 'moderator':
      navLinks = moderatorNavLinks
      break
    default:
      break
  }

  let activateIcon = null
  navLinks.map(navLink => {
    if (match.params.usertype == 'customer') {
      if (navLink.activeUrls.includes(location.pathname.split('/')[3])) {
        activateIcon = navLink.label.toLowerCase()
      }
    }
    if (match.params.usertype == 'moderator') {
      if (navLink.label.toLowerCase() === location.pathname.split('/')[2])
        activateIcon = navLink.label.toLowerCase()
    }
  })

  const linkUrl = navUrl => {
    let newUrl = ''
    if (match.params.usertype == 'customer') {
      newUrl = `/${match.params.usertype}/${
        match.params.campaignuuid
      }/${navUrl}`
    } else if (match.params.usertype == 'moderator') {
      newUrl = `/${match.params.usertype}/${navUrl}`
    }
    return newUrl
  }

  return (
    <Paper square={true} variant={'outlined'} className={classes.paper}>
      <div>
        <Toolbar />
        <List>
          {navLinks.map((navEl, index) => {
            return (
              <NavLink
                key={index}
                to={linkUrl(navEl.url)}
                className={classes.link}
                activeStyle={{
                  color: '#0dbffe',
                }}
                isActive={() => {
                  if (navEl.label.toLowerCase() === activateIcon) return true
                  else return false
                }}
              >
                <ListItem id={index} button>
                  {navEl.label.toLowerCase() === activateIcon ? (
                    <img className={classes.ico} src={navEl.activeIco} />
                  ) : (
                    <img className={classes.ico} src={navEl.defaultIco} />
                  )}
                  {navEl.label}
                </ListItem>
              </NavLink>
            )
          })}
        </List>
      </div>
      <Feedback />
    </Paper>
  )
}

export { drawerWidth }
export default compose(
  withStyles(styles),
  withRouter
)(LeftNavigation)

LeftNavigation.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  userType: PropTypes.string,
}
