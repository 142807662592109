import React from 'react'
import PropTypes from 'prop-types'

import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'

import { LineChart } from '../../../../../shared/components/charts'
import { CHART_QUERY } from '../../../../../util/apollo/queries/data'
import {
  removeEdgesAndNodes,
  formatLineChartData,
} from '../../../../../shared/helperfunctions'

const Component = ({ startDate, endDate }) => {
  const { campaignuuid } = useParams()
  const { loading, error, data } = useQuery(CHART_QUERY, {
    variables: {
      startDate: startDate,
      endDate: endDate,
      campaignuuid,
      qType: 'impressions',
    },
  })
  if (loading) return 'Loading...'
  if (error) return 'Error'
  const cleanedData = removeEdgesAndNodes(data.summaryStatistics)
  const chartData = formatLineChartData(cleanedData.edges[0].results)
  return <LineChart data={chartData} />
}

Component.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
}

export default Component
