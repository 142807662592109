import React from 'react'
import PropTypes from 'prop-types'

import { Switch, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import { BackButton } from '../../../shared/components'

import Campaigns from './Campaigns'
import Details from './Details'
import ManageUsers from './ManageUsers'
import Payment from './Payment'

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
    width: '100%',
  },
  backbutton: {
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  icon: {
    color: theme.palette.text.primary,
  },
})

const Client = ({
  classes,
  match: {
    params: { campaignuuid },
    path,
  },
}) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route
          exact
          path={path + '/:clientuuid'}
          render={props => (
            <>
              <BackButton
                className={classes.backbutton}
                link={`/customer/${campaignuuid}/settings/clients`}
              />
              <Details {...props} />
              <Campaigns {...props} />
              <Payment {...props} />
              <ManageUsers {...props} />
            </>
          )}
        />
      </Switch>
    </div>
  )
}

Client.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Client)
