import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import RRule from 'rrule'
import WeekdayField, { getRRuleDayIndexFromLuxon } from '../WeekdayField'

export default function WeeklyTabPanel({
  startDate,
  endDate,
  interval,
  onChange,
  selectedDays: initialSelectedDays,
  isSelected,
}) {
  const [selectedDays, setSelectedDays] = useState(
    initialSelectedDays || [
      getRRuleDayIndexFromLuxon(DateTime.fromISO(startDate)),
    ]
  )
  const onChangeRef = useRef(onChange)

  // calling  onChange when the tab is selected
  useEffect(() => {
    if (!isSelected) {
      return
    }
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval,
      byweekday: selectedDays,
      wkst: RRule.SU,
      dtstart: DateTime.fromISO(startDate).toJSDate(),
      ...(endDate ? { until: DateTime.fromISO(endDate).toJSDate() } : {}),
    })
    onChangeRef.current(rule.toString())
  }, [startDate, endDate, isSelected, interval, selectedDays])
  if (!isSelected) {
    return null
  }
  return <WeekdayField weekdays={selectedDays} onChange={setSelectedDays} />
}

WeeklyTabPanel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  interval: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedDays: PropTypes.array,
}
