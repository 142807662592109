import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  withStyles,
  Divider,
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { removeEdgesAndNodes } from '../../../../../shared/helperfunctions'
import {
  GET_COMPANY_CONTACTS,
  DELETE_CONTACT,
} from '../../../../../util/apollo/queries/contacts'

import AddNewContact from './AddNewContact'
import Contact from './ContactItem'

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  pushRight: {
    marginLeft: 'auto',
  },
})

const Contacts = ({ classes, companyUUID }) => {
  const [openState, openModal] = useState(false)
  const [contactUUID, setContactUUID] = useState()
  const query_variables = {
    companyUUID: companyUUID,
  }
  const refetchQueries = [
    {
      query: GET_COMPANY_CONTACTS,
      variables: {
        companyUUID: companyUUID,
      },
    },
  ]

  const { loading, error, data } = useQuery(GET_COMPANY_CONTACTS, {
    variables: query_variables,
    skip: !companyUUID,
  })
  if (!companyUUID) {
    return null
  }
  if (loading) return 'Loading...'
  if (error) {
    return null
  }

  const contacts =
    data && data.allContacts.edges[0]
      ? removeEdgesAndNodes(data.allContacts).edges
      : []
  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Company Contacts</Typography>
        <AddNewContact
          className={classes.pushRight}
          companyUUID={companyUUID}
          openState={openState}
          openModal={openModal}
          contactUUID={contactUUID}
          setContactUUID={setContactUUID}
          refetchQueries={refetchQueries}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => openModal(true)}
        >
          Add New Contact
        </Button>
      </div>
      <Divider />
      <div className={classes.content}>
        {contacts.map((contact, index) => (
          <Contact
            key={index}
            index={index}
            contact={contact}
            openModal={openModal}
            setContactUUID={setContactUUID}
            refetchQueries={refetchQueries}
            deleteMutation={DELETE_CONTACT}
          />
        ))}
      </div>
    </Paper>
  )
}

Contacts.propTypes = {
  classes: PropTypes.object,
  companyUUID: PropTypes.string,
}

export default withStyles(styles)(Contacts)
