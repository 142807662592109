import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'

const styles = theme => ({
  button: {
    marginLeft: theme.spacing(2),
    overflow: 'hidden',
    width: 300,
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    width: 300,
  },
})

const CampaignMenu = ({
  campaigns,
  classes,
  history,
  match: {
    params: { campaignuuid },
  },
  location: { pathname },
  userType,
}) => {
  const [anchor, setAnchor] = useState(null)
  if (userType !== 'buyer') return null
  if (!campaigns || campaigns.length === 0) return null
  const openMenu = ({ currentTarget }) => setAnchor(currentTarget)
  const closeMenu = () => anchor && setAnchor(null)

  const injectCampaignIntoUrl = uuid => () => {
    const newUrl = pathname.split('/')
    newUrl[2] = uuid
    history.push(newUrl.join('/'))
    closeMenu()
  }

  const resolveCampaignNameFromUUID = () => {
    if (campaignuuid === 'undefined') {
      injectCampaignIntoUrl(campaigns[0].uuid)()
    } else {
      const campaign = campaigns.filter(({ uuid }) => uuid === campaignuuid)[0]
      if (campaign) return `${campaign.client.name} - ${campaign.name}`
      else injectCampaignIntoUrl('undefined')
    }
  }

  return (
    <>
      <Button
        onClick={openMenu}
        variant="outlined"
        className={classes.button}
        classes={{ label: classes.label }}
      >
        <Typography variant="subtitle1">
          {resolveCampaignNameFromUUID()}
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={closeMenu}>
        {campaigns.map(
          ({ uuid, name: campaignName, client: { name: clientName } }) => {
            const title = `${clientName} - ${campaignName}`
            return (
              <MenuItem
                className={classes.menuItem}
                key={uuid}
                value={uuid}
                selected={uuid === campaignuuid}
                onClick={injectCampaignIntoUrl(uuid, title)}
              >
                {title}
              </MenuItem>
            )
          }
        )}
      </Menu>
    </>
  )
}

const mapStateToProps = state => ({
  userType: state.userInfo.type,
  campaigns: state.campaigns,
})

CampaignMenu.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  client: PropTypes.object,
  setCampaigns: PropTypes.func,
  userType: PropTypes.string,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter,
  withApollo
)(CampaignMenu)
