import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Paper, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import DateRangePicker from '../DateRangePicker'

import ImpressionsChart from './ImpressionsChart'
import SpendChart from './SpendChart'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  chartsContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(6),
    width: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
})

const Component = ({ classes }) => {
  const startDateHook = useState(moment().subtract(1, 'months'))
  const endDateHook = useState(moment())

  const [startDate] = startDateHook
  const [endDate] = endDateHook

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" color="textSecondary">
          How many people are viewing your ads?
        </Typography>
        <DateRangePicker
          startDateHook={startDateHook}
          endDateHook={endDateHook}
        />
      </div>
      <div className={classes.chartsContainer}>
        <div>
          <Typography variant="h6" className={classes.title} align="center">
            Impressions
          </Typography>
          <ImpressionsChart startDate={startDate} endDate={endDate} />
        </div>
        <div>
          <Typography variant="h6" className={classes.title} align="center">
            Spend
          </Typography>
          <SpendChart startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </Paper>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Component)
