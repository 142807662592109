import React from 'react'
import {
  withStyles,
  Divider,
  Paper,
  TextField,
  Typography,
  Button,
  MenuItem,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import RichTextEditor from './RichTextEditor'

const styles = theme => ({
  header: {
    padding: theme.spacing(3),
    display: 'flex',
  },
  content: {
    padding: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
})

const GenericTable = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    classes,
    fields,
    title,
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">{title}</Typography>
      </div>
      <Divider />
      <div className={classes.content}>
        <form onSubmit={props.handleSubmit}>
          {fields.map((field, index) => {
            switch (field.type) {
              case 'richeditorfield':
                return (
                  <RichTextEditor
                    key={index}
                    name={field.name}
                    label={field.label}
                    value={values[field.name]}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                )
              case 'multilinetextfield':
                return (
                  <TextField
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                )
              case 'selectfield':
                return (
                  <TextField
                    select
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled={field.disabled ? true : false}
                  >
                    {field.options.map(option => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.title}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                )
              case 'textfield':
              default:
                return (
                  <TextField
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    helperText={touched[field.name] ? errors[field.name] : ''}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    value={values[field.name]}
                    onChange={change.bind(null, [field.name])}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled={field.disabled ? true : false}
                  />
                )
            }
          })}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Save
          </Button>
        </form>
      </div>
    </Paper>
  )
}

GenericTable.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  props: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  fields: PropTypes.array,
  title: PropTypes.string,
}

export default withStyles(styles)(GenericTable)
