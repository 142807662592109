import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import { Dialog, DialogActions, Button } from '@material-ui/core'
import Title from './Title'
import Content from './Content'

function getInitialValue(modalType) {
  switch (modalType) {
    case 'newRecurringRule': {
      return 'FREQ=DAILY'
    }
  }
}
export default function NewRuleDialog({ modalType, onClose, onSave }) {
  return (
    <Dialog open={modalType !== null} onClose={onClose} maxWidth="xs" fullWidth>
      <Title modalType={modalType} />
      <Formik
        onSubmit={values => {
          onSave(values.value)
          onClose()
        }}
        initialValues={{ value: getInitialValue(modalType) }}
      >
        <Form>
          <Content modalType={modalType} />
          <DialogActions>
            <Button type="submit">Save</Button>
            <Button onClick={onClose}>Cancel</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

NewRuleDialog.propTypes = {
  modalType: Title.propTypes.modalType,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}
