import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import {
  Button,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useQuery, useMutation } from 'react-apollo'

import * as actions from '../../../../util/redux/actions'
import {
  GET_USER_INFO,
  SET_USER_INFO,
} from '../../../../util/apollo/queries/user'
import { withNotifications } from '../../../../util'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    maxWidth: 800,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const Component = ({ classes, notify, setUserInfo }) => {
  const defaultState = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
  }

  const [control, setFormControl] = useState(defaultState)
  const [mutate] = useMutation(SET_USER_INFO)

  const { data: { currentUser } = {} } = useQuery(GET_USER_INFO)
  if (!currentUser) return null
  if (currentUser) setUserInfo(currentUser)

  if (JSON.stringify(defaultState) === JSON.stringify(control))
    setFormControl({ ...defaultState, ...currentUser })

  if (control) {
    const {
      email,
      firstName,
      lastName,
      phone,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
    } = control

    const updateControl = key => ({ target: { value } }) =>
      setFormControl(prev => ({ ...prev, [key]: value }))

    const updateUserProfile = () => {
      const variables = Object.keys(currentUser).reduce((variables, key) => {
        if (control[key] && control[key] !== currentUser[key])
          variables[key] = control[key]

        return variables
      }, {})

      if (Object.keys(variables).length)
        mutate({ variables: { ...variables, uuid: currentUser.uuid } }).then(
          ({ data }) => {
            if (data.updateUser.ok) notify.success('Saved')
            else notify.error('Error Saving Info')
          }
        )
    }

    return (
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h6">Profile</Typography>
          <Button variant="contained" color="primary">
            Reset Password
          </Button>
        </div>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={firstName}
          onChange={updateControl('firstName')}
          label="First Name"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={lastName}
          onChange={updateControl('lastName')}
          label="Last Name"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={email}
          onChange={updateControl('email')}
          label="Email"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={phone}
          onChange={updateControl('phone')}
          label="Phone"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={addressLine1}
          onChange={updateControl('addressLine1')}
          label="Address"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={addressLine2}
          onChange={updateControl('addressLine2')}
          label="Apt/Ste"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={addressCity}
          onChange={updateControl('addressCity')}
          label="City"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={addressState}
          onChange={updateControl('addressState')}
          label="State"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={addressZip}
          onChange={updateControl('addressZip')}
          label="Zip"
        />
        <Button variant="contained" color="primary" onClick={updateUserProfile}>
          Save
        </Button>
      </Paper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setUserInfo: info =>
    new Promise(resolve => {
      dispatch(actions.userInfo.setUserInfo(info))
      resolve(info)
    }),
})

Component.propTypes = {
  classes: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withNotifications,
  connect(
    null,
    mapDispatchToProps
  )
)(Component)
