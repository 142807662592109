import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useMutation } from 'react-apollo'

import { DELETE_PRODUCT_SIZE } from '../../../../../../../../util/apollo/queries/products'

import NewAdType from '../New'

const Component = ({ refetchQueries, uuid }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openState, openModal] = useState(false)

  const openMenu = e => {
    setAnchorEl(e.currentTarget)
    e.stopPropagation()
  }

  const closeMenu = e => {
    e && e.stopPropagation()
    setAnchorEl(null)
  }

  const [deleteMutation] = useMutation(DELETE_PRODUCT_SIZE)

  const editHandler = e => {
    closeMenu()
    openModal(true)
    e.stopPropagation()
  }

  const deleteHandler = e => {
    deleteMutation({
      variables: { uuid, status: 'inactive' },
      refetchQueries,
    })
    closeMenu()
    e.stopPropagation()
  }

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={editHandler}>Edit</MenuItem>
        <MenuItem onClick={deleteHandler}>Delete</MenuItem>
      </Menu>

      <NewAdType
        adSizeUUID={uuid}
        openState={openState}
        openModal={openModal}
        refetchQueries={refetchQueries}
        noButton
      />
    </>
  )
}

Component.propTypes = {
  refetchQueries: PropTypes.array,
  uuid: PropTypes.string,
}

export default Component
