import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import {
  formatCurrency,
  removeEdgesAndNodes,
  snakeToTitleCase,
  capitalizationCase,
} from '../../../../../shared/helperfunctions'
import { GET_TERMS } from '../../../../../util/apollo/queries/contracts'

import DeleteButton from './DeleteButton'
import DuplicateButton from './DuplicateButton'
import EditButton from './EditButton'
import MessagesButton from './MessagesButton'
import PauseButton from './PauseButton'
import ViewAdButton from './ViewAdButton'

const Terms = ({ classes, contractuuid }) => {
  const { loading, error, data } = useQuery(GET_TERMS, {
    variables: { contract: contractuuid },
  })
  if (loading) return 'Loading...'
  if (error) return alert(error.message)
  removeEdgesAndNodes(data)
  const contractItem = data.allContracts.edges[0]
  const keyDates = contractItem.contractDate.edges
    .concat(contractItem.invoice)
    .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
    .filter(
      item =>
        item.type != 'CREATIVE_PROOF_DUE' &&
        item.type != 'PROOF_OF_DELIVERY_DUE'
    )

  const stage = contractItem.stage.toLowerCase()
  return (
    <Paper className={classes.termsRoot}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.pushRight} noWrap>
          Contract Terms
        </Typography>
        <div>
          <DeleteButton stage={stage} />
          <PauseButton stage={stage} />
          <ViewAdButton productuuid={contractItem.productSize.product.uuid} />
          <DuplicateButton />
          <MessagesButton />
          <EditButton />
        </div>
      </div>
      <Divider />
      <div className={classes.infoSingle}>
        <Typography variant="caption" className={classes.caption}>
          Item
        </Typography>
        <Typography>{contractItem.title}</Typography>
      </div>
      <Divider />
      <div className={classes.infoSingle}>
        <Typography variant="caption" className={classes.caption}>
          Start Date
        </Typography>
        <Typography>
          {contractItem.stage == 'INQUIRY'
            ? '-'
            : DateTime.fromISO(contractItem.startDate).toLocaleString(
                DateTime.DATE_SHORT
              )}
        </Typography>
      </div>
      <Divider />
      <div className={classes.infoSingle}>
        <Typography variant="caption" className={classes.caption}>
          End Date
        </Typography>
        <Typography>
          {contractItem.stage == 'INQUIRY'
            ? '-'
            : DateTime.fromISO(contractItem.endDate).toLocaleString(
                DateTime.DATE_SHORT
              )}
        </Typography>
      </div>
      <Divider />
      <div className={classes.infoSingle}>
        <Typography variant="caption" className={classes.caption}>
          Status
        </Typography>
        <Typography>{snakeToTitleCase(contractItem.stage)}</Typography>
      </div>
      <Divider />
      <div className={classes.infoSingle}>
        <Typography variant="caption" className={classes.caption}>
          Contract Amount
        </Typography>
        <Typography>
          {contractItem.stage == 'INQUIRY'
            ? '-'
            : formatCurrency(contractItem.actualBuyerPrice)}
        </Typography>
      </div>
      <Divider />
      <div className={classes.infoContainer}>
        <div>
          <Typography variant="subtitle1">Key Dates</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Due Date</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keyDates
                .sort(({ dueDate: a }, { dueDate: b }) => a > b)
                .map(({ dueDate, description, amount, company }, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      {DateTime.fromISO(Date.parse(dueDate)).toLocaleString(
                        DateTime.DATE_SHORT
                      )}
                    </TableCell>
                    <TableCell>
                      {description ||
                        `${capitalizationCase(
                          company.type
                        )} payment due: $${amount.toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Paper>
  )
}

Terms.propTypes = {
  classes: PropTypes.object,
  contractuuid: PropTypes.string,
}

export default Terms
