import gql from 'graphql-tag'

const LOGIN_USER = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(input: { username: $username, password: $password }) {
      token
      refreshToken
      userType
    }
  }
`

const CREATE_USER = gql`
  mutation(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $phone: String
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressCity: $addressCity
        addressState: $addressState
        addressZip: $addressZip
        phone: $phone
      }
    ) {
      user {
        id
      }
      ok
    }
  }
`

const DELETE_USER = gql`
  mutation($useruuid: String!) {
    updateUser(input: { uuid: $useruuid, status: "inactive" }) {
      ok
    }
  }
`

const GENERATE_NEW_TOKENS = gql`
  mutation($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      token
      refreshToken
    }
  }
`

const GET_USER_INFO = gql`
  query {
    currentUser {
      id
      uuid
      email
      firstName
      lastName
      phone
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      type
      role
      company {
        name
        uuid
      }
    }
  }
`

const GET_CURRENT_USER_UUID = gql`
  query {
    currentUser {
      uuid
    }
  }
`

const SET_USER_INFO = gql`
  mutation(
    $uuid: String!
    $email: String
    $phone: String
    $firstName: String
    $lastName: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $addressCountry: String
  ) {
    updateUser(
      input: {
        uuid: $uuid
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressCity: $addressCity
        addressState: $addressState
        addressZip: $addressZip
        addressCountry: $addressCountry
      }
    ) {
      ok
      user {
        id
        uuid
        email
        phone
        firstName
        lastName
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        addressCountry
      }
    }
  }
`

export {
  CREATE_USER,
  DELETE_USER,
  GENERATE_NEW_TOKENS,
  GET_USER_INFO,
  GET_CURRENT_USER_UUID,
  LOGIN_USER,
  SET_USER_INFO,
}
