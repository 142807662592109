import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Typography, withStyles, Grid } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { darkGray, dividerGray, headerHeight } from '../constants'

import { GET_CHANNEL_STATUS } from '../../../../util/apollo/queries/messages'

import ArchiveButton from './ArchiveButton'
import FilterMenu from './FilterMenu'
import RefreshButton from './RefreshButton'

const styles = theme => ({
  root: {
    backgroundColor: darkGray,
    boxSizing: 'border-box',
    borderBottom: `solid 1px ${dividerGray}`,
    height: headerHeight,
    width: '100%',
  },
  leftPanel: {
    boxSizing: 'border-box',
    borderRight: `solid 1px ${dividerGray}`,
    padding: `0 ${theme.spacing(4)}px`,
  },
  rightPanel: {
    padding: theme.spacing(3),
  },
})

const Header = ({ classes, channeluuid }) => {
  const { data } = useQuery(GET_CHANNEL_STATUS, {
    variables: { channeluuid },
  })

  const { status, uuid: channelactivityuuid, title } =
    data?.allChannelactivities?.edges?.[0]?.node || {}

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={4}
        alignItems="center"
        className={classes.leftPanel}
      >
        <FilterMenu />
      </Grid>
      <Grid
        container
        item
        xs={8}
        alignItems="center"
        justify="space-between"
        className={classes.rightPanel}
      >
        {channeluuid && (
          <>
            <ArchiveButton
              status={status}
              channelactivityuuid={channelactivityuuid}
              channeluuid={channeluuid}
            />
            <Typography variant="h6">{title}</Typography>
            <RefreshButton channeluuid={channeluuid} />
          </>
        )}
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  classes: PropTypes.object,
  channeluuid: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withRouter
)(Header)
