import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  checkboxLabel: {
    marginTop: theme.spacing(0.5),
  },
  checkboxLabelLabel: {
    marginLeft: theme.spacing(2),
  },
  checkboxLabelRoot: {
    marginLeft: -2,
  },
  checkboxRoot: {
    padding: 0,
  },
  clearFilter: {
    color: '#00bcfa',
    cursor: 'pointer',
    fontWeight: 700,
    marginLeft: theme.spacing(1),
  },
  filterGroup: {
    marginTop: theme.spacing(1.5),
  },
  mediaTypeCheckboxes: {
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 700,
  },
})

const Component = ({ classes, setFilters }) => {
  const [control, setControl] = useState({
    starred: false,
  })

  const onClear = () => {
    setControl(
      Object.keys(control).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {})
    )
  }

  useEffect(() => {
    setFilters(prev => {
      if (control.starred) {
        return { ...prev, starred: control.starred }
      } else {
        delete prev.starred
        return { ...prev }
      }
    })
  }, [control, setFilters])

  const handleCheckboxChange = key => ({ target: { checked } }) =>
    setControl(prev => ({ ...prev, [key]: checked }))

  return (
    <div className={classes.filterGroup}>
      <Typography className={classes.sectionTitle} display="inline">
        Saved Ads
      </Typography>
      <Typography
        className={classes.clearFilter}
        display="inline"
        variant="caption"
        onClick={onClear}
      >
        Clear Filter
      </Typography>
      <FormGroup className={classes.mediaTypeCheckboxes}>
        <FormControlLabel
          className={classes.checkboxLabel}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabelLabel,
          }}
          control={
            <Checkbox
              checked={control.starred}
              onChange={handleCheckboxChange('starred')}
              value="Show only Starred Ads"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="Show only Starred Ads"
        />
      </FormGroup>
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
