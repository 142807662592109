import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation, useQuery } from 'react-apollo'
import SnoozeForm from '../../../Overview/SnoozeForm'

import {
  GET_CONTRACT_ALERTS,
  REMOVE_ALERT,
} from '../../../../../util/apollo/queries/alertactivities'
import {
  removeEdgesAndNodes,
  formatDate,
} from '../../../../../shared/helperfunctions'
import { withNotifications } from '../../../../../util'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  tableTitle: {
    paddingTop: 24,
    paddingLeft: 24,
  },
  tableButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: 180,
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

const Alerts = ({ classes, notify, contract }) => {
  const queryVariables = { contract: contract, date: today.toISOString() }
  const { loading, error, data } = useQuery(GET_CONTRACT_ALERTS, {
    variables: queryVariables,
  })
  const today = new Date()
  const refetchQueries = [
    {
      query: GET_CONTRACT_ALERTS,
      variables: queryVariables,
    },
  ]

  const [mutation] = useMutation(REMOVE_ALERT)

  const removeAlert = uuid => {
    mutation({
      variables: { uuid },
      refetchQueries,
    }).then(({ data }) => {
      if (data.updateAlertactivity.ok) notify.success('Removed')
    })
  }
  if (loading) return 'Loading...'
  if (error) return error.message
  removeEdgesAndNodes(data)

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.tableTitle}>
        Alerts
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Reason For Alert</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.allAlertactivities.edges.map((row, index) => {
            let description = row.alertInformation.message
            if (row.note) description = description.concat('. ' + row.note)
            return (
              <TableRow key={index} hover className={classes.tableRow}>
                <TableCell>{row.alertInformation.title}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{formatDate(row.notificationDate)}</TableCell>
                <TableCell>
                  <SnoozeForm uuid={row.uuid} refetchQueries={refetchQueries} />
                  <IconButton onClick={() => removeAlert(row.uuid)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

Alerts.propTypes = {
  classes: PropTypes.object,
  contract: PropTypes.string,
  notify: PropTypes.object,
}

export default compose(
  withNotifications,
  withStyles(styles)
)(Alerts)
