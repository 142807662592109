import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Formik } from 'formik'
import { withStyles } from '@material-ui/core'
import { Query, Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  GET_PRODUCT_INFO_FOR_SELLER,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  GET_SELLERS_PRODUCTS,
} from '../../../../../../util/apollo/queries/products'
import { GET_COMPANY_CONTACTS } from '../../../../../../util/apollo/queries/contacts'
import { removeEdgesAndNodes } from '../../../../../../shared/helperfunctions'
import GenericForm from '../../../../../../shared/components/forms/GenericForm'
import { BackButton } from '../../../../../../shared/components'

const styles = () => ({
  root: {},
})

const ProductInfo = ({
  classes,
  productUUID,
  setProductUUID,
  companyUUID,
  history,
}) => {
  const fieldss = [
    {
      name: 'title',
      label: 'Title',
      type: 'multilinetextfield',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'richeditorfield',
    },
    {
      name: 'demographic',
      label: 'Demographics',
      type: 'richeditorfield',
    },
    {
      name: 'mediaType',
      label: 'Media Type',
      type: 'selectfield',
      options: [
        { value: 'billboard', title: 'Billboard' },
        { value: 'radio', title: 'Radio' },
        {
          value: 'event_sponsorship',
          title: 'Events',
        },
        { value: 'podcast', title: 'Podcast' },
        { value: 'newspaper', title: 'Newspaper' },
      ],
    },
    {
      name: 'contentRestriction',
      label: 'Content Restrictions',
      type: 'richeditorfield',
    },
    {
      name: 'priceDescription',
      label: 'Price Description',
      type: 'richeditorfield',
    },
    {
      name: 'logistics',
      label: 'Logistics',
      type: 'multilinetextfield',
    },
    {
      name: 'location',
      label: 'Location',
      type: 'multilinetextfield',
    },
    {
      name: 'ageFocus',
      label: 'Age Focus',
      type: 'selectfield',
      options: [
        {
          value: 'less_than_18',
          title: 'less than 18',
        },
        { value: '19_24', title: '19-24' },
        { value: '25_45', title: '25-45' },
        { value: '46_64', title: '46-64' },
        {
          value: '65_and_older',
          title: '65 and older',
        },
        {
          value: 'general_population',
          title: 'General population',
        },
        { value: 'unknown', title: 'unknown' },
      ],
    },
    {
      name: 'genderFocus',
      label: 'Gender Focus',
      type: 'selectfield',
      options: [
        { value: 'male', title: 'male' },
        { value: 'female', title: 'female' },
        { value: 'equal', title: 'equal' },
        { value: 'unknown', title: 'unknown' },
      ],
    },
  ]

  const cleanData = data => {
    data.edges[0].genderFocus = data.edges[0].genderFocus.toLowerCase()
    data.edges[0].mediaType = data.edges[0].mediaType.toLowerCase()
    data.edges[0].status = data.edges[0].status.toLowerCase()
    if (data.edges[0].contact.edges.length != 0)
      data.edges[0].contactperson = data.edges[0].contact.edges[0].uuid
    else data.edges[0].contactperson = ''
    if (data.edges[0].ageFocus.substring(0, 2) == 'A_') {
      data.edges[0].ageFocus = data.edges[0].ageFocus.substring(2).toLowerCase()
    } else {
      data.edges[0].ageFocus = data.edges[0].ageFocus.toLowerCase()
    }
    data.edges[0].location = data.edges[0].productLocation.edges
      .map(location => location.address)
      .join('; ')
  }

  return (
    <React.Fragment>
      <BackButton
        className={classes.backbutton}
        link={`/moderator/companies/seller/${companyUUID}/overview`}
      />
      <div className={classes.container}>
        {productUUID && (
          <Query
            query={GET_PRODUCT_INFO_FOR_SELLER}
            variables={{ productUUID: productUUID }}
          >
            {({ loading, error, data }) => {
              if (loading) return 'Loading...'
              if (error) {
                alert(error)
                return null
              }
              if (data.allProducts.edges[0]) {
                data = removeEdgesAndNodes(data.allProducts)
                cleanData(data)
              } else return 'Error: CompanyInfo Not Found'
              return (
                <Query query={GET_COMPANY_CONTACTS} variables={{ companyUUID }}>
                  {({
                    loading: loadingOne,
                    error: errorOne,
                    data: dataOne,
                  }) => {
                    if (loadingOne) return 'Loading...'
                    if (errorOne) {
                      alert(errorOne)
                    }
                    if (dataOne) {
                      dataOne = removeEdgesAndNodes(dataOne.allContacts)
                      const nameOptions = dataOne.edges.map(contact => ({
                        value: contact.uuid,
                        title: contact.firstName + ' ' + contact.lastName,
                      }))
                      const fields = [
                        ...fieldss.slice(0, 7),
                        {
                          name: 'contactperson',
                          label: 'Contact',
                          type: 'selectfield',
                          options: nameOptions,
                        },
                        ...fieldss.slice(7),
                      ]
                      return (
                        <Mutation mutation={UPDATE_PRODUCT}>
                          {(updateProduct, { error }) => {
                            if (error) alert(error)
                            return (
                              <Formik
                                onSubmit={values => {
                                  updateProduct({
                                    variables: {
                                      productUUID: productUUID,
                                      resetContactList: [values.contactperson],
                                      productLocationString: values.location,
                                      ...values,
                                    },
                                  })
                                }}
                                initialValues={data.edges[0]}
                                render={props => (
                                  <GenericForm
                                    {...props}
                                    title={'Product Information'}
                                    fields={fields}
                                  />
                                )}
                              />
                            )
                          }}
                        </Mutation>
                      )
                    }
                    return null
                  }}
                </Query>
              )
            }}
          </Query>
        )}
        {!productUUID && (
          <Query query={GET_COMPANY_CONTACTS} variables={{ companyUUID }}>
            {({ loading: loadingOne, error: errorOne, data: dataOne }) => {
              if (loadingOne) return 'Loading...'
              if (errorOne) {
                alert(errorOne)
              }
              if (dataOne) {
                dataOne = removeEdgesAndNodes(dataOne.allContacts)
                const nameOptions = dataOne.edges.map(contact => ({
                  value: contact.uuid,
                  title: contact.firstName + ' ' + contact.lastName,
                }))
                const fields = [
                  ...fieldss.slice(0, 7),
                  {
                    name: 'contactperson',
                    label: 'Contact',
                    type: 'selectfield',
                    options: nameOptions,
                  },
                  ...fieldss.slice(7),
                ]
                return (
                  <Mutation
                    mutation={CREATE_PRODUCT}
                    refetchQueries={[
                      {
                        query: GET_SELLERS_PRODUCTS,
                        variables: {
                          companyUUID: companyUUID,
                        },
                      },
                    ]}
                    onCompleted={({ createProduct }) => {
                      history.push(
                        `/moderator/companies/seller/${companyUUID}/product/${
                          createProduct.product.uuid
                        }`
                      )
                      setProductUUID(createProduct.product.uuid)
                    }}
                  >
                    {(createProduct, { error }) => {
                      if (error) alert(error)

                      return (
                        <Formik
                          onSubmit={values => {
                            if (
                              Object.values(values).reduce(
                                (prev, curr) => prev && curr !== '',
                                true
                              )
                            )
                              createProduct({
                                variables: {
                                  seller: companyUUID,
                                  resetContactList: [values.contactperson],
                                  productLocationString: values.location,
                                  status: 'active',
                                  ...values,
                                },
                              })
                            else alert('Please fill out all fields')
                          }}
                          initialValues={{
                            title: '',
                            description: '',
                            demographic: '',
                            mediaType: '',
                            contentRestriction: '',
                            priceDescription: '',
                            logistics: '',
                            contactperson: '',
                            location: '',
                            ageFocus: '',
                            genderFocus: '',
                          }}
                          render={props => (
                            <GenericForm
                              {...props}
                              title={'Product Information'}
                              fields={fields}
                            />
                          )}
                        />
                      )
                    }}
                  </Mutation>
                )
              }
              return null
            }}
          </Query>
        )}
      </div>
    </React.Fragment>
  )
}

ProductInfo.propTypes = {
  classes: PropTypes.object,
  companyUUID: PropTypes.string,
  productUUID: PropTypes.string,
  setProductUUID: PropTypes.func,
  history: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(ProductInfo)
