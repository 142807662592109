import React from 'react'
import PropType from 'prop-types'

import { Box } from '@material-ui/core'

import { generateOpenFileUrl } from '../../../../../../shared/helperfunctions'

const ProductImage = ({ uuid }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%;"
    >
      <img src={generateOpenFileUrl(uuid)} width="100%;" />
    </Box>
  )
}

ProductImage.propTypes = {
  uuid: PropType.string,
}

export default ProductImage
