import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  withStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { PropTypes } from 'prop-types'
import { useMutation, useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
  GET_DOMAINS,
  SET_DOMAINS,
} from '../../../../../../util/apollo/queries/companies'

const styles = theme => ({
  button: {
    textTransform: 'none',
  },
  dialog: {
    minWidth: 400,
  },
  domain: {
    alignItems: 'center',
    border: 'solid 1px #e0e0e0',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(1),
    width: 350,
  },
  textField: {
    width: 384,
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
})

const ManageDomains = ({ classes, match }) => {
  const uuid = match.params.uuid
  const apollo_variables = {
    companyUUID: uuid,
  }

  const [open, openModal] = useState(false)
  const [newDomain, setNewDomain] = useState('')

  const { loading, error, data } = useQuery(GET_DOMAINS, {
    variables: apollo_variables,
  })

  const [setDomainsMutation] = useMutation(SET_DOMAINS)

  if (loading) return 'Loading'
  if (error) return 'Error'

  const domains = data.allCompanies.edges[0].node.emailDomain.split(',')
  const addDomain = e => {
    e.preventDefault()
    if (newDomain.includes('@')) {
      const variables = {
        companyUUID: uuid,
        emailDomains: [...domains, newDomain].join(','),
      }

      setDomainsMutation({ variables })
      setNewDomain('')
    }
  }

  const removeDomain = (e, domainToRemove) => {
    e.preventDefault()

    const variables = {
      companyUUID: uuid,
      emailDomains: domains
        .filter(domain => domain !== domainToRemove)
        .join(','),
    }

    setDomainsMutation({
      variables,
      refetchQueries: [
        {
          query: GET_DOMAINS,
          variables: apollo_variables,
        },
      ],
    })
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => openModal(true)}
      >
        Manage Domains
      </Button>
      <Dialog
        open={open}
        onClose={() => openModal(false)}
        className={classes.dialog}
      >
        <DialogTitle>Manage Domains</DialogTitle>
        <Divider />
        <DialogContent>
          {domains.map(domain => (
            <form onSubmit={e => removeDomain(e, domain)} key={domain}>
              <div className={classes.domain}>
                <Typography>{domain}</Typography>
                <IconButton type="submit">
                  <ClearIcon />
                </IconButton>
              </div>
            </form>
          ))}
        </DialogContent>
        <Divider />
        <form onSubmit={addDomain}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Add New Domain"
            placeholder="@gmail.com"
            value={newDomain}
            onChange={e => setNewDomain(e.target.value)}
          />
          <DialogActions>
            <Button onClick={() => openModal(false)} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

ManageDomains.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(ManageDomains)
