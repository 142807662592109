const initState = {
  token: null,
  refreshToken: null,
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_TOKENS':
      return action.tokens
    case 'CLEAR_TOKENS':
      return initState
    default:
      return state
  }
}
