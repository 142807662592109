import React from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
  },
  chartContainer: {
    position: 'relative',
  },
  chartTitle: {
    position: 'absolute',
    top: theme.spacing(1) * -6,
    left: theme.spacing(4),
  },
})

// data prop should be formatted this way
// data = {
//   labels: ['Organic Direct Traffic'],
//   bars: [
//     { x: 'Jan', y: 0 },
//     { x: 'Feb', y: 100 },
//     { x: 'Mar', y: 120 },
//     { x: 'Apr', y: 160 },
//     { x: 'May', y: 225 },
//     { x: 'Jun', y: 250 },
//   ],
// }

const View = ({ classes, title, data }) => {
  const colors = ['#5792ee', '#56e0a2', '#ffd944']

  return (
    <Paper className={classes.root}>
      <div className={classes.chartContainer}>
        <Typography className={classes.chartTitle} variant="h6">
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data.bars}>
            {data.labels.map((label, i) => (
              <Bar name={label} dataKey="y" fill={colors[i]} key={i} />
            ))}
            <XAxis dataKey="x" />
            <YAxis />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  )
}

View.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.object,
}

export default withStyles(styles)(View)
