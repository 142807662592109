import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import { withStyles, Divider, AppBar, Tabs, Tab } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Admin from './Admin'
import Clients from './Clients'
import Customization from './Customization'
import Profile from './Profile'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    boxShadow: 'none',
  },
  tabBar: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
  tab: {
    textTransform: 'none',
    fontSize: 14,
  },
  indicator: {
    backgroundColor: '#0dbffe',
    height: 4,
  },
  tabContainer: {
    margin: 0,
  },
  tabSelected: {
    color: '#0dbffe',
  },
})

const Overview = ({
  classes,
  match,
  match: {
    params: { tab, campaignuuid },
  },
  history,
  role,
}) => {
  if (!tab) history.push(match.url + '/clients')
  const rootPath = match.url
    .split('/')
    .slice(0, 4)
    .join('/')

  const handleChange = (_event, value) => {
    history.push(`/customer/${campaignuuid}/settings/${value}`)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs
          value={tab}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            label="Clients"
            value={'clients'}
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
          />
          {role === 'admin' && (
            <Tab
              label="Admin"
              value={'admin'}
              className={classes.tab}
              classes={{ selected: classes.tabSelected }}
            />
          )}
          {role === 'admin' && (
            <Tab
              label="Customization"
              value={'customization'}
              className={classes.tab}
              classes={{ selected: classes.tabSelected }}
            />
          )}
          <Tab
            label="Profile"
            value={'profile'}
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
          />
        </Tabs>
      </AppBar>
      <Divider />
      <Switch>
        <Route path={rootPath + '/clients'} component={Clients} />
        {role === 'admin' && (
          <>
            <Route path={rootPath + '/admin'} component={Admin} />
            <Route
              path={rootPath + '/customization'}
              component={Customization}
            />
          </>
        )}
        <Route path={rootPath + '/profile'} component={Profile} />
      </Switch>
    </div>
  )
}

const mapStateToProps = state => ({
  role: state.userInfo.role,
})

Overview.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  role: PropTypes.string,
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Overview)
