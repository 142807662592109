import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'

import { Elements } from 'react-stripe-elements'

import { AddPaymentMethod } from '../../../../../shared/dialogs'
import Invoices from './Invoices'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 0`,
  },
})

const PaymentSchedule = ({
  classes,
  invoice,
  payment,
  clientuuid,
  //paymentButton,
}) => {
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Payment Schedule</Typography>
        <div className={classes.buttonContainer}>
          <Elements>
            <AddPaymentMethod paymentIcon clientuuid={clientuuid} />
          </Elements>
          {/*paymentButton*/}
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Due Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Invoices invoices={invoice} payments={payment} />
        </TableBody>
      </Table>
    </Paper>
  )
}

PaymentSchedule.propTypes = {
  classes: PropTypes.object,
  invoice: PropTypes.array,
  payment: Invoices.propTypes.payments,
  clientuuid: PropTypes.string,
  paymentButton: PropTypes.element,
}

export default withStyles(styles)(PaymentSchedule)
