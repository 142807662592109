/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'

import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'

import Menu from './Menu'

const styles = theme => ({
  root: {},
  contactHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  contactInfo: {
    padding: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
})

const AdType = ({ classes, adType, refetchQueries }) => {
  const { title, uuid } = adType
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <div className={classes.contactHeader}>
          <div>
            <Typography variant="h6" display="inline">
              {title}
            </Typography>
          </div>
          <Menu uuid={uuid} refetchQueries={refetchQueries} />
        </div>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  )
}

AdType.propTypes = {
  classes: PropTypes.object,
  adType: PropTypes.object,
  index: PropTypes.number,
  openModal: PropTypes.func,
  refetchQueries: PropTypes.array,
  productUUID: PropTypes.string,
}

export default withStyles(styles)(AdType)
