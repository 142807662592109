// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import StripeInput from './StripeInput'

const styles = theme => ({
  outlined: {
    zIndex: 1,
    pointerEvents: 'none !important',
    transform: 'translate(14px, 20px) scale(1)',
    // '&:marginDense': {
    //   transform: 'translate(14px, 17px) scale(1)',
    // },
  },
  shrink: {
    background: '#fff',
    padding: `0 ${theme.spacing(1)}px`,
    transform: 'translate(14px, -6px) scale(0.75)',
  },
})

const StripeElementWrapper = ({ classes, component, label }) => {
  const [focused, setFocused] = useState(false)
  const [empty, setEmpty] = useState(true)
  const [error, setError] = useState(false)

  const handleBlur = () => setFocused(false)
  const handleFocus = () => setFocused(true)

  const handleChange = changeObj => {
    setError(changeObj.error)
    setEmpty(changeObj.empty)
  }

  return (
    <div>
      <FormControl fullWidth margin="normal">
        <InputLabel
          focused={focused}
          shrink={focused || !empty}
          error={!!error}
          classes={{
            root: clsx(classes.outlined, {
              [classes.shrink]: focused || !empty,
            }),
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          fullWidth
          inputComponent={StripeInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          inputProps={{ component }}
        />
      </FormControl>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  )
}

StripeElementWrapper.propTypes = {
  classes: PropTypes.object,
  component: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default withStyles(styles)(StripeElementWrapper)
