import gql from 'graphql-tag'

const CREATE_PAYMENT_METHOD = gql`
  mutation(
    $paymentMethodType: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressZip: String
    $client: String
    $primary: Boolean
    $stripeToken: String
    $status: String
  ) {
    createPaymentmethod(
      input: {
        type: $paymentMethodType
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        addressCity: $addressCity
        addressState: $addressState
        addressZip: $addressZip
        addressCountry: "US"
        client: $client
        primary: $primary
        stripeToken: $stripeToken
        status: $status
      }
    ) {
      ok
      paymentMethod {
        id
        uuid
      }
    }
  }
`

const DELETE_PAYMENT_METHOD = gql`
  mutation($paymentmethoduuid: String!) {
    deletePaymentMethod(input: { paymentMethod: $paymentmethoduuid }) {
      ok
    }
  }
`

const GET_PAYMENT_METHODS = gql`
  query($clientuuid: UUID, $campaignuuid: UUID) {
    allPaymentmethods(
      client_Uuid: $clientuuid
      client_Campaign_Uuid: $campaignuuid
      status_In: "active, pending_verification"
    ) {
      edges {
        node {
          id
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          description
          type
          uuid
          status
          client {
            uuid
          }
        }
      }
    }
  }
`

const GET_PAYMENTS = gql`
  query(
    $company: String
    $contract: String
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allPayments(
      company_Name: $company
      contract_Title: $contract
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          createdDate
          company {
            name
            type
          }
          contract {
            title
          }
          amount
          type
        }
      }
    }
  }
`

const VERIFY_BANK_ACCOUNT = gql`
  mutation($deposit1: Float!, $deposit2: Float!, $paymentmethoduuid: String!) {
    verifyBankAccount(
      input: {
        deposit1: $deposit1
        deposit2: $deposit2
        paymentMethod: $paymentmethoduuid
      }
    ) {
      ok
    }
  }
`

export {
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  GET_PAYMENTS,
  VERIFY_BANK_ACCOUNT,
}
