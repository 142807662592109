import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { pluralize } from '../../../../shared/helperfunctions'
//import GenerateProposalPDF from './GenerateProposalPDF'

const styles = theme => ({
  root: {},
  buttonfiller: {
    width: 192,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
})

const Component = ({ classes, numItems }) => {
  const title = `${numItems} ${pluralize('Item', numItems)}`
  return (
    <div className={classes.header}>
      <Typography variant="h6" color="textSecondary">
        {title}
      </Typography>
      {numItems ? (
        //<GenerateProposalPDF />
        <div className={classes.buttonfiller} />
      ) : (
        <div className={classes.buttonfiller} />
      )}
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  numItems: PropTypes.number,
}

export default compose(
  withStyles(styles),
  withRouter
)(Component)
