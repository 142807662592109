import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Typography, withStyles } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { GET_ALL_CONVERSATIONS } from '../../../../util/apollo/queries/messages'
import { dividerGray, lightGray } from '../constants'

import Conversation from './Conversation'

const styles = () => ({
  root: {
    background: lightGray,
    borderRight: `solid 1px ${dividerGray}`,
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: '100%',
  },
  noChannelsMessage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})

const conversationRefetchQueries = [
  {
    query: GET_ALL_CONVERSATIONS,
    variables: { readStatus: false },
  },
  {
    query: GET_ALL_CONVERSATIONS,
    variables: { status: 'active' },
  },
  {
    query: GET_ALL_CONVERSATIONS,
    variables: { status: 'archive' },
  },
]

export function getConversationQueryVariables(filter, campaignuuid) {
  return {
    status:
      filter === 'archived'
        ? 'archive'
        : filter === 'all'
        ? 'active'
        : undefined,
    readStatus: filter === 'unread' ? false : undefined,
    campaignUUID: campaignuuid,
  }
}

const Conversations = ({
  classes,
  className,
  match: {
    params: { filter, campaignuuid },
  },
}) => {
  const [channels, setChannels] = useState([])

  const { data } = useQuery(GET_ALL_CONVERSATIONS, {
    variables: getConversationQueryVariables(filter, campaignuuid),
  })

  useEffect(() => {
    if (data && Object.entries(data).length)
      setChannels(data.allChannelactivities.edges.map(({ node }) => node))
  }, [data])

  return (
    <div className={clsx(classes.root, className)}>
      {!channels.length ? (
        <div className={classes.noChannelsMessage}>
          <Typography variant="body1" color="textSecondary">
            No Conversations
          </Typography>
        </div>
      ) : (
        channels
          .sort((a, b) => {
            if (
              a.channel.lastMessageTimestamp === b.channel.lastMessageTimestamp
            ) {
              return 0
            } else if (a.channel.lastMessageTimestamp === null) {
              return 1
            } else if (b.channel.lastMessageTimestamp === null) {
              return -1
            } else {
              return a.channel.lastMessageTimestamp <
                b.channel.lastMessageTimestamp
                ? 1
                : -1
            }
          })
          .map(
            ({
              read,
              uuid: channelactivityuuid,
              status,
              title,
              channel: {
                uuid: channeluuid,
                lastMessageFragment,
                lastMessageTimestamp,
              },
            }) => (
              <Conversation
                key={channelactivityuuid}
                channeluuid={channeluuid}
                body={lastMessageFragment}
                date={DateTime.fromISO(lastMessageTimestamp).toLocaleString(
                  DateTime.DATE_SHORT
                )}
                title={title}
                status={status}
                read={read}
                channelactivityuuid={channelactivityuuid}
              />
            )
          )
      )}
    </div>
  )
}

Conversations.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  className: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withRouter
)(Conversations)

export { conversationRefetchQueries }
