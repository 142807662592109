import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { DateTime } from 'luxon'

import {
  Divider,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { removeEdgesAndNodes } from '../../../../shared/helperfunctions'
import { GET_CONTRACT_OVERVIEW } from '../../../../util/apollo/queries/contracts'
import { BackButton } from '../../../../shared/components'

import PaymentSchedule from './PaymentSchedule'
import SaveButton from './SaveButton'

const styles = theme => ({
  root: {
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
  },
  backButton: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  descriptionTextfield: {
    marginTop: theme.spacing(4),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
  },
  overviewContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
    marginTop: theme.spacing(2),
  },
  paymentsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(2),
  },
})

const Component = ({
  classes,
  match: {
    params: { contractuuid },
  },
}) => {
  // Form control
  const [form, setForm] = useState({
    description: '',
    endDate: '',
    sellerid: '',
    startDate: '',
    signingDueDate: '',
    creativeDueDate: '',
    creativeProofDueDate: '',
    deliveryProofDueDate: '',
  })

  const {
    description,
    endDate,
    sellerid,
    startDate,
    signingDueDate,
    creativeDueDate,
    creativeProofDueDate,
    deliveryProofDueDate,
  } = form

  const handleChange = name => ({ target: { value } }) => {
    setForm(prev => ({ ...prev, [name]: value }))
  }

  // Payment amounts control
  const [buyerPaymentList, setBuyerPaymentList] = useState([])
  const [sellerPaymentList, setSellerPaymentList] = useState([])
  const [totals, setTotals] = useState({
    buyer: 0,
    seller: 0,
  })

  // Updates buyer/seller totals when payment dates added
  useEffect(() => {
    setTotals({
      seller: sellerPaymentList.reduce(
        (acc, item) => (acc += Number(item.amount)),
        0
      ),
      buyer: buyerPaymentList.reduce(
        (acc, item) => (acc += Number(item.amount)),
        0
      ),
    })
  }, [buyerPaymentList, sellerPaymentList])

  // Get contract's info
  const { data: { allContracts } = {} } = useQuery(GET_CONTRACT_OVERVIEW, {
    variables: { contractuuid },
  })

  // Flag for updating form control values after query
  const [hasUpdatedFormControl, setHasUpdatedFormControl] = useState(false)

  // Wait until query completes to render anything
  if (!allContracts) return null

  // Pull out relevant information from query response
  const contract = allContracts.edges.map(({ node }) => node)[0]
  removeEdgesAndNodes(contract)

  const getDateByType = inputType => {
    const date = contract.contractDate.edges.filter(
      ({ type }) => type === inputType
    )
    if (date.length)
      return DateTime.fromISO(date[0].dueDate).toFormat('yyyy-MM-dd')
    return ''
  }

  const {
    productSize: {
      product: {
        title: productTitle,
        seller: { name: sellerName },
      },
      title: productSizeTitle,
    },
    buyerContact: {
      company: { name: buyerName },
    },
  } = contract

  if (!hasUpdatedFormControl) {
    setForm({
      description: contract.description || '',
      endDate: DateTime.fromISO(contract.endDate).toFormat('yyyy-MM-dd') || '',
      sellerid: contract.sellerContractId || '',
      startDate:
        DateTime.fromISO(contract.startDate).toFormat('yyyy-MM-dd') || '',
      signingDueDate: getDateByType('CONTRACT_SIGNING_DUE'),
      creativeDueDate: getDateByType('CREATIVE_DUE'),
      creativeProofDueDate: getDateByType('CREATIVE_PROOF_DUE'),
      deliveryProofDueDate: getDateByType('PROOF_OF_DELIVERY_DUE'),
    })

    setBuyerPaymentList(
      contract.invoice
        .filter(({ company: { type } }) => type === 'BUYER')
        .map(({ dueDate, amount }) => ({ dueDate, amount }))
    )

    setSellerPaymentList(
      contract.invoice
        .filter(({ company: { type } }) => type === 'SELLER')
        .map(({ dueDate, amount }) => ({ dueDate, amount }))
    )

    setHasUpdatedFormControl(true)
  }

  return (
    <>
      <BackButton className={classes.backButton} />
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            {productTitle} ({productSizeTitle})
          </Typography>
          <SaveButton
            {...{
              buyerPaymentList,
              contract,
              form,
              sellerPaymentList,
              totals,
            }}
          />
        </div>
        <Divider />
        <div className={classes.overviewContainer}>
          <div>
            <Typography variant="caption" color="textSecondary">
              Buyer
            </Typography>
            <Typography>{buyerName}</Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Seller
            </Typography>
            <Typography>{sellerName}</Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Contract ID
            </Typography>
            <Typography>{contractuuid}</Typography>
          </div>
          <TextField
            label="Seller ID"
            InputLabelProps={{ shrink: true }}
            value={sellerid}
            onChange={handleChange('sellerid')}
          />
          <div>
            <Typography variant="caption" color="textSecondary">
              Total Buyer Amount
            </Typography>
            <Typography>${totals.buyer.toFixed(2)}</Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Total Seller Amount
            </Typography>
            <Typography>${totals.seller.toFixed(2)}</Typography>
          </div>
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="Start Date"
            value={startDate}
            onChange={handleChange('startDate')}
          />
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="End Date"
            value={endDate}
            onChange={handleChange('endDate')}
          />
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="Contract Signing Due"
            value={signingDueDate}
            onChange={handleChange('signingDueDate')}
          />
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="Creative Due"
            value={creativeDueDate}
            onChange={handleChange('creativeDueDate')}
          />
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="Creative Proof Due"
            value={creativeProofDueDate}
            onChange={handleChange('creativeProofDueDate')}
          />
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            label="Proof of Delivery Due"
            value={deliveryProofDueDate}
            onChange={handleChange('deliveryProofDueDate')}
          />
        </div>
        <TextField
          label="Description"
          className={classes.descriptionTextfield}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={handleChange('description')}
        />
      </Paper>
      <div className={classes.paymentsContainer}>
        <PaymentSchedule
          schedule={buyerPaymentList}
          setSchedule={setBuyerPaymentList}
          type="Buyer"
        />
        <PaymentSchedule
          schedule={sellerPaymentList}
          setSchedule={setSellerPaymentList}
          type="Seller"
        />
      </div>
    </>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default compose(withStyles(styles))(Component)
