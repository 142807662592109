import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withRouter } from 'react-router-dom'
import { withStyles, Divider, Paper, Typography } from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { UploadFile } from '../../../../../../shared/dialogs'
import { removeEdgesAndNodes } from '../../../../../../shared/helperfunctions'
import {
  GET_PRODUCT_TEMPLATES,
  ADD_PRODUCT_FILE,
} from '../../../../../../util/apollo/queries/products'

import Template from './Template'

const styles = theme => ({
  root: {},
  content: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  pushRight: {
    marginLeft: 'auto',
  },
})

const Templates = ({ classes, productUUID }) => {
  const query_variables = {
    uuid: productUUID,
  }

  const refetchQueries = [
    {
      query: GET_PRODUCT_TEMPLATES,
      variables: query_variables,
    },
  ]

  const { loading, error, data } = useQuery(GET_PRODUCT_TEMPLATES, {
    variables: query_variables,
  })
  if (!productUUID) {
    return null
  }
  if (loading) return 'Loading...'
  if (error) {
    return null
  }

  const fileItems =
    data && data.allProducts.edges[0]
      ? removeEdgesAndNodes(data.allProducts).edges[0].fileItem.edges
      : []
  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Templates</Typography>
        <UploadFile
          fileTypes={['other', 'product_image', 'product_materials']}
          onFileTypeChange={(
            fileType,
            { setHideDescription, setDescription }
          ) => {
            setHideDescription(fileType === 'product_image')
            setDescription(fileType === 'product_image' ? 'Product Image' : '')
          }}
          mutation={ADD_PRODUCT_FILE}
          variables={{ productUUID }}
          refetchQueries={refetchQueries}
          security={'public'}
        />
      </div>
      <Divider />
      <div className={classes.content}>
        {fileItems.map((fileItem, index) => (
          <Template
            key={index}
            index={index}
            fileItem={fileItem}
            refetchQueries={refetchQueries}
            productUUID={productUUID}
          />
        ))}
      </div>
    </Paper>
  )
}

Templates.propTypes = {
  classes: PropTypes.object,
  productUUID: PropTypes.string,
}

export default compose(
  withRouter,
  withStyles(styles)
)(Templates)
