import React from 'react'
import { rrulestr, RRuleSet } from 'rrule'
import { DateTime } from 'luxon'
import { store } from '../util/redux/store'
import { baseUrl } from '../util/apollo/config'

const formatAddress = (line1, line2, city, state, zip, country) => {
  return (
    <>
      {line1}, {line2 ? line2 : null}
      <br />
      {city}, {state} {zip}
      <br />
      {country}
    </>
  )
}

const formatDate = (date, defaultString = 'mm/dd/yy') => {
  if (!date) return defaultString
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
}

const formatDateUTCNoon = date => {
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd') + 'T12:00:00'
}

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const snakeToTitleCase = str => {
  if (str == null) {
    return null
  }
  return titleCase(str.replace(/_/g, ' '))
}

const titleCase = str => {
  if (str == null) {
    return null
  }
  return str
    .toLowerCase()
    .split(' ')
    .map(function(word) {
      return word.replace(word[0], word[0].toUpperCase())
    })
    .join(' ')
}

const camelCase = str => {
  return str
    .split(' ')
    .map((word, i) => {
      if (i !== 0) return word[0].toUpperCase() + word.slice(1)
      return word.toLowerCase()
    })
    .join('')
}

const isUUID = uuid => {
  const re = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return re.test(uuid)
}

const capitalizationCase = str => {
  if (str == null) {
    return null
  }

  return str
    .toLowerCase()
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ')
}

const formatCurrency = amount => {
  if (amount == null) {
    return '$-.--'
  }
  if (typeof amount === 'string') {
    amount = parseFloat(amount)
  }
  return '$' + formatMoney(amount)
}

function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    alert(e)
  }
}

const pluralize = (str, quantity) => {
  if (quantity == 1) {
    return str
  } else {
    return str + 's'
  }
}

const abbreviateNumber = (num, digits) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

const removeEdgesAndNodes = data => {
  if (data == null) {
    return null
  }
  Object.keys(data).forEach(key => {
    if (key === 'edges')
      data.edges = data.edges.map(edge => {
        if (edge.node) return removeEdgesAndNodes(edge.node) //if (edge.node) return edge.node
        return edge
      })
    else {
      if (typeof data[key] === 'object')
        data[key] = removeEdgesAndNodes(data[key])
    }
  })
  return data
}

const saveFileInGraphQl = async (config, fileUUID) => {
  const { client, mutation, successFn, errorFn, refetchQueries } = config
  let { variables } = config

  variables = { ...variables, fileUUID }

  await client
    .mutate({ mutation, variables, refetchQueries })
    .then(() => successFn())
    .catch(e => {
      if (errorFn) {
        errorFn(e.message)
      } else alert(e)
    })
}

const uploadFile = async (token, formData, config) => {
  const { description, file, source, type, security = 'private' } = formData
  const { saveFileOnly } = config

  let data = new FormData()
  data.append('file', file)
  data.append('type', type)
  data.append('description', description)
  data.append('source', source)
  data.append('security', security)

  return await fetch(baseUrl + '/api/rest/v1/file/upload', {
    method: 'POST',
    headers: {
      Authorization: 'JWT ' + token,
    },
    body: data,
  })
    .then(response => {
      if (!response.ok) throw new Error(response.statusText)
      return response.json()
    })
    .then(({ uuid }) => {
      if (saveFileOnly) return config.successFn(uuid)

      switch (type) {
        case 'other':
          switch (source) {
            case 'message':
              return [uuid]
            default:
              saveFileInGraphQl(config, uuid)
              break
          }
          break
        default:
          saveFileInGraphQl(config, uuid)
          break
      }
    })
    .catch(e => {
      if (config && config.errorFn) {
        config.errorFn(e.message)
      } else alert(e)
    })
}

const formatLineChartData = data => {
  let newData = []
  data.forEach(({ x, y, category }) => {
    const index = newData.findIndex(
      e => e.x == DateTime.fromFormat(x, 'MM/dd/y').toFormat('MM/dd/yy')
    )
    if (index == -1) {
      let newDictionary = {}
      newDictionary['x'] = DateTime.fromFormat(x, 'MM/dd/y').toFormat(
        'LL/dd/yy'
      )
      newDictionary[snakeToTitleCase(category)] = y
      newData.push(newDictionary)
    } else {
      newData[index][snakeToTitleCase(category)] = y
    }
  })
  const labels = [
    // eslint-disable-next-line no-undef
    ...new Set(data.map(datapoint => snakeToTitleCase(datapoint.category))),
  ]
  return { labels: labels, lines: newData }
}

const formatCategoryChartData = data => {
  let newData = []
  data.forEach(({ x, y, category }) => {
    const index = newData.findIndex(e => e.x == x)
    if (index == -1) {
      let newDictionary = {}
      newDictionary['x'] = x
      newDictionary[snakeToTitleCase(category)] = y
      newData.push(newDictionary)
    } else {
      newData[index][snakeToTitleCase(category)] = y
    }
  })
  const labels = [
    // eslint-disable-next-line no-undef
    ...new Set(data.map(datapoint => snakeToTitleCase(datapoint.category))),
  ]
  return { labels: labels, lines: newData }
}

const generateOpenFileUrl = uuid =>
  baseUrl + `/api/rest/v1/file/download/o/${uuid}`

const generateFileUrl = async uuid => {
  if (!uuid) return null

  return await fetch(baseUrl + '/api/rest/v1/file/download/r/' + uuid, {
    method: 'GET',
    headers: {
      authorization: 'JWT ' + store.getState().tokens.token,
    },
  })
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(new Blob([blob])))
    .catch(() => 'error')
}

const downloadFileOnClick = (url, downloadAs = 'download') => () => {
  const token = store.getState().tokens.token

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'JWT ' + token,
    },
  })
    .then(response => response.blob())
    .then(blob => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadAs)

      // 3. Append to html page
      document.body.appendChild(link)

      // 4. Force download
      link.click()

      // 5. Clean up and remove the link
      link.parentNode.removeChild(link)
    })
    .catch(error => {
      alert(error)
    })
}

const formatPhoneNumber = phoneNumber => {
  const formattedNumber =
    phoneNumber.substring(2, 5) +
    '-' +
    phoneNumber.substring(5, 8) +
    '-' +
    phoneNumber.substring(8, 12)
  return formattedNumber
}

export function getRRuleSetFromValue(value) {
  let rruleSet
  const parseResult = rrulestr(value)
  if (parseResult instanceof RRuleSet) {
    rruleSet = parseResult
  } else {
    rruleSet = new RRuleSet()
    rruleSet.rrule(parseResult)
  }
  return rruleSet
}

export function ordinalSuffixOf(i) {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + 'st'
  }
  if (j == 2 && k != 12) {
    return i + 'nd'
  }
  if (j == 3 && k != 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export {
  abbreviateNumber,
  camelCase,
  capitalizationCase,
  downloadFileOnClick,
  formatAddress,
  formatCategoryChartData,
  formatCurrency,
  formatDate,
  formatDateUTCNoon,
  formatLineChartData,
  formatPhoneNumber,
  generateFileUrl,
  generateOpenFileUrl,
  isUUID,
  removeEdgesAndNodes,
  pluralize,
  snakeToTitleCase,
  titleCase,
  uploadFile,
  validateEmail,
}
