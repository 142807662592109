import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { Typography, withStyles } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import Circle from '../../../../shared/components/IndicatorCircle'
import { BUYER_CONTRACT_LIST } from '../../../../util/apollo/queries/contracts'
import GenericTableTwo from '../../../../shared/components/GenericTableTwo'
import {
  capitalizationCase,
  formatDate,
} from '../../../../shared/helperfunctions'

const styles = () => ({
  root: {
    width: '100%',
  },
})

const generateStatusIndicator = contractStatus => {
  const status = {
    Inquiry: '#e0e0e0',
    'Pre Flight': '#00ff00',
    'In Flight': '#00ff00',
    Complete: '#e0e0e0',
    Paused: '#ffa500',
    Canceled: '#e0e0e0',
    'On Schedule': '#00ff00',
    Late: '#ff0000',
  }
  const displayStatus = contractStatus[1] === true ? 'Late' : contractStatus[0]
  return <Circle color={status[displayStatus]} />
}

const columns = [
  { name: 'uuid', options: { display: 'excluded' } },
  { name: 'title', label: 'Ad' },
  { name: 'startDate', label: 'Dates' },
  { name: 'actualBuyerPrice', label: 'Amount' },
  { name: 'mediaType', label: 'Type' },
  {
    name: 'stage',
    label: 'Contract Status',
    options: {
      // eslint-disable-next-line react/display-name
      customBodyRender: value => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {generateStatusIndicator(value)}
          <Typography variant="body1">{value[0]}</Typography>
        </div>
      ),
    },
  },
]

const parseData = data =>
  data.allContracts.edges
    .map(({ node }) => node)
    .map(
      ({
        uuid,
        title,
        startDate,
        actualBuyerPrice,
        productSize: {
          product: { mediaType },
        },
        stage,
        lateStatus,
      }) => ({
        uuid,
        title,
        startDate: formatDate(startDate, '-') || '-',
        actualBuyerPrice: actualBuyerPrice || '-',
        mediaType: mediaType[0] + mediaType.slice(1).toLowerCase(),
        stage: [capitalizationCase(stage), lateStatus],
      })
    )

const Overview = ({
  match: {
    params: { campaignuuid },
  },
  history,
}) => {
  const count = 10
  const { loading, error, data, fetchMore } = useQuery(BUYER_CONTRACT_LIST, {
    variables: {
      campaignuuid,
      first: count,
      stageIn: 'inquiry,pre_flight,in_flight,complete,paused',
    },
  })

  const onRowClick = row => {
    if (row.stage[0].toLowerCase() !== 'inquiry')
      history.push(`/customer/${campaignuuid}/contracts/detail/${row.uuid}`)
  }
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (!data) return 'Error'

  return (
    <GenericTableTwo
      data={parseData(data)}
      title="Contracts"
      columns={columns}
      pagination={{
        fetchMore,
        pageInfo: data.allContracts.pageInfo,
        count: count,
      }}
      options={{
        selectableRows: 'none',
        onRowClick,
      }}
    />
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withRouter,
  withStyles(styles)
)(Overview)
