import React from 'react'
import PropTypes from 'prop-types'

import { Grid, withStyles } from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { GET_PROPOSAL_ITEMS } from '../../../util/apollo/queries/proposalItems'

import ProposalItem from './ProposalItem'
import RequestContract from './RequestContract'
import Header from './Header'

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
  },
})

const totals = proposalItems => ({
  totalImpressions: proposalItems.reduce(
    (sum, { estimatedTotalImpressions }) => sum + estimatedTotalImpressions,
    0
  ),
  contractTotal: proposalItems.reduce(
    (sum, { estimatedTotalPrice }) => sum + estimatedTotalPrice,
    0
  ),
  length: proposalItems.length,
})

const Proposal = ({
  classes,
  match: {
    params: { campaignuuid },
  },
}) => {
  const { loading, data, refetch } = useQuery(GET_PROPOSAL_ITEMS, {
    variables: {
      campaignuuid,
      status: 'in',
    },
  })
  if (loading) return 'Loading...'
  if (data) {
    const proposalItems = data.allProposalitems.edges.map(e => e.node)
    const { totalImpressions, contractTotal, length } = totals(proposalItems)
    return (
      <div className={classes.root}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Header numItems={length} />
          </Grid>
          <Grid item>
            {length ? (
              <Grid container spacing={4}>
                <Grid item xs={8}>
                  {proposalItems.map((item, index) => {
                    return <ProposalItem key={`item${index}`} item={item} />
                  })}
                </Grid>
                <Grid item xs={4}>
                  {length && (
                    <RequestContract
                      refetchProposalItems={refetch}
                      totalImpressions={totalImpressions}
                      contractTotal={contractTotal}
                      currentCampaign={campaignuuid}
                    />
                  )}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </div>
    )
  }

  return null
}

Proposal.propTypes = {
  classes: PropTypes.object,
  currentCampaign: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Proposal)
