import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { IconButton, Tooltip } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import { withRouter } from 'react-router-dom'

const Component = ({
  match: {
    params: { contractuuid },
  },
  history,
}) => {
  const viewMessages = () => {
    history.push(`/moderator/messages/${contractuuid}`)
  }

  return (
    <Tooltip title="View Messages">
      <IconButton onClick={viewMessages}>
        <ChatIcon />
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default compose(withRouter)(Component)
