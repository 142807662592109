import React from 'react'
import { PropTypes } from 'prop-types'

import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'

import CompanyList from './List'
import Seller from './Seller'
import Product from './Seller/Product'
import Buyer from './Buyer'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(0),
    boxShadow: 'none',
  },
})

const Company = ({ classes, match }) => {
  return (
    <div className={classes.root}>
      <Switch>
        <Route path={match.path + '/seller/new'} component={Seller} />
        <Route
          path={match.path + '/seller/:uuid/overview'}
          component={Seller}
        />
        <Route
          path={match.path + '/seller/:cuuid/product/new'}
          component={Product}
        />
        <Route
          path={match.path + '/seller/:cuuid/product/:uuid'}
          component={Product}
        />
        <Route path={match.path + '/buyer/:uuid/overview'} component={Buyer} />
        <Route path={match.path + '/buyer/new'} component={Buyer} />
        <Route path={match.path + '/'} component={CompanyList} />
      </Switch>
    </div>
  )
}

Company.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(Company)
