import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import {
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  root: {},
  divider: {
    height: theme.spacing(2),
  },
  input: {
    display: 'flex',
    height: 56,
    padding: 0,
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: theme.spacing(2),
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1350,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: 56 * 10,
    overflowY: 'scroll',
  },
  paperAbove: {
    bottom: 60,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: `${theme.spacing(2) - 4.75 / 2}px ${theme.spacing(2)}px`,
  },
})

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.node,
}

function Menu(props) {
  return (
    <Paper
      square
      className={
        props.selectProps.attachAbove
          ? `${props.selectProps.classes.paper} ${
              props.selectProps.classes.paperAbove
            }`
          : props.selectProps.classes.paper
      }
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

const IntegrationReactSelect = ({
  attachAbove,
  classes,
  selectProps,
  options,
  value,
  setValue,
}) => {
  const updateValue = newValue => setValue(newValue)

  return (
    <Select
      attachAbove={attachAbove}
      classes={classes}
      options={options}
      components={components}
      menuPlacement="top"
      value={value}
      onChange={updateValue}
      isClearable
      textFieldProps={{
        className: classes.textField,
      }}
      {...selectProps}
    />
  )
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  selectProps: PropTypes.object,
  options: PropTypes.array.isRequired,
  attachAbove: PropTypes.bool,
}

export default withStyles(styles)(IntegrationReactSelect)
