import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { GET_PAYMENTS } from '../../../util/apollo/queries/payments'
import GenericTableTwo from '../../../shared/components/GenericTableTwo'
import { formatDate, titleCase } from '../../../shared/helperfunctions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    boxShadow: 'none',
    padding: theme.spacing(4),
  },
})

const columns = [
  { name: 'uuid', options: { display: 'excluded' } },
  { name: 'companyName', label: 'Company' },
  { name: 'companyType', label: 'Type' },
  { name: 'contractTitle', label: 'Contract' },
  { name: 'createdDate', label: 'Date' },
  { name: 'amount', label: 'Amount' },
]

const parseData = data =>
  data.allPayments.edges
    .map(({ node }) => node)
    .map(
      ({
        uuid,
        company: { name: companyName, type: companyType },
        contract: { title: contractTitle },
        type,
        status,
        createdDate,
        amount,
      }) => ({
        uuid,
        companyName,
        companyType: titleCase(companyType),
        contractTitle,
        type,
        status,
        createdDate: formatDate(createdDate, '-'),
        amount,
      })
    )

const Payments = ({ classes }) => {
  const count = 10
  const { loading, error, data, fetchMore } = useQuery(GET_PAYMENTS, {
    variables: { first: count },
  })
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (!data) return 'Error'

  return (
    <div className={classes.root}>
      <GenericTableTwo
        data={parseData(data)}
        title="Payments"
        columns={columns}
        pagination={{
          fetchMore,
          pageInfo: data.allPayments.pageInfo,
          count: count,
        }}
        options={{
          selectableRows: 'none',
        }}
      />
    </div>
  )
}

Payments.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  withRouter
)(Payments)
