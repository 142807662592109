import gql from 'graphql-tag'

const ADD_USER = gql`
  mutation($userList: [String], $clientuuid: String) {
    updateClient(input: { addUser: $userList, uuid: $clientuuid }) {
      ok
    }
  }
`

const REMOVE_USER = gql`
  mutation($useruuid: [String], $clientuuid: String) {
    updateClient(input: { removeUser: $useruuid, uuid: $clientuuid }) {
      ok
    }
  }
`

const CREATE_CLIENT = gql`
  mutation($name: String!) {
    createClient(input: { name: $name }) {
      ok
      client {
        uuid
      }
    }
  }
`

const GET_ALL_CLIENTS = gql`
  query {
    allClients {
      edges {
        node {
          name
          uuid
          campaign {
            edges {
              node {
                status
                name
              }
            }
          }
        }
      }
    }
  }
`

const GET_CLIENTS = gql`
  query($useruuid: UUID!) {
    allClients(user_Userprofile_Uuid: $useruuid) {
      edges {
        node {
          name
          uuid
          campaign {
            edges {
              node {
                status
                name
              }
            }
          }
        }
      }
    }
  }
`

const GET_CLIENT_DETAILS = gql`
  query($clientuuid: UUID!) {
    allClients(uuid: $clientuuid) {
      edges {
        node {
          name
        }
      }
    }
  }
`

const DELETE_CLIENT_USER = gql`
  mutation($clientuuid: String!, $useruuid: [String!]) {
    updateClient(input: { uuid: $clientuuid, removeUser: $useruuid }) {
      ok
    }
  }
`

const GET_CLIENT_USERS = gql`
  query($clientuuid: UUID!) {
    allClients(uuid: $clientuuid) {
      edges {
        node {
          user {
            uuid
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

const UPDATE_CLIENT = gql`
  mutation($clientuuid: String!, $name: String) {
    updateClient(input: { uuid: $clientuuid, name: $name }) {
      ok
    }
  }
`

export {
  ADD_USER,
  CREATE_CLIENT,
  DELETE_CLIENT_USER,
  GET_ALL_CLIENTS,
  GET_CLIENT_DETAILS,
  GET_CLIENT_USERS,
  GET_CLIENTS,
  UPDATE_CLIENT,
  REMOVE_USER,
}
