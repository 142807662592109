import React from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { PropTypes } from 'prop-types'
import { CallContact, SnoozeAlert } from './menuitems'

class AlertActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      buyerName: null,
      buyerPhone: null,
      sellerName: null,
      sellerPhone: null,
    }
  }

  componentWillMount() {
    const { data } = this.props

    let buyer = ''
    let seller = ''

    if (data && data.allContracts.edges.length) {
      seller =
        data.allContracts.edges[0].node.productSize.product.seller.contact
          .edges[0].node
      buyer = data.allContracts.edges[0].node.buyerContact

      this.setState({
        sellerName: `${seller.firstName} ${seller.lastName}`,
        sellerPhone: seller.phone,
        buyerName: `${buyer.firstName} ${buyer.lastName}`,
        buyerPhone: buyer.phone,
      })
    }
  }

  openMenu = event => this.setState({ anchorEl: event.currentTarget })

  closeMenu = () => this.setState({ anchorEl: null })

  openCallModal = () => this.setState({ callPopupOpen: true, anchorEl: null })

  closeCallModal = () => this.setState({ callPopupOpen: false })

  callBuyer = () => {
    this.setState({ contactType: 'Buyer' })
    this.openCallModal()
  }

  callSeller = () => {
    this.setState({ contactType: 'Seller' })
    this.openCallModal()
  }

  messageBuyer = () => {
    return
  }

  messageSeller = () => {
    return
  }

  options = [
    { label: 'Message Buyer', action: this.messageBuyer, disabled: true },
    { label: 'Message Seller', action: this.messageSeller, disabled: true },
  ]

  render() {
    const {
      anchorEl,
      buyerName,
      buyerPhone,
      sellerName,
      sellerPhone,
    } = this.state
    const { data, reason, uuid } = this.props
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton id="icon-button" onClick={this.openMenu}>
          <MoreVertIcon id="more-vert-icon" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.closeMenu}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {this.options.map(option => (
            <MenuItem
              disabled={data ? false : option.disabled}
              key={option.label}
              onClick={option.action}
            >
              {option.label}
            </MenuItem>
          ))}
          <CallContact
            contactType={'Buyer'}
            contactName={buyerName}
            contactPhone={buyerPhone}
            closeParentMenu={this.closeMenu}
          />
          <CallContact
            contactType={'Seller'}
            contactName={sellerName}
            contactPhone={sellerPhone}
            closeParentMenu={this.closeMenu}
          />
          <SnoozeAlert
            closeParentMenu={this.closeMenu}
            reason={reason}
            uuid={uuid}
          />
        </Menu>
      </div>
    )
  }
}

AlertActionMenu.propTypes = {
  reason: PropTypes.string,
  data: PropTypes.object,
  uuid: PropTypes.string,
}

export default AlertActionMenu
