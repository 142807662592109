import { createMuiTheme } from '@material-ui/core/styles'

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0dbfff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fe5764',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
      },
    },
    MuiDialogContent: {
      root: {
        minWidth: 300,
      },
    },
    MuiDialogTitle: {
      root: {
        minWidth: 300,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, -6px) scale(0.75)',
        transformOrigin: 'top left',
        padding: '0 2px',
        background: '#ffffff',
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
  },
  tabs: {
    backgroundColor: '#fafafa',
  },
  inkBar: {
    backgroundColor: '#0dbffe',
  },
})

export default Theme
