import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router-dom'

import {
  GET_PROPOSAL_ITEMS,
  UPDATE_PROPOSAL_ITEM,
} from '../../../../util/apollo/queries/proposalItems'
import {
  formatDate,
  formatCurrency,
  pluralize,
  abbreviateNumber,
} from '../../../../shared/helperfunctions'
import { withNotifications } from '../../../../util'
import ProductImage from '../../Search/Products/Product/ProductImage'
import { snakeToTitleCase } from '../../../../shared/helperfunctions'
import Form from './Form'

const styles = theme => ({
  root: {
    display: 'flex',
    gridTemplateColumns: '200px 1fr',
    marginBottom: theme.spacing(4),
    minWidth: 615,
    padding: theme.spacing(4),
  },
  button: {
    boxSizing: 'border-box',
    color: '#3bbae5',
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  leftContainer: {
    height: '100%',
    flexGrow: 1,
    minHeight: '0',
  },
  imageItem: {
    minHeight: '0',
    flexGrow: 1,
  },
  buttonItem: {},
})

const ProposalItem = ({
  classes,
  item,
  match: {
    params: { campaignuuid },
  },
  notify,
}) => {
  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = () => setEditMode(prev => !prev)

  let {
    datePreferenceNotes,
    otherPreferenceNotes,
    preferredStartDate,
    estimatedTotalPrice,
    estimatedTotalImpressions,
    productSize: {
      buyUnit,
      title,
      product: { mediaType, fileItem },
    },
    quantity,
    uuid,
  } = item

  const totalImpressions = abbreviateNumber(estimatedTotalImpressions, 1)
  const totalCost = formatCurrency(estimatedTotalPrice)
  const fileuuid = fileItem.edges
    .map(({ node }) => node)
    .filter(({ type }) => type === 'PRODUCT_IMAGE')[0].uuid

  quantity = quantity.toString()
  buyUnit = pluralize(buyUnit, quantity).toLowerCase()
  preferredStartDate = formatDate(preferredStartDate)

  const removeFromProposal = mutation => () => {
    mutation({
      variables: {
        uuid,
        status: 'abandoned',
      },
      refetchQueries: [
        {
          query: GET_PROPOSAL_ITEMS,
          variables: {
            campaignuuid,
            status: 'in',
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data.updateProposalitem.ok) notify.default('Removed From Proposal')
      })
      .catch(() => null)
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.leftContainer}
          >
            <Grid item className={classes.imageItem}>
              <ProductImage uuid={fileuuid} />
            </Grid>
            <Grid item className={classes.buttonItem}>
              <Mutation
                mutation={UPDATE_PROPOSAL_ITEM}
                refetchQueries={[
                  {
                    query: GET_PROPOSAL_ITEMS,
                    variables: {
                      campaign: campaignuuid,
                      status: 'in',
                    },
                  },
                ]}
              >
                {(removeItem, { error }) => {
                  if (error) alert(error)
                  return (
                    <Button
                      onClick={removeFromProposal(removeItem)}
                      className={classes.button}
                    >
                      Remove from Proposal
                    </Button>
                  )
                }}
              </Mutation>
            </Grid>
            <Grid item className={classes.buttonItem}>
              <Button className={classes.button} onClick={toggleEditMode}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {editMode ? (
            <Mutation
              mutation={UPDATE_PROPOSAL_ITEM}
              refetchQueries={[
                {
                  query: GET_PROPOSAL_ITEMS,
                  variables: {
                    campaign: campaignuuid,
                    status: 'in',
                  },
                },
              ]}
            >
              {(mutation, { error }) => {
                if (error) alert(error)
                return (
                  <Formik
                    onSubmit={values => {
                      const variables = {
                        ...values,
                        uuid,
                      }
                      delete variables.title

                      variables.preferredStartDate = DateTime.fromISO(
                        variables.preferredStartDate
                      ).toISO()

                      mutation({ variables })
                      setEditMode(false)
                    }}
                    initialValues={{
                      datePreferenceNotes,
                      otherPreferenceNotes,
                      preferredStartDate,
                      title,
                      quantity,
                    }}
                    render={props => (
                      <Form
                        {...props}
                        toggleEditMode={toggleEditMode}
                        uuid={uuid}
                      />
                    )}
                  />
                )
              }}
            </Mutation>
          ) : (
            <>
              <Typography noWrap variant="h5" className={classes.title}>
                {title}
              </Typography>
              <Typography noWrap variant="subtitle1">
                Ad Details
              </Typography>
              <Divider className={classes.divider} />
              <div className={classes.content}>
                <Typography noWrap color="textSecondary">
                  Ad Type
                </Typography>
                <Typography align="right">
                  {snakeToTitleCase(mediaType)}
                </Typography>
                <Typography noWrap color="textSecondary">
                  Quantity
                </Typography>
                <Typography align="right">
                  {quantity} {buyUnit}
                </Typography>
                <Typography noWrap color="textSecondary">
                  Preferred Start Date
                </Typography>
                <Typography align="right">{preferredStartDate}</Typography>
                <Typography noWrap color="textSecondary">
                  Date Preference
                </Typography>
                <Typography align="right">{datePreferenceNotes}</Typography>
                <Typography noWrap color="textSecondary">
                  Other Comments
                </Typography>
                <Typography align="right">{otherPreferenceNotes}</Typography>
                <Typography noWrap color="textSecondary">
                  Total Impressions
                </Typography>
                <Typography align="right">{totalImpressions}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.footer}>
                <Typography noWrap variant="subtitle1">
                  Total Cost
                </Typography>
                <Typography variant="subtitle1">{totalCost}</Typography>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

ProposalItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  withNotifications,
  withRouter
)(ProposalItem)
