import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import {
  GET_CLIENT_USERS,
  DELETE_CLIENT_USER,
} from '../../../../../util/apollo/queries/clients'

import { withNotifications } from '../../../../../util'

const styles = () => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const DeleteButton = ({
  classes,
  useruuid,
  match: {
    params: { clientuuid },
  },
  notify,
}) => {
  const [open, setOpen] = useState(false)
  const updateOpen = state => () => setOpen(state)

  const [mutation] = useMutation(DELETE_CLIENT_USER)

  const deletePayment = () => {
    mutation({
      variables: { useruuid, clientuuid },
      refetchQueries: [{ query: GET_CLIENT_USERS, variables: { clientuuid } }],
    }).then(({ data }) => {
      if (data.updateClient.ok) notify.error('Deleted')
    })
  }

  return (
    <>
      <IconButton onClick={updateOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={updateOpen(false)}>
        <DialogTitle>Remove User</DialogTitle>
        <DialogContent>Are you sure?</DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={updateOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={deletePayment}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteButton.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  client: PropTypes.object,
  useruuid: PropTypes.string.isRequired,
  match: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withNotifications,
  withRouter,
  withApollo
)(DeleteButton)
