export const headerHeight = 86
export const conversationWidth = 400
export const footerHeight = 86

export const darkGray = '#f5f5f5'
export const dividerGray = '#e0e0e0'
export const hoverGray = '#e8e8e8'
export const lightGray = '#f5f7fa'
export const selectedGray = '#f9f9f9'
export const timestampColor = '#555'
