import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import { withStyles, IconButton } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useMutation, useQuery } from 'react-apollo'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  GET_ALERTS,
  GET_SUMMARY,
  REMOVE_ALERT,
} from '../../../../util/apollo/queries/alertactivities'
import SnoozeForm from '../SnoozeForm'
import {
  removeEdgesAndNodes,
  snakeToTitleCase,
  formatDate,
} from '../../../../shared/helperfunctions'
import { withNotifications } from '../../../../util'

const styles = () => ({
  root: {},
  actionButton: {
    zIndex: 9999,
  },
  tableTitle: {
    paddingTop: 24,
    paddingLeft: 24,
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relavive',
  },
})

const AlertTable = ({ classes, history, notify }) => {
  const createRow = item => {
    const reason = snakeToTitleCase(item.alertInformation.type)
    const dueDate = formatDate(item.notificationDate)

    let description = item.alertInformation.message
    if (item.note) description = description.concat('. ' + item.note)

    const contractUuid = item.alertInformation.contract.uuid

    const alertUuid = item.uuid

    return { reason, description, dueDate, contractUuid, alertUuid }
  }

  const handleClick = uuid => ({ target: { id } }) => {
    if (id === 'table-row-element')
      history.push('/moderator/contracts/overview/' + uuid)
  }

  const today = new Date()
  const queryVariables = { todaysDate: today.toISOString() }
  const refetchQueries = [
    {
      query: GET_ALERTS,
      variables: queryVariables,
    },
    {
      query: GET_SUMMARY,
    },
  ]

  const { loading, error, data } = useQuery(GET_ALERTS, {
    variables: queryVariables,
  })
  const [mutation] = useMutation(REMOVE_ALERT)

  const removeAlert = uuid => {
    mutation({
      variables: { uuid },
      refetchQueries,
    }).then(({ data }) => {
      if (data.updateAlertactivity.ok) notify.success('Removed')
    })
  }
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  removeEdgesAndNodes(data)
  const rows = data.allAlertactivities.edges
    .map(item => createRow(item))
    .sort((a, b) => a.dueDate > b.dueDate)
  return (
    <Paper>
      <Typography variant="h6" className={classes.tableTitle}>
        Alerts
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Reason For Alert</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow
                key={index}
                hover
                className={classes.tableRow}
                onClick={handleClick(row.contractUuid)}
              >
                <TableCell key={index + '-1'} id="table-row-element">
                  {row.reason}
                </TableCell>

                <TableCell key={index + '-2'} id="table-row-element">
                  {row.description}
                </TableCell>

                <TableCell key={index + '-3'} id="table-row-element">
                  {row.dueDate}
                </TableCell>
                <TableCell key={index + '-4'} id="table-row-element">
                  <SnoozeForm
                    uuid={row.alertUuid}
                    refetchQueries={refetchQueries}
                  />
                  <IconButton onClick={() => removeAlert(row.alertUuid)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

AlertTable.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  notify: PropTypes.object,
}

export default compose(
  withRouter,
  withNotifications,
  withStyles(styles)
)(AlertTable)
