import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  withStyles,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import PaymentIcon from '@material-ui/icons/Payment'
import { withApollo } from 'react-apollo'
import { injectStripe } from 'react-stripe-elements'
import { withRouter } from 'react-router-dom'

import { withNotifications } from '../../util'
import { CreditCardForm } from '../../util/stripe/components'
import {
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
} from '../../util/apollo/queries/payments'

const styles = theme => ({
  creditCardYear: {
    display: 'inline-block',
    width: 100,
    marginLeft: theme.spacing(2),
  },
  dialogContent: {
    width: 400,
    overflow: 'hidden',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  expirationText: {
    marginTop: theme.spacing(2),
  },
  liabilityWarning: {
    margin: theme.spacing(1),
  },
  monthSelect: {
    width: 100,
  },
  pushToRight: {
    marginLeft: 'auto',
  },
})

const AddPaymentMethod = ({
  classes,
  client,
  clientuuid,
  noButton,
  paymentIcon,
  notify,
  setPaymentMethod,
  stripe,
}) => {
  const defaultModalValues = {
    accountType: 'ach',
    accountHolderName: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    nameOnCard: '',
    cardNumber: '',
    expirationDateMonth: '1',
    expirationDateYear: '',
    addressName: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
    addressCountry: 'US',
  }
  const [open, setOpenState] = useState(!!noButton)
  const [formControl, setFormControl] = useState(defaultModalValues)

  const setOpen = openState => {
    setOpenState(openState)
    //reset the model on close
    if (!openState) {
      //giving the model time to close before reseting values
      setTimeout(() => {
        setFormControl(defaultModalValues)
      }, 200)
    }
  }

  const updateState = e => {
    // Intentional pattern below to cache target for asynchronous state update
    // since React reuses the event object for SyntheticEvents
    const target = e.target
    setFormControl(prev => ({ ...prev, [target.name]: target.value }))
  }

  const createPaymentMethod = async e => {
    e.preventDefault()

    if (stripe) {
      let variables = {
        paymentMethodType: formControl.accountType,
        client: clientuuid,
        primary: false,
        status: 'active',
      }

      switch (formControl.accountType) {
        case 'invoice':
          variables = {
            ...variables,
            addressLine1: formControl.addressLine1,
            addressLine2: formControl.addressLine2,
            addressCity: formControl.addressCity,
            addressState: formControl.addressState,
            addressZip: formControl.addressZip,
          }
          break
        case 'credit_card':
          // eslint-disable-next-line no-case-declarations
          let { token: stripeToken, error } = await stripe
            .createToken({
              name: formControl.nameOnCard,
              address_line1: formControl.addressLine1,
              address_line2: formControl.addressLine2,
              address_city: formControl.addressCity,
              address_state: formControl.addressState,
              address_zip: formControl.addressZip,
              address_country: 'US',
              currency: 'usd',
            })
            .then(result => result)
            .catch(e => alert('Error generating stripe token\n' + e))

          if (error) return alert('Error generating stripe token\n' + error)

          variables = {
            ...variables,
            stripeToken: stripeToken.id,
            addressLine1: formControl.addressLine1,
            addressLine2: formControl.addressLine2,
            addressCity: formControl.addressCity,
            addressState: formControl.addressState,
            addressZip: formControl.addressZip,
          }
          break

        case 'ach':
          // eslint-disable-next-line no-case-declarations
          let { token: achStripeToken, error: achStripeError } = await stripe
            .createToken('bank_account', {
              country: 'US',
              currency: 'usd',
              routing_number: formControl.routingNumber,
              account_number: formControl.accountNumber,
              account_holder_name: formControl.accountHolderName,
              account_holder_type: 'individual',
            })
            .then(result => result)
            .catch(e => alert('Error generating stripe token\n' + e))

          if (achStripeError)
            return alert('Error generating stripe token\n' + achStripeError)

          variables = {
            ...variables,
            stripeToken: achStripeToken.id,
          }
          break
      }

      await client
        .mutate({
          mutation: CREATE_PAYMENT_METHOD,
          variables,
          refetchQueries: [
            {
              query: GET_PAYMENT_METHODS,
              variables: { clientuuid },
            },
            { query: GET_PAYMENT_METHODS },
          ],
        })
        .then(({ data }) => {
          if (data.createPaymentmethod.ok) {
            notify.default('Payment Method Created')
            setPaymentMethod &&
              setPaymentMethod(data.createPaymentmethod.paymentMethod.uuid)
            setOpen(false)
          }
        })
        .catch(e => alert(e))
    }
  }

  const addressForm = (
    <>
      <Typography variant="subtitle1">Billing Address</Typography>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <TextField
            name="addressLine1"
            label="Address Line 1"
            value={formControl.addressLine1}
            onChange={updateState}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="addressLine2"
            label="Unit/Apt"
            value={formControl.addressLine2}
            onChange={updateState}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="addressCity"
            label="City"
            value={formControl.addressCity}
            onChange={updateState}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="addressState"
            label="State"
            value={formControl.addressState}
            onChange={updateState}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="addressZip"
            label="Zip"
            value={formControl.addressZip}
            onChange={updateState}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </>
  )

  const cardForm = (
    <>
      <Typography variant="subtitle1">Card Information</Typography>
      <TextField
        name="nameOnCard"
        label="Name On Card"
        value={formControl.nameOnCard}
        onChange={updateState}
        margin="normal"
        variant="outlined"
        fullWidth
        required
      />
      <CreditCardForm />
    </>
  )
  const buttonComponent = () => {
    if (paymentIcon) {
      return (
        <Tooltip title="Update Payment Method">
          <IconButton
            className={classes.pushToRight}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <PaymentIcon />
          </IconButton>
        </Tooltip>
      )
    } else if (!noButton) {
      return (
        <Button
          className={classes.pushToRight}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Payment
        </Button>
      )
    } else return ''
  }

  return (
    <>
      {buttonComponent()}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Payment Method</DialogTitle>
        <Divider />
        <form onSubmit={createPaymentMethod}>
          <DialogContent className={classes.dialogContent}>
            <TextField
              name="accountType"
              label="Account Type"
              value={formControl.accountType}
              onChange={updateState}
              margin="normal"
              variant="outlined"
              fullWidth
              required
              select
            >
              <MenuItem value="ach">Checking</MenuItem>
              <MenuItem value="credit_card">Credit Card</MenuItem>
              <MenuItem value="invoice">Invoice</MenuItem>
            </TextField>
            {formControl.accountType === 'ach' && (
              <>
                {[
                  [`Account Holder's Name`, 'accountHolderName'],
                  ['Routing Number', 'routingNumber'],
                  ['Account Number', 'accountNumber'],
                  ['Confirm Account Number', 'confirmAccountNumber'],
                ].map(field => (
                  <TextField
                    key={field[1]}
                    name={field[1]}
                    label={field[0]}
                    value={formControl[field[1]]}
                    onChange={updateState}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                ))}
                <Typography
                  color="textSecondary"
                  className={classes.liabilityWarning}
                >
                  {`By linking your bank account, you're instructing PlaceIt to verify
              your bank account information with a consumer reporting agency for
              fraud and risk prevention purposes. This will not affect your
              credit score.`}
                </Typography>
              </>
            )}
            {formControl.accountType === 'credit_card' && (
              <>
                <Divider className={classes.divider} />
                {cardForm}
                <Divider className={classes.divider} />
                {addressForm}
              </>
            )}
            {formControl.accountType === 'invoice' && addressForm}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save Payment Method
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

AddPaymentMethod.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object,
  match: PropTypes.object,
  noButton: PropTypes.bool,
  paymentIcon: PropTypes.bool,
  stripe: PropTypes.object,
  notify: PropTypes.object,
  setPaymentMethod: PropTypes.func,
  clientuuid: PropTypes.string,
}

export default compose(
  withRouter,
  withStyles(styles),
  withApollo,
  injectStripe,
  withNotifications
)(AddPaymentMethod)
