import React, { useState } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Paper,
  Button,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import PropTypes from 'prop-types'

let styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    boxShadow: 'none',
  },
  alignRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  button_alert: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#f35d66',
  },
  caption: {
    color: '#888',
  },
  center: {
    alignItems: 'center',
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  iconButton: {
    button: {
      width: 36,
      height: 36,
    },
  },
  info: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  infoSingle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  infoContainer: {
    padding: theme.spacing(3),
  },
  halfPageModal: {
    height: '100%',
  },
  pushRight: {
    marginRight: 'auto',
  },
  textField: {
    width: '100%',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e0e0e0',
    },
  },
})

const GenericTableTwo = ({
  buttonItem,
  classes,
  columns,
  data,
  hover,
  title,
  pagination,
  options,
}) => {
  const [hasNext, setHasNext] = useState(true)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const clickPagination = direction => {
    const variables =
      direction == 'next'
        ? {
            first: pagination.count,
            last: null,
            endCursor: pagination.pageInfo.endCursor,
            startCursor: null,
          }
        : {
            first: null,
            last: pagination.count,
            endCursor: null,
            startCursor: pagination.pageInfo.startCursor,
          }
    pagination.fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousItems = fetchMoreResult[Object.keys(previousResult)[0]]
        const fetchMoreItems = fetchMoreResult[Object.keys(fetchMoreResult)[0]]
        const newData = fetchMoreItems.edges
        const pageInfo = fetchMoreItems.pageInfo
        setFirstLoad(false)
        if (direction == 'next') {
          setHasNext(pageInfo.hasNextPage)
          if (newData.length) setHasPrevious(true)
        }
        if (direction == 'previous') {
          setHasPrevious(pageInfo.hasPreviousPage)
          if (newData.length) setHasNext(true)
        }
        let returnObject = {}
        returnObject[Object.keys(previousResult)[0]] = {
          __typename: previousItems.__typename,
          edges: [...newData],
          pageInfo,
        }
        return newData.length ? returnObject : previousResult
      },
    })
  }

  return (
    <Grid item xs={12}>
      <Paper>
        {title ? (
          <div className={classes.header}>
            <Typography variant="h6">{title}</Typography>
            {buttonItem}
          </div>
        ) : null}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(
                (item, index) =>
                  item.label && <TableCell key={index}>{item.label}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow
                  className={classes.tableRow}
                  key={index}
                  hover={hover}
                  onClick={() => {
                    if (typeof options.onRowClick === 'function') {
                      options.onRowClick(row)
                    }
                  }}
                >
                  {columns.map(
                    (item, index) =>
                      item.label && (
                        <TableCell key={index} className={classes.tableCell}>
                          <div
                            className={
                              item.alignRight
                                ? classes.alignRight
                                : classes.center
                            }
                          >
                            {item.options && item.options.customBodyRender
                              ? item.options.customBodyRender(row[item.name])
                              : row[item.name]}
                          </div>
                        </TableCell>
                      )
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {(hasPrevious ||
          (firstLoad ? pagination.pageInfo.hasNextPage : hasNext)) && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                clickPagination('previous')
              }}
              disabled={!hasPrevious}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                clickPagination('next')
              }}
              disabled={firstLoad ? !pagination.pageInfo.hasNextPage : !hasNext}
            >
              Next
            </Button>
          </>
        )}
      </Paper>
    </Grid>
  )
}

GenericTableTwo.propTypes = {
  classes: PropTypes.object,
  click: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  history: PropTypes.object,
  hover: PropTypes.bool,
  title: PropTypes.string,
  buttonItem: PropTypes.element,
  token: PropTypes.string,
  options: PropTypes.object,
  pagination: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(GenericTableTwo)
