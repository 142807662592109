import React from 'react'
import PropTypes from 'prop-types'

import { useQuery } from 'react-apollo'
import { withStyles } from '@material-ui/core'

import { MODERATOR_GET_CONTRACTS } from '../../../../util/apollo/queries/contracts'
import {
  capitalizationCase,
  formatCurrency,
  formatDate,
} from '../../../../shared/helperfunctions'
import GenericTableTwo from '../../../../shared/components/GenericTableTwo'

const styles = () => ({
  root: {},
})

const parseData = data =>
  data.allContracts.edges
    .map(({ node }) => node)
    .map(
      ({
        uuid,
        campaign: {
          client: {
            company: { name: buyerName },
          },
        },
        sellerName,
        startDate,
        actualBuyerPrice,
        buyerOwe,
        sellerOwe,
        stage,
      }) => ({
        uuid,
        buyerName,
        sellerName,
        startDate: formatDate(startDate, '-'),
        actualBuyerPrice: formatCurrency(actualBuyerPrice),
        buyerOwe: formatCurrency(buyerOwe),
        sellerOwe: formatCurrency(sellerOwe),
        stage: capitalizationCase(stage),
      })
    )

const columns = [
  { name: 'uuid', options: { display: 'excluded' } },
  { name: 'buyerName', label: 'Buyer' },
  { name: 'sellerName', label: 'Seller' },
  { name: 'startDate', label: 'Start Date' },
  { name: 'actualBuyerPrice', label: 'Buyer Price' },
  { name: 'buyerOwe', label: 'Buyer Balance' },
  { name: 'sellerOwe', label: 'Seller Balance' },
  { name: 'stage', label: 'Stage' },
]

const List = ({ history }) => {
  const onRowClick = row => {
    history.push(`/moderator/contracts/overview/${row.uuid}`)
  }
  const count = 10
  const { loading, error, data, fetchMore } = useQuery(
    MODERATOR_GET_CONTRACTS,
    {
      variables: {
        first: count,
        stageIn: 'inquiry,pre_flight,in_flight,complete,paused',
      },
    }
  )
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (!data) return 'Error'
  return (
    <GenericTableTwo
      data={parseData(data)}
      title="Contracts"
      columns={columns}
      pagination={{
        fetchMore,
        pageInfo: data.allContracts.pageInfo,
        count: count,
      }}
      options={{
        selectableRows: 'none',
        onRowClick,
      }}
    />
  )
}

List.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(List)
