import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  withStyles,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { Mutation } from 'react-apollo'

const styles = theme => ({
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#f35d66',
  },
})

const ConfirmDelete = ({
  classes,
  buttonLabel,
  MUTATION,
  MUTATION_VARIABLES,
}) => {
  const [open, openModal] = useState(false)

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    mutation({ variables: MUTATION_VARIABLES })
    openModal(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => openModal(true)}
      >
        {buttonLabel}
      </Button>
      <Dialog open={open} onClose={() => openModal(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <Divider />
        <Mutation mutation={MUTATION}>
          {mutation => (
            <form onSubmit={e => submitHandler(e, mutation)}>
              <DialogActions>
                <Button onClick={() => openModal(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Delete
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

ConfirmDelete.propTypes = {
  classes: PropTypes.object,
  buttonLabel: PropTypes.string,
  MUTATION: PropTypes.object,
  MUTATION_VARIABLES: PropTypes.object,
}

export default withStyles(styles)(ConfirmDelete)
