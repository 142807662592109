import React from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import {
  downloadFileOnClick,
  removeEdgesAndNodes,
  formatDate,
  titleCase,
  capitalizationCase,
  formatCurrency,
  snakeToTitleCase,
} from '../../helperfunctions'

let styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f7fa',
    padding: theme.spacing(4),
    boxShadow: 'none',
  },
  alignRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#0dbffe',
  },
  button_alert: {
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    color: '#f35d66',
  },
  caption: {
    color: '#888',
  },
  center: {
    alignItems: 'center',
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  iconButton: {
    button: {
      width: 36,
      height: 36,
    },
  },
  info: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  infoSingle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  infoContainer: {
    padding: theme.spacing(3),
  },
  halfPageModal: {
    height: '100%',
  },
  pushRight: {
    marginRight: 'auto',
  },
  textField: {
    width: '100%',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

const GenericTable = ({
  buttonItem,
  classes,
  click,
  columnInfo,
  data,
  history,
  hover,
  tableTitle,
}) => {
  /* const resolvePath = (obj = self, path, separator = '.') => {
    if (!path) return null
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
  } */

  Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    const a = s.split('.')
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const formatText = (str, style) => {
    let formattedStr = str
    if (str == null) {
      formattedStr = '-'
    } else if (style == 'date') {
      formattedStr = formatDate(str)
    } else if (style == 'title') {
      formattedStr = titleCase(str)
    } else if (style == 'capitalization') {
      formattedStr = capitalizationCase(str)
    } else if (style == 'currency') {
      formattedStr = formatCurrency(str)
    } else if (style == 'snake') {
      formattedStr = snakeToTitleCase(str)
    }
    return formattedStr
  }

  removeEdgesAndNodes(data)

  const clickUrl = (row, click) => {
    let url
    let i
    if (Array.isArray(click.dataName)) {
      url = click.url
      for (i = 0; i < click.dataName.length; i++) {
        url = url.replace(
          '{'.concat(click.dataName[i]).concat('}'),
          row[click.dataName[i]].toLowerCase()
        )
      }
    } else {
      url = click.url.replace(
        '{'.concat(click.dataName).concat('}'),
        row[click.dataName]
      )
    }
    return url
  }

  return (
    <Grid item xs={12}>
      {tableTitle ? (
        <div className={classes.header}>
          <Typography variant="h6">{tableTitle}</Typography>
          {buttonItem}
        </div>
      ) : null}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columnInfo.map((item, index) => (
              <TableCell key={index}>{item.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.edges.map((row, index) => {
            return (
              <TableRow
                className={classes.tableRow}
                key={index}
                hover={hover}
                onClick={() => {
                  if (click && click.url) {
                    if (click.isDownload) {
                      const url = click.url.replace(
                        '{'.concat(click.dataName).concat('}'),
                        row[click.dataName]
                      )
                      downloadFileOnClick(url, row.originalFileName)()
                    } else if (!click.url.toLowerCase().startsWith('http')) {
                      history.push(clickUrl(row, click))
                    }
                  }
                }}
              >
                {columnInfo.map((item, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    <div
                      className={
                        item.alignRight ? classes.alignRight : classes.center
                      }
                    >
                      {item.component ? item.component : null}
                      {item.decoratorFunc &&
                        item.decoratorFunc(Object.byString(row, item.dataName))}
                      {item.dataName &&
                        formatText(
                          Object.byString(row, item.dataName),
                          item.format
                        )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Grid>
  )
}

GenericTable.propTypes = {
  classes: PropTypes.object,
  click: PropTypes.object,
  columnInfo: PropTypes.array,
  data: PropTypes.object,
  history: PropTypes.object,
  hover: PropTypes.bool,
  tableTitle: PropTypes.string,
  buttonItem: PropTypes.element,
  token: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withRouter
)(GenericTable)
