import React from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import clsx from 'clsx'
import { baseUrl } from '../../../../../util/apollo/config'

import { UploadFile } from '../../../../../shared/dialogs'
import {
  formatDate,
  removeEdgesAndNodes,
  downloadFileOnClick,
} from '../../../../../shared/helperfunctions'

const GET_FILEITEMS = gql`
  query($contract: UUID) {
    allContracts(uuid: $contract) {
      edges {
        node {
          fileItem {
            edges {
              node {
                uuid
                originalFileName
                createdDate
                source
                type
                description
              }
            }
          }
        }
      }
    }
  }
`

const Files = ({ classes, contract }) => {
  const { loading, error, data } = useQuery(GET_FILEITEMS, {
    variables: { contract },
  })
  if (loading) return 'Loading...'
  if (error) return alert(error.message)
  removeEdgesAndNodes(data.allContracts)
  return (
    <Paper className={clsx(classes.itemRoot, classes.halfPageModal)}>
      <div className={classes.header}>
        <Typography variant="h6">Files</Typography>
        <UploadFile
          fileTypes={[
            'creative',
            'creative_proof',
            'proof_of_delivery',
            'unsigned_contract',
            'other',
          ]}
          refetchQueries={[
            {
              query: GET_FILEITEMS,
              variables: { contract },
            },
          ]}
          variables={{ contractUUID: contract }}
          customButton={props => (
            <Tooltip title="Upload Creative">
              <IconButton className={classes.button} {...props}>
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.allContracts.edges[0].fileItem.edges.map(
            ({ uuid, originalFileName, createdDate, description, type }) => (
              <TableRow key={uuid}>
                <TableCell>{originalFileName}</TableCell>
                <TableCell>{formatDate(createdDate)}</TableCell>
                <TableCell>{type.replace('_', ' ').toLowerCase()}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell padding="none">
                  <IconButton
                    onClick={downloadFileOnClick(
                      baseUrl + '/api/rest/v1/file/download/r/' + uuid,
                      originalFileName
                    )}
                  >
                    <SaveAltIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

Files.propTypes = {
  classes: PropTypes.object,
  contract: PropTypes.string,
}

export default withRouter(Files)
