import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  withStyles,
  TextField,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const SNOOZE_ALERT = gql`
  mutation snoozeAlert($alertUUID: String!, $date: DateTime!, $note: String!) {
    updateAlertactivity(
      input: { uuid: $alertUUID, notificationDate: $date, note: $note }
    ) {
      ok
    }
  }
`

const SnoozeDialog = ({ classes, closeParentMenu, reason, uuid }) => {
  const [note, setNote] = useState('')
  const [date, setDate] = useState(null)
  const [open, openModal] = useState(false)

  const closeHandler = () => {
    openModal(false)
    closeParentMenu()
  }

  const openHandler = () => {
    openModal(true)
    // closeParentMenu()
  }

  const submitHandler = (e, snoozeAlert) => {
    e.preventDefault()

    const variables = {
      alertUUID: uuid,
      date: new Date(date),
      note,
    }
    snoozeAlert({ variables: variables })
    closeHandler()
  }

  return (
    <React.Fragment>
      <MenuItem onClick={openHandler}>Snooze</MenuItem>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle>Snooze - {reason}</DialogTitle>
        <Divider />
        <Mutation mutation={SNOOZE_ALERT}>
          {snoozeAlert => (
            <form onSubmit={e => submitHandler(e, snoozeAlert)}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Note"
                      multiline
                      rows="4"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={e => setNote(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Date"
                      type="date"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      variant="outlined"
                      onChange={e => setDate(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeHandler} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Snooze
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

SnoozeDialog.propTypes = {
  reason: PropTypes.string,
  classes: PropTypes.object,
  closeParentMenu: PropTypes.func,
  uuid: PropTypes.string,
}

export default withStyles(styles)(SnoozeDialog)
