import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, Grid } from '@material-ui/core'

import ConversationsPanel from './ConversationsPanel'
import Header from './Header'
import MessagesPanel from './MessagesPanel'
import SendMessageBar from './Footer/SendMessageBar'

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
  },
  panel: {
    height: '100%',
    minHeight: '0',
  },
  messagesContainer: {
    height: '100%',
    flexGrow: 1,
    minHeight: '0',
  },
  messagesPanel: {
    minHeight: '0',
    flexGrow: 1,
    overflowY: 'auto',
  },
})

const Messages = ({
  classes,
  history,
  match: {
    params: { filter, channeluuid },
    url,
  },
}) => {
  if (!filter) history.push(url + '/all')

  return (
    <div className={classes.root}>
      <Header channeluuid={channeluuid} />
      <Grid container className={classes.panel} spacing={0}>
        <Grid item xs={4} className={classes.panel}>
          <ConversationsPanel className={classes.conversations} />
        </Grid>
        <Grid item xs={8} className={classes.panel}>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.messagesContainer}
          >
            <Grid item className={classes.messagesPanel}>
              <MessagesPanel channeluuid={channeluuid} />
            </Grid>
            <Grid item className={classes.sendBarPanel}>
              <SendMessageBar channeluuid={channeluuid} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

Messages.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Messages)
