import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useMutation, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import clsx from 'clsx'

import {
  CREATE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_DETAILS,
  UPDATE_CLIENT,
} from '../../../../util/apollo/queries/clients'
import { withNotifications } from '../../../../util'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  clickable: {
    display: 'flex',
    cursor: 'pointer',
  },
  editIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1.2rem',
  },
})

const Details = ({
  classes,
  client,
  history,
  match,
  match: {
    params: { clientuuid },
  },
  notify,
  useruuid,
}) => {
  const creatingClient = clientuuid === 'new'

  const [createMutation] = useMutation(CREATE_CLIENT)
  const [updateMutation] = useMutation(UPDATE_CLIENT)

  const [name, setName] = useState('')
  const onChange = ({ target: { value } }) => setName(value)

  const [editingName, setEditingName] = useState(false)
  const changeToEditForm = () => setEditingName(true)

  const [showEditIcon, setShowEditIcon] = useState(false)
  const updateEditIconVisibility = state => () => setShowEditIcon(state)

  const [fetched, setFetched] = useState(false)

  if (!creatingClient && !fetched) {
    client
      .query({ query: GET_CLIENT_DETAILS, variables: { clientuuid } })
      .then(({ data: { allClients } }) => {
        setFetched(true)
        setName(allClients.edges[0].node.name)
      })
  }

  const updateClient = () => (creatingClient ? createClient() : saveNewName())

  const createClient = () => {
    createMutation({
      variables: { name },
      refetchQueries: [{ query: GET_CLIENTS, variables: { useruuid } }],
    }).then(({ data: { createClient: { ok, client: { uuid } } } }) => {
      if (ok) {
        const modifiedUrl = match.url.split('/')
        modifiedUrl[4] = uuid
        history.push(modifiedUrl.join('/'))
        notify.success('Created')
      }
    })
  }
  const saveNewName = () => {
    updateMutation({ variables: { name, clientuuid } }).then(({ data }) => {
      if (data.updateClient.ok) {
        notify.success('Saved')
        setEditingName(false)
      }
    })
  }

  if (creatingClient || editingName)
    return (
      <Paper className={classes.root}>
        <Typography variant="h6">Details</Typography>
        <TextField
          variant="outlined"
          label="Client Name"
          value={name}
          onChange={onChange}
          margin="normal"
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={updateClient}>
          {creatingClient ? 'Create' : 'Save'}
        </Button>
      </Paper>
    )

  return (
    <Paper
      className={clsx(classes.root, classes.clickable)}
      onClick={changeToEditForm}
      onMouseEnter={updateEditIconVisibility(true)}
      onMouseLeave={updateEditIconVisibility(false)}
    >
      <div>
        <Typography variant="caption" color="textSecondary">
          Name
        </Typography>
        <Typography variant="h6">{name}</Typography>
      </div>
      {showEditIcon && <EditIcon className={classes.editIcon} />}
    </Paper>
  )
}

const mapStateToProps = state => ({
  useruuid: state.userInfo.uuid,
})

Details.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object,
  client: PropTypes.object,
  useruuid: PropTypes.string,
}

export default compose(
  withStyles(styles),
  withNotifications,
  connect(mapStateToProps),
  withApollo
)(Details)
