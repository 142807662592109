import React, { useMemo, useState } from 'react'
import { PropTypes } from 'prop-types'

import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import { baseUrl } from '../../../../util/apollo/config'

import { ProductMap } from '../../../../shared/components'
import {
  GET_PRODUCT_DETAIL,
  ADD_STARRED_PRODUCT,
  REMOVE_STARRED_PRODUCT,
  GET_STARRED_PRODUCTS,
  GET_PRODUCTS_SINGULAR,
} from '../../../../util/apollo/queries/products'
import {
  removeEdgesAndNodes,
  downloadFileOnClick,
} from '../../../../shared/helperfunctions'
import MarkdownDisplay from '../../../../shared/components/MarkdownDisplay'

import ProductImage from '../../Search/Products/Product/ProductImage'
import PurchaseBox from './PurchaseBox'
import AskAQuestion from './AskAQuestion'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  backLink: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  content: {
    padding: theme.spacing(3),
  },
  downloadLink: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(3),
    textDecoration: 'none',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 375px',
    gridGap: `${theme.spacing(4)}px`,
    maxWidth: theme.spacing(4) + 375 + 625,
  },
  headlineImgContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 375,
    justifyContent: 'center',
    position: 'relative',
  },
  permMediaIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
  },
  questionButton: {
    marginRight: theme.spacing(2),
  },
  saveContainer: {
    alignItems: 'center',
    background: theme.palette.background.paper,
    display: 'flex',
    position: 'absolute',
    right: 20,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    top: 20,
    width: 108,
  },
  spaceAround: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  starIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
})

const ProductDetails = ({ classes, match }) => {
  const [saved, setSaved] = useState(false)
  const { uuid, campaignuuid: campaign } = useParams()

  const { loading, error, data } = useQuery(GET_PRODUCT_DETAIL, {
    variables: { productUUID: uuid, campaign },
  })
  const mutation = useMemo(
    () => (saved ? REMOVE_STARRED_PRODUCT : ADD_STARRED_PRODUCT),
    [saved]
  )
  const [addOrRemoveStarredProduct] = useMutation(mutation, {
    refetchQueries: [
      {
        query: GET_STARRED_PRODUCTS,
        variables: {
          campaign: campaign,
        },
      },
      {
        query: GET_PRODUCTS_SINGULAR,
        variables: {
          uuid: uuid,
          campaign: campaign,
        },
      },
    ],
    variables: {
      campaign,
      product: uuid,
    },
  })

  if (loading) return 'Loading....'
  if (error) return error.message

  removeEdgesAndNodes(data.allProducts)
  const product = data.allProducts.edges[0]
  const {
    productLocation: { edges: productLocation },
  } = product
  if (product.starred != saved) {
    setSaved(product.starred)
  }
  const fileItems = removeEdgesAndNodes(product.fileItem)

  const productSizes = removeEdgesAndNodes(product.productSize)

  const imageuuid = fileItems.edges.filter(
    ({ type }) => type === 'PRODUCT_IMAGE'
  )[0].uuid

  return (
    <div className={classes.root}>
      <div>
        {match.path ==
          '/:usertype(customer)/:campaignuuid?/products/details/:uuid' && (
          <Link
            to={
              match.url
                .split('/')
                .slice(0, 3)
                .join('/') + '/search'
            }
            className={classes.backLink}
          >
            <ArrowBackIcon /> Back to search
          </Link>
        )}
        <div className={classes.grid}>
          <div className={classes.leftColumn}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.headlineImgContainer}>
                  <Button
                    variant="contained"
                    className={classes.saveContainer}
                    onClick={() => addOrRemoveStarredProduct(mutation)}
                  >
                    {saved ? (
                      <StarIcon className={classes.starIcon} />
                    ) : (
                      <StarBorderIcon className={classes.starIcon} />
                    )}
                    <Typography variant="subtitle1" display="inline">
                      {saved ? 'Saved' : 'Save '}
                    </Typography>
                  </Button>
                  <ProductImage uuid={imageuuid} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.content}>
                  <Typography variant="h4">{product.title}</Typography>
                  <div className={classes.spaceAround}>
                    <AskAQuestion currentProduct={product.uuid} />
                    <Typography display="inline" color="primary">
                      {false && '1 day average response time'}
                    </Typography>
                  </div>
                  <MarkdownDisplay markdown={product.description} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" className={classes.content}>
                    Pricing/CPM Details
                  </Typography>
                  <Divider />
                  <MarkdownDisplay markdown={product.priceDescription} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" className={classes.content}>
                    Demographics
                  </Typography>
                  <Divider />
                  <MarkdownDisplay markdown={product.demographic} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" className={classes.content}>
                    Logistics
                  </Typography>
                  <Divider />
                  <Typography className={classes.content}>
                    {product.logistics}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" className={classes.content}>
                    Ad Content Restrictions
                  </Typography>
                  <Divider />
                  <Typography className={classes.content}>
                    {product.contentRestriction}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" className={classes.content}>
                    Downloads
                  </Typography>
                  {fileItems.edges
                    .filter(item => item.type != 'PRODUCT_IMAGE')
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        <Divider />
                        <div
                          onClick={downloadFileOnClick(
                            baseUrl +
                              '/api/rest/v1/file/download/r/' +
                              item.uuid,
                            item.originalFileName
                          )}
                          className={classes.downloadLink}
                        >
                          <PermMediaIcon className={classes.permMediaIcon} />
                          <Typography display="inline">
                            {item.description}
                          </Typography>
                        </div>
                      </React.Fragment>
                    ))}
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div className={classes.rightColumn}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProductMap productLocation={productLocation} />
              </Grid>
              <Grid item xs={12}>
                {match.params.usertype == 'customer' && (
                  <PurchaseBox
                    productSizes={productSizes}
                    campaign={campaign}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(ProductDetails)
