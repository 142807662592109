import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Mutation } from 'react-apollo'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { withRouter } from 'react-router-dom'

import { DELETE_FILEITEM } from '../../../../../../../../util/apollo/queries/products'

// eslint-disable-next-line no-unused-vars
const EditDeleteMenu = ({ uuid, productUUID, refetchQueries }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const deleteHandleClose = async (event, mutation) => {
    const variables = { productUUID, fileItemArray: [uuid] }
    await mutation({ variables })
    handleClose(event)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = event => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  return (
    <div>
      <Mutation mutation={DELETE_FILEITEM} refetchQueries={refetchQueries}>
        {(deleteItem, { error }) => {
          if (error) alert(error)
          return (
            <>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                <MenuItem
                  onClick={event => {
                    deleteHandleClose(event, deleteItem)
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        }}
      </Mutation>
    </div>
  )
}

EditDeleteMenu.propTypes = {
  uuid: PropTypes.string,
  productUUID: PropTypes.string,
  refetchQueries: PropTypes.array,
}

export default withRouter(EditDeleteMenu)
