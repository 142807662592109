// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

const styles = () => ({
  root: {
    boxSizing: 'border-box',
    cursor: 'text',
    height: 56,
    padding: '18.5px 14px',
    width: '100%',
  },
})

const StripeInput = ({
  classes: c,
  theme,
  component: Component,
  onFocus,
  onBlur,
  onChange,
}) => {
  return (
    <Component
      className={c.root}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      placeholder=""
      style={{
        base: {
          fontSize: `${theme.typography.fontSize}px`,
          fontFamily: theme.typography.fontFamily,
          color: '#000000de',
        },
      }}
    />
  )
}

StripeInput.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
}

StripeInput.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
}

export default withStyles(styles, { withTheme: true })(StripeInput)
