import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'

const CALL_CONTACT = gql`
  mutation callContact($contactPhone: String!) {
    createPhonecall(input: { phone: $contactPhone })
  }
`

const GET_CURRENT_USER_PHONE = gql`
  query {
    currentUser {
      uuid
      email
      firstName
      lastName
      phone
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
    }
  }
`

const CallDialog = ({
  closeParentMenu,
  contactName,
  contactPhone,
  contactType,
}) => {
  const [open, openModal] = useState(false)
  const [currentUserPhone, setCurrentUserPhone] = useState(null)

  const closeHandler = () => {
    openModal(false)
    closeParentMenu()
  }

  const openHandler = () => {
    openModal(true)
  }

  // eslint-disable-next-line no-unused-vars
  const submitHandler = (e, callContact) => {
    e.preventDefault()
    const { contactPhone, contactName } = this.state

    const variables = { contactPhone, contactName }
    callContact({ variables: variables })
    closeHandler()
  }

  return (
    <React.Fragment>
      <MenuItem onClick={openHandler} disabled={!contactPhone}>
        {`Call ${contactType}`}
      </MenuItem>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle>Connect call with {contactName}?</DialogTitle>
        <Divider />
        <Mutation mutation={CALL_CONTACT}>
          {callContact => (
            <form onSubmit={e => submitHandler(e, callContact)}>
              <DialogContent>
                <Typography>
                  {`${contactPhone || '<No Phone Number>'} 
                    (${contactType || '<No Contact>'})`}
                </Typography>
                <Typography>
                  <Query query={GET_CURRENT_USER_PHONE}>
                    {({ loading, error, data }) => {
                      if (loading) return '<Loading> (You)'
                      if (error) return '<Error> (You)'
                      if (currentUserPhone !== data.currentUser.phone)
                        setCurrentUserPhone(data.currentUser.phone)
                      return `${data.currentUser.phone ||
                        '<No Phone Number>'} (You)`
                    }}
                  </Query>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeHandler} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Call
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

CallDialog.propTypes = {
  closeParentMenu: PropTypes.func,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactType: PropTypes.string,
}

export default CallDialog
