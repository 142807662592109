import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import SnoozeIcon from '@material-ui/icons/Snooze'
import { withStyles, IconButton } from '@material-ui/core'

import GenericModalForm from '../../../../shared/components/forms/GenericModalForm'
import { formatDateUTCNoon } from '../../../../shared/helperfunctions'
import { SNOOZE_ALERT } from '../../../../util/apollo/queries/alertactivities'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const SnoozeForm = ({ uuid, refetchQueries }) => {
  const [openModal, setOpenModal] = useState(false)

  const setOpen = () => setOpenModal(true)

  return (
    <React.Fragment>
      <IconButton onClick={setOpen}>
        <SnoozeIcon />
      </IconButton>
      <Mutation mutation={SNOOZE_ALERT} refetchQueries={refetchQueries}>
        {(snoozeAlert, { error }) => {
          if (error) alert(error)

          return (
            <>
              <Formik
                onSubmit={values => {
                  let variables = {
                    ...values,
                    alertUUID: uuid,
                  }
                  variables.date = formatDateUTCNoon(variables.date)
                  snoozeAlert({
                    variables: variables,
                  })
                  setOpenModal(false)
                }}
                initialValues={{
                  note: '',
                  date: '',
                }}
              >
                {props => (
                  <GenericModalForm
                    {...props}
                    title={'Snooze'}
                    fields={[
                      {
                        name: 'note',
                        label: 'Note',
                        type: 'textfield-multiline',
                        rows: 3,
                      },
                      { name: 'date', label: 'Date', type: 'date' },
                    ]}
                    openState={openModal}
                    openModal={setOpenModal}
                  />
                )}
              </Formik>
            </>
          )
        }}
      </Mutation>
    </React.Fragment>
  )
}

SnoozeForm.propTypes = {
  classes: PropTypes.object,
  uuid: PropTypes.string,
  refetchQueries: PropTypes.array,
}

export default withStyles(styles)(SnoozeForm)
