import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { DateTime } from 'luxon'

import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'

import { CREATE_NEW_CONTRACT } from '../../../../../util/apollo/queries/contracts'
import { withNotifications } from '../../../../../util/'

const Component = ({
  buyerPaymentList,
  contract: {
    productSize: {
      product: { title: productTitle },
      title: productSizeTitle,
    },
  },
  form: {
    description,
    endDate,
    sellerid,
    startDate,
    signingDueDate,
    creativeDueDate,
    creativeProofDueDate,
    deliveryProofDueDate,
  },
  history,
  match: {
    params: { contractuuid },
  },
  notify,
  sellerPaymentList,
  totals,
}) => {
  const [create_mutation] = useMutation(CREATE_NEW_CONTRACT)

  let otherDeadlineList = [
    {
      dueDate: signingDueDate && DateTime.fromISO(signingDueDate).toJSDate(),
      type: 'contract_signing_due',
      description: 'Signing Due',
    },
    {
      dueDate: creativeDueDate && DateTime.fromISO(creativeDueDate).toJSDate(),
      type: 'creative_due',
      description: 'Creative Due',
    },
    {
      dueDate:
        creativeProofDueDate &&
        DateTime.fromISO(creativeProofDueDate).toJSDate(),
      type: 'creative_proof_due',
      description: 'Creative Proof Due',
    },
    {
      dueDate:
        deliveryProofDueDate &&
        DateTime.fromISO(deliveryProofDueDate).toJSDate(),
      type: 'proof_of_delivery_due',
      description: 'Delivery Proof Due',
    },
  ].filter(({ dueDate }) => !!dueDate)

  // Handlers for saving contract
  const saveHandler = () =>
    create_mutation({
      variables: {
        contractuuid,
        buyerAmount: totals.buyer,
        sellerAmount: totals.seller,
        sellerid,
        startDate: DateTime.fromISO(startDate).toJSDate(),
        endDate: DateTime.fromISO(endDate).toJSDate(),
        title: `${productTitle} (${productSizeTitle})`,
        description,
        buyerPaymentList,
        sellerPaymentList,
        otherDeadlineList,
      },
    }).then(({ data }) => {
      if (data.createContractterms.ok) {
        notify.success('Saved')
        history.push(`/moderator/contracts/overview/${contractuuid}`)
      }
    })

  return (
    <Button variant="contained" color="primary" onClick={saveHandler}>
      Save
    </Button>
  )
}

Component.propTypes = {
  buyerPaymentList: PropTypes.array,
  contract: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  notify: PropTypes.object,
  sellerPaymentList: PropTypes.array,
  totals: PropTypes.object,
}

export default compose(
  withNotifications,
  withRouter
)(Component)
