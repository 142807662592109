import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  makeStyles,
  CircularProgress,
  styled,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { useQuery } from 'react-apollo'
import { animateScroll } from 'react-scroll'

import { GET_MESSAGES } from '../../../../../../../util/apollo/queries/messages'

import Message from '../../../../../Messages/MessagesPanel/Message'

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    overflowY: 'scroll',
    maxHeight: '60vh',
  },
  fileSpacer: {
    height: theme.spacing(5),
    width: '100%',
  },
  noMessagesContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}))

const LoadingSpinner = styled(CircularProgress)(spacing)

export default function MessagesPanel({ channeluuid }) {
  const classes = useStyles()
  const { loading, error, data } = useQuery(GET_MESSAGES, {
    variables: { channeluuid },
  })

  // Scroll messages pane to bottom when we fetch messages
  useEffect(
    () =>
      animateScroll.scrollToBottom({
        containerId: 'messages-panel',
        duration: 1,
      }),
    [data]
  )

  if (loading) {
    return <LoadingSpinner marginX="auto" marginY={2} />
  }

  if (error) {
    return error
  }

  const messages = data.allMessageactivities.edges.map(({ node }) => node)
  return (
    <div className={classes.root} id="messages-panel">
      {!messages.length ? (
        <div className={classes.noMessagesContainer}>
          <Typography variant="body1" color="textSecondary">
            No messages
          </Typography>
        </div>
      ) : (
        messages.map(message => (
          <Message key={message.uuid} message={message} />
        ))
      )}
      <div className={classes.fileSpacer} />
    </div>
  )
}

MessagesPanel.propTypes = {
  channeluuid: PropTypes.string.isRequired,
}
