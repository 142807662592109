import gql from 'graphql-tag'

const REQUEST_CONTRACT = gql`
  mutation REQUEST_CONTRACT(
    $campaignUuid: String!
    $paymentMethodUuid: String!
  ) {
    requestContract(
      input: { campaign: $campaignUuid, paymentMethod: $paymentMethodUuid }
    ) {
      ok
    }
  }
`

const BUYER_CONTRACT_LIST = gql`
  query BUYER_CONTRACT_LIST(
    $campaignuuid: UUID
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
    $stage: String
    $stageIn: String
  ) {
    allContracts(
      campaign_Uuid: $campaignuuid
      stage: $stage
      stage_In: $stageIn
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uuid
          title
          startDate
          endDate
          actualBuyerPrice
          stage
          lateStatus
          productSize {
            product {
              mediaType
            }
          }
        }
      }
    }
  }
`

const MODERATOR_GET_CONTRACTS = gql`
  query MODERATOR_GET_CONTRACTS(
    $buyer: String
    $seller: String
    $stage: String
    $stageIn: String
    $endCursor: String
    $startCursor: String
    $first: Int
    $last: Int
  ) {
    allContracts(
      first: $first
      last: $last
      after: $endCursor
      before: $startCursor
      campaign_Client_Company_Name: $buyer
      productSize_Product_Seller_Name: $seller
      stage: $stage
      stage_In: $stageIn
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uuid
          sellerName
          title
          startDate
          endDate
          actualSellerPrice
          actualBuyerPrice
          buyerOwe
          sellerOwe
          stage
          campaign {
            client {
              company {
                name
              }
            }
          }
        }
      }
    }
  }
`

const GET_CONTRACT_DETAIL = gql`
  query GET_CONTRACT_DETAIL($contractuuid: UUID!) {
    allContracts(uuid: $contractuuid) {
      edges {
        node {
          uuid
          campaign {
            client {
              uuid
            }
          }
          title
          startDate
          endDate
          actualBuyerPrice
          stage
          paymentStatus
          contractSigningStatus
          contractDocumentUrl
          creativeUploaded
          buyerContractSigned
          creativeStatus
          preProductionProofStatus
          postProductionProofStatus
          productSize {
            product {
              uuid
            }
          }
          channel {
            uuid
          }
          contractDate {
            edges {
              node {
                uuid
                type
                dueDate
                description
              }
            }
          }
          invoice {
            uuid
            dueDate
            amount
          }
          payment {
            amount
          }
          fileItem {
            edges {
              node {
                originalFileName
                description
                source
                createdDate
                uuid
                type
              }
            }
          }
          preferredPaymentMethod {
            uuid
            description
            type
          }
        }
      }
    }
  }
`

const GET_CONTRACT_OVERVIEW = gql`
  query GET_CONTRACT_OVERVIEW($contractuuid: UUID!) {
    allContracts(uuid: $contractuuid) {
      edges {
        node {
          uuid
          title
          startDate
          endDate
          description
          sellerContractId
          invoice {
            dueDate
            amount
            company {
              type
            }
          }
          payment {
            uuid
            amount
          }
          contractDate {
            edges {
              node {
                dueDate
                type
                description
              }
            }
          }
          buyerContact {
            company {
              name
            }
          }
          productSize {
            title
            product {
              title
              seller {
                name
              }
            }
          }
        }
      }
    }
  }
`

const UPLOAD_FILE = gql`
  mutation UPLOAD_FILE($contractUUID: String!, $fileUUID: [String]) {
    uploadContractFile(
      input: { contract: $contractUUID, addFileItem: $fileUUID }
    ) {
      ok
    }
  }
`

const GET_CONTRACTNOTES = gql`
  query GET_CONTRACTNOTES($contract: UUID) {
    allContractnotes(contract_Uuid: $contract, status: "active") {
      edges {
        node {
          uuid
          description
          reminderDate
          status
          createdDate
        }
      }
    }
  }
`

const GET_CONTRACTURL = gql`
  query GET_CONTRACTURL($contract: UUID) {
    allContracts(uuid: $contract) {
      edges {
        node {
          contractDocumentUrl
        }
      }
    }
  }
`

const GET_TERMS = gql`
  query GET_TERMS($contract: UUID) {
    allContracts(uuid: $contract) {
      edges {
        node {
          uuid
          title
          startDate
          endDate
          stage
          actualBuyerPrice
          productSize {
            product {
              uuid
            }
          }
          contractDate {
            edges {
              node {
                type
                description
                dueDate
              }
            }
          }
          invoice {
            dueDate
            amount
            company {
              type
            }
          }
        }
      }
    }
  }
`

const DUPLICATE_CONTRACT = gql`
  mutation DUPLICATE_CONTRACT($contractuuid: String!) {
    duplicateContract(input: { contract: $contractuuid }) {
      ok
    }
  }
`

const CHANGE_CONTRACT_STATUS = gql`
  mutation CHANGE_CONTRACT_STATUS($contractuuid: String!, $status: String!) {
    disputeContract(input: { contract: $contractuuid, status: $status }) {
      ok
    }
  }
`

const CREATE_NEW_CONTRACT = gql`
  mutation CREATE_NEW_CONTRACT(
    $contractuuid: String!
    $buyerAmount: Float!
    $sellerAmount: Float!
    $sellerid: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $title: String!
    $description: String!
    $buyerPaymentList: [BuyerSellerPaymentNode]!
    $sellerPaymentList: [BuyerSellerPaymentNode]!
    $otherDeadlineList: [OtherDeadlineNode]
  ) {
    createContractterms(
      input: {
        contract: $contractuuid
        buyerAmount: $buyerAmount
        sellerAmount: $sellerAmount
        sellerContractId: $sellerid
        startDate: $startDate
        endDate: $endDate
        title: $title
        description: $description
        buyerPayment: $buyerPaymentList
        sellerPayment: $sellerPaymentList
        otherDeadline: $otherDeadlineList
      }
    ) {
      ok
    }
  }
`

const GET_CONTRACT_INFO_FOR_BUYER_CHANNEL = gql`
  query GET_CONTRACT_INFO_FOR_BUYER_CHANNEL($contractuuid: UUID!) {
    allContracts(uuid: $contractuuid) {
      edges {
        node {
          campaign {
            uuid
          }
          productSize {
            product {
              seller {
                uuid
              }
            }
          }
        }
      }
    }
  }
`

export {
  BUYER_CONTRACT_LIST,
  CHANGE_CONTRACT_STATUS,
  CREATE_NEW_CONTRACT,
  DUPLICATE_CONTRACT,
  GET_CONTRACTNOTES,
  GET_CONTRACT_DETAIL,
  GET_CONTRACT_OVERVIEW,
  GET_TERMS,
  MODERATOR_GET_CONTRACTS,
  REQUEST_CONTRACT,
  UPLOAD_FILE,
  GET_CONTRACTURL,
  GET_CONTRACT_INFO_FOR_BUYER_CHANNEL,
}
