import React from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements'
import { Grid } from '@material-ui/core'
import { StripeElementWrapper } from './'

const InjectedCardForm = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <StripeElementWrapper
          label="Card Number"
          component={CardNumberElement}
        />
      </Grid>
      <Grid item xs={7}>
        <StripeElementWrapper
          label="Expiry (MM / YY)"
          component={CardExpiryElement}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={4}>
        <StripeElementWrapper label="CVC" component={CardCVCElement} />
      </Grid>
    </Grid>
  )
}

InjectedCardForm.propTypes = {}

export default InjectedCardForm
