import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  withStyles,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import { Formik } from 'formik'

const styles = () => ({})

const ADD_PAYMENT = gql`
  mutation addPayment(
    $amount: Float!
    $paymentMethod: String!
    $campaignUUID: String!
    $clientUUID: String!
    $contractUUID: String!
    $checkNumber: String!
    $description: String!
  ) {
    createCharge(
      input: {
        amount: $amount
        paymentMethod: $paymentMethod
        client: $clientUUID
        contract: $contractUUID
        campaign: $campaignUUID
        checkNumber: $checkNumber
        description: $description
      }
    ) {
      ok
    }
  }
`

const AddPayment = ({
  accountType,
  accountTypeUUID,
  buttonProps,
  classes,
  customButton,
  config,
  description,
  payee,
  preferredPaymentMethod,
}) => {
  const [addPayment] = useMutation(ADD_PAYMENT)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Formik
      initialValues={{
        payee: payee ? payee : '',
        accountType: accountType ? accountType : '',
        paymentMethod: preferredPaymentMethod
          ? preferredPaymentMethod.uuid
          : '',
        checkNumber: '',
        description,
        type: 'payment',
      }}
      onSubmit={async (values, { resetForm }) => {
        const variables = {
          amount: values.amount,
          paymentMethod: values.paymentMethod,
          campaignUUID:
            values.accountType === 'campaign' ? accountTypeUUID : null,
          clientUUID: values.accountType === 'client' ? accountTypeUUID : null,
          contractUUID:
            values.accountType === 'contract' ? accountTypeUUID : null,
          checkNumber: values.checkNumber.length ? values.checkNumber : null,
          description: values.description.length ? values.description : null,
        }
        await addPayment({ variables })
        resetForm()
        setIsOpen(false)
      }}
    >
      {renderProps => {
        const { handleChange, values, resetForm } = renderProps
        return (
          <>
            {customButton ? (
              customButton({ onClick: () => setIsOpen(true) })
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setIsOpen(true)}
                {...buttonProps}
              >
                Add Payment
              </Button>
            )}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
              <DialogTitle>Make Payment</DialogTitle>
              <Divider />

              <DialogContent>
                <Grid container spacing={1}>
                  {config.payee && (
                    <Grid item xs={3}>
                      <TextField
                        value={values.payee}
                        name="payee"
                        label="Payee"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        disabled={config.payee.buyer || config.payee.seller}
                        select
                        fullWidth
                      >
                        <MenuItem value="buyer">Buyer</MenuItem>
                        <MenuItem value="seller">Seller</MenuItem>
                      </TextField>
                    </Grid>
                  )}

                  {config.type && (
                    <Grid item xs={4}>
                      <TextField
                        value={values.type}
                        name="type"
                        label="Payment Type"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        select
                        fullWidth
                      >
                        <MenuItem
                          disabled={!config.type.payment}
                          value="payment"
                        >
                          Payment
                        </MenuItem>
                        <MenuItem
                          disabled={!config.type.payment}
                          value="credit"
                        >
                          Credit
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}

                  {config.accountType && (
                    <Grid item xs={4}>
                      <TextField
                        value={values.accountType}
                        name="accountType"
                        label="Account Type"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        select
                        fullWidth
                      >
                        <MenuItem
                          disabled={!config.accountType.contract}
                          value="contract"
                        >
                          Contract
                        </MenuItem>
                        <MenuItem
                          disabled={!config.accountType.campaign}
                          value="campaign"
                        >
                          Campaign
                        </MenuItem>
                        <MenuItem
                          disabled={!config.accountType.client}
                          value="client"
                        >
                          Client
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}

                  {config.paymentMethod && (
                    <Grid item xs={12}>
                      <TextField
                        value={values.paymentMethod}
                        name="paymentMethod"
                        label="Payment Method"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        select
                        fullWidth
                      >
                        {preferredPaymentMethod ? (
                          config.preferredPaymentMethod.map(item => {
                            return (
                              <MenuItem key={item.uuid} value={item.uuid}>
                                {item.description}
                              </MenuItem>
                            )
                          })
                        ) : (
                          <MenuItem value="">-</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                  )}

                  {config.amount && (
                    <Grid item xs={6}>
                      <TextField
                        value={values.amount}
                        name="amount"
                        label="Amount"
                        placeholder="0.00"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {config.checkNumber && (
                    <Grid item xs={3}>
                      <TextField
                        value={values.checkNumber}
                        name="checkNumber"
                        label="Check Number"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {config.description && (
                    <Grid item xs={12}>
                      <TextField
                        value={values.description}
                        name="description"
                        label="Description"
                        required
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsOpen(false)
                    resetForm()
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="primary">
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }}
    </Formik>
  )
}

AddPayment.propTypes = {
  accountType: PropTypes.string,
  accountTypeUUID: PropTypes.string,
  buttonProps: PropTypes.object,
  config: PropTypes.object,
  customButton: PropTypes.func,
  classes: PropTypes.object,
  description: PropTypes.string,
  match: PropTypes.object,
  payee: PropTypes.string,
  preferredPaymentMethod: PropTypes.string,
}

export default withStyles(styles)(AddPayment)
