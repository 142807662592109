import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, Paper } from '@material-ui/core'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'

import GenericTable from '../../../../shared/components/GenericTable'

const GET_BUYER_STATEMENTS = gql`
  query($campaignUuid: UUID) {
    allStatements(campaign_Uuid: $campaignUuid) {
      edges {
        node {
          uuid
          createdDate
          dueDate
          status
        }
      }
    }
  }
`

const styles = () => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
  },
})

const Overview = ({ classes }) => {
  const { campaignuuid: currentCampaign } = useParams()
  const variables = {
    campaignUuid: currentCampaign,
  }

  const { loading, error, data } = useQuery(GET_BUYER_STATEMENTS, { variables })
  if (loading) return 'Loading...'
  if (error) return error.message
  return (
    <Paper className={classes.root}>
      <GenericTable
        tableTitle={'Invoices'}
        data={data.allStatements}
        hover
        click={{
          url: `/customer/${currentCampaign}/invoices/detail/{uuid}`,
          dataName: 'uuid',
        }}
        columnInfo={[
          { title: 'Date', dataName: 'dueDate', format: 'date' },
          { title: 'Status', dataName: 'status', format: 'title' },
        ]}
      />
    </Paper>
  )
}

Overview.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(Overview)
