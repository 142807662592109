import React from 'react'
import { compose } from 'recompose'

import { Route, Redirect, withRouter } from 'react-router'
import { connect } from 'react-redux'

import withNotifications from '../notifications'

const validAccessPathForUser = (userType, accessPath) =>
  (userType === 'buyer' && accessPath === ':usertype(customer)') ||
  (userType === 'moderator' && accessPath === ':usertype(moderator)')

const buyerWithNoCampaigns = (userType, campaigns) =>
  userType === 'buyer' && !campaigns.length

const allowedPathForNewBuyer = path => {
  const accesspoint = path.split('/')[3]
  if (accesspoint === 'settings' || accesspoint === 'client') return true
  return false
}

const AuthRoute = ({
  campaigns,
  component: Component,
  path,
  exact,
  history,
  location,
  notify,
  userType,
  tokens: { token },
}) => {
  if (token) {
    const accessPath = path.split('/')[1].toLowerCase()

    if (validAccessPathForUser(userType, accessPath)) {
      if (campaigns && buyerWithNoCampaigns(userType, campaigns))
        if (!allowedPathForNewBuyer(location.pathname)) {
          history.push('/customer/undefined/settings/clients')
          return null
        }

      return (
        <Route
          path={path}
          exact={exact}
          render={props => <Component {...props} />}
        />
      )
    }
    notify.error('Please login with an account with correct permissions')
    return <Redirect to="/auth/login" />
  }
  notify.error('No authorization token')
  return <Redirect to="/auth/login" />
}

const mapStateToProps = state => ({
  campaigns: state.campaigns,
  userType: state.userInfo.type,
  tokens: state.tokens,
})

export default compose(
  connect(mapStateToProps),
  withRouter,
  withNotifications
)(AuthRoute)
