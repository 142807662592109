import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress } from '@material-ui/core'

export default function LoadingSpinner({ className }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      className={className}
    >
      <CircularProgress />
    </Box>
  )
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
}
