import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  root: {},
  content: {
    marginBottom: theme.spacing(1),
    width: 350,
  },
})

const Component = ({ classes }) => {
  const [open, setOpen] = useState(true)
  const closeDialog = () => setOpen(false)

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Welcome to PlaceIt!</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>Begin by creating a client and campaign.</Typography>
        <Typography variant="caption">
          If you are a member of an organization and would like to join
          preexisting clients and campaigns you may select that option instead.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeDialog}>
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Component)
