import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, withStyles, Button } from '@material-ui/core'
import { useQuery } from 'react-apollo'

import { drawerWidth } from '../../../../shared/user-interface'
import {
  GET_PRODUCTS,
  GET_STARRED_PRODUCTS,
} from '../../../../util/apollo/queries/products'
import { filterPanelWidth } from '../Filter'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'

import Product from './Product'
import RequestConsultation from './RequestConsultation'

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    position: 'fixed',
    top: 64,
    bottom: 0,
    left: drawerWidth + filterPanelWidth,
    right: 0,
    overflowY: 'scroll',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100%',
  },
  productList: {
    height: '95vh',
    overflow: 'scroll',
    background: '#f6f7fb',
  },
})

const Products = ({ campaign, classes, filters }) => {
  const [showOnlyStarred, setShowOnlyStarred] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true) //this is to make it possible to check "hasNextPage" on the first load
  const query = useMemo(
    () => (!showOnlyStarred ? GET_PRODUCTS : GET_STARRED_PRODUCTS),
    [showOnlyStarred]
  )
  const queryVariables = useMemo(
    () =>
      !showOnlyStarred
        ? { ...filters, campaign, first: numberOfProductsToDisplay }
        : { campaign, first: numberOfProductsToDisplay },
    [showOnlyStarred, campaign, filters]
  )
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  })
  const numberOfProductsToDisplay = 10

  const clickPrevious = (fetchMore, startCursor) => {
    setFirstLoad(false)
    fetchMore({
      variables: {
        first: null,
        last: numberOfProductsToDisplay,
        endCursor: null,
        startCursor: startCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = !showOnlyStarred
          ? fetchMoreResult.allProducts.edges
          : fetchMoreResult.allSavedproducts.edges
        const pageInfo = !showOnlyStarred
          ? fetchMoreResult.allProducts.pageInfo
          : fetchMoreResult.allSavedproducts.pageInfo
        setHasPrevious(pageInfo.hasPreviousPage)
        if (newEdges.length) setHasNext(true)
        if (newEdges.length) {
          if (!showOnlyStarred) {
            return {
              allProducts: {
                __typename: previousResult.allProducts.__typename,
                edges: [...newEdges],
                pageInfo,
              },
            }
          } else {
            return {
              allSavedproducts: {
                __typename: previousResult.allSaveproducts.__typename,
                edges: [...newEdges],
                pageInfo,
              },
            }
          }
        } else return previousResult
      },
    })
  }

  const clickNext = (fetchMore, endCursor) => {
    setFirstLoad(false)
    fetchMore({
      variables: {
        first: numberOfProductsToDisplay,
        last: null,
        endCursor: endCursor,
        startCursor: null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = !showOnlyStarred
          ? fetchMoreResult.allProducts.edges
          : fetchMoreResult.allSavedproducts.edges
        const pageInfo = !showOnlyStarred
          ? fetchMoreResult.allProducts.pageInfo
          : fetchMoreResult.allSavedproducts.pageInfo
        setHasNext(pageInfo.hasNextPage)
        if (newEdges.length) setHasPrevious(true)
        if (newEdges.length) {
          if (!showOnlyStarred) {
            return {
              allProducts: {
                __typename: previousResult.allProducts.__typename,
                edges: [...newEdges],
                pageInfo,
              },
            }
          } else {
            return {
              allSavedproducts: {
                __typename: previousResult.allSaveproducts.__typename,
                edges: [...newEdges],
                pageInfo,
              },
            }
          }
        } else return previousResult
      },
    })
  }

  useEffect(() => {
    if (filters.starred) return setShowOnlyStarred(true)
    return setShowOnlyStarred(false)
  }, [filters.starred])

  useEffect(() => {
    setHasPrevious(false)
    setHasNext(true)
    setFirstLoad(true)
  }, [filters])

  if (loading) return <LoadingSpinner />
  if (error) {
    alert(error.message)
    return null
  }

  const products = showOnlyStarred
    ? data.allSavedproducts.edges.map(item => item.node.product)
    : data.allProducts.edges.map(item => item.node)
  // prettier-ignore
  const firstHasNext =
    (showOnlyStarred && firstLoad && data.allSavedproducts.pageInfo.hasNextPage) ||
    (!showOnlyStarred && firstLoad && data.allProducts.pageInfo.hasNextPage) ||
    false
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.filters} />
        <RequestConsultation currentCampaign={campaign} />
      </div>
      {/*<Typography variant="h6" color="textSecondary">
                  {products.length} Results
                </Typography>*/}
      <Grid container spacing={3} style={{ marginTop: 6 }}>
        <Grid item xs={12} />
        {products.map((product, i) => {
          return (
            <Grid item xs={12} key={i}>
              <Product product={product} campaignUuid={campaign} />
            </Grid>
          )
        })}
      </Grid>
      {(hasPrevious || hasNext) && (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() =>
              clickPrevious(
                fetchMore,
                !showOnlyStarred
                  ? data.allProducts.pageInfo.startCursor
                  : data.allSavedproducts.pageInfo.startCursor
              )
            }
            disabled={!hasPrevious}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              clickNext(
                fetchMore,
                !showOnlyStarred
                  ? data.allProducts.pageInfo.endCursor
                  : data.allSavedproducts.pageInfo.endCursor
              )
            }}
            disabled={firstLoad ? !firstHasNext : !hasNext}
          >
            Next
          </Button>
        </>
      )}
    </div>
  )
}

Products.propTypes = {
  campaign: PropTypes.string,
  classes: PropTypes.object,
  filters: PropTypes.object,
}

export default withStyles(styles)(Products)
