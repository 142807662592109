import React from 'react'

import { connect } from 'react-redux'

import { notifications } from '../redux/actions'

const mapDispatchToProps = dispatch => ({
  notify: {
    default: message => dispatch(notifications.notify(message)),
    success: message => dispatch(notifications.notifySuccess(message)),
    error: message => dispatch(notifications.notifyError(message)),
    warning: message => dispatch(notifications.notifyWarning(message)),
  },
})

const withNotifications = BaseComponent => {
  const ConnectedComponent = props => <BaseComponent {...props} />

  return connect(
    null,
    mapDispatchToProps
  )(ConnectedComponent)
}

export default withNotifications
