import React from 'react'
import PropTypes from 'prop-types'

import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { Typography, withStyles } from '@material-ui/core'
import clsx from 'clsx'

const styles = () => ({
  root: {
    position: 'relative',
  },
  chartTitle: {
    position: 'absolute',
    top: 13,
    left: 56,
  },
})

const View = ({ classes, className, title, data }) => {
  const colors = ['#5792ee', '#5ae0A4', '#ffdb41', '#858ea2', '#313241']

  if (data && JSON.stringify(data.labels) === JSON.stringify(['']))
    data.labels = ['no-title']
  if (!data) data = { labels: [], lines: [] }

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.chartTitle} variant="h6">
        {title}
      </Typography>
      <ResponsiveContainer width="99%" height={300}>
        <LineChart data={data.lines}>
          {data.labels.map((label, i) => (
            <Line
              name={label}
              dataKey={label}
              stroke={colors[i]}
              strokeWidth={2}
              type="linear"
              legendType="square"
              dot={false}
              key={i}
            />
          ))}
          <Legend
            verticalAlign="top"
            iconType="circle"
            wrapperStyle={{
              left: 24,
              paddingBottom: 16,
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="x" />
          <YAxis />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

View.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
}

export default withStyles(styles)(View)
