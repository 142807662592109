import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'

import { useQuery } from 'react-apollo'

import { GET_CAMPAIGN_LIST } from '../../../../util/apollo/queries/campaigns'

import CreateButton from './CreateButton'
import DeleteButton from './DeleteButton'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const Component = ({
  classes,
  match: {
    params: { clientuuid },
  },
  history,
}) => {
  const { data: { allCampaigns } = {} } = useQuery(GET_CAMPAIGN_LIST, {
    variables: { clientuuid },
    skip: clientuuid === 'new',
  })

  if (clientuuid === 'new') return null

  let campaigns = []
  if (allCampaigns) campaigns = allCampaigns.edges.map(({ node }) => node)

  const viewCampaignContracts = campaignuuid => () => {
    history.push(`/customer/${campaignuuid}/contracts`)
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Campaigns</Typography>
        <CreateButton />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Active Contracts</TableCell>
            <TableCell>createdDate</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.length ? (
            campaigns.map(
              ({
                uuid: campaignuuid,
                name,
                createdDate,
                status,
                contractNumberActive,
              }) => (
                <TableRow
                  key={campaignuuid}
                  onClick={viewCampaignContracts(campaignuuid)}
                  hover
                >
                  <TableCell>{name}</TableCell>
                  <TableCell>{status.toLowerCase()}</TableCell>
                  <TableCell>{contractNumberActive}</TableCell>
                  <TableCell>
                    {DateTime.fromISO(createdDate).toLocaleString(
                      DateTime.DATE_SHORT
                    )}
                  </TableCell>
                  <TableCell padding="none">
                    <DeleteButton campaignuuid={campaignuuid} />
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withStyles(styles)(Component)
