import React from 'react'
import { PropTypes } from 'prop-types'
import { useQuery } from 'react-apollo'

import Iframe from 'react-iframe'
import { withStyles } from '@material-ui/core'
import { GET_CONTRACTURL } from '../../../../util/apollo/queries/contracts'

const styles = () => ({
  root: {
    width: 'calc(100% + 60px)',
    margin: -32,
    height: 'calc(100% + 60px)',
  },
})

const EsignPage = ({
  classes,
  match: {
    params: { contractuuid },
  },
}) => {
  const { loading, error, data } = useQuery(GET_CONTRACTURL, {
    variables: { contractuuid },
  })
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  const contractUrl = data.allContracts.edges[0].node.contractDocumentUrl
  return (
    <div className={classes.root}>
      <Iframe
        url={contractUrl}
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
      />
    </div>
  )
}

EsignPage.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(EsignPage)
