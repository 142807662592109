import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Paper, Typography, withStyles } from '@material-ui/core'

import { snakeToTitleCase } from '../../../../../shared/helperfunctions'
import Circle from '../../../../../shared/components/IndicatorCircle'

import Buttons from './Buttons'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  leftContainer: {
    display: 'flex',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  statusIndicatorContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing(4),
  },
  titleContainer: {
    borderLeft: 'solid 1px black',
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
})

const indicatorColor = (stage, status, completed) => {
  if (status == 'ON_SCHEDULE' && !completed) {
    status = 'PENDING'
  }

  switch (stage) {
    case 'COMPLETE':
    case 'PAUSED':
    case 'CANCELED':
    case 'INQUIRY':
      return '#8f8c88'
    default:
      switch (status) {
        case 'ON_SCHEDULE':
          return '#00ff00'
        case 'COMPLETED':
          return '#00ff00'
        case 'PENDING':
          return '#ffa500'
        case 'LATE':
          return '#ff0000'
      }
  }
}

const Header = ({
  actualBuyerPrice,
  classes,
  channel,
  contractSigningStatus,
  contractDocumentUrl,
  creativeStatus,
  endDate,
  paymentStatus,
  product,
  stage,
  startDate,
  title,
  creativeUploaded,
  buyerContractSigned,
  invoice,
  paymentButton,
  fileItem,
}) => {
  startDate = DateTime.fromISO(startDate)
  endDate = DateTime.fromISO(endDate)
  actualBuyerPrice = 5000

  const paymentsStarted = () => {
    const minDate = new Date(
      Math.min.apply(null, invoice.map(item => DateTime.fromISO(item.dueDate)))
    )
    return new Date() > minDate ? true : false
  }
  return (
    <Paper className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.statusContainer}>
          <div className={classes.statusIndicatorContainer}>
            <Circle
              color={indicatorColor(
                stage,
                contractSigningStatus,
                buyerContractSigned
              )}
            />
            <Typography display="inline">Contract Signed</Typography>
          </div>
          <div className={classes.statusIndicatorContainer}>
            <Circle
              color={indicatorColor(stage, creativeStatus, creativeUploaded)}
            />
            <Typography>Creative</Typography>
          </div>
          <div className={classes.statusIndicatorContainer}>
            <Circle
              color={indicatorColor(stage, paymentStatus, paymentsStarted())}
            />
            <Typography>Payments</Typography>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <Typography variant="h5">{title}</Typography>
          {startDate && endDate && (
            <Typography>
              {startDate.toLocaleString(DateTime.DATE_SHORT)} -{' '}
              {endDate.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          )}
          {actualBuyerPrice && (
            <Typography>${actualBuyerPrice.toFixed(2)}</Typography>
          )}
          <Typography color="textSecondary">
            ({snakeToTitleCase(stage)})
          </Typography>
        </div>
      </div>
      <Buttons
        product={product}
        channel={channel}
        contractDocumentUrl={contractDocumentUrl}
        paymentButton={paymentButton}
        fileItem={fileItem}
      />
    </Paper>
  )
}

Header.propTypes = {
  actualBuyerPrice: PropTypes.number,
  classes: PropTypes.object,
  channel: PropTypes.array,
  contractSigningStatus: PropTypes.string,
  creativeStatus: PropTypes.string,
  paymentStatus: PropTypes.string,
  product: PropTypes.object,
  stage: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  title: PropTypes.string,
  contractDocumentUrl: PropTypes.string,
  creativeUploaded: PropTypes.bool,
  buyerContractSigned: PropTypes.bool,
  invoice: PropTypes.array,
  paymentButton: PropTypes.element,
  fileItem: PropTypes.object,
}

export default withStyles(styles)(Header)
