import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withApollo } from 'react-apollo'
import { useDropzone } from 'react-dropzone'

import { uploadFile, snakeToTitleCase } from '../helperfunctions'
import { UPLOAD_FILE } from '../../util/apollo/queries/contracts'

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    height: 44.5,
    boxSizing: 'border-box',
  },
  browseButton: {
    marginRight: theme.spacing(2),
    width: 100,
  },
  browseButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  dialogContent: {
    minHeight: 260,
  },
})

const UploadFile = ({
  buttonText,
  buttonProps,
  classes,
  client,
  customButton,
  fileTypes,
  mutation,
  paperclip,
  saveFileOnly,
  onComplete,
  refetchQueries,
  security,
  variables,
  token,
  onFileTypeChange = () => {},
}) => {
  const [open, openModalState] = useState(false)
  const [fileType, setFileType] = useState('')
  const [savedFilename, setSavedFilename] = useState('')
  const [description, setDescription] = useState('')
  const [hideDescription, setHideDescription] = useState(false)
  const [file, setFile] = useState(null)
  const [snackbarSuccess, setSnackbarSuccess] = useState(false)
  const [snackbarError, setSnackbarError] = useState(false)

  const defaultModelValues = () => {
    setFileType('')
    setSavedFilename('')
    setDescription('')
    setFile(null)
  }

  const openModal = openState => {
    openModalState(openState)
    //reset the model on close
    if (!openState) {
      //giving the model time to close before reseting values
      setTimeout(() => {
        defaultModelValues()
      }, 200)
    }
  }

  const openSuccessSnackbar = () => setSnackbarSuccess(true)
  const closeSuccessSnackbar = () => setSnackbarSuccess(false)
  const openErrorSnackbar = message => setSnackbarError(message)
  const closeErrorSnackbar = () => setSnackbarError(false)

  useEffect(() => {
    file && setSavedFilename(file.name)
  }, [file])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: acceptedFiles => setFile(acceptedFiles[0]),
    onDropRejected: () => alert('Accepted File Types are: '),
  })

  const onClickFileTypeChange = e => {
    onFileTypeChange(e.target.value, { setDescription, setHideDescription })
    setFileType(e.target.value)
  }

  const submitUpload = async () => {
    const formData = {
      file,
      type: fileType,
      description,
      source: 'other',
    }

    if (security) formData.security = security

    if (!mutation) {
      mutation = UPLOAD_FILE
    }

    const config = {
      saveFileOnly,
      client,
      mutation,
      variables,
      successFn: uuid => {
        onComplete && onComplete(uuid)
        openSuccessSnackbar()
        openModal(false)
      },
      refetchQueries,
      errorFn: openErrorSnackbar,
    }

    await uploadFile(token, formData, config)
  }

  return (
    <React.Fragment>
      {paperclip ? (
        <IconButton onClick={() => openModal(true)}>
          <AttachFileIcon />
        </IconButton>
      ) : customButton ? (
        customButton({ onClick: () => openModal(true) })
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => openModal(true)}
          {...buttonProps}
        >
          {buttonText || 'Upload'}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => openModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Upload File</DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <input {...getInputProps()} />
          <div className={classes.browseButtonContainer}>
            <Button
              {...getRootProps({
                className: classes.browseButton,
                variant: 'outlined',
              })}
            >
              Browse
            </Button>
            {file && (
              <Typography variant="caption" color="textSecondary">
                {file.name}
              </Typography>
            )}
          </div>
          <TextField
            label="File Type"
            value={fileType}
            onChange={e => onClickFileTypeChange(e)}
            variant="outlined"
            margin="normal"
            fullWidth
            select
            className={classes.selectTextField}
          >
            {fileTypes.map(type => (
              <MenuItem key={type} value={type}>
                {snakeToTitleCase(type)}
              </MenuItem>
            ))}
          </TextField>
          {/*
          <TextField
            label="Saved Filename"
            value={savedFilename}
            onChange={e => setSavedFilename(e.target.value)}
            variant="outlined"
            disabled={!file}
            margin="normal"
            fullWidth
          />
          */}
          {hideDescription ? null : (
            <TextField
              label="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              variant="outlined"
              disabled={!file}
              margin="normal"
              fullWidth
              rows={3}
              helperText="*All files and file descriptions are visible to buyers"
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => openModal(false)}>Cancel</Button>
          <Button
            disabled={
              !fileType.length ||
              !savedFilename.length ||
              !description.length ||
              !file
            }
            variant="contained"
            color="primary"
            onClick={submitUpload}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarSuccess}
        autoHideDuration={2000}
        onClose={closeSuccessSnackbar}
        message="Saved File Successfully"
      />
      <Snackbar
        open={!!snackbarError}
        autoHideDuration={2000}
        onClose={closeErrorSnackbar}
        message={snackbarError}
      />
    </React.Fragment>
  )
}

UploadFile.propTypes = {
  button: PropTypes.node,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
  classes: PropTypes.object,
  client: PropTypes.object,
  customButton: PropTypes.func,
  fileTypes: PropTypes.arrayOf(
    PropTypes.oneOf([
      'proof_of_delivery',
      'product_image',
      'product_materials',
      'creative_proof',
      'company_logo',
      'creative',
      'unsigned_contract',
      'signed_contract',
      'other',
    ])
  ),
  mutation: PropTypes.object,
  onComplete: PropTypes.func,
  paperclip: PropTypes.bool,
  saveFileOnly: PropTypes.bool,
  security: PropTypes.string,
  token: PropTypes.string,
  variables: PropTypes.object,
  onFileTypeChange: PropTypes.func,
}

const mapStateToProps = state => ({
  token: state.tokens.token,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withApollo
)(UploadFile)
