import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import {
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { compose } from 'recompose'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useLastLocation } from 'react-router-last-location'

import { oClient } from '../../../util/apollo/client'
import { LOGIN_USER, GET_USER_INFO } from '../../../util/apollo/queries/user'
import { GET_ALL_CAMPAIGNS } from '../../../util/apollo/queries/campaigns'
import * as actions from '../../../util/redux/actions'
import { buyerHomePath, moderatorHomePath } from '../constants'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: 400,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  submitButton: {
    letterSpacing: 0.5,
    fontWeight: 700,
    margin: `${theme.spacing(2)}px 0`,
    width: '100%',
  },
  submitText: {
    color: theme.palette.primary.contrastText,
  },
  textField: {
    width: '100%',
  },
})

const LoginForm = ({
  classes,
  client,
  history,
  location: { state: { preventLastLocation } = {} },
  setCampaigns,
  setTokens,
  setUserInfo,
}) => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })

  // Grab last attempted location if exists to redirect on login
  const lastLocation = useLastLocation()
  let lastLocationPath = null
  if (lastLocation) lastLocationPath = lastLocation.pathname

  const getUserInfo = () =>
    client
      .query({
        query: GET_USER_INFO,
      })
      .then(({ data: { currentUser } }) => setUserInfo(currentUser))

  const validPathForUser = (userType, path) =>
    (path.includes('customer') && userType === 'buyer') ||
    (path.includes('moderator') && userType === 'moderator')

  const getCampaigns = () =>
    client
      .query({
        query: GET_ALL_CAMPAIGNS,
      })
      .then(({ data: { allCampaigns: { edges } } }) => {
        const campaigns = edges.map(({ node }) => node)
        return setCampaigns(campaigns)
      })

  const generateLoginPath = (userType, lastLocationPath) => {
    if (
      lastLocationPath &&
      validPathForUser(userType, lastLocationPath) &&
      !preventLastLocation
    ) {
      return lastLocationPath
    } else {
      if (userType === 'buyer') return buyerHomePath
      if (userType === 'moderator') return moderatorHomePath
    }
    return '/auth/login'
  }

  const userSpecificRequests = ({ type }) =>
    new Promise(resolve => {
      switch (type) {
        case 'buyer':
          getCampaigns().then(campaigns => {
            !campaigns.length
              ? resolve('New buyer with no campaigns')
              : resolve()
          })
          break
        case 'moderator':
          resolve()
          break
        default:
          resolve()
      }
    })

  const loginUser = e => {
    e.preventDefault()

    oClient
      .mutate({
        mutation: LOGIN_USER,
        variables: credentials,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data) {
          const { tokenAuth } = data
          const { userType } = tokenAuth

          setTokens(tokenAuth)
            .then(() => getUserInfo())
            .then(user => userSpecificRequests(user))
            .then(specialLogin => {
              if (specialLogin) {
                switch (specialLogin) {
                  case 'New buyer with no campaigns':
                    history.push({
                      pathname: '/customer/undefined/settings/clients',
                    })
                    break
                  default:
                    break
                }
              } else {
                history.push({
                  pathname: generateLoginPath(userType, lastLocationPath),
                })
              }
            })
        }
      })
      .catch(() => null)
  }

  const handleInputs = e => {
    const { name, value } = e.target
    setCredentials({ ...credentials, [name]: value })
  }

  return (
    <Paper className={classes.root}>
      <form onSubmit={loginUser}>
        <div className={classes.header}>
          <Typography variant="h5">Login</Typography>
        </div>
        <TextField
          label="username"
          type="text"
          name="username"
          margin="normal"
          variant="outlined"
          required
          className={classes.textField}
          value={credentials.username}
          onChange={handleInputs}
        />
        <TextField
          label="password"
          type="password"
          name="password"
          margin="normal"
          variant="outlined"
          required
          className={classes.textField}
          value={credentials.password}
          onChange={handleInputs}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          <Typography variant="subtitle1" className={classes.submitText}>
            Login
          </Typography>{' '}
          <ChevronRightIcon />
        </Button>

        <Divider light />
        <div className={classes.footer}>
          <Link to="/auth/register/">
            <Button>Create Account</Button>
          </Link>
        </div>
      </form>
    </Paper>
  )
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  setCampaigns: PropTypes.func,
  setTokens: PropTypes.func,
  setUserInfo: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  setTokens: tokens =>
    new Promise(resolve => {
      dispatch(actions.tokens.setTokens(tokens))
      resolve()
    }),
  setUserInfo: info =>
    new Promise(resolve => {
      dispatch(actions.userInfo.setUserInfo(info))
      resolve(info)
    }),
  setCampaigns: campaigns =>
    new Promise(resolve => {
      dispatch(actions.campaigns.setCampaigns(campaigns))
      resolve(campaigns)
    }),
})

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withApollo,
  withRouter,
  withStyles(styles)
)(LoginForm)
