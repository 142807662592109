import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  withStyles,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import moment from 'moment'

import { CHART_QUERY } from '../../../../util/apollo/queries/data'
import {
  removeEdgesAndNodes,
  formatLineChartData,
  formatCategoryChartData,
} from '../../../../shared/helperfunctions'

import DateRangePicker from '../DateRangePicker'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
})

const PricePerImpression = ({
  classes,
  match: {
    params: { campaignuuid },
  },
}) => {
  const startDateHook = useState(moment().subtract(1, 'months'))
  const endDateHook = useState(moment())

  const [startDate] = startDateHook
  const [endDate] = endDateHook

  const options = ['By Date', 'By Media', 'By Contract']
  const [anchor, setAnchor] = useState(null)
  const [menuIndex, setMenuIndex] = useState(0)
  const [tableType, setTableType] = useState('price_per_impression_by_date')
  const { loading, error, data } = useQuery(CHART_QUERY, {
    variables: {
      startDate,
      endDate,
      campaignuuid,
      qType: tableType,
    },
  })

  const clickOnItem = (_e, i) => {
    setMenuIndex(i)
    if (i == 0) {
      setTableType('price_per_impression_by_date')
    }
    if (i == 1) {
      setTableType('price_per_impression_by_media_type')
    }
    if (i == 2) {
      setTableType('price_per_impression_by_contract')
    }
    setAnchor(null)
  }

  const getCell = () => {
    switch (menuIndex) {
      case 0:
        return 'Date'
      case 1:
        return 'Media'
      case 2:
        return 'Contract'
    }
  }
  if (error || loading) return null
  const cleanedData = removeEdgesAndNodes(data.summaryStatistics)
  const chartData =
    tableType == 'price_per_impression_by_date'
      ? formatLineChartData(cleanedData.edges[0].results)
      : formatCategoryChartData(cleanedData.edges[0].results)
  const { lines } = chartData
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" color="textSecondary">
          How much are you spending on your impressions?
        </Typography>
        <DateRangePicker
          startDateHook={startDateHook}
          endDateHook={endDateHook}
        />
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h6">Price Per Impression</Typography>
          <div className={classes.fixVertAlign}>
            <Button
              onClick={e => setAnchor(e.currentTarget)}
              className={classes.button}
              variant="outlined"
            >
              <Typography variant="caption">{options[menuIndex]}</Typography>
              <ArrowDropDownIcon />
            </Button>
          </div>
        </div>
        <Menu
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          {options.map((option, i) => (
            <MenuItem
              key={option}
              selected={i === menuIndex}
              onClick={e => clickOnItem(e, i)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{getCell()}</TableCell>
              <TableCell>Impressions</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>CPM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((row, index) => (
              <TableRow key={index} hover className={classes.tableRow}>
                <TableCell id="table-row-element">{row.x}</TableCell>
                <TableCell id="table-row-element">{row.Impressions}</TableCell>
                <TableCell id="table-row-element">${row.Cost}</TableCell>
                <TableCell id="table-row-element">${row.Cpm}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

PricePerImpression.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default compose(
  withStyles(styles),
  withRouter
)(PricePerImpression)
