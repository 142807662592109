import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Query, withApollo } from 'react-apollo'
import { DateTime } from 'luxon'
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core/'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import BellIcon from '@material-ui/icons/NotificationImportant'

import { headerHeight } from '../..'
import { UPDATE_NOTIFICATION_STATUS } from '../../../../util/apollo/queries/notifications'
import { GET_ALERTS } from '../../../../util/apollo/queries/alertactivities'

const customerStyles = theme => ({
  button: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    fontSize: 12,
    marginLeft: theme.spacing(4),
    width: '100%',
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  notification: {
    border: 'solid 1px #e0e0e0',
    margin: theme.spacing(4),
  },
  notificationAlert: {
    alignItems: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  notificationHeader: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  unreadNotification: {
    background: '#f3fdff',
  },
})

const drawerStyles = () => ({
  content: {
    paddingTop: headerHeight,
    width: 400,
  },
  drawer: {
    zIndex: 50,
  },
})

const Notifications = ({ classes, userType }) => {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(prev => !prev)

  if (userType === 'buyer')
    return (
      <>
        <IconButton onClick={toggleDrawer}>
          <BellIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
          className={classes.drawer}
        >
          <div className={classes.content}>
            <CustomerNotifications setOpen={setOpen} />
          </div>
        </Drawer>
      </>
    )

  return null
}

const mapStateToCustomerProps = state => ({
  campaigns: state.campaigns,
  userType: state.userInfo.type,
})

const CustomerNotifications = compose(
  withStyles(customerStyles),
  connect(mapStateToCustomerProps),
  withRouter,
  withApollo
)(({ campaigns, client, match, history, classes, setOpen }) => {
  const [anchor, setAnchor] = useState(null)
  const [menuSelection, setMenuSelection] = useState(null)

  const options = ['All Projects', 'Archived Projects']

  const clickOnItem = (_e, option) => {
    setMenuSelection(option)
    setAnchor(null)
  }

  const goToNotification = notification => () => {
    client.mutate({
      mutation: UPDATE_NOTIFICATION_STATUS,
      variables: {
        alertActivityUuid: notification.uuid,
        status: 'read',
      },
    })

    if (notification.alertInformation.message === 'New message') {
      history.push(`/${match.url.split('/')[1]}/messages`)
    } else {
      history.push(
        `/${match.url.split('/')[1]}/contracts/detail/${
          notification.alertInformation.contract.uuid
        }`
      )
    }
    setOpen(false)
  }

  const renderAlerts = edges => {
    if (menuSelection) {
      let clients = edges.reduce((acc, edge) => {
        const notification = {
          message: edge.alertInformation.message,
          timestamp: DateTime.fromISO(edge.createdDate).toLocaleString(
            DateTime.DATE_SHORT
          ),
          status: edge.status,
          uuid: edge.alertInformation.uuid,
          read: edge.read,
          alertInformation: edge.alertInformation,
        }
        if (acc[edge.alertInformation.campaign.client.name]) {
          acc[edge.alertInformation.campaign.client.name].notifications.push(
            notification
          )
        } else {
          acc[edge.alertInformation.campaign.client.name] = {
            uuid: edge.uuid,
            notifications: [notification],
          }
        }
        return acc
      }, {})

      if (menuSelection === 'Archived Projects') {
        Object.keys(clients).forEach(client => {
          clients[client].notifications = clients[client].notifications.filter(
            notification => notification.status === 'ARCHIVE'
          )
        })
        Object.keys(clients).forEach(client => {
          if (!clients[client].notifications.length) delete clients[client]
        })
      }

      if (
        menuSelection !== 'Archived Projects' &&
        menuSelection !== 'All Projects'
      ) {
        clients = {
          [menuSelection]: clients[menuSelection],
        }
      }

      return Object.keys(clients).map(client => {
        if (clients[client] && clients[client].notifications.length)
          return (
            <div className={classes.notification} key={clients[client].uuid}>
              <div className={classes.notificationHeader}>
                <Typography variant="caption" color="textSecondary">
                  {client}
                </Typography>
              </div>
              <Divider />
              <div className={classes.notificationContent}>
                {clients[client].notifications.map((notification, i) => (
                  <React.Fragment key={notification.uuid}>
                    <div
                      className={
                        notification.read
                          ? classes.notificationAlert
                          : `${classes.notificationAlert} ${
                              classes.unreadNotification
                            }`
                      }
                      onClick={goToNotification(notification)}
                    >
                      <Typography>{notification.message}</Typography>
                      <Typography>{notification.timestamp}</Typography>
                    </div>
                    {i !== clients[client].notifications.length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )
      })
    }
    return null
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">Notifications</Typography>
        <Button
          onClick={e => setAnchor(e.currentTarget)}
          className={classes.button}
          variant="outlined"
        >
          <Typography variant="subtitle1">
            {menuSelection || 'Select'}
          </Typography>
          <ArrowDropDownIcon />
        </Button>
        <Menu
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          {options.map(option => (
            <MenuItem
              key={option}
              selected={option === menuSelection}
              onClick={e => clickOnItem(e, option)}
            >
              {option}
            </MenuItem>
          ))}
          <Divider />
          {[...new Set(campaigns.map(campaign => campaign.client.name))].map(
            name => (
              <MenuItem
                key={'clientName' + name}
                selected={name === menuSelection}
                onClick={e => clickOnItem(e, name)}
              >
                {name}
              </MenuItem>
            )
          )}
        </Menu>
      </div>
      <Divider />
      <Query query={GET_ALERTS}>
        {({ loading, error, data }) => {
          if (loading) return null
          if (error) {
            alert(error)
            return null
          }
          if (data) {
            return (
              <div className={classes.content}>
                {renderAlerts(
                  data.allAlertactivities.edges.map(edge => edge.node)
                )}
              </div>
            )
          }
        }}
      </Query>
    </>
  )
})

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
}

CustomerNotifications.propTypes = {
  classes: PropTypes.object,
  campaigns: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  setOpen: PropTypes.func,
}

export default withStyles(drawerStyles)(Notifications)
