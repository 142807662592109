import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'

const Component = ({
  history,
  match: {
    params: { contractuuid },
  },
}) => {
  const editHandler = () => {
    history.push(`/moderator/contracts/overview/edit/${contractuuid}`)
  }

  return (
    <Tooltip title="Edit">
      <IconButton onClick={editHandler}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}

Component.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(Component)
