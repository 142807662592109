import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core'
import { useMutation, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  CREATE_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN_LIST,
} from '../../../../../util/apollo/queries/campaigns'
import { GET_CLIENT_DETAILS } from '../../../../../util/apollo/queries/clients'
import * as actions from '../../../../../util/redux/actions'
import { withNotifications } from '../../../../../util'

import SelectCampaignTooltip from './SelectCampaignTooltip'

const styles = () => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const CreateButton = ({
  campaigns,
  classes,
  client,
  history,
  match: {
    params: { clientuuid },
    url,
  },
  notify,
  setCampaigns,
}) => {
  const [open, setOpen] = useState(false)
  const updateOpen = state => () => setOpen(state)

  const [showTooltip, setShowTooltip] = useState(false)

  const [name, setName] = useState('')
  const handleChange = ({ target: { value } }) => setName(value)

  const [clientName, setClientName] = useState('')
  const [fetched, setFetched] = useState(false)
  if (!fetched) {
    client
      .query({ query: GET_CLIENT_DETAILS, variables: { clientuuid } })
      .then(({ data: { allClients } }) => {
        setFetched(true)
        setClientName(allClients.edges[0].node.name)
      })
  }

  const updateCampaigns = () =>
    client
      .query({
        query: GET_ALL_CAMPAIGNS,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { allCampaigns: { edges } } }) => {
        const campaigns = edges.map(({ node }) => node)
        return setCampaigns(campaigns)
      })

  const [mutation] = useMutation(CREATE_CAMPAIGN)
  const createCampaign = () => {
    mutation({
      variables: { clientuuid, name },
      refetchQueries: [
        {
          query: GET_CAMPAIGN_LIST,
          variables: { clientuuid },
        },
      ],
    })
      .then(({ data }) => {
        if (data.createCampaign.ok) {
          const usersFirstCampaign = !campaigns.length

          let newUrl = url.split('/')
          newUrl[2] = data.createCampaign.campaign.uuid
          newUrl = newUrl.join('/')
          history.push(newUrl)

          updateCampaigns().then(() => {
            setOpen(false)

            if (usersFirstCampaign) setShowTooltip(true)
            else {
              notify.success('Created')
            }
          })
        }
      })
      .catch(() => null)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={updateOpen(true)}>
        Create
      </Button>
      <Dialog open={open} onClose={updateOpen(false)}>
        <DialogTitle>Creating New Campaign</DialogTitle>
        <DialogContent>
          <TextField
            label="Client Name"
            variant="outlined"
            margin="normal"
            value={clientName}
            onChange={handleChange}
            fullWidth
            disabled
          />
          <TextField
            label="Campaign Name"
            variant="outlined"
            margin="normal"
            value={name}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={updateOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={createCampaign}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <SelectCampaignTooltip open={showTooltip} setOpen={setShowTooltip} />
    </>
  )
}

const mapStateToProps = state => ({
  campaigns: state.campaigns,
})

const mapDispatchToProps = dispatch => ({
  setCampaigns: campaigns =>
    new Promise(resolve => {
      dispatch(actions.campaigns.setCampaigns(campaigns))
      resolve(campaigns)
    }),
})

CreateButton.propTypes = {
  campaigns: PropTypes.array,
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  setCampaigns: PropTypes.func,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withApollo,
  withNotifications,
  withRouter
)(CreateButton)
