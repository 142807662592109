import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  withStyles,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { GET_CONTRACTNOTES } from '../../../../../../../util/apollo/queries/contracts'

const styles = () => ({
  textField: {
    width: '100%',
  },
})

const EDIT_NOTE = gql`
  mutation(
    $noteUUID: String!
    $description: String!
    $reminderDate: DateTime!
    $status: String!
  ) {
    updateContractnote(
      input: {
        uuid: $noteUUID
        description: $description
        reminderDate: $reminderDate
        status: $status
      }
    ) {
      ok
    }
  }
`

const EditContractNote = ({ classes, closeParentMenu, note, contract }) => {
  const [open, openModal] = useState(false)
  const [description, setDescription] = useState(note.description)

  const closeHandler = () => {
    openModal(false)
    closeParentMenu()
  }

  const openHandler = () => {
    openModal(true)
  }

  const submitHandler = (e, mutation) => {
    e.preventDefault()
    const variables = {
      noteUUID: note.uuid,
      description,
      reminderDate: new Date(),
      status: note.status.toLowerCase(),
    }

    mutation({ variables })
    closeHandler()
  }

  return (
    <React.Fragment>
      <MenuItem onClick={openHandler}>Edit</MenuItem>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle>Edit Note</DialogTitle>
        <Divider />
        <Mutation
          mutation={EDIT_NOTE}
          refetchQueries={[
            {
              query: GET_CONTRACTNOTES,
              variables: {
                contract: contract,
              },
            },
          ]}
        >
          {mutation => (
            <form onSubmit={e => submitHandler(e, mutation)}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={e => setDescription(e.target.value)}
                      placeholder="Add Note"
                      value={description}
                      multiline
                      rows="4"
                      label="Note"
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={closeHandler} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Edit
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
    </React.Fragment>
  )
}

EditContractNote.propTypes = {
  classes: PropTypes.object,
  closeParentMenu: PropTypes.func,
  note: PropTypes.object,
  contract: PropTypes.string,
}

export default withStyles(styles)(EditContractNote)
