import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { Mutation } from 'react-apollo'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { withRouter } from 'react-router-dom'

import { DELETE_PRODUCT } from '../../../../../../../util/apollo/queries/products'

const EditDeleteMenu = ({ uuid, refetchQueries, history }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const deleteHandleClose = (event, deleteItem) => {
    handleClose(event)
    deleteItem({
      variables: {
        uuid: uuid,
      },
    })
  }

  const editHandleClose = event => {
    handleClose(event)
    history.push(`product/${uuid}`)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = event => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  return (
    <div>
      <Mutation mutation={DELETE_PRODUCT} refetchQueries={refetchQueries}>
        {(deleteItem, { error }) => {
          if (error) alert(error)
          return (
            <>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                <MenuItem onClick={editHandleClose}>Edit</MenuItem>
                <MenuItem
                  onClick={event => {
                    deleteHandleClose(event, deleteItem)
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        }}
      </Mutation>
    </div>
  )
}

EditDeleteMenu.propTypes = {
  uuid: PropTypes.string,
  companyUUID: PropTypes.string,
  refetchQueries: PropTypes.array,
  history: PropTypes.object,
}

export default withRouter(EditDeleteMenu)
