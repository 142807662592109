import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Typography, TextField, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
  },
  sectionTitle: {
    fontWeight: 700,
  },
})

const Component = ({ classes, setFilters }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const updateStartDate = ({ target: { value } }) => setStartDate(value)
  const updateEndDate = ({ target: { value } }) => setEndDate(value)

  useEffect(() => {
    if (startDate.length) {
      setFilters(prev => ({
        ...prev,
        startDate: new Date(Date.UTC(startDate)),
      }))
    } else {
      setFilters(prev => {
        delete prev.startDate
        return { ...prev }
      })
    }
  }, [startDate, setFilters])

  useEffect(() => {
    if (endDate.length) {
      setFilters(prev => ({
        ...prev,
        endDate: new Date(Date.UTC(endDate)),
      }))
    } else {
      setFilters(prev => {
        delete prev.endDate
        return { ...prev }
      })
    }
  }, [endDate, setFilters])

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.sectionTitle}>
        Start Date
      </Typography>
      <Typography gutterBottom className={classes.sectionTitle}>
        End Date
      </Typography>
      <TextField type="date" value={startDate} onChange={updateStartDate} />
      <TextField type="date" value={endDate} onChange={updateEndDate} />
    </div>
  )
}

Component.propTypes = {
  classes: PropTypes.object,
  setFilters: PropTypes.func,
}

export default withStyles(styles)(Component)
