import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles, InputAdornment, InputBase } from '@material-ui/core'
import { PropTypes } from 'prop-types'

const styles = theme => ({
  input: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#fff',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    borderRadius: 3,
    border: 'solid 1px #eee',
  },
})

const SearchBar = ({ classes }) => {
  return (
    <InputBase
      placeholder="Search"
      className={classes.input}
      endAdornment={
        <InputAdornment>
          <SearchIcon color="action" />
        </InputAdornment>
      }
    />
  )
}

SearchBar.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SearchBar)
