import gql from 'graphql-tag'

const UPDATE_NOTIFICATION_STATUS = gql`
  mutation($alertActivityUuid: String, $status: String) {
    updateAlertactivity(input: { uuid: $alertActivityUuid, status: $status }) {
      ok
    }
  }
`

export { UPDATE_NOTIFICATION_STATUS }
