import React from 'react'
import PropTypes from 'prop-types'

import { Mutation } from 'react-apollo'
import { Button, withStyles } from '@material-ui/core'

import { titleCase } from '../../../../../../shared/helperfunctions'
import { SET_ACCOUNT_STATUS } from '../../../../../../util/apollo/queries/companies'

const styles = () => ({})

const ActivationButtion = ({ classes, uuid, refetchQueries, status }) => {
  const activationHandler = mutation => {
    const variables = {
      companyUUID: uuid,
      status: getOppositeStatus(),
    }
    mutation({ variables })
  }

  const getOppositeStatus = () => {
    if (status == 'active') return 'inactive'
    if (status == 'inactive') return 'active'
    return 'Error'
  }

  return (
    <Mutation mutation={SET_ACCOUNT_STATUS} refetchQueries={refetchQueries}>
      {mutation => (
        <>
          {status && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => activationHandler(mutation)}
            >
              Mark as {titleCase(getOppositeStatus())}
            </Button>
          )}
        </>
      )}
    </Mutation>
  )
}

ActivationButtion.propTypes = {
  classes: PropTypes.object,
  uuid: PropTypes.string,
  status: PropTypes.string,
  refetchQueries: PropTypes.array,
}

export default withStyles(styles)(ActivationButtion)
